import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Card, Line } from 'ktm-ui';

const DistroAccountDetailsModal = ({ selectedItem, showModal, onHide }) => {
  if (!selectedItem) return null;

  return (
    <Modal 
          show={showModal} 
          onHide={onHide}
          className="Account-Details-Modal"
        >
          <Modal.Header>
            <Modal.Title style={{fontSize: "17px", fontWeight: 500 }}>Distro Account Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem && (
              <Card>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Company Name</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.name == null ? "N/A" : selectedItem.name}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Health Claim Account ID</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.healthClaimAccountId == null ? "N/A" : selectedItem.healthClaimAccountId}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Health Claim Distro ID</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.healthClaimDistroId == null ? "N/A" : selectedItem.healthClaimDistroId}
                      </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Health Claim Email 1</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.healthClaimEmail1 == null ? "N/A" : selectedItem.healthClaimEmail1}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Health Claim Email 2</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.healthClaimEmail2 == null ? "N/A" : selectedItem.healthClaimEmail2}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Street 1</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingStreet1 == null ? "N/A" : selectedItem.mailingStreet1}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Street 2</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingStreet2 == null ? "N/A" : selectedItem.mailingStreet2}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing City</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingCity == null ? "N/A" : selectedItem.mailingCity}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Province</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingProv == null ? "N/A" : selectedItem.mailingProv}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Country</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingCountry == null ? "N/A" : selectedItem.mailingCountry}
                        </div>
                    </div>
                </Line>
                <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Mailing Postal Code</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.mailingPostalCode == null ? "N/A" : selectedItem.mailingPostalCode}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Phone</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.phone == null ? "N/A" : selectedItem.phone}
                        </div>
                    </div>
                </Line>
                <Line>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Contact Person</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.contactPerson == null ? "N/A" : selectedItem.contactPerson}
                      </div>
                  </div>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Contact Email</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.contactEmail == null ? "N/A" : selectedItem.contactEmail}
                      </div>
                  </div>
                  </Line>
                  <Line>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Type</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.type == null ? "N/A" : selectedItem.type}
                        </div>
                    </div>
                    <div className="RT-Static-Field">
                        <label className="RT-Static-Field__label">Bank Transit No</label>
                        <div className="RT-Static-Field__content">
                            {selectedItem.bankTransitNo == null ? "N/A" : selectedItem.bankTransitNo}
                        </div>
                    </div>
                  </Line>
                  <Line>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Bank No</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.bankNo == null ? "N/A" : selectedItem.bankNo}
                      </div>
                  </div>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Group Ins Comp Type</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.groupInsCompType == null ? "N/A" : selectedItem.groupInsCompType}
                      </div>
                  </div>
                </Line>
                <Line>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Allow HSA</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.allowHSA ? "Yes" : "No"}
                      </div>
                  </div>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Allow LSA</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.allowLSA ? "Yes" : "No"}
                      </div>
                  </div>
                  </Line>
                  <Line>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Allow Group Ins</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.allowGroupIns ? "Yes" : "No"}
                      </div>
                  </div>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Allow Diag Img</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.allowDiagImg ? "Yes" : "No"}
                      </div>
                  </div>
                   </Line>
                   <Line>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Bank Account No</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.bankAccountNo == null ? "N/A" : selectedItem.bankAccountNo}
                      </div>
                  </div>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Account No</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.accountNo == null ? "N/A" : selectedItem.accountNo}
                      </div>
                  </div>
                </Line>
                <Line>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">Is Active</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.isActive  ? "Yes" : "No"}
                      </div>
                  </div>
                  <div className="RT-Static-Field">
                      <label className="RT-Static-Field__label">User ID</label>
                      <div className="RT-Static-Field__content">
                          {selectedItem.user_Id == null ? "N/A" : selectedItem.user_Id}
                      </div>
                  </div>
                </Line>
              </Card>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button 
              variant="secondary" 
              onClick={onHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
  );
};

export default DistroAccountDetailsModal;
