import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  RecordHeader,
  TabSet,
  Tab,
  Display,
  Column,
  Panel,
  Button,
  Card,
  Line,
} from "ktm-ui";
import Expense_typesScreen from "./Expense_types";
import Edit_hsa_limitsScreen from "./Edit_hsa_limits";
import Edit_hsa_settingsScreen from "./Edit_hsa_settings";
import Edit_lsa_limitsScreen from "./Edit_lsa_limits";
import Edit_lsa_settingsScreen from "./Edit_lsa_settings";
import Edit_flex_limitsScreen from "./Edit_flex_limits";
import Edit_flex_settingsScreen from "./Edit_flex_settings";
import Remove_hsaScreen from "./Remove_hsa";
import Remove_flexScreen from "./Remove_flex";
import Remove_lsaScreen from "./Remove_lsa";
import Add_lsaScreen from "./Add_lsa";
import Add_hsaScreen from "./Add_hsa";
import Add_flexScreen from "./Add_flex";
import Change_credit_calcScreen from "./Change_credit_calc";
import CustomToolTip from "../../components/CustomToolTip";
import CustomCategoriesScreen from "./CustomCategoriesScreen";
import axios from "axios";

interface IdsBenCat {
  Id: string;
  Policy_Id: string;
  FlexSingleLimit: number;
  FlexCoupleLimit: number;
  FlexFamilyLimit: number;
  Benefit: string;
  HSASingleLimit: number;
  HSACoupleLimit: number;
  HSAFamilyLimit: number;
  HSASinglePercent: number;
  HSACouplePercent: number;
  HSAFamilyPercent: number;
  CreditCalcMethod: string;
  HSALabel: string;
  HasProrating: boolean;
  LSASingleLimit: number;
  LSACoupleLimit: number;
  LSAFamilyLimit: number;
  LSASinglePercent: number;
  LSACouplePercent: number;
  LSAFamilyPercent: number;
  LSALabel: string;
  AllowLSADependentClaims: boolean;
  FlexSinglePercent: number;
  FlexCouplePercent: number;
  FlexFamilyPercent: number;
  AllowRSPOption: boolean;
  AllowStockOption: boolean;
  HasHSA: boolean;
  HasLSA: boolean;
  RSPLabel: string;
  Stocklabel: string;
  DefaultRspAllocation: number;
  DefaultStockAllocation: number;
  DefaultHSAAllocation: number;
  DefaultLSAAllocation: number;
  IsFlex: boolean;
  IsNonParticipating: boolean;
  BenfitCategory: string;
  BenefitCaegoryId: string;
  PolicyHasHsa: boolean;
  PolicyHasLsa: boolean;
  PolicyHasFlex: boolean;
  HSACap: number;
  LSACap: number;
  RSPCap: number;
  StockCap: number;
}

interface IdsPrevBenCat {
  Id: string;
  Policy_Id: string;
  PrevYearFlexSingleLimit: number;
  PrevYearFlexCoupleLimit: number;
  PrevYearFlexFamilyLimit: number;
  PrevYearHSASingleLimit: number;
  PrevYearHSACoupleLimit: number;
  PrevYearHSAFamilyLimit: number;
  PrevYearHSASinglePercent: number;
  PrevYearHSACouplePercent: number;
  PrevYearHSAFamilyPercent: number;
  PrevYearCreditCalcMethod: string;
  PrevYearHSALabel: string;
  PrevYearHasProrating: boolean;
  PrevYearLSASingleLimit: number;
  PrevYearLSACoupleLimit: number;
  PrevYearLSAFamilyLimit: number;
  PrevYearLSASinglePercent: number;
  PrevYearLSACouplePercent: number;
  PrevYearLSAFamilyPercent: number;
  PrevYearLSALabel: string;
  PrevYearAllowLSADependentClaims: boolean;
  PrevYearFlexSinglePercent: number;
  PrevYearFlexCouplePercent: number;
  PrevYearFlexFamilyPercent: number;
  PrevYearAllowRSPOption: boolean;
  PrevYearAllowStockOption: boolean;
  PrevYearHasHSA: boolean;
  PrevYearHasLSA: boolean;
  PrevYearRSPLabel: string;
  PrevYearStocklabel: string;
  PrevYearDefaultRspAllocation: number;
  PrevYearDefaultStockAllocation: number;
  PrevYearDefaultHSAAllocation: number;
  PrevYearDefaultLSAAllocation: number;
  PrevYearIsFlex: boolean;
  PrevYearIsNonParticipating: boolean;
  Status: string;
  PrevHSACap: number;
  PrevLSACap: number;
  PrevRSPCap: number;
  PrevStockCap: number;
}

export default KTMScreen(
  { name: "enroll.ben-limit.record" },
  class extends React.Component<{ policy_id: string; limit_id: string },any> {
    data: {
      dsBenCat: IdsBenCat;
      dsPrevBenCat: IdsPrevBenCat;
    };

    constructor(props) {
      super(props);
      this.state = {
          employeesCount: 0,
      };
    }

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenCat";
      await KTMApi.start(screen, {
        "@policy_id": this.props.policy_id,
        "@limit_id": this.props.limit_id,
      });
      screen.title = this.data.dsBenCat.Benefit;
      await screen.triggerAction(this.rule_calc_method);
      await screen.triggerAction(this.rule_convert_tools);
      await screen.triggerAction(this.rule_add_toolbar);
      await screen.triggerAction(this.rule_account_sections);
      await screen.triggerAction(this.rule_previous_year_calc_method);
      await screen.triggerAction(this.rule_previous_year_account_sections);
      await this.getNumberofEmployees();
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.tabs)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return (
        <RecordHeader entityLabel="Benefit" label={this.data.dsBenCat.Benefit}>
          <Display field={field("BenfitCategory")} />
        </RecordHeader>
      );
    }

    tabs({ field, draw, action }: RenderEvent) {
      return (
        <TabSet>
          <Tab label="Details" template={this.main_tab} />
          <Tab label="Expense Types" template={this.expense_type_list} />
          <Tab label="Additional Accounts" template={this.custom_categories} />
        </TabSet>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.current_year_benefits)}
            {this.data.dsPrevBenCat.Status == "OPEN" && draw(this.previous_year_benefits)}
            {draw(this.add_action_bar)}
          </Column>
        </Panel>
      );
    }

    current_year_benefits({ field, draw, action }: RenderEvent) {
      return (
       <>
          {draw(this.credit_calculation)}
          {/* {draw(this.convert_action_bar)} */}
          {draw(this.hsa)}
          {draw(this.lsa)}
          {draw(this.flex)}
       </>
      );
    }

    previous_year_benefits({ field, draw, action }: RenderEvent) {
      return (
        <>
          {draw(this.previous_year_credit_calculation)}
          {draw(this.previous_year_hsa)}
          {draw(this.previous_year_lsa)}
          {draw(this.previous_year_flex)}
        </>
      );
    }

    expense_type_list({ field, draw, action }: RenderEvent) {
      return (
        <Expense_typesScreen
          policy_id={this.props.policy_id}
          limit_id={this.data.dsBenCat.Id}
          category_id={this.data.dsBenCat.BenefitCaegoryId}
          showRunoff={this.data.dsPrevBenCat.Status == "OPEN"}
        />
      );
    }

    custom_categories({ field, draw, action }: RenderEvent) {
      return (
        <CustomCategoriesScreen
          policy_id={this.props.policy_id}
          limit_id={this.data.dsBenCat.Id}
          category_id={this.data.dsBenCat.BenefitCaegoryId}
          is_flex={this.data.dsBenCat.IsFlex}
        />
      );
    }

    hsa({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.hsa_limits)}</Column>
          <Column>{draw(this.hsa_settings)}</Column>
        </Panel>
      );
    }

    lsa({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.lsa_limits)}</Column>
          <Column>{draw(this.lsa_settings)}</Column>
        </Panel>
      );
    }

    flex({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.flex_limits)}</Column>
          <Column>{draw(this.flex_settings)}</Column>
        </Panel>
      );
    }

    credit_calculation({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Credit Calculation"
          actions={
            <>
              <Button
                action={action(this.change_credit_calc)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Display
            field={field("CreditCalcMethod")}
            toolTip={
              <CustomToolTip
                header="Credit Calculation Method"
                bodyContent="Choose Flat until we can offer Spending Accounts based on % of Salary."
              />
            }
          />
        </Card>
      );
    }

    hsa_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="HSA Limits"
          actions={
            <>
              <Button
                action={action(this.edit_hsa_limits)}
                label="Edit"
                style="link"
              />
              <Button
                action={action(this.remove_hsa)}
                label="Remove"
                style="link"
              />
            </>
          }
        >
          {draw(this.hsa_amount)}
          {draw(this.hsa_percent)}
        </Card>
      );
    }

    hsa_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="HSA options"
          actions={
            <>
              <Button
                action={action(this.edit_hsa_settings)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Display field={field("HSALabel")} />
        </Card>
      );
    }

    lsa_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="LSA Limits"
          actions={
            <>
              <Button
                action={action(this.edit_lsa_limits)}
                label="Edit"
                style="link"
              />
              <Button
                action={action(this.remove_lsa)}
                label="Remove"
                style="link"
              />
            </>
          }
        >
          {draw(this.lsa_amount)}
          {draw(this.lsa_percent)}
        </Card>
      );
    }

    lsa_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="LSA options"
          actions={
            <>
              <Button
                action={action(this.edit_lsa_settings)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display field={field("LSALabel")} />
            <Display
              field={field("AllowLSADependentClaims")}
              toolTip={
                <CustomToolTip
                  header="Allow LSA Dependent Claims"
                  bodyContent="This option determines if employees can reimburse LSA expenses for their dependents or if the LSA is an Employee Only benefit."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    flex_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Flex settings"
          actions={
            <>
              <Button
                action={action(this.edit_flex_settings)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display field={field("HasHSA")} />
            <Display field={field("DefaultHSAAllocation")} />
          </Line>
          <Line>
            <Display field={field("HSALabel")} />
            {this.data.dsBenCat.CreditCalcMethod === "FLAT" ? (
              <Display field={field("HSACap")} />
            ) : <></>}
          </Line>
          <Line>
            <Display field={field("HasLSA")} />
            <Display field={field("DefaultLSAAllocation")} />
          </Line>
          <Line>
            <Display field={field("LSALabel")} />
            {this.data.dsBenCat.CreditCalcMethod === "FLAT" ? (
              <Display field={field("LSACap")} />
            ) : <></>}
          </Line>
          <Line>
            <Display
                field={field("AllowLSADependentClaims")}
                toolTip={
                  <CustomToolTip
                    header="Allow LSA Dependent Claims"
                    bodyContent="This option determines if employees can reimburse LSA expenses for their dependents or if the LSA is an Employee Only benefit."
                  />
                }
            />
          </Line>
          <Line>
            <Display field={field("AllowRSPOption")} />
            <Display field={field("DefaultRspAllocation")} />
          </Line>
          <Line>
            <Display field={field("RSPLabel")} />
            {this.data.dsBenCat.CreditCalcMethod === "FLAT" ? (
              <Display field={field("RSPCap")} />
            ) : <></>}
          </Line>
          {/* <Line>
            <Display field={field("AllowStockOption")} />
            <Display field={field("DefaultStockAllocation")} />
            <Display field={field("Stocklabel")} />
          </Line> */}
        </Card>
      );
    }

    flex_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Flex Limits"
          actions={
            <>
              <Button
                action={action(this.edit_flex_limits)}
                label="Edit"
                style="link"
              />
              <Button
                action={action(this.remove_flex)}
                label="Remove"
                style="link"
              />
            </>
          }
        >
          {draw(this.flex_amount)}
          {draw(this.flex_percent)}
        </Card>
      );
    }

    hsa_amount({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display
            field={field("HSASingleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("HSACoupleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("HSAFamilyLimit")}
            size="medium"
            textStyle="bold"
          />
        </Line>
      );
    }

    hsa_percent({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("HSASinglePercent")} />
          <Display field={field("HSACouplePercent")} />
          <Display field={field("HSAFamilyPercent")} />
        </Line>
      );
    }

    lsa_amount({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display
            field={field("LSASingleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("LSACoupleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("LSAFamilyLimit")}
            size="medium"
            textStyle="bold"
          />
        </Line>
      );
    }

    lsa_percent({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("LSASinglePercent")} />
          <Display field={field("LSACouplePercent")} />
          <Display field={field("LSAFamilyPercent")} />
        </Line>
      );
    }

    flex_amount({ field, draw, action }: RenderEvent) {
      return (
        <>
          <Line>
            <Display field={field("FlexSingleLimit")} />
            <Display field={field("FlexCoupleLimit")} />
            <Display field={field("FlexFamilyLimit")} />
          </Line>
        </>
      );
    }

    flex_percent({ field, draw, action }: RenderEvent) {
      return (
        <>
          <Line>
            <Display field={field("FlexSinglePercent")} />
            <Display field={field("FlexCouplePercent")} />
            <Display field={field("FlexFamilyPercent")} />
          </Line>
        </>
      );
    }

    previous_year_hsa({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.previous_year_hsa_limits)}</Column>
          <Column>{draw(this.previous_year_hsa_settings)}</Column>
        </Panel>
      );
    }

    previous_year_lsa({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.previous_year_lsa_limits)}</Column>
          <Column>{draw(this.previous_year_lsa_settings)}</Column>
        </Panel>
      );
    }

    previous_year_flex({ field, draw, action }: RenderEvent) {
      return (
        <Panel columnSize="equalHt">
          <Column>{draw(this.previous_year_flex_limits)}</Column>
          <Column>{draw(this.previous_year_flex_settings)}</Column>
        </Panel>
      );
    }

    previous_year_credit_calculation({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Previous year credit calculation"
          background="previous_year_credit_calculation previous-year-section"
        >
          <Display
            field={field("@dsPrevBenCat.PrevYearCreditCalcMethod")}
          />
        </Card>
      );
    }

    previous_year_hsa_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Previous Year HSA Limits"
          background="previous_year_hsa_limits"
        >
          {draw(this.previous_year_hsa_amount)}
          {draw(this.previous_year_hsa_percent)}
        </Card>
      );
    }

    previous_year_hsa_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Previous year HSA options"
          background="previous_year_hsa_settings previous-year-section"
        >
          <Display field={field("HSALabel")} />
        </Card>
      );
    }

    previous_year_lsa_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Previous Year LSA Limits"
          background="previous_year_lsa_limits previous-year-section"
        >
          {draw(this.previous_year_lsa_amount)}
          {draw(this.previous_year_lsa_percent)}
        </Card>
      );
    }

    previous_year_lsa_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Previous year LSA options"
          background="previous_year_lsa_settings previous-year-section"
        >
          <Line>
            <Display field={field("@dsPrevBenCat.PrevYearLSALabel")} />
            <Display
              field={field("@dsPrevBenCat.PrevYearAllowLSADependentClaims")}
            />
          </Line>
        </Card>
      );
    }

    previous_year_flex_settings({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Previous year Flex settings"
          background="previous_year_flex_settings previous-year-section"
        >
          <Line>
            <Display field={field("@dsPrevBenCat.PrevYearHasHSA")} />
            <Display field={field("@dsPrevBenCat.PrevYearDefaultHSAAllocation")} />
            <Display field={field("@dsPrevBenCat.PrevYearHSALabel")} />
          </Line>
          <Line>
            <Display field={field("@dsPrevBenCat.PrevYearHasLSA")} />
            <Display field={field("@dsPrevBenCat.PrevYearDefaultLSAAllocation")} />
            <Display field={field("@dsPrevBenCat.PrevYearLSALabel")} />
          </Line>
          <Line>
            <Display field={field("@dsPrevBenCat.PrevYearAllowRSPOption")} />
            <Display field={field("@dsPrevBenCat.PrevYearDefaultRspAllocation")} />
            <Display field={field("@dsPrevBenCat.PrevYearRSPLabel")} />
          </Line>
        </Card>
      );
    }

    previous_year_flex_limits({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Previous Year Flex Limits"
          background="previous_year_flex_limits previous-year-section"
        >
          {draw(this.previous_year_flex_amount)}
          {draw(this.previous_year_flex_percent)}
        </Card>
      );
    }

    previous_year_hsa_amount({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display
            field={field("@dsPrevBenCat.PrevYearHSASingleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("@dsPrevBenCat.PrevYearHSACoupleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("@dsPrevBenCat.PrevYearHSAFamilyLimit")}
            size="medium"
            textStyle="bold"
          />
        </Line>
      );
    }

    previous_year_hsa_percent({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("@dsPrevBenCat.PrevYearHSASinglePercent")} />
          <Display field={field("@dsPrevBenCat.PrevYearHSACouplePercent")} />
          <Display field={field("@dsPrevBenCat.PrevYearHSAFamilyPercent")} />
        </Line>
      );
    }

    previous_year_lsa_amount({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display
            field={field("@dsPrevBenCat.PrevYearLSASingleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("@dsPrevBenCat.PrevYearLSACoupleLimit")}
            size="medium"
            textStyle="bold"
          />
          <Display
            field={field("@dsPrevBenCat.PrevYearLSAFamilyLimit")}
            size="medium"
            textStyle="bold"
          />
        </Line>
      );
    }

    previous_year_lsa_percent({ field, draw, action }: RenderEvent) {
      return (
        <Line>
          <Display field={field("@dsPrevBenCat.PrevYearLSASinglePercent")} />
          <Display field={field("@dsPrevBenCat.PrevYearLSACouplePercent")} />
          <Display field={field("@dsPrevBenCat.PrevYearLSAFamilyPercent")} />
        </Line>
      );
    }

    previous_year_flex_amount({ field, draw, action }: RenderEvent) {
      return (
        <>
          <Line>
            <Display field={field("@dsPrevBenCat.PrevYearFlexSingleLimit")} />
            <Display field={field("@dsPrevBenCat.PrevYearFlexCoupleLimit")} />
            <Display field={field("@dsPrevBenCat.PrevYearFlexFamilyLimit")} />
          </Line>
        </>
      );
    }

    previous_year_flex_percent({ field, draw, action }: RenderEvent) {
      return (
        <>
          <Line>
            <Display field={field("@dsPrevBenCat.PrevYearFlexSinglePercent")} />
            <Display field={field("@dsPrevBenCat.PrevYearFlexCouplePercent")} />
            <Display field={field("@dsPrevBenCat.PrevYearFlexFamilyPercent")} />
          </Line>
        </>
      );
    }

    add_action_bar({ field, draw, action }: RenderEvent) {
      return (
        <div className="RT-Action-Bar">
          <Button action={action(this.add_hsa)} label="Add HSA" style="add" />
          <Button action={action(this.add_lsa)} label="Add LSA" style="add" />
          <Button action={action(this.add_flex)} label="Add Flex" style="add" />
        </div>
      );
    }

    convert_action_bar({ field, draw, action }: RenderEvent) {
      return (
        <div className="RT-Action-Bar">
          <Button
            action={action(this.convert_to_flex)}
            label="Convert to flex"
            style="link"
          />
          <Button
            action={action(this.convert_to_fixed)}
            label="Convert to fixed"
            style="link"
          />
        </div>
      );
    }

    async edit_hsa_limits({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_hsa_limitsScreen 
          limit_id={this.data.dsBenCat.Id}
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_hsa_settings({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_hsa_settingsScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_lsa_limits({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_lsa_limitsScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_lsa_settings({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_lsa_settingsScreen 
          limit_id={this.data.dsBenCat.Id}
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_flex_limits({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_flex_limitsScreen 
          limit_id={this.data.dsBenCat.Id}
          employeesCount={this.state.employeesCount} 
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async edit_flex_settings({ screen }: ActionEvent) {
      await screen.openDialog(
        <Edit_flex_settingsScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async remove_hsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_hsaScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async remove_flex({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_flexScreen 
          limit_id={this.data.dsBenCat.Id} 
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async remove_lsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Remove_lsaScreen 
          limit_id={this.data.dsBenCat.Id}
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async add_lsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_lsaScreen limit_id={this.data.dsBenCat.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async add_hsa({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_hsaScreen limit_id={this.data.dsBenCat.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async add_flex({ screen }: ActionEvent) {
      await screen.openDialog(
        <Add_flexScreen limit_id={this.data.dsBenCat.Id} />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async convert_to_flex({ screen }: ActionEvent) {}

    async convert_to_fixed({ screen }: ActionEvent) {}

    async change_credit_calc({ screen }: ActionEvent) {
      await screen.openDialog(
        <Change_credit_calcScreen 
          limit_id={this.data.dsBenCat.Id}
          policy_id={this.data.dsBenCat.Policy_Id}
          employeesCount={this.state.employeesCount}
        />,
        { onRecordChanged: this.refresh_data }
      );
    }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }

    async rule_calc_method({ screen }: ActionEvent) {
      screen.hide(this.hsa_amount);
      screen.hide(this.hsa_percent);
      screen.hide(this.lsa_amount);
      screen.hide(this.lsa_percent);
      screen.hide(this.flex_amount);
      screen.hide(this.flex_percent);
      if (screen.getValue("@dsBenCat.CreditCalcMethod") == "SALARY") {
        screen.unhide(this.hsa_percent);
        screen.unhide(this.lsa_percent);
        screen.unhide(this.flex_percent);
      } else {
        screen.unhide(this.hsa_amount);
        screen.unhide(this.lsa_amount);
        screen.unhide(this.flex_amount);
      }
    }

    async rule_convert_tools({ screen }: ActionEvent) {
      screen.hide(this.convert_to_flex);
      screen.hide(this.convert_to_fixed);
      if (screen.getValue("@dsBenCat.IsFlex")) {
        screen.unhide(this.convert_to_fixed);
      } else if (
        screen.getValue("@dsBenCat.HasHSA") ||
        screen.getValue("@dsBenCat.HasLSA")
      ) {
        screen.unhide(this.convert_to_flex);
      }
    }

    async rule_add_toolbar({ screen }: ActionEvent) {
      screen.hide(this.add_lsa);
      screen.hide(this.add_hsa);
      screen.hide(this.add_flex);
      if (screen.getValue("@dsBenCat.PolicyHasFlex")) {
        screen.unhide(this.add_flex);
      }
      if(screen.getValue("@dsBenCat.PolicyHasHsa")){
        screen.unhide(this.add_hsa);
      }
      if(screen.getValue("@dsBenCat.PolicyHasLsa")){
        screen.unhide(this.add_lsa);
      }

      if (screen.getValue("@dsBenCat.IsFlex")) {
        screen.hide(this.add_hsa);
        screen.hide(this.add_lsa);
        screen.hide(this.add_flex);
      }
      if(screen.getValue("@dsBenCat.HasHSA")){
        screen.hide(this.add_hsa);
        screen.hide(this.add_flex);
      }
      if(screen.getValue("@dsBenCat.HasLSA")){
        screen.hide(this.add_lsa);
        screen.hide(this.add_flex);
      }
    }

    async rule_account_sections({ screen }: ActionEvent) {
      screen.hide(this.hsa);
      screen.hide(this.lsa);
      screen.hide(this.flex);
      if (screen.getValue("@dsBenCat.IsFlex")) {
        screen.unhide(this.flex);
      } else {
        if (screen.getValue("@dsBenCat.HasHSA")) {
          screen.unhide(this.hsa);
        }
        if (screen.getValue("@dsBenCat.HasLSA")) {
          screen.unhide(this.lsa);
        }
      }
    }

    async rule_previous_year_calc_method({ screen }: ActionEvent) {
      screen.hide(this.previous_year_hsa_amount);
      screen.hide(this.previous_year_hsa_percent);
      screen.hide(this.previous_year_lsa_amount);
      screen.hide(this.previous_year_lsa_percent);
      screen.hide(this.previous_year_flex_amount);
      screen.hide(this.previous_year_flex_percent);
      if (screen.getValue("@dsPrevBenCat.PrevYearCreditCalcMethod") == "SALARY") {
        screen.unhide(this.previous_year_hsa_percent);
        screen.unhide(this.previous_year_lsa_percent);
        screen.unhide(this.previous_year_flex_percent);
      } else {
        screen.unhide(this.previous_year_hsa_amount);
        screen.unhide(this.previous_year_lsa_amount);
        screen.unhide(this.previous_year_flex_amount);
      }
    }

    async rule_previous_year_account_sections({ screen }: ActionEvent) {
      // control the visibility of account sections
      screen.hide(this.previous_year_hsa);
      screen.hide(this.previous_year_lsa);
      screen.hide(this.previous_year_flex);
      if (screen.getValue("@dsPrevBenCat.PrevYearIsFlex")) {
        screen.unhide(this.previous_year_flex);
      } else {
        if (screen.getValue("@dsPrevBenCat.PrevYearHasHSA")) {
          screen.unhide(this.previous_year_hsa);
        }
        if (screen.getValue("@dsPrevBenCat.PrevYearHasLSA")) {
          screen.unhide(this.previous_year_lsa);
        }
      }
    }

    getNumberofEmployees = async () => {
      try {
          axios.get("/api/BenefitCategories/getnumberofemployees", {
              params: { 
                  benefitCategoryId: this.data.dsBenCat.BenefitCaegoryId 
              }
          }).then((response) => {
              if (response.data.success) {
                  this.setState({
                    employeesCount: response.data.employeesCount
                  });
              }
          });
      } catch (error) {
          console.error(error);
      }
    }
  }
);
