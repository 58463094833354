import React from 'react';
import {FieldRef} from './FieldRef';
import {ActionRef} from './ActionRef';
import { FieldType } from './types';
import { Dialog } from './Dialog';
import { FieldFormatter } from './FieldFormatter';

export interface IDisplayProps {
    size?:null | 'small' | 'medium' | 'large';
    vertAlign?:null | 'center';
    noTabStop?:boolean;
    hideLabel?:boolean;
    textStyle?: null | 'normal' | 'bold' | 'italic';
    color?:string;
    span?:string;
    marginTop?:string;
    marginBottom?:string;
    marginLeft?:string;
    marginRight?:string;
}



export interface IInputControlKeyListener {
    obj:any,
    onKeyDown:(e:React.KeyboardEvent<HTMLInputElement>) => any;
}


export interface InputControl {
    editValue:any;
    dirty:boolean;
    field:FieldRef;
    type:FieldType;
    focusElemRef:React.RefObject<HTMLInputElement>;
    handleLaunchFinder:() => void;
    findButtonRef:React.RefObject<HTMLButtonElement>;
    finderOpen:boolean;
    allowInputWithFinderOpen:boolean;
    onBlur(e:React.FocusEvent<HTMLInputElement>);
    onChange(e:React.ChangeEvent<HTMLInputElement>);
    onKeyDown(e:React.KeyboardEvent<HTMLInputElement>);
    onKeyPress(e:React.KeyboardEvent<HTMLInputElement>);
    isLookup: boolean;
    launchFinder(isAutoLaunch:boolean):Promise<any>;
    setValue(value:any);
    refresh();
    connectKeyListener(obj:any,onKeyDown:(e:React.KeyboardEvent<HTMLInputElement>) => void);
    disconnectKeyListener(obj:any);
}


export function makeInput<T>(Component:React.ComponentClass<T>):React.ComponentClass<T>{ 
    return (
class extends React.Component<{field:FieldRef,type?:FieldType,onValueChanged?:ActionRef}> implements InputControl {
    editValue:any;
    dirty:boolean;
    field:FieldRef;
    type:FieldType;
    onValueChanged:ActionRef;

    focusElemRef:React.RefObject<HTMLInputElement> = React.createRef();
    
    finderOpen:boolean;
    allowInputWithFinderOpen:boolean;

    findButtonRef:React.RefObject<HTMLButtonElement> = React.createRef();
    dropValues:any;
    keyListeners:IInputControlKeyListener[];
    handleLaunchFinder:() => void;

    constructor(props){
        super(props);
        this.field = this.props.field;

    }

    render(){
        this.field = this.props.field;
        this.type = this.props.type;
        this.onValueChanged = this.props.onValueChanged;
        if (!this.dirty && this.field){
            this.editValue = this.field.formattedValue;
        }
        let props = {...this.props,input:this} as any;
        return React.createElement(Component,props);
    }

    get isLookup():boolean {
        return this.field && (this.field.lookup != null || this.type == "date");
    }

    onBlur = (e:React.FocusEvent<HTMLInputElement>) =>{
        if ((this.finderOpen && !this.allowInputWithFinderOpen) || (e.relatedTarget && this.findButtonRef && e.relatedTarget == this.findButtonRef.current)) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (this.dirty && this.field && this.isLookup && this.editValue){
            let lookup = this.field.lookup;
            if (lookup && lookup.autoLaunch){
                this.launchFinder(true);
                return;
            }
            
        }
        if (this.finderOpen){
            let clickInsidePopup:boolean;
            if (e.relatedTarget){
                let targetNode = e.relatedTarget as HTMLElement;
                if (targetNode.closest('.RT-DatePicker')){
                    clickInsidePopup = true;
                }
            }
            if (!clickInsidePopup){
                this.finderOpen = false;
                this.refresh();
            }
        }
        this.updateValue();
    }

    onChange = (e:React.ChangeEvent<HTMLInputElement>) =>{
        let newValue = e.target.value;
        this.editValue = newValue;
        this.dirty = true;
        this.refresh();
    }

    setValue(value:any){
        if (value != this.editValue){
            this.editValue = value;
            this.dirty = true;
            this.updateValue();
        }
    }

    onKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter") {
            if (this.field && this.type == "longchar") return;
            e.preventDefault();
            e.stopPropagation();
            this.updateValue();
            this.field.handleEnterKey();
        }
    }

    onKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (this.finderOpen){
            if (this.keyListeners){
                for(let i = 0; i < this.keyListeners.length;i++){
                    let listener = this.keyListeners[i];
                    listener.onKeyDown(e);
                }
            }
        }
        else if (e.key == "ArrowDown") {
            if (this.isLookup){
                e.preventDefault();
                if (this.finderOpen) return;
                this.launchFinder(false);
            }
        }
    }

    refresh(){
        this.forceUpdate();
    }

    async updateValue() {
        if (this.dirty) {
            let field = this.field;

            let value = FieldFormatter.parse(this.editValue,this.type);
            if (field.def && field.def.uppercase){
                if (value && value.toUpperCase){
                    value = value.toUpperCase();
                }
            }
            if (value){
                let error = field.error;
                if (error && error.message == "required"){
                    field.clearError();
                }
            }
            field.lookupLabel = null;
        
            field.canvas.setValue(field, value,this.onValueChanged);
           
            this.dirty = false;
            this.editValue = field.formattedValue;
            this.forceUpdate();
           
        }
    }

    async launchFinder(isAutoLaunch:boolean) {
        if (this.handleLaunchFinder){
            this.handleLaunchFinder();
            return;
        }
        let eventValue;
        if (this.dirty){
            eventValue = this.editValue;
        }

        this.dirty = false;
        let field = this.field;
        let previous = field.value;
        this.editValue = field.value;
        this.finderOpen = true;
        let result = await Dialog.openLookup(field, eventValue, previous,{isAutoLaunch});
        if (result.continue){
            field.lookupLabel = result.label;
            await field.canvas.setValue(field,result.value);
        }
        this.finderOpen = false;
        this.refresh();
    }

    handleDropSelection(value:any,displayValue:string){
        this.finderOpen = false;
        this.dropValues = null;
        let field = this.field;
        field.lookupLabel = displayValue;
        field.canvas.setValue(field,value);
        this.dirty = false;
        this.editValue = field.formattedValue;
        this.forceUpdate();
    }

    connectKeyListener(obj:any,onKeyDown:(e:React.KeyboardEvent<HTMLInputElement>) => any){
        let i = this.findListener(obj);
        if ( i == -1){
            this.keyListeners = this.keyListeners || [];
            this.keyListeners.push({obj,onKeyDown});
        }
    }

    findListener(obj:any):number{
        if (!this.keyListeners) return -1;
        for(let i = 0; i < this.keyListeners.length;i++){
            let listener = this.keyListeners[i];
            if (listener.obj == obj){
                return i;
            }
        }
        return -1;
    }

    disconnectKeyListener(obj:any){
        let i = this.findListener(obj);
        if (i != -1){
            this.keyListeners.splice(i,1);
        }
    }
}) as any;
}








