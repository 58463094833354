import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Card} from 'ktm-ui';

interface IdsCostCentre {
    Id:string;
    Policy_Id:string;
    Name:string;
    Description:string;
    IsActive:boolean;
};

export default KTMScreen({name:"enroll.cost-centre.edit"}, class extends React.Component<{costcentre_id:string}> {

    data:{
        dsCostCentre:IdsCostCentre;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsCostCentre";
        await KTMApi.start(screen,{
            "@costcentre_id":this.props.costcentre_id,
        });
        screen.title = "Edit cost centre";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.details)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add cost centre"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        var isDisabled = this.data.dsCostCentre.Name == null || 
        this.data.dsCostCentre.Name == "" ||
        this.data.dsCostCentre.Description == null ||
        this.data.dsCostCentre.Description == "";
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary" disable = {isDisabled}/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("Name")}/>
                <Display field={field("Description")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsCostCentre.Name":this.data.dsCostCentre.Name,
            "@dsCostCentre.Description":this.data.dsCostCentre.Description,
            "@dsCostCentre.IsActive":this.data.dsCostCentre.IsActive,
            "@costcentre_id":this.props.costcentre_id,
        });
        screen.close({"continue":true});
    }

});
