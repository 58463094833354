import React from 'react';
import {makeComponent,ActionRef} from '../core';
import { EditLink } from './EditLink';
import {ClickThrottle} from './helpers/ClickThrottle';
import { Icon } from './Icon';

export type ButtonStyle = 'primary' | 'link' | 'secondary' | 'add' | 'cancel' | 'destructive' | 'warning' | 'outline';

export const Button = makeComponent(class extends React.Component<{label:string,size?:string;style?:ButtonStyle;action?:ActionRef;disable?:boolean;className?:string}>{

    render(){
        let action = this.props.action;
        if (!action || action.hidden) return null;

        let icon;
        let className = ["RT-Button"+(this.props.className ? " " + this.props.className : "")];

        let label = this.props.label || action.label;
        let style = this.props.style || action.def.variant;
        if (style == "link"){
            return <EditLink label={label} action={action}/>
        }
        else if (style == "add"){
            className.push("RT-Button--add");
            icon = "plus";
        }
        else {
            className.push("RT-Button--" + style);
        }
      

       
        if (this.props.size){
            className.push("RT-Button--" + this.props.size);
        }
        let tabIndex; //  = getTabIndex(this.props);
        return <ButtonPresenter disable={this.props.disable} className={className} size={this.props.size} 
            tabIndex={tabIndex} label={label} action={action} icon={icon}/>;
    }

});


class ButtonPresenter extends React.Component<{className:string[],size:string,
    tabIndex:number,icon?:string,label:string,action:ActionRef,disable?:boolean}>{

    clickThrottle:ClickThrottle;

    buttonRef = React.createRef<HTMLButtonElement>();
    componentDidMount(){
        let action = this.props.action;
        if (action && action.shouldFocus){
            if (this.buttonRef.current){
                this.buttonRef.current.focus();
            }
        }
    }

    componentDidUpdate(){
        let action = this.props.action;
        if (action && action.shouldFocus){
            if (this.buttonRef.current){
                this.buttonRef.current.focus();
            }
        }
    }
    render(){

        let action = this.props.action;
        let label = this.props.label;
        let disabled:boolean;
        let help:string;
        if (action){
            disabled = action.disabled;
            if (disabled){
                help = action.disabledHelp;
            }
            else {
                help = action.help;
            }
        }
       
       
        let size = this.props.size;
        let className = this.props.className;

        switch (size) {
            case "small":
                className.push("RT-Button--small");
                break;
            case "large":
                className.push("RT-Button--large");
                break;
        }

        let content;
        if (this.props.icon){
            content = <div style={{display:"flex",alignItems:'center'}}>{<Icon icon={this.props.icon} className="RT-Button__icon"/>}{label}</div>
        }
        else {
            content = label;
        }

        return (
            <>
                <button
                    ref={this.buttonRef}
                    className={className.join(" ")}
                    disabled={this.props.disable}
                    onClick={this.handleClick}
                    tabIndex={this.props.tabIndex}
                    title={help}
                >
                    {content}
                </button>
                {"\n"}
            </>
        );
    }


    handleClick = (e:React.MouseEvent) => {
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,() => {
            e.preventDefault();
            e.stopPropagation();
            this.props.action.trigger();
        });
    }


}
