import React from 'react';
import { FieldRef, makeComponent } from "ktm-ui/core";
import { TextBox } from './display/TextBox';

export const ColorSwatch = makeComponent(class extends React.Component<{field:FieldRef}>{

    render(){
        let field = this.props.field;
        if (!field) return null;
        let color = field.value;
        let label = <div style={{fontWeight:500,letterSpacing:"0.35px",fontSize:"12px"}}>{field.label}</div>
        if (color){
            return (<div  style={{marginBottom:"1rem"}}>
                <div style={{display:"flex",alignItems:"center",marginBottom:6}}>
                    {label}
                    <button onClick={this.handleClearClick} style={{fontSize:"13px",marginLeft:20}} className="RT-Link">Edit</button>
                </div>
                <div style={{display:"flex",alignItems:"center"}}>
                    <div style={{backgroundColor:color,height:50,width:50,marginRight:10}}/>
                    <div style={{fontWeight:500,fontSize:"14px"}}>{color}</div>
                </div>
            </div>)
        }
        else {
            return <TextBox field={field} label={field.label}/>
        }
    }

    
    handleClearClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let field = this.props.field;
        field.setValue(null);
    }

    
});