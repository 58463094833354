import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, RadioButtons} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Card,Display,Line} from 'ktm-ui';

interface IdsAdvisor {
    Mode:string;
    FirstName:string;
    LastName:string;
    Email:string;
    Phone:string;
    DistroAccountName:string;
    DistroAccount_Id:string;
};

export default KTMScreen({name:"enroll.advisor.new"}, class extends React.Component<{}> {

    data:{
        dsAdvisor:IdsAdvisor;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsAdvisor";
        await KTMApi.start(screen,{
        });
        screen.title = "New advisor";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="full">
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="New advisor"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Create advisor" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.enrollment_type)}
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    enrollment_type({field,draw,action}:RenderEvent){
        return (
            <Card>
                {draw(this.mode_radio)}
                {draw(this.add_advisor)}
                {draw(this.new_distro)}
            </Card>
        );
    }

    add_advisor({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("DistroAccount_Id")}/>
            </Card>
        );
    }

    new_distro({field,draw,action}:RenderEvent){
        return (
            <Card background="none" padding="none">
                <Display field={field("DistroAccountName")}/>
            </Card>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card label="Advisor">
                <Line>
                    <Display field={field("FirstName")}/>
                    <Display field={field("LastName")}/>
                </Line>
                <Line>
                    <Display field={field("Email")}/>
                    <Display field={field("Phone")}/>
                </Line>
            </Card>
        );
    }

    mode_radio({field,draw,action}:RenderEvent){
        return (
            <RadioButtons field={field("@dsAdvisor.Mode")} hideLabel direction="horizontal"/>
        );
    }

    async submit({screen}:ActionEvent){

    }

    async mode_onchange({screen,eventValue}:ActionEvent){
        if (eventValue == 'ADD'){
            screen.setValue("@dsAdvisor.DistroAccountName",null);
            screen.hide(this.new_distro);
            screen.unhide(this.add_advisor);
        }
        else {
            screen.setValue("@dsAdvisor.DistroAccountName",null);
            screen.hide(this.add_advisor);
            screen.unhide(this.new_distro);
        }
    }

});
