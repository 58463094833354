import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';

interface IdsCampaign {
    Id:string;
    SelectionDeadline:string;
    LaunchDate:string;
    ReminderDate:string;
    LastDayReminder:string;
};

export default KTMScreen({name:"planadmin.campaign.edit-dates"}, class extends React.Component<{campaign_id:string}> {

    data:{
        dsCampaign:IdsCampaign;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsCampaign";
        await KTMApi.start(screen,{
            "@campaign_id":this.props.campaign_id,
        });
        screen.title = "Edit campaign dates";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit campaign dates"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <Display field={field("LaunchDate")}/>
                    <Display field={field("SelectionDeadline")}/>
                </Line>
                <Line>
                    <Display field={field("ReminderDate")}/>
                    <Display field={field("LastDayReminder")}/>
                </Line>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsCampaign.SelectionDeadline":this.data.dsCampaign.SelectionDeadline,
            "@dsCampaign.LaunchDate":this.data.dsCampaign.LaunchDate,
            "@dsCampaign.ReminderDate":this.data.dsCampaign.ReminderDate,
            "@dsCampaign.LastDayReminder":this.data.dsCampaign.LastDayReminder,
            "@campaign_id":this.props.campaign_id,
        });
        screen.close({"continue":true});
    }

});
