import React, { Component } from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  Button,
} from "ktm-ui";
import fileDownload from "js-file-download";
import toastr from "toastr";

export default KTMScreen(
  { name: "paymentfiles" },
  class extends React.Component<any> {
    state = {
      paymentBatches: [],
      skip: 0,
      loading: false,
      adjudicated: 0,
      unpaidEft: 0,
      unpaidChq: 0,
    };

    componentDidMount() {
      this.setState({ loading: true });
      fetch("/api/Payment/getpendingpayments", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            adjudicated: data.adjudicated,
            unpaidEft: data.unpaidEft,
            unpaidChq: data.unpaidChq,
          });
        });
      fetch("/api/Payment/getbatch?skip=" + this.state.skip, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            paymentBatches: data,
            skip: this.state.skip + 10,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }

    loadMore = () => {
      this.setState({ loading: true });
      fetch("/api/Payment/getbatch?skip=" + this.state.skip, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          const updatedPaymentBatches = [...this.state.paymentBatches, ...data];

          this.setState({
            paymentBatches: updatedPaymentBatches,
            skip: this.state.skip + 10,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    };

    payEftBatch = (id) => {
      fetch("/api/Payment/payeftbatch/" + id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ paymentBatches: data });
        });
    };

    payChqBatch = (id) => {
      fetch("/api/Payment/paychqbatch/" + id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ paymentBatches: data });
        });
    };

    getEftFile = (id) => {
      fetch("/api/Payment/geteftbatchfile/" + id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(data.textFile)
          );
          element.setAttribute("download", data.fileName);

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
          toastr.success("EFT file downloaded", "Success");
        });
    };

    getChqFile = (id) => {
      fetch("/api/Payment/getchqbatchfile/" + id, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((data) => {
          let url = window.URL.createObjectURL(data);

          let a = document.createElement("a");
          let date = new Date();
          let formattedDate =
            date.getFullYear() +
            "_" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "_" +
            ("0" + date.getDate()).slice(-2);

          a.href = url;
          a.download = "CHQ_" + formattedDate + ".zip"; // Change the file extension to .zip
          document.body.appendChild(a);
          a.click();
          a.remove();
          toastr.success("CHQ file downloaded", "Success");
        });
    };

    run_payment = async () => {
      this.setState({loading:true});

      fetch("/api/Payment/getpendingpayments", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            adjudicated: data.adjudicated,
            unpaidEft: data.unpaidEft,
            unpaidChq: data.unpaidChq,
          });
        });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };

      await fetch("/api/Payment/run", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ paymentBatches: data, loading:false }, () => {
            toastr.success("Payment process complete", "Success");
          });
        })
        .catch((error) => {
          this.setState({loading:false});
          toastr.error("Payment process failed", "Error");
        });
    };

    run_eftpaymentbatch = async () => {
      const requestOptions = {
        method: "GET",
      };

      // await fetch("/api/Payment/generateeftbatch", requestOptions);
      await fetch("/api/Payment/generateeftbatch", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ paymentBatches: data }, () => {
            toastr.success("EFT payment batch generated", "Success");
          });
        })
        .catch((error) => {
          toastr.error("Failed to generate EFT batch", "Error");
        });
    };

    run_chqpaymentbatch = async () => {
      const requestOptions = {
        method: "GET",
      };

      // await fetch("/api/Payment/generatechqbatch", requestOptions);
      await fetch("/api/Payment/generatechqbatch", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ paymentBatches: data }, () => {
            toastr.success("CHQ payment batch generated", "Success");
          });
        })
        .catch((error) => {
          toastr.error("Failed to generate CHQ batch", "Error");
        });
    };

    generate_payment_report = () => {
      fetch("/api/Payment/report")
        .then((response) => response.json())
        .then((data) => {
          let a = document.createElement("a");
          a.href = "data:application/octet-stream;base64," + data.base64;
          a.download = "paymentreport.pdf";
          a.click();
        });
    };

    render() {
      let batchRows = null;
      batchRows = this.state.paymentBatches.map((data, i) => {
        let isPaidText = <td style={{ color: "red" }}>No</td>;
        let markPaidButton = null;
        if (data.paymentMethod == "EFT") {
          markPaidButton = (
            <td>
              <button
                onClick={() => this.payEftBatch(data.id)}
                type="button"
                className="btn btn-sm btn-danger"
              >
                Mark as Submitted
              </button>
            </td>
          );
        } else if (data.paymentMethod == "CHEQUE") {
          markPaidButton = (
            <td>
              <button
                onClick={() => this.payChqBatch(data.id)}
                type="button"
                className="btn btn-sm btn-danger"
              >
                Mark as Submitted
              </button>
            </td>
          );
        }

        let downloadFileButton = <td></td>;
        if (data.isPaid) {
          isPaidText = <td style={{ color: "green" }}>Yes</td>;
          markPaidButton = (
            <td>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                disabled
              >
                Paid
              </button>
            </td>
          );
          if (data.paymentMethod == "EFT") {
            downloadFileButton = (
              <td>
                <button
                  onClick={() => this.getEftFile(data.id)}
                  type="button"
                  className="btn btn-sm btn-outline-primary pmt-file-dl-btn"
                >
                  Download EFT
                </button>
              </td>
            );
          } else if (data.paymentMethod == "CHEQUE") {
            downloadFileButton = (
              <td>
                <button
                  onClick={() => this.getChqFile(data.id)}
                  type="button"
                  className="btn btn-sm btn-outline-primary pmt-file-dl-btn"
                >
                  Download CHQ
                </button>
              </td>
            );
          }
        }

        return (
          <tr key={i}>
            <td>{data.id}</td>
            <td>{data.eftfileSeq}</td>
            {isPaidText}
            <td>{data.paymentDate}</td>
            <td>{data.paymentMethod}</td>
            <td>{data.createdAt}</td>
            {markPaidButton}
            {downloadFileButton}
          </tr>
        );
      });

      let batchTable = null;
      if (batchRows != null) {
        batchTable = (
          <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>File Sequence</th>
                  <th>Paid</th>
                  <th>Payment Date</th>
                  <th>Payment Method</th>
                  <th>Created</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{batchRows}</tbody>
            </table>
          </div>
        );
      }

      let paymentActionButtons = (
        <div className="btn-group mb-3">
          <button
            onClick={() => this.run_payment()}
            type="button"
            className="btn btn-sm btn-danger"
          >
            Run Payment Process
          </button>
          <button
            onClick={() => this.run_eftpaymentbatch()}
            type="button"
            className="btn btn-sm btn-outline-danger"
          >
            Generate EFT
          </button>
          <button
            onClick={() => this.run_chqpaymentbatch()}
            type="button"
            className="btn btn-sm btn-outline-danger"
          >
            Generate CHQ
          </button>
          {/* <button
            onClick={() => this.generate_payment_report()}
            type="button"
            className="btn btn-sm btn-primary"
          >
            Payment Report
          </button> */}
        </div>
      );

      let spinner = null;
      if (this.state.loading) {
        spinner = (
          <div
            style={{
              width: "100%",
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        );
      }

      return (
        <div className="p-4">
          {this.state.loading ? 
            null
           : (
            <div className="card p-3 alert alert-success" style={{marginBottom: 15 }}>
              <ul>
                <li>
                  Adjudicated claims pending payment processing:{" "}
                  <b>{this.state.adjudicated}</b>
                </li>
                <li>
                  Unpaid EFT pending generation:{" "}
                  <b>{this.state.unpaidEft}</b>
                </li>
                <li>
                  Unpaid CHQ pending generation:{" "}
                  <b>{this.state.unpaidChq}</b>
                </li>
              </ul>
            </div>
          )}
          <div>{paymentActionButtons}</div>
          {this.state.loading ? spinner : batchTable}
          <button
            className="btn btn-sm btn-primary"
            onClick={() => this.loadMore()}
          >
            Load More
          </button>
        </div>
      );
    }
  }
);
