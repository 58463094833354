export const EmptyStateTemplate = () => {
    return (
        <div className="empty-state-template">
            <div style={{
                fontSize: "15px",
                fontWeight: 500,
                marginBottom: "20px"}
            }>
                No data found for the selected date range.
            </div>
        </div>
    )
}