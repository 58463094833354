import React from "react";
import Spinner from "components/Spinner";
import axios from "axios";

export default class EligibleExpenses extends React.Component<any> {
  state = {
    loaded: false,
    expenseTypes: null,
  };

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }
  async startLoading() {
    axios
      .get(`/api/eligibleexpenses?type=${this.props.type}&policyId=${this.props.policyId}&employeeId=${this.props.employeeId}`)
      .then((response) => {
        this.setState({ loaded: true, expenseTypes: response.data });
      })
      .catch((error) => {
        if (error.response.status == 401) {
        } else {
          console.log(error);
        }
      });
  }

  renderContent() {
    if (!this.state.loaded) {
      return <Spinner />;
    }

    var lsaDep = null;
    if (this.props.type == "LSA") {
      if (this.state.expenseTypes[0].lsaDepClaim) {
        lsaDep = <p>Your lifestyle spending account extends coverage to both yourself and the eligibile dependents added to your account.</p>
      }else{
        lsaDep = <p>Your lifestyle spending account is an employee only benefit and does not cover expenses for dependents added to your account.</p>
      }
    }

    let expenseRows = this.state.expenseTypes.map((data, i) => {
      if ((i + 1) % 5 === 0) {
        return (
          <>
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{data.shortName}</td>
              <td>{data.description}</td>
              <td className="text-right">{data.coverageRate}%</td>
            </tr>
            <div className="pagebreak"> </div>
          </>
        );
      } else {
        return (
          <>
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{data.shortName}</td>
            <td>{data.description}</td>
            <td className="text-right">{data.coverageRate}%</td>
          </tr>
          <div className="no-pagebreak"> </div>
          </>
        );
      }
    });

    let label;
    if (this.props.type == "LSA") {
      label = "Lifestyle Spending Account";
    } else {
      label = "Health Spending Account";
    }

    return (
      <div className="br-mainpanel pd-b-50">
        <div
          className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30"
        >
          <h4 className="tx-gray-800 mg-b-45 mg-t-25 no-print-margin">Eligible Expenses</h4>
        </div>

        <div className="br-pagebody no-padding no-print-margin" style={{ maxWidth: 1250 }}>
          <div className="br-section-wrapper mg-x-50 mg-b-30 no-padding document-body">
            <div className="card mg-t-35 no-print-border no-print-margin" style={{ color: "black" }}>
              <div className="card-header tx-medium tx-uppercase bg-white d-print-none">
                {label}
              </div>
              <div className="card-body">
                <div className="mg-b-0">
                  <div
                    className="bd bd-gray-300 rounded table-responsive"

                  >
                    <table
                      className="table table-hover black"
                      style={{ marginBottom: 0 }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: 35 }}></th>
                          <th style={{ width: 200 }}>Name</th>
                          <th>Description</th>
                          <th style={{ width: 150 }} className="text-right">
                            Coverage Rate
                          </th>
                        </tr>
                      </thead>
                      <tbody>{expenseRows}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
