import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Display,
  Line,
  Card,
  Column,
  Panel,
} from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  LSAAdminRate: number;
  LSAMinAdminFee: number;
  LSAMaxAdminFee: number;
  CarryFwdLSA: string;
}

export default KTMScreen(
  { name: "enroll.product.edit-lsa" },
  class extends React.Component<{ policyBenefit_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.title = "Edit product LSA settings";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit product LSA settings" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      var isDisabled =
        this.data.dsBenefit.LSAAdminRate === 0 ||
        this.data.dsBenefit.CarryFwdLSA === "-- Select --"
          ? true
          : false;

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={isDisabled}
          />
        </Dialog.Footer>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display
              required
              field={field("LSAAdminRate")}
              toolTip={
                <CustomToolTip
                  header="LSA Admin Rate"
                  bodyContent="Typically 10% The administration fee applied per approved claim (plus tax). The amount of tax is determined by the Tax Jurisdiction of your policy."
                />
              }
            />
            <Display
              required
              field={field("LSAMinAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Min Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a minimum admin charge applied per claim."
                />
              }
            />
            <Display
              required
              field={field("LSAMaxAdminFee")}
              toolTip={
                <CustomToolTip
                  header="LSA Max Admin Fee"
                  bodyContent="Rarely used. Leave blank unless you want a maximum admin charge applied per claim. "
                />
              }
            />
          </Line>
          <Display
            required
            field={field("CarryFwdLSA")}
            toolTip={
              <CustomToolTip
                header="LSA CarryFwd"
                bodyContent="Choose between Credit, Expense or No CarryForward."
              />
            }
          />
        </Card>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    async submit({ screen }: ActionEvent) {
      if (this.data.dsBenefit.CarryFwdLSA === "No Carry Forward") {
        this.data.dsBenefit.CarryFwdLSA = null;
      }
      if (this.data.dsBenefit.LSAAdminRate === null) {
        this.data.dsBenefit.LSAAdminRate = 0;
      }
      if (this.data.dsBenefit.LSAMinAdminFee === null) {
        this.data.dsBenefit.LSAMinAdminFee = 0;
      }
      if (this.data.dsBenefit.LSAMaxAdminFee === null) {
        this.data.dsBenefit.LSAMaxAdminFee = 0;
      }
      
      await KTMApi.action(screen, "submit", {
        "@dsBenefit.LSAAdminRate": this.data.dsBenefit.LSAAdminRate,
        "@dsBenefit.LSAMinAdminFee": this.data.dsBenefit.LSAMinAdminFee,
        "@dsBenefit.LSAMaxAdminFee": this.data.dsBenefit.LSAMaxAdminFee,
        "@dsBenefit.CarryFwdLSA": this.data.dsBenefit.CarryFwdLSA,
        "@policyBenefit_id": this.props.policyBenefit_id,
      });
      screen.close({ continue: true });
    }
  }
);
