import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Button, Table, Form } from 'react-bootstrap';
import CustomPagination from 'components/CustomPagination';
import '../styles/Advisors.css';
import DistroAccountDetailsModal from './DistroAccountDetailsModal';
import { CustomLoading } from 'components/CustomLoading';

const DistroAccountsReport = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortColumn, setSortColumn] = useState('healthClaimAccountId');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/Advisors/distroaccounts');
      setData(response.data);
      setFilteredData(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = data.filter((item) =>
      item.healthClaimAccountId.toString().includes(query) ||
      item.healthClaimDistroId.toString().includes(query) ||
      item.name.toLowerCase().includes(query) ||
      item.contactEmail?.toLowerCase().includes(query) ||
      item.user_Id?.toString().includes(query)
    );
    setSearchQuery(query);
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    const sorted = [...filteredData].sort((a, b) => {
      if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setSortColumn(column);
    setSortDirection(direction);
    setFilteredData(sorted);
  };

  const handleShowDetails = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const currentRecords = useMemo(() => {
    const indexOfLastRecord = currentPage * itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
    return filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  }, [currentPage, itemsPerPage, filteredData]);

  const nPages = useMemo(() => {
    return Math.ceil(filteredData.length / itemsPerPage);
  }, [filteredData.length, itemsPerPage]);

  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <>
          <DistroAccountDetailsModal
            showModal={showModal}
            onHide={handleCloseModal}
            selectedItem={selectedItem}
          />
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="mb-3"
          />
          <Table hover className="report-table advisors-table">
            <thead>
              <tr>
                <th className="table-text-content" onClick={() => handleSort('healthClaimAccountId')}>
                  Health Claim Account ID {sortColumn === 'healthClaimAccountId' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="table-text-content" onClick={() => handleSort('healthClaimDistroId')}>
                  Health Claim Distro ID {sortColumn === 'healthClaimDistroId' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="table-text-content" onClick={() => handleSort('name')}>
                  Company Name {sortColumn === 'name' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="table-text-content" onClick={() => handleSort('isActive')}>
                  Is Active {sortColumn === 'isActive' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="table-text-content" onClick={() => handleSort('user_Id')}>
                  User ID {sortColumn === 'user_Id' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="table-text-content" onClick={() => handleSort('email')}>
                  Email {sortColumn === 'email' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                </th>
                <th className="table-text-content">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((item, index) => (
                <tr 
                  key={index} 
                  className="table-body-item"
                  style={item.isActive ? null : { backgroundColor: 'pink' }}
                >
                  <td className="table-text-content">{item.healthClaimAccountId}</td>
                  <td className="table-text-content">{item.healthClaimDistroId}</td>
                  <td className="table-text-content">{item.name}</td>
                  <td className="table-text-content">{item.isActive ? 'Yes' : 'No'}</td>
                  <td className="table-text-content">{item.user_Id != null ? item.user_Id : 'N/A'}</td>
                  <td className="table-text-content">{item.contactEmail ?? 'N/A'}</td>
                  <td>
                    <Button onClick={() => handleShowDetails(item)} variant="primary">
                      Show Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-container" style={{ float: 'inline-end' }}>
            <div className="pagination-info">
              <span>Showing {currentPage * itemsPerPage - itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredData.length)} entries out of {filteredData.length} entries</span>
            </div>
            <CustomPagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DistroAccountsReport;
