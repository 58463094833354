import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import moment from "moment";
import toastr from "toastr";

interface IdsBenefit {
  Id: string;
  FlexLaunchDate: string;
  FlexReminderDate: string;
  FlexLastDayReminderDate: string;
  FlexSelectionDeadline: string;
  IsFlexLaunchEmailSent: boolean;
  IsFlexReminderEmailSent: boolean;
  IsFlexLastDayReminderEmailSent: boolean;
  IsFlexSelectionDone: boolean;
}

export default KTMScreen(
  { name: "planadmin.employee-benefits.edit-flex-dates" },
  class extends React.Component<{ employeeBenefit_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    state = {
      flexLaunchDate: null,
      flexReminderDate: null,
      flexLastDayReminderDate: null,
      flexSelectionDeadline: null,
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@employeeBenefit_id": this.props.employeeBenefit_id,
      });
      screen.title = "Edit employee benefit limits";

      this.setState({
        flexLaunchDate: this.data.dsBenefit.FlexLaunchDate,
        flexReminderDate: this.data.dsBenefit.FlexReminderDate,
        flexLastDayReminderDate: this.data.dsBenefit.FlexLastDayReminderDate,
        flexSelectionDeadline: this.data.dsBenefit.FlexSelectionDeadline,
      });
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label={"Edit Flex Dates"} />;
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.flex)}
          </Column>
        </Panel>
      );
    }

    flex({ field }: RenderEvent) {
      const currentDate = moment().format("YYYY-MM-DD");
      const { flexLaunchDate, flexReminderDate, flexLastDayReminderDate } = this.state;
      
      if (this.data.dsBenefit.IsFlexSelectionDone) {
          return (
              <Card>
                  <Line>
                      <Display field={field("FlexSelectionDeadline")} />
                  </Line>
              </Card>
          );
      }
      
      return (
          <Card>
              <Line>
                {(!this.data.dsBenefit.IsFlexLaunchEmailSent || moment(flexLaunchDate).format("YYYY-MM-DD") > currentDate) ? (
                  <Display field={field("FlexLaunchDate")} />
                ) : <></>}
                {(!this.data.dsBenefit.IsFlexReminderEmailSent || moment(flexReminderDate).format("YYYY-MM-DD") > currentDate) ? (
                  <Display field={field("FlexReminderDate")} />
                ) : <></>}
              </Line>
              <Line>
                {(!this.data.dsBenefit.IsFlexLastDayReminderEmailSent || moment(flexLastDayReminderDate).format("YYYY-MM-DD") > currentDate) ? (
                  <Display field={field("FlexLastDayReminderDate")} />
                ) : <></>}
                  <Display field={field("FlexSelectionDeadline")} />
              </Line>
          </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      const currentDate = moment().format("YYYY-MM-DD"); 
      const selectionDeadline = moment(this.state.flexSelectionDeadline).format("YYYY-MM-DD");
      if(currentDate < selectionDeadline)
      {
        const futureDate = moment().add(1, 'days').format("YYYY-MM-DD");
        const {
          FlexLaunchDate,
          FlexReminderDate,
          FlexLastDayReminderDate,
          FlexSelectionDeadline,
          IsFlexLaunchEmailSent,
          IsFlexReminderEmailSent,
          IsFlexLastDayReminderEmailSent,
          IsFlexSelectionDone,
        } = this.data.dsBenefit;
    
        if(!IsFlexSelectionDone)
        {
            if(!IsFlexLaunchEmailSent)
            {
              if(moment(FlexLaunchDate).isBefore(futureDate))
              {
                toastr.error("Flex Launch Date should be greater than today's date");
                return;
              }
              if(moment(FlexLaunchDate).isAfter(FlexReminderDate))
              {
                toastr.error("Flex Launch Date should be less than Flex Reminder Date");
                return;
              }
              if(moment(FlexLaunchDate).isAfter(FlexLastDayReminderDate))
              {
                toastr.error("Flex Launch Date should be less than Flex Last Day Reminder Date");
                return;
              }
              if(moment(FlexLaunchDate).isAfter(FlexSelectionDeadline))
              {
                toastr.error("Flex Launch Date should be less than Flex Selection Deadline");
                return;
              }
            }
            if(!IsFlexReminderEmailSent)
            {
              if(moment(FlexReminderDate).isBefore(futureDate))
              {
                toastr.error("Flex Reminder Date should be greater than today's date");
                return;
              }
              if(moment(FlexReminderDate).isSame(FlexLaunchDate))
              {
                toastr.error("Flex Reminder Date should not be same as Flex Launch Date");
                return;
              }
              if(moment(FlexReminderDate).isAfter(FlexLastDayReminderDate))
              {
                toastr.error("Flex Reminder Date should be less than Flex Last Day Reminder Date");
                return;
              }
              if(moment(FlexReminderDate).isAfter(FlexSelectionDeadline))
              {
                toastr.error("Flex Reminder Date should be less than Flex Selection Deadline");
                return;
              }
              if(moment(FlexReminderDate).isSame(FlexLastDayReminderDate))
              {
                toastr.error("Flex Reminder Date should not be same as Flex Last Day Reminder Date");
                return;
              }
            }
            if(!IsFlexLastDayReminderEmailSent)
            {
              if(moment(FlexLastDayReminderDate).isBefore(futureDate))
              {
                toastr.error("Flex Last Day Reminder Date should be greater than today's date");
                return;
              }
              if(moment(FlexLastDayReminderDate).isBefore(FlexLaunchDate))
              {
                toastr.error("Flex Last Day Reminder Date should be greater than Flex Launch Date");
                return;
              }
              if(moment(FlexLastDayReminderDate).isSame(FlexLaunchDate))
              {
                toastr.error("Flex Last Day Reminder Date should not be same as Flex Launch Date");
                return;
              }
              if(moment(FlexLastDayReminderDate).isAfter(FlexSelectionDeadline))
              {
                toastr.error("Flex Last Day Reminder Date should be less than Flex Selection Deadline");
                return;
              }
            }
        }
        if(moment(FlexSelectionDeadline).isBefore(futureDate))
        {
          toastr.error("Flex Selection Deadline should be greater than today's date");
          return;
        } 
      }

      await KTMApi.action(screen, "submit", {
        "@dsEmployee.FlexLaunchDate": this.data.dsBenefit.FlexLaunchDate,
        "@dsEmployee.FlexSelectionDeadline": this.data.dsBenefit.FlexSelectionDeadline,
        "@dsEmployee.FlexReminderDate": this.data.dsBenefit.FlexReminderDate,
        "@dsEmployee.FlexLastDayReminderDate": this.data.dsBenefit.FlexLastDayReminderDate,
      });
      
      screen.close({ continue: true });
    }
  }
);
