
import React from 'react';
import { Canvas } from '.';
import { ActionEvent } from './ActionEvent';
import { IComponentDefinition } from './ComponentDefinition';
import { RenderEvent} from './RenderEvent';


export const COMMENT = "Comment";
export const KIND = {
    IF:"If",
    ELSE_IF:"ElseIf",
    ELSE:"Else",
    DRAW:"Draw",
    COMMENT:"Comment",
    PARAM_VALUE:"ParamValue",
    RETURN:"Return"
}
export interface ISidebarFrame {
    name:string;
    label:string;
    content?:any;
}
export class SidebarState {
    frames:ISidebarFrame[];
    activeFrame:string;
    isOpen:boolean;
}


export interface ComponentContextValue {
    canvas:Canvas;
}

export interface IActionResult {
    continue:boolean;
    error?:string;
}

export interface IEventOptions {
    current?:any,
    value?:any,
    returnValue?:any,
    table?:any[],
    scope?:any;
    showStackContent?:(content:any) => void;
    actionName?:string;
    title?:string;
    noUpdate?:boolean;
 }

export const ComponentContextType = React.createContext<ComponentContextValue>(null);


export function makeComponent<T>(c:T,def?:IComponentDefinition):T {
    let comp = c as any;
  //  comp.contextType = ComponentContextType;
    comp.$def = def;
    return c;
}

export type ActionHandler = (event?:ActionEvent)=> Promise<any>;
export type Template  = (event?:RenderEvent) => any;


export type FieldType = 'character' | 'logical' | 'date' | 'datetime' 
| 'integer' | 'money' | 'decimal' 
| 'longchar' | 'uid' | 'record' | 'collection';


export interface IEventScope {
    enterKeyAction?:string;
}

export type FilterOp = "=" | ">" | ">=" | "<" | "<=" | "begins" | "contains" | "between" | "ends" | "<>" | 'or' | 'and' | 'is empty' | 'is not empty';

export type FieldErrorType = 'VALIDATION' | 'NOTFOUND' | 'ERROR' | 'FATAL' | 'INFORMATION' | 'WARNING' | 'NO-DIALOG';

export type ICodeError = {
    id:string,
    message:string
}
export interface ICanvasError {
    showDialog?:boolean;
    messages:string[];
    sourceCode?:any;
    codeErrors?:ICodeError[];
    validationErrors?:IValidationError[];
    $$error:true
}

export interface IValidationError {
    field?:string;
    type?:FieldErrorType;
    message?:string;
    label?:string;
    noDialog?:boolean;
}

export interface IScriptMethod {
    parameters:string[];
}

export interface IActionDefinition {
    label?:string;
    help?:string;
    size?:string;
    icon?:string;
    variant?:string;
    type?:string;
    statements?:any[];
}

export interface ITemplateDefinition {
    draw?:any;
}


export interface IScreenLaunchParams {
    screen:string;
    props:any;
}

export function getActionName(action:ActionHandler | string):string{
    if (typeof action == "string"){
        return action;
    }
    if (action) return (action as any).$name;
}

export function getTemplateName(template:Template | string):string{
    if (typeof template == "string") return template;
    if (template) return (template as any).$name;
}

export function getTargetName(target:string | ActionHandler | Template):string{
    if (typeof target == "string") return target;
    if (target) return (target as any).$name;
}

export interface ISigninSettings {
    supportLinkLabel?:string;
    supportLinkHref?:string;
    usernameLabel?:string;
    submitLabel?:string;
    registerLabel?:string;
    forgotPasswordLabel?:string;
    passwordResetInstruction?:string;
    resetSentMessage?:string;
    logoUrl?:string;
    redirect?:string;
}

export interface IStudioSettings {
    enabled:boolean;
    launchUrl:string
}