import React, { useState, useEffect } from 'react';
import { Card, Line } from 'ktm-ui';
import { MdEmail } from 'react-icons/md';
import EditCampaignModal from './Edit_campaign_modal';
import ReactMoment from 'react-moment';
import EmployeeCampaignTable from './Employee_campaigns';
import NewCampaignModal from './New_campaign_modal';
import { CustomLoading } from 'components/CustomLoading';
import axios from 'axios';
import toastr from 'toastr';

interface CampaignProps {
    policy_id: string;
}

const Campaign: React.FC<CampaignProps> = ({ policy_id }) => {
    const [loading, setLoading] = useState(true);
    const [hasCampaign, setHasCampaign] = useState(false);
    const [campaignData, setCampaignData] = useState({
        SelectionDeadline: null,
        LaunchDate: null,
        ReminderDate: null,
        LastDayReminder: null,
    });
    const [benefitEndDate, setBenefitEndDate] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showNewCampaignModal, setShowNewCampaignModal] = useState(false);

    useEffect(() => {
        getCampaign();
        getBenefitEndDate();
    }, [policy_id]);

    const getCampaign = async () => {
        try {
            const response = await fetch(`/api/Flex/getpolicycampaign?PolicyId=${policy_id}`);
            const data = await response.json();
            if (data.success) {
                setCampaignData({
                    SelectionDeadline: data.campaign.selectionDeadline,
                    LaunchDate: data.campaign.launchDate,
                    ReminderDate: data.campaign.reminderDate,
                    LastDayReminder: data.campaign.lastDayReminder,
                });
                setHasCampaign(true);
            } else {
                setHasCampaign(false);
            }
        } catch (error) {
            toastr.error('Error getting campaign info');
            setHasCampaign(false);
        } finally {
            setLoading(false);
        }
    };

    const getBenefitEndDate = async () => {
        try {
            const response = await axios.get(`/api/Flex/getbenefitenddate?policyId=${policy_id}`);
            if (response.data.success) {
                setBenefitEndDate(response.data.currentBenYearEndDate.endDate);
            } else {
                console.log(response.data.message, 'Error');
            }
        } catch (error) {
            console.log('Failed to get benefit end date', 'Error');
        }
    };

    if (loading) {
        return <CustomLoading />;
    }

    const renderCampaignDetails = () => (
        <div className="campaign-info-container">
            <EditCampaignModal
                showEditModal={showEditModal}
                policy_id={policy_id}
                SelectionDeadline={campaignData.SelectionDeadline}
                LaunchDate={campaignData.LaunchDate}
                ReminderDate={campaignData.ReminderDate}
                LastDayReminder={campaignData.LastDayReminder}
                refreshCampaigns={() => {
                    toastr.success('Campaign updated successfully');
                    setShowEditModal(false);
                    getCampaign();
                }}
                closeModal={() => setShowEditModal(false)}
                benefitEndDate={benefitEndDate}
            />
            <Card
                actions={
                    <a href="#" className="RT-Link" onClick={() => setShowEditModal(true)}>
                        Edit
                    </a>
                }
            >
                <h1><span style={{fontWeight: 'bold', fontSize: '20px'}}>Flex Selection Email Campaign Dates</span></h1>
                <br/>
                <Line>
                {['LaunchDate', 'ReminderDate', 'LastDayReminder', 'SelectionDeadline'].map((dateType) => (
                        <div key={dateType} className="RT-Static-Field">
                            <label className="RT-Static-Field__label">{`${dateType.replace(/([A-Z])/g, ' $1')} (YYYY-MM-DD)`}</label>
                            <div className="RT-Static-Field__content" style={{ height: '21px' }}>
                                {campaignData[dateType] ? <ReactMoment format="YYYY-MM-DD">{campaignData[dateType]}</ReactMoment> : ''}
                            </div>
                        </div>
                    ))}
                </Line>
                <div style={{ marginTop: '20px' }}>
                    <p>
                        <span style={{fontWeight: 'bold'}}>Welcome to your Year-End Flex Campaign!</span>
                        <br/>
                        Flex Selection emails are scheduled to go out automatically according to the dates chosen.
                        Please take a moment to review the employee list and flex amounts below for the next benefit
                        year. Any updates made within the Campaign tab apply only to the next benefit year, never the
                        current one.
                        <br/>
                        You also have the ability to send Flex Selection emails manually to all or to selected
                        employees, at any time after Launch, with our <span style={{fontWeight: 'bold'}}>Send Manual Email(s)</span> button!
                        This does not impact the automatically scheduled emailing.
                        <br/>
                        If necessary, use the <span style={{fontWeight: 'bold'}}>Toggle Email(s)</span> button to enable
                        or disable selected employees from receiving scheduled email notifications.
                        <br/>
                        Don’t hesitate to contact <a href="mailto:support@nationalhealthclaim.com"
                                                     style={{color: "#4987ef"}}>support@nationalhealthclaim.com</a> if you require assistance!
                    </p>
                </div>
            </Card>
            <div className="employee-campaigns-container" style={{ marginTop: '15px' }}>
                <EmployeeCampaignTable
                    policy_id={policy_id}
                    disableBtn={!campaignData.SelectionDeadline}
                    benefitEndDate={benefitEndDate}
                    selectionDeadline={campaignData.SelectionDeadline}
                    launchDate={campaignData.LaunchDate}
                />
            </div>
        </div>
    );

    const renderEmptyState = () => (
        <div className="campaign-empty-state" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 60 }}>
            <MdEmail style={{ fill: 'rgb(100,120,140)', fontSize: 80, opacity: 0.2, marginBottom: 20 }} />
            <div style={{ fontSize: '15px', fontWeight: 500, marginBottom: 20 }}>
                Campaign is currently inactive for this benefit year. Would you like to start next year's Flex campaign?
            </div>
            <button className="RT-Button RT-Button--primary" onClick={() => setShowNewCampaignModal(true)}>
                Add Flex Campaign
            </button>
            <NewCampaignModal
                policy_id={policy_id}
                showNewCampaignModal={showNewCampaignModal}
                closeModal={() => setShowNewCampaignModal(false)}
                refreshCampaigns={() => {
                    setShowNewCampaignModal(false);
                    getCampaign();
                }}
                benefitEndDate={benefitEndDate}
            />
        </div>
    );

    return <div>{hasCampaign ? renderCampaignDetails() : renderEmptyState()}</div>;
};

export default Campaign;
