import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "../styles/CustomComponents.css";

export const CustomDropdown = ({ options, selectedOption, setSelectedOption, disabled = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const optionIndex = options.indexOf(selectedOption) || 0;
    const [selectedIndex, setSelectedIndex] = useState(optionIndex);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (isOpen) {
            dropdownRef.current.querySelector(".dropdown-menu").focus();
        }
    }, [isOpen]);

    const handleKeyDown = (event) => {
        switch (event.key) {
            case "ArrowDown":
                event.preventDefault();
                setSelectedIndex(Math.min(selectedIndex + 1, options.length - 1));
                break;
            case "ArrowUp":
                event.preventDefault();
                setSelectedIndex(Math.max(selectedIndex - 1, 0));
                break;
            case "Tab":
                setIsOpen(false);
                break;
            case "Enter":
                setSelectedOption(options[selectedIndex]);
                setIsOpen(false);
                break;
            default:
                break;
        }

        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            const dropdownMenu = dropdownRef.current.querySelector(".dropdown-menu");
            const dropdownMenuItems = dropdownMenu.querySelectorAll(".dropdown-item");
            const currentItem = dropdownMenuItems[selectedIndex];
            currentItem.scrollIntoView({ block: "start" });
        }
    };

    return (
        <div className="dropdown" ref={dropdownRef}>
            <button
                className="btn btn-secondary dropdown-toggle"
                onClick={() => !disabled && setIsOpen(!isOpen)}
                disabled={disabled}
            >
                <span className="btn-label">{selectedOption}</span>
                <FontAwesomeIcon icon={faAngleDown} className="ml-2 dropdown-icon" />
            </button>
            {isOpen && !disabled ? (
                <ul className="dropdown-menu" tabIndex={0} onKeyDown={handleKeyDown}>
                    {options.map((option, index) => (
                        <li
                            className={"dropdown-item" + (selectedIndex === index ? " active" : "")}
                            key={option}
                            onClick={() => {
                                setSelectedOption(option);
                                setIsOpen(false);
                            }}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
};