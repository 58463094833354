import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import toastr from "toastr";

export default class ClaimSubmissionFailedModal extends React.Component<any> {
  state = {
    open: false,
    notes: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // setTimeout(
    //     function() {
    //         this.props.close();
    //     }
    //     .bind(this),
    //     4000
    // );
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  render() {
    let modalDisplay = {};
    let backdropClass = "";

    backdropClass = "modal-backdrop show";
    modalDisplay = { display: "block" };
    return (
      <>
        <div className={backdropClass}></div>
        <div className="modal" style={modalDisplay}>
          <div
            className="modal-dialog modal-dialog-vertical-center"
            role="document"
          >
            <div className="modal-content bd-0 tx-14" style={{ width: 350 }}>
              <div className="modal-header pd-y-20 pd-x-25">
                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                  Error
                </h6>
                <button
                  onClick={this.props.close}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{paddingTop:0,paddingBottom:25}}>
                <div className="mg-b-0 text-center black">
                  <img className="mg-y-20" src="./../assets/images/redcirclex.png" height="100" />
                  <div className="bold tx-20 mg-b-15 pd-t-5">Claim Submission Failed</div>
                  <div>Please Try Again</div>
                  {/* <div className="mg-t-15">Scroll down to edit or delete submitted images</div> */}
                </div>
              </div>
              {/* <div className="modal-footer">
                <button
                  onClick={this.props.close}
                  type="button"
                  className="btn btn-secondary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
