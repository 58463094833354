import React from "react";

export default class ClaimSubmittedModal extends React.Component<any> {
  state = {
    open: false,
    notes: null,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  render() {
    let modalDisplay = {};
    let backdropClass = "";

    backdropClass = "modal-backdrop show";
    modalDisplay = { display: "block" };
    return (
      <>
        <div className={backdropClass}></div>
        <div className="modal" style={modalDisplay}>
          <div
            className="modal-dialog modal-dialog-vertical-center"
            role="document"
          >
            <div className="modal-content bd-0 tx-14" style={{ width: 350 }}>
              <div className="modal-header pd-y-20 pd-x-25">
                <h6 className="tx-14 mg-b-0 tx-uppercase tx-inverse tx-bold">
                  Congratulations
                </h6>
                <button
                  onClick={this.props.close}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body" style={{paddingTop:0,paddingBottom:25}}>
                <div className="mg-b-0 text-center black">
                  <img className="mg-y-20" src="./../assets/images/greencheckstatic.png" height="100" />
                  <div className="bold tx-20 mg-b-15 pd-t-5">Claim Sent!</div>
                  <div>You will receive an email notification once your claim has been processed.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
