import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {Layout,Dialog,Button,Display,Line,Column,Panel,Card} from 'ktm-ui';
import CustomToolTip from "../../components/CustomToolTip";

interface IdsLimit {
  Id: string;
  Policy_Id: string;
  CreditCalcMethod: string;
}

export default KTMScreen({ name: "enroll.ben-limit.change-credit-calc" }, class extends React.Component<{ limit_id: string, policy_id: string, employeesCount:number }> {

    data:{
        dsLimit:IdsLimit;
    };

    state = {
        showCampaignWarning: false,
    };

    render() {
        return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsLimit";
      await KTMApi.start(screen, {
        "@limit_id": this.props.limit_id,
        "@policy_id": this.props.policy_id,
      });
      screen.title = "Change credit calculation method";
      await this.checkCampaignStatus();
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                    <Card>
                        <Line>
                            <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                                This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                                <br />
                                If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                            </span>
                        </Line>
                    </Card>
          )}
          {draw(this.details)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Header label="Edit Credit Calculation Method" />
      );
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button 
              action={action(this.submit)} 
              label="Submit" 
              style="primary" 
            />
        </Dialog.Footer>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Display
            required
            field={field("CreditCalcMethod")}
            toolTip={
              <CustomToolTip
                header="Credit Calculation Method"
                bodyContent="Choose Flat until we can offer Spending Accounts based on % of Salary."
              />
            }
          />
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@dsLimit.CreditCalcMethod": this.data.dsLimit.CreditCalcMethod,
        "@limit_id": this.props.limit_id,
      });
      screen.recordChanged("updated", this.props.limit_id);
      screen.close({ continue: true });
    }

    async checkCampaignStatus() {
        let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsLimit.Policy_Id);
        let res = await response.json();
        if (res.isCampaignOpen) {
            this.setState({ showCampaignWarning: true });
        }
    }
});
