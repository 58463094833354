import React from "react";
import {KTMScreen} from "ktm-ui";
import toastr from "toastr";
import {Modal, Spinner,} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import ReactButton from "react-bootstrap/Button";
import {CustomDateRangePicker} from "components/CustomDateRangePicker";

export default KTMScreen(
    {name: "operationaltransfer"},
    class extends React.Component<any, any> {
        state = {
            loading: true,
            success: false,
            pendingAdminFee: 0,
            pendingGst: 0,
            pendingOnRst: 0,
            pendingOnPremium: 0,
            submitAdminFee: 0,
            submitGst: 0,
            submitOnRst: 0,
            submitOnPremium: 0,
            showConfirm: false,
            formType: "",
            formAmount: 0,
            confirmValue: null,
            selectBy: "all",
            fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            toDate: new Date(),
            submissionDate: new Date(),
        };

        componentDidMount() {
            this.initialize();
        }

        initialize = () => {
            this.setState({loading: true});
            fetch("/api/opstransfer/getpendingtransfersall", {
                method: "GET",
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState({
                        pendingAdminFee: data.pendingAdminFee,
                        pendingGst: data.pendingGst,
                        pendingOnRst: data.pendingOnRst,
                        pendingOnPremium: data.pendingOnPremium,
                        loading: false,
                        submitAdminFee: data.pendingAdminFee,
                        submitGst: data.pendingGst,
                        submitOnRst: data.pendingOnRst,
                        submitOnPremium: data.pendingOnPremium,
                    });
                })
                .catch((error) => {
                    this.setState({loading: false});
                    toastr.error("Failed to load data", "Error");
                });
        };

        setFromDate = (date) => {
            this.setState({
                fromDate: date,
            });
        };

        setToDate = (date) => {
            this.setState({
                toDate: date,
                submissionDate: date,
            });
        };

        filterByDate = () => {
            this.setState({loading: true});
            fetch(
                "/api/opstransfer/getpendingtransfersdate?dateFrom=" +
                this.state.fromDate.toISOString() +
                "&dateTo=" +
                this.state.toDate.toISOString(),
                {
                    method: "GET",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    this.setState({
                        pendingAdminFee: data.pendingAdminFee,
                        pendingGst: data.pendingGst,
                        pendingOnRst: data.pendingOnRst,
                        pendingOnPremium: data.pendingOnPremium,
                        loading: false,
                        submitAdminFee: data.pendingAdminFee,
                        submitGst: data.pendingGst,
                        submitOnRst: data.pendingOnRst,
                        submitOnPremium: data.pendingOnPremium,
                    });
                })
                .catch((error) => {
                    this.setState({loading: false});
                    toastr.error("Failed to load data", "Error");
                });
        };

        handleRadioChange = (e) => {
            this.setState(
                {
                    selectBy: e.target.value,
                },
                () => {
                    if (this.state.selectBy == "all") {
                        this.initialize();
                    }
                }
            );
        };

        setInput = (event) => {
            var val = event.target.value;
            val = val.replace(/^0+(?=\d)/, "");
            val = val.replace(/[^0-9.]/g, "");
            val = val.replace(/(\..*)\./g, "$1");
            this.setState({
                [event.target.name]: val,
            });
        };

        copyClipboard = (val) => {
            navigator.clipboard
                .writeText(val)
                .then(() => {
                    toastr.success("Copied to clipboard", "Success");
                })
                .catch((error) => {
                    toastr.error("Failed to copy", "Error");
                });
        };

        confirmAmount = (type, amount) => {
            let description = null;
            if (type == "admin") {
                description = "NHC Revenue";
            } else if (type == "gst") {
                description = "GST/HST";
            } else if (type == "rst") {
                description = "Ontario RST";
            } else if (type == "premium") {
                description = "Ontario Premium Tax";
            }
            let confirmText =
                description +
                ": " + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(amount).replace(/^(\D+)/, '$');
            this.setState({
                showConfirm: true,
                formType: type,
                formAmount: amount,
                confirmValue: confirmText,
            });
        };
        submitAmount = (type, amount) => {
            this.setState({
                loading: true,
                showConfirm: false,
            });
            // if (isNaN(amount) || amount.trim() === "") {
            //   toastr.error("Invalid amount", "Error");
            //   return;
            // }

            const numericAmount = parseFloat(amount);

            fetch("/api/opstransfer/submittransfer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    Type: type,
                    Amount: numericAmount,
                    SubmissionDate: this.state.submissionDate,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            success: data.success,
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            } else {
                                toastr.success("Transfer submitted", "Success");
                                this.setState({
                                    formType: "",
                                    formAmount: "",
                                    selectBy: "all",
                                });
                                this.initialize();
                            }
                        }
                    );
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");
                });
        };

        render() {
            let confirmModal = null;
            confirmModal = (
                <Modal
                    className="react-modal react-modal-confirm"
                    show={this.state.showConfirm}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header className="react-modal-title">
                        <Modal.Title>Confirm Submission</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            Are you sure you want to submit this operational transfer?
                        </div>
                        {this.state.confirmValue != null ? (
                            <div style={{color: "green", marginTop: 15, fontSize: 20}}>
                                {this.state.confirmValue}
                            </div>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton
                            variant="light"
                            onClick={() => this.setState({showConfirm: false})}
                        >
                            Cancel
                        </ReactButton>
                        <ReactButton
                            variant="primary"
                            onClick={() =>
                                this.submitAmount(this.state.formType, this.state.formAmount)
                            }
                        >
                            Submit
                        </ReactButton>
                    </Modal.Footer>
                </Modal>
            );

            return (
                <div>
                    {confirmModal}
                    <div className="ktm-financials-content">
                        {this.state.loading ? (
                            <div className="financial-spinner">
                                <Spinner animation="border"/>
                            </div>
                        ) : (
                            <>
                                <div className="ktm-financials-label">
                                    Transfers to Operational Account
                                </div>
                                <div>
                                    <div className="container mt-2">
                                        <div className="row">
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="form-check">
                                                                <input
                                                                    type="radio"
                                                                    name="selectByDefault"
                                                                    value="all"
                                                                    checked={this.state.selectBy === "all"}
                                                                    onChange={this.handleRadioChange}
                                                                />
                                                                <label className="form-check-label">
                                                                    Select all
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    type="radio"
                                                                    name="selectByDefault"
                                                                    value="date"
                                                                    checked={this.state.selectBy === "date"}
                                                                    onChange={this.handleRadioChange}
                                                                />
                                                                <label className="form-check-label">
                                                                    Select by date
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {this.state.selectBy == "date" ? (
                                                            <div>
                                                                <div className="mb-2 d-flex flex-start">
                                                                    <CustomDateRangePicker
                                                                        fromDate={this.state.fromDate}
                                                                        toDate={this.state.toDate}
                                                                        setFromDate={this.setFromDate}
                                                                        setToDate={this.setToDate}
                                                                    />
                                                                    <button
                                                                        onClick={this.filterByDate}
                                                                        type="button"
                                                                        className="btn btn-primary btn-sm mt-2 mx-2"
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>NHC Revenue</b>
                                                            </div>
                                                            <div className="ml-auto">
                                <span
                                    style={{
                                        color:
                                            this.state.pendingAdminFee > 0
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                  <b>
                                    Pending{" "}
                                      {new Intl.NumberFormat('en-US', {
                                          style: 'currency',
                                          currency: 'USD'
                                      }).format(this.state.pendingAdminFee).replace(/^(\D+)/, '$')}
                                  </b>
                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(
                                                                            this.state.pendingAdminFee
                                                                        )
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingAdminFee == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitAdminFee"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitAdminFee}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingAdminFee == 0 ||
                                                                        this.state.submitAdminFee == 0 ||
                                                                        this.state.submitAdminFee >
                                                                        this.state.pendingAdminFee
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "admin",
                                                                            this.state.submitAdminFee
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>GST/HST Amount</b>
                                                            </div>
                                                            <div className="ml-auto">
                                <span
                                    style={{
                                        color:
                                            this.state.pendingGst > 0
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                  <b>
                                    Pending{" "}
                                      {new Intl.NumberFormat('en-US', {
                                          style: 'currency',
                                          currency: 'USD'
                                      }).format(this.state.pendingGst).replace(/^(\D+)/, '$')}
                                  </b>
                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(this.state.pendingGst)
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingGst == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitGst"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitGst}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingGst == 0 ||
                                                                        this.state.submitGst == 0 ||
                                                                        this.state.submitGst > this.state.pendingGst
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "gst",
                                                                            this.state.submitGst
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>Ontario RST Amount</b>
                                                            </div>
                                                            <div className="ml-auto">
                                <span
                                    style={{
                                        color:
                                            this.state.pendingOnRst > 0
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                  <b>
                                    Pending{" "}
                                      {new Intl.NumberFormat('en-US', {
                                          style: 'currency',
                                          currency: 'USD'
                                      }).format(this.state.pendingOnRst).replace(/^(\D+)/, '$')}
                                  </b>
                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(this.state.pendingOnRst)
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingOnRst == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitOnRst"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitOnRst}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingOnRst == 0 ||
                                                                        this.state.submitOnRst == 0 ||
                                                                        this.state.submitOnRst >
                                                                        this.state.pendingOnRst
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "rst",
                                                                            this.state.submitOnRst
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col my-2">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <b>Ontario Premium Tax Amount</b>
                                                            </div>
                                                            <div className="ml-auto">
                                <span
                                    style={{
                                        color:
                                            this.state.pendingOnPremium > 0
                                                ? "red"
                                                : "green",
                                    }}
                                >
                                  <b>
                                    Pending{" "}
                                      {this.state.pendingOnPremium.toLocaleString(
                                          undefined,
                                          {style: "currency", currency: "USD"}
                                      )}
                                  </b>
                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.copyClipboard(
                                                                            this.state.pendingOnPremium
                                                                        )
                                                                    }
                                                                    className="btn btn-sm no-focus-shadow"
                                                                >
                                                                    <FontAwesomeIcon icon={faCopy}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="input-group mt-2">
                                                                <input
                                                                    // disabled={this.state.pendingOnPremium == 0}
                                                                    disabled={true}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="submitOnPremium"
                                                                    onChange={(e) => this.setInput(e)}
                                                                    value={this.state.submitOnPremium}
                                                                    placeholder={"Enter pending amount"}
                                                                />
                                                                <button
                                                                    disabled={
                                                                        this.state.pendingOnPremium == 0 ||
                                                                        this.state.submitOnPremium == 0 ||
                                                                        this.state.submitOnPremium >
                                                                        this.state.pendingOnPremium
                                                                    }
                                                                    onClick={() =>
                                                                        this.confirmAmount(
                                                                            "premium",
                                                                            this.state.submitOnPremium
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary"
                                                                    type="button"
                                                                    style={{zIndex: 0}}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        }
    }
);
