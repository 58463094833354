
import React from 'react';
import {makeComponent} from '../core';

 export const Card  = makeComponent(class extends React.Component<{label?:string,actions?:any,background?:string,padding?:string}>{
    render() {
       
        let label = this.props.label;
        return Card.renderSection(this.props.children, label,this.props.background,this.props.padding,null,this.props.actions);

    }

    static renderSection(content:any, label:string,background:string,padding:string,helpTopic:string,actions:any){
        let header;
        let actionElems = actions;
        if (label || helpTopic || actionElems){
            let title = <div className="RT-Section__title">{label}</div>
            let buttons;
            let helpButton;
            if (helpTopic){
              //  helpButton =<Help.DialogHelpButton topic={helpTopic}/>;
            }
            if (actionElems || helpButton){
                buttons = <div className="RT-Section__actions">{actionElems}{helpButton}</div>;
            }

            header = <div className="RT-Section__header">
                {title}
                {buttons}
            </div>
        }
        let className = "RT-Section";
       
        if (background){
            className += " RT-Section--background-" + background;
        }
        if (padding){
            className += " RT-Section--padding-" + padding;
        }
        return <div className={className}>
            {header}
            {content}
        </div>
    
    }
});