import React from "react";
import {KTMScreen} from "ktm-ui";
import toastr from "toastr";
import {Button, Modal,} from "react-bootstrap";
import moment from "moment";

export default KTMScreen(
    {name: "policyfunding"},
    class extends React.Component<any, any> {
        state = {
            loading: true,
            success: false,
            searchInput: null,
            searchResult: null,
            note: null,
            chqNo: null,
            amount: 0,
            ledger: [],
            showFundingModal: false,
            hasTeladoc: false
        };

        componentDidMount() {
        }

        submitSearch = () => {
            if (this.state.searchInput != null && this.state.searchInput != "") {
                fetch("/api/payment/searchpolicy/" + this.state.searchInput, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        this.setState(
                            {
                                searchResult: data,
                                loading: false,
                                success: data.success,
                                amount: 0,
                                chqNo: null,
                                hasTeladoc: data.hasTeladoc
                            },
                            () => {
                                if (!this.state.success) {
                                    toastr.error("Policy not found", "Error");
                                }
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState({searchResult: null, success: false});
                        toastr.error("Search failed", "Error");
                    });
            }
        };

        handleCloseFundingModal = () => {
            this.setState({showFundingModal: false});
        };

        handleSubmitFundingModal = () => {
            this.submitFunding();
            this.setState({showFundingModal: false});
        };

        verifyFunding = () => {
            var amountExists = false,
                currentDate = new Date();
            if (
                this.state.searchResult.ledgerEntries != null &&
                this.state.searchResult.ledgerEntries.length > 0
            ) {
                amountExists = this.state.searchResult.ledgerEntries.some((entry) => {
                    let entryDate = new Date(entry.transDate);
                    return entry.amount === Number(this.state.amount) && entryDate.getFullYear() === currentDate.getFullYear() && entryDate.getMonth() === currentDate.getMonth() && entryDate.getDate() === currentDate.getDate();
                });
                if (amountExists) {
                    this.setState({showFundingModal: true});
                } else {
                    this.submitFunding();
                }
            } else {
                this.submitFunding();
            }
        };
        submitFunding = () => {
            fetch("/api/payment/setonlinefunds", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    policyId: this.state.searchResult.policyId,
                    amount: this.state.amount,
                    note: this.state.note,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: data,
                            loading: false,
                            success: data.success,
                            amount: 0,
                            chqNo: null
                        },
                        () => {
                            if (!this.state.success) {
                                toastr.error("Failed to submit", "Error");
                            }
                        }
                    );
                })
                .catch((error) => {
                    toastr.error("Submission failed", "Error");
                });
        };

        setInput = (e) => {
            this.setState({searchInput: e.target.value});
        };
        setNote = (e) => {
            this.setState({note: e.target.value});
        };
        setChqNo = (e) => {
            this.setState({chqNo: e.target.value}, () => {
                var newNote = "Cheque " + this.state.chqNo;
                this.setState({note: newNote})
            });
        };
        setAmount = (e) => {
            this.setState({amount: e.target.value});
        };

        handleKeyDown = (event) => {
            if (event.key === "Enter") {
                this.submitSearch();
            }
        };

        render() {
            let responseContents = null;
            let ledgerTable = null;

            let fundingModal = <Modal show={this.state.showFundingModal} onHide={this.handleCloseFundingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Apply Funding</Modal.Title>
                </Modal.Header>
                <Modal.Body>There is a recent funding entry for this amount. Would you like to proceed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseFundingModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmitFundingModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            if (this.state.searchResult != null && this.state.success) {
                if (
                    this.state.searchResult.ledgerEntries != null &&
                    this.state.searchResult.ledgerEntries.length > 0
                ) {
                    ledgerTable = (
                        <table className="table">
                            <thead>
                            <tr>
                                <td>Type</td>
                                <td style={{textAlign: "right"}}>Amount</td>
                                <td>Date</td>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.searchResult.ledgerEntries.map((data, key) => {
                                return (
                                    <tr>
                                        <td>{data.note}</td>
                                        <td style={{textAlign: "right"}}>
                                            {data.amount.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </td>
                                        <td>
                                            {moment(data.transDate).local().format('YYYY-MM-DD')}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    );
                }

                let teladocBadge = null;
                if (this.state.hasTeladoc) {
                    teladocBadge = (
                        <span className="badge bg-primary text-white rounded-pill px-3 py-2">
                            Teladoc
                        </span>
                    );
                }

                responseContents = (
                    <div>
                        <div className="ktm-funding-name">
                            {this.state.searchResult.policyName} {teladocBadge}
                        </div>
                        <div className="ktm-funding-balance-container">
                            <div className="ktm-funding-balance-label">
                                Current Balance:{" "}
                                <div className="ktm-funding-balance">
                                    $
                                    {this.state.searchResult.currentBalance.toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                </div>
                            </div>
                            <div className="ktm-funding-balance-label ktm-funding-balance-label-right">
                                <div style={{paddingRight: 15}}>Submit Funding</div>
                                <div>
                                    <select
                                        className="form-control"
                                        onChange={(e) => this.setNote(e)}
                                    >
                                        <option>Select Type</option>
                                        <optgroup label="Payment Method">
                                            <option value="Cheque">Cheque</option>
                                            <option value="Direct Deposit">Direct Deposit</option>
                                            <option value="e-Tranfer">e-Transfer</option>
                                            <option value="EA">EA</option>
                                            <option value="Telpay">Telpay</option>
                                            <option value="Wire">Wire</option>
                                        </optgroup>
                                        <option disabled>----------</option>
                                        <optgroup label="Bank">
                                            <option value="ATB">ATB Financial</option>
                                            <option value="BMO">BMO Bank of Montreal</option>
                                            <option value="BNC">BNC</option>
                                            <option value="BoA">BoA</option>
                                            <option value="Central 1">Central 1</option>
                                            <option value="CIBC">CIBC</option>
                                            <option value="HSBC">HSBC</option>
                                            <option value="MLB">MLB</option>
                                            <option value="RBC">RBC</option>
                                            <option value="Scotiabank">Scotiabank</option>
                                            <option value="TD">TD Canada Trust</option>
                                            <option value="Credit Union / Other">
                                                Credit Union / Other
                                            </option>
                                            <option value="Other">Other</option>
                                        </optgroup>
                                        {/* <optgroup label="Misc">
                    </optgroup> */}
                                    </select>
                                    <div>
                                        <div>
                                            {this.state.note != null &&
                                            this.state.note.toLowerCase().includes("cheque") ? (
                                                <div>
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) => this.setChqNo(e)}
                                                        placeholder="Cheque No."
                                                        value={this.state.chqNo}
                                                        onKeyPress={(e) => {
                                                            const keyCode = e.keyCode || e.which;
                                                            if (keyCode < 48 || keyCode > 57) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                            <div>
                                                <input
                                                    className="form-control"
                                                    onChange={(e) => this.setAmount(e)}
                                                    value={this.state.amount}
                                                    placeholder="Amount"
                                                    onKeyPress={(e) => {
                                                        // Allow only digits and decimal point
                                                        const keyCode = e.keyCode || e.which;
                                                        const keyValue = String.fromCharCode(keyCode);
                                                        const validCharRegex = /[0-9.]/;
                                                        if (!validCharRegex.test(keyValue)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => this.verifyFunding()}
                                                className="btn btn-primary"
                                                type="button"
                                                style={{width: "100%", marginTop: 15}}
                                                disabled={
                                                    this.state.note == null || this.state.amount <= 0
                                                }
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>Recent Funding (Last 5)</div>
                        <div>{ledgerTable}</div>
                    </div>
                );
            }
            return (
                <div>
                    <div className="ktm-financials-content">
                        <div className="input-group mb-3">
                            <input
                                onKeyDown={this.handleKeyDown}
                                type="text"
                                className="form-control"
                                name="searchInput"
                                onChange={(e) => this.setInput(e)}
                                value={this.state.searchInput}
                                placeholder={
                                    "Enter policy name, policy number, or account number"
                                }
                            />
                            <button
                                onClick={() => this.submitSearch()}
                                className="btn btn-outline-primary"
                                type="button"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    {responseContents}
                    {fundingModal}
                </div>
            );
        }
    }
);