import React from 'react';
import { InputControl } from '../../core';

let KeyCode = {
    BACKSPACE: 8,
    TAB: 9,
    RETURN: 13,
    ESCAPE: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
}



export class DatePicker extends React.Component<{ value: string, onChange: (date: string) => void, close: () => void ,input?:InputControl}> {

    state = {
        activeDate: null,
        activeStartOfMonthDate: null
    }

    componentDidMount(){
        let input = this.props.input;
        if (input){
            input.connectKeyListener(this,(e:React.KeyboardEvent<HTMLInputElement>)=> {
                this.handleInputKey({keyCode:e.keyCode,altKey:e.altKey})
            })
        }
    }

    componentWillUnmount(){
        let input = this.props.input;
        if (input){
            input.disconnectKeyListener(this);
        }
    }

    handleDayClick = (date: StoredDateFormat) => e => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onChange(date)
        this.setState(state => {
            return {
                isOpen: false
            }
        })
    }

    handlePrevMonthClick = e => {
        e.stopPropagation();
        e.preventDefault();
        this.goPrevMonth();
    }

    handleNextMonthClick = e => {
        e.stopPropagation();
        e.preventDefault();
        this.goNextMonth();
    }

    handleCalendarClick = e => {
        let ele = e.target as HTMLElement
        if (ele.nodeName == "") {
            ele = ele.parentNode as HTMLElement;
        }
        if (ele != null) {
            let handler = ele.attributes.getNamedItem("data-handler")
            if (handler != null && handler.value == 'selectDay') {
                e.preventDefault();
                e.stopPropagation();
                let selectedDateString = ele.dataset["date"] as string;
                this.props.onChange(selectedDateString);

            }
        }
    }

    goPrevMonth() {
        let dateHelper = new DateHelper(this.getActiveStartOfMonthDate())
        dateHelper.goPreviousMonth();
        let activeStartOfMonthDate = { ...dateHelper.currentDate(), day: 1 }
        this.setState(state => {
            return {
                activeDate: null,
                activeStartOfMonthDate
            }
        })
    }

    goNextMonth() {
        let dateHelper = new DateHelper(this.getActiveStartOfMonthDate())
        dateHelper.goNextMonth();
        let activeStartOfMonthDate = { ...dateHelper.currentDate(), day: 1 }
        this.setState(state => {
            return {
                activeDate: null,
                activeStartOfMonthDate
            }
        })
    }

    getActiveStartOfMonthDate() {
        let { activeDate, activeStartOfMonthDate } = this.state
        let startOfMonthDate: IDate
        if (activeStartOfMonthDate != null) {
            startOfMonthDate = { ...(activeStartOfMonthDate as IDate), day: 1 }
        }
        else if (activeDate != null) {
            startOfMonthDate = { ...(activeDate as IDate), day: 1 }
        }
        else {
            startOfMonthDate = { ...this.getCalendarDate(), day: 1 }
        }
        return startOfMonthDate
    }

    getCalendarDate() {
        let calendarDate: IDate
        let parsedDate
        if (this.props.value && this.props.value.length == 10) {
            parsedDate = parseDateFromInput(this.props.value)
        }
        if (parsedDate != null) {
            calendarDate = { ...parsedDate, day: 1 }
        }
        else {
            calendarDate = { ...getToday(), day: 1 }
        }
        return calendarDate
    }

    moveActiveDayIfPossible(numDays: number) {
        let { activeDate, activeStartOfMonthDate } = this.state
        let effectiveActiveDate: IDate
        if (activeDate != null) {
            effectiveActiveDate = activeDate
        } else if (activeStartOfMonthDate != null) {
            effectiveActiveDate = activeStartOfMonthDate
        }
        else {
            effectiveActiveDate = this.getCalendarDate()
        }
        let dateHelper = new DateHelper(this.getActiveStartOfMonthDate())
        let moveToDate = addDaysToDate(effectiveActiveDate, numDays)
        if (dateHelper.canDayBeDisplayed(moveToDate)) {
            this.setState({ activeDate: moveToDate })
        }
    }

    render() {

        let displayDate = this.getActiveStartOfMonthDate()
        let calendarDate = this.getCalendarDate()
        let dateHelper = new DateHelper(displayDate)

        let datePickerMonth = dateHelper.getDisplayCalendarMonth({ selectedDate: calendarDate })
        if (datePickerMonth == null) return null

        let monthName = getMonthName(displayDate.month)
        let yearName = displayDate.year

        let weeks = datePickerMonth.weeks as ICalendarWeek[]

        let renderedWeeks: JSX.Element[] = []
        for (let weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
            let week = weeks[weekIndex];
            let renderedDays: JSX.Element[] = []
            for (let dayIndex = 0; dayIndex < week.days.length; dayIndex++) {
                let day = week.days[dayIndex];
                let dayClass = "RT-DatePicker__day"
                if (dayIndex == 0 || dayIndex == 6) {
                    dayClass += " RT-DataPicker__day--weekend"
                }
                if (day.inActive) {
                    dayClass += " RT-DatePicker__day--other-month"
                }
                if (day.isToday) {
                    dayClass += " RT-DatePicker__day--today"
                }
                if (day.selected) {
                    dayClass += " RT-DatePicker__day--selected";
                }
                renderedDays.push(<button key={dayIndex} className={dayClass} data-handler="selectDay" data-event="click" data-date={day.dateString} data-month={day.date.month} data-year={day.date.year}>
                    {day.day}
                </button>)
            }
            renderedWeeks.push(<div className="RT-DatePicker__week" key={weekIndex}>{renderedDays}</div>)
        }

        return (
            <div className="RT-DatePicker" tabIndex={-1} onKeyDown={this.handleInputKey}>
                <div className="RT-DatePicker__header" >
                    <button className="RT-DatePicker__prev-month" onClick={this.handlePrevMonthClick} title="Prev">
                        {this.renderPrevIcon()}
                    </button>
                    <div className="RT-DatePicker__title">
                        <span className="RT-DatePicker__month">{monthName}</span>
                        <span className="RT-DatePicker__year">{yearName}</span>
                    </div>
                    <button className="RT-DatePicker__next-month" onClick={this.handleNextMonthClick} title="Next">
                        {this.renderNextIcon()}
                    </button>

                </div>
                <div className="RT-DatePicker__day-labels">

                    <div className="RT-DatePicker__day-label">
                        <span title="Sunday">Su</span>
                    </div>
                    <div className="RT-DatePicker__day-label">
                        <span title="Monday">Mo</span>
                    </div>
                    <div className="RT-DatePicker__day-label">
                        <span title="Tuesday">Tu</span>
                    </div>
                    <div className="RT-DatePicker__day-label">
                        <span title="Wednesday">We</span>
                    </div>
                    <div className="RT-DatePicker__day-label">
                        <span title="Thursday">Th</span>
                    </div>
                    <div className="RT-DatePicker__day-label">
                        <span title="Friday">Fr</span>
                    </div>
                    <div className="RT-DatePicker__day-label">
                        <span title="Saturday">Sa</span>
                    </div>
                </div>
                <div onClick={this.handleCalendarClick}>
                    {renderedWeeks}
                </div>

            </div>
        )
    }

    renderPrevIcon() {
        return <svg viewBox="0 0 20 20"><path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2" fillRule="evenodd"></path></svg>
    }

    renderNextIcon() {
        return <svg viewBox="0 0 20 20"><path d="M17.707 9.293l-5-5a.999.999 0 1 0-1.414 1.414L14.586 9H3a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414" fillRule="evenodd"></path></svg>
    }

    handleInputKey = ({ keyCode, altKey }) => {
        if (keyCode == KeyCode.ESCAPE) {
            if (this.props.close) {
                this.props.close();
            }
        }
        else if (keyCode == KeyCode.DOWN) {
            let { activeDate } = this.state
            if (activeDate == null) {
                this.setState({ activeDate: this.getActiveStartOfMonthDate() })
            }
            else {
                this.moveActiveDayIfPossible(7)
            }
            return true
        }
        else if (keyCode == KeyCode.UP) {
            this.moveActiveDayIfPossible(-7)
            return true
        }
        else if (keyCode == KeyCode.RIGHT) {
            this.moveActiveDayIfPossible(1)
            return true
        }
        else if (keyCode == KeyCode.LEFT) {
            this.moveActiveDayIfPossible(-1)
            return true
        }
        else if (keyCode == KeyCode.RETURN) {
            let { activeDate } = this.state
            /*
            if (activeDate != null) {
                this.props.onChange(stringDate(activeDate))
            }
            this.closeCalendar()
            */
            return true
        }
        else if (keyCode == KeyCode.PAGEUP) {
            this.goPrevMonth()
            return true
        }
        else if (keyCode == KeyCode.PAGEDOWN) {
            this.goNextMonth()
            return true
        }
        return false;
    }
}


export interface IDate {
    year: number
    month: number
    day: number
}

export interface ITime {
    hour: number;
    minute: number;
    second: number;
}

export interface IDateParseResult {
    date: IDate
    time: ITime
    isDateTime: boolean
}

export type StoredDateFormat = string

export interface ICalendarDay {
    day: string,
    dateString: StoredDateFormat,
    date: IDate,
    selected: boolean,
    isToday: boolean,
    inActive: boolean
}

export interface ICalendarWeek {
    days: ICalendarDay[]
}

export interface IDisplayCalendarMonth {
    weeks: ICalendarWeek[]
}

let defaultMonthPosition: number = 0;
let defaultDayPosition: number = 1;
let defaultYearPosition: number = 2;

export class DateHelper {

    public static dayNames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    year: number = 0
    month: number = 0
    day: number = 0

    constructor({ year, month, day }: IDate) {
        this.year = year
        this.month = month
        this.day = day
    }

    currentDate(): IDate {
        return {
            year: this.year,
            month: this.month,
            day: this.day
        }
    }

    goPreviousMonth() {
        if (this.month == 1) {
            this.month = 12;
            this.year--;
        }
        else {
            this.month--;
        }
    }

    goNextMonth() {
        if (this.month == 12) {
            this.month = 1;
            this.year++;
        }
        else {
            this.month++;
        }
    }

    addDays(numDays: number) {
        let newDate = addDaysToDate(this.currentDate(), numDays)
        this.year = newDate.year
        this.month = newDate.month
        this.day = newDate.day
    }

    canDayBeDisplayed(searchDate: IDate) {
        let baseYear
        let baseMonth

        if (this.month == 1) {
            baseYear = this.year - 1;
            baseMonth = 11;
        }
        else {
            baseYear = this.year;
            baseMonth = this.month - 1;
        }

        let prevMonthDays = this.daysInMonth(baseYear, baseMonth);
        let curMonthDays = this.daysInMonth(this.year, this.month);
        let firstDayOfMonth = new Date(this.year, this.month - 1, 1).getDay();

        let startDay: number;
        let inPrevMonth: boolean = true;
        let inCurMonth = false;

        if (firstDayOfMonth == 0) { // is Sunday
            startDay = 1;
            baseMonth = this.month;
            baseYear = this.year;
            inCurMonth = true;
            inPrevMonth = false;
        }
        else {
            startDay = prevMonthDays - firstDayOfMonth + 1;
        }

        let baseDay = startDay;

        let col = 0;
        let row = 0;
        let d = startDay;
        let v = "";

        let today = getToday()
        let todayIndex = this.dateDiffDays(baseYear, baseMonth, baseDay, today.year, today.month, today.day);

        let dayIndex = 0

        let baseDate: IDate = {
            year: baseYear,
            month: baseMonth,
            day: baseDay
        }

        for (row = 0; row <= 5; row++) {
            if (row > 0 && !inCurMonth) {
                break;
            }
            for (col = 0; col <= 6; col++) {
                let date = addDaysToDate(baseDate, dayIndex)
                if (compareDate(searchDate, date)) {
                    return true
                }
                d++;
                dayIndex++;
                if (inPrevMonth) {
                    if (d > prevMonthDays) {
                        d = 1;
                        inPrevMonth = false;
                        inCurMonth = true;
                    }
                }
                else if (inCurMonth) {
                    if (d > curMonthDays) {
                        d = 1;
                        inCurMonth = false;
                    }
                }
            }
        }
        return false
    }

    getDisplayCalendarMonth({ selectedDate }: { selectedDate?: IDate | null }): IDisplayCalendarMonth | null {
        let baseYear
        let baseMonth

        if (this.month == 1) {
            baseYear = this.year - 1;
            baseMonth = 12;
        }
        else {
            baseYear = this.year;
            baseMonth = this.month - 1;
        }

        var prevMonthDays = this.daysInMonth(baseYear, baseMonth);
        var curMonthDays = this.daysInMonth(this.year, this.month);
        var firstDayOfMonth = new Date(this.year, this.month - 1, 1).getDay();

        var startDay: number;
        var inPrevMonth: boolean = true;
        var inCurMonth = false;

        if (firstDayOfMonth == 0) { // is Sunday
            startDay = 1;
            baseMonth = this.month;
            baseYear = this.year;
            inCurMonth = true;
            inPrevMonth = false;
        }
        else {
            startDay = prevMonthDays - firstDayOfMonth + 1;
        }

        let baseDay = startDay;

        let col = 0;
        let row = 0;
        let d = startDay;
        let v = "";

        let today = getToday()
        let todayIndex = this.dateDiffDays(baseYear, baseMonth, baseDay, today.year, today.month, today.day);
        let selectedIndex = -1
        if (selectedDate != null) {
            selectedIndex = this.dateDiffDays(baseYear, baseMonth, baseDay, selectedDate.year, selectedDate.month, selectedDate.day);
        }

        let dayIndex = 0;
        let rows: ICalendarWeek[] = [];

        let baseDate: IDate = {
            year: baseYear,
            month: baseMonth,
            day: baseDay
        }

        for (row = 0; row <= 5; row++) {
            var cols: ICalendarDay[] = [];
            var rowData = { days: cols };
            rows.push(rowData);
            if (row > 0 && !inCurMonth) {
                break;
            }
            for (col = 0; col <= 6; col++) {
                let colData: Partial<ICalendarDay> = {}

                colData.day = d.toString();
                colData.dateString = addDays(baseDate, dayIndex)
                colData.date = (parse(colData.dateString) as IDateParseResult).date

                if (dayIndex == selectedIndex) {
                    colData.selected = true;
                }
                if (dayIndex == todayIndex) {
                    colData.isToday = true;
                }
                if (!inCurMonth) {
                    colData.inActive = true;
                }

                cols.push(colData as ICalendarDay)

                d++;
                dayIndex++;
                if (inPrevMonth) {
                    if (d > prevMonthDays) {
                        d = 1;
                        inPrevMonth = false;
                        inCurMonth = true;
                    }
                }
                else if (inCurMonth) {
                    if (d > curMonthDays) {
                        d = 1;
                        inCurMonth = false;
                    }
                }
            }
        }
        return { weeks: rows };
    }

    dateDiffDays(year: number, month: number, day: number, year2: number, month2: number, day2: number) {
        var ms = Date.UTC(year2, month2 - 1, day2) - Date.UTC(year, month - 1, day);
        return ms / 1000 / 60 / 60 / 24;
    }
    daysInMonth(year: number, month: number) {
        var d = Date.UTC(year, month, 1) - Date.UTC(year, month - 1, 1);
        d = d / 1000 / 60 / 60 / 24;
        return d;
    }
}

export let compareDate = (a: IDate, b: IDate) => {
    return a.day === b.day && a.month === b.month && a.year === b.year
}

export let addDaysToDate = (date: IDate, numDays: number) => {
    let dt = Date.UTC(date.year, date.month - 1, date.day) + numDays * 1000 * 60 * 60 * 24;
    let d = new Date(dt);
    return {
        year: d.getUTCFullYear(),
        month: d.getUTCMonth() + 1,
        day: d.getUTCDate()
    }
}

export let getToday = (): IDate => {
    var date = new Date();
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    }
}

export let addDays = (date: IDate, numDays: number) => {
    let newDate = addDaysToDate(date, numDays)
    return storedDateFormat(newDate.year, newDate.month, newDate.day);
}

export let storedDateFormat = (year: number, month: number, day: number): string => {
    return year + '-' + padZero(month.toString(), 2) + '-' + padZero(day.toString(), 2);
}

export let parseDateFromInput = (value: string): IDate | null => {
    let parsedDate = parseDateFromInput_(value)
    if (parsedDate == null) {
        let parseResult = parse(value)
        if (parseResult != null) {
            parsedDate = parseResult.date
        }
    }
    if (parsedDate != null) {
        return parsedDate
    }
    return null
}

const parseDateFromInput_ = (value: string): IDate | null => {
    if (!value) return null;

    var segments = value.split(/\W+/);

    var today = new Date();
    var day: number;
    var month: number;
    var year: number;

    if (segments.length == 1) {
        // assume entered only day of month
        day = parseInt(segments[0], 10);
        if (isFinite(day) && day > 0 && day <= 31) {
            return {
                year: today.getFullYear(),
                day: day,
                month: today.getMonth() + 1,
            }
        }
        else {
            return null;
        }
    }
    else if (segments.length == 2) {
        // assume entered month and day
        if (defaultMonthPosition < defaultDayPosition) {
            month = parseInt(segments[0], 10);
            day = parseInt(segments[1], 10);
        }
        else {
            month = parseInt(segments[1], 10);
            day = parseInt(segments[0], 10);
        }
        if (!isFinite(month) || month < 1 || month > 12) {
            return null;
        }

        if (!isFinite(day) || day < 1 || day > 31) {
            return null;
        }
        return {
            year: today.getFullYear(),
            month,
            day
        }
    }
    else {
        month = parseInt(segments[defaultMonthPosition], 10);
        if (!isFinite(month) || month < 1 || month > 12) {
            return null;
        }
        day = parseInt(segments[defaultDayPosition], 10);
        if (!isFinite(day) || day < 1 || day > 31) {
            return null;
        }
        year = parseInt(segments[defaultYearPosition], 10);
        if (!isFinite(year)) {
            return null;
        }
        if (year < 40) {
            year += 2000;
        }
        else if (year < 100) {
            year += 1900;
        }
        return {
            year,
            month,
            day
        }
    }
}

export let parse = (value: string): IDateParseResult | null => {
    if (value == null || value == '') return null;
    var segments = ['year', 'month', 'day', 'hour', 'minute', 'second']
    let isDateTime = false
    let isInvalid = false
    let parsedDate = {}
    let parsedTime = {}
    if (value != null && typeof value == "string") {
        var parts = value.split(/[-\s:]/)
        if (parts.length > 3) {
            isDateTime = true
        }
        for (var i = 0; i < segments.length; i++) {
            var segment = segments[i];
            var part = parts[i];

            var num = parseInt(part, 10);
            if (isNaN(num)) {
                isInvalid = true
            } else {
                if (i < 3) {
                    parsedDate[segment] = num
                }
                else {
                    parsedTime[segment] = num
                }
            }
            if (!isDateTime && i == 2) break
        }
    }
    if (!isInvalid) {
        return {
            date: parsedDate as IDate,
            time: parsedTime as ITime,
            isDateTime
        }
    }
    return null
}

export let getMonthName = (month: number) => {
    var monthName = new Array(null, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
    return monthName[month];
}

export let getDayName = (dw: number) => {
    var dayName = new Array(null, 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
    return dayName[dw];
}

const padZero = (value: string, len: number): string => {
    var count = len - value.length;
    if (count <= 0) {
        return value;
    }
    else {
        for (var i = 1; i <= count; i++) {
            value = '0' + value;
        }
        return value;
    }
}