import React from 'react';
import { FieldRef, makeComponent } from "ktm-ui/core";

export const FileUpload = makeComponent(class extends React.Component<{field:FieldRef}>{
    render(){
        let field = this.props.field;
        if (!field) return null;

        let src = field.value;
        let label = <div style={{fontWeight:500,letterSpacing:"0.35px",fontSize:"12px"}}>{field.label}</div>
        if (src){
            let border = "dashed 2px rgb(192, 208, 230)";
            return (<div  style={{marginBottom:"1rem"}}>
               <div style={{display:"flex",alignItems:"center",marginBottom:6}}>
                    {label}
                    <button onClick={this.handleClearClick} style={{fontSize:"13px",marginLeft:20}} className="RT-Link">Edit</button>
                </div>
                <div style={{border,padding:10,position:"relative"}}>
                    <img src={src}/>
                </div>
            </div>);
        }
        return <div  style={{marginBottom:"1rem"}}> 
            <div style={{marginBottom:6}}>{label}</div>
            <FileDropZone onDrop={this.handleFileDrop} hasContent={false} />
        </div>
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let field = this.props.field;
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
            field.setValue(e.target.result);
        }
        reader.readAsDataURL(file);
    }

    handleFileDrop = (file:File) => {
        let field = this.props.field;
        let reader = new FileReader();
        reader.onload = (e) => {
            field.setValue(e.target.result);
        }
        reader.readAsDataURL(file);
    }

    handleClearClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let field = this.props.field;
        field.setValue(null);
    }
});



class FileDropZone extends React.Component<{onDrop:(file:File) => void,hasContent:boolean},{isDropTarget:boolean}>{

    dragCounter = 0;

    constructor(props){
        super(props);
        this.state = {isDropTarget:false};
    }
    render(){
        let content;
        let border;
        let background;
    
        if (this.state.isDropTarget){
            background = "rgb(255,255,255)";
            content = "Drop file here";
            border = "dashed 2px rgb(192, 208, 230)";
        }
        else if (!this.props.hasContent){
            content = "Drop file here";
            border = "dashed 2px rgb(192, 208, 230)";
        }
        else {
            content = "File content uploaded";
            border = "solid 2px rgb(192, 208, 230)";
        }
        let icon = this.renderIcon("rgb(192,208,230)",48);
        return <div style={{padding:40,border,background,borderRadius:4,textAlign:'center',fontWeight:500,fontSize:"14px",position:"relative"}} onDrop={this.handleDrop} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDragOver={this.handleDragOver}>
            {content}
            <div style={{marginTop:20,display:'flex',justifyContent:'center'}}>{icon}</div>
            <div style={{marginTop:10}}>or click to choose file</div>
            <input style={{position:"absolute",top:0,bottom:0,width:"100%",left:0,opacity:0}} type="file" onChange={this.handleChange}/>

        </div>
    }


    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let file = e.target.files[0];
        this.props.onDrop(file);
    }


    handleDrop = (e:React.DragEvent) => {
        e.preventDefault();
        this.setState({isDropTarget:false});

        if (e.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          for (var i = 0; i < e.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (e.dataTransfer.items[i].kind === 'file') {
              var file = e.dataTransfer.items[i].getAsFile();
               this.props.onDrop(file);
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          for (var i = 0; i < e.dataTransfer.files.length; i++) {
              var file = e.dataTransfer.files[i];
            this.props.onDrop(file);
          }
        }
    }

    handleDragOver = (e:React.DragEvent) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy"
    }

    handleDragEnter = (e:React.DragEvent) => {
        
        e.dataTransfer.dropEffect = "copy";
        if (!this.state.isDropTarget){
            this.setState({isDropTarget:true})
        }
        this.dragCounter++;
    }

    handleDragLeave = (e:React.DragEvent) => {
        this.dragCounter--;
        if (this.dragCounter == 0) {
            this.setState({ isDropTarget: false });
        }
        return false;
    }

    renderIcon(fill: string,width:number) {
        return (
            <svg
                style={{ width, height: width, display: "block", fill }}
                viewBox="0 0 24 24"
            >
                <path  d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,0-2,0v6a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12Z"/>
            </svg>
        );
    }

}
