import { useState } from "react";
import { CustomLoading } from "components/CustomLoading";

const TwoFactorScreen = ({ onSubmit, logoUrl }) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    setToken(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(null);

    const isErrorWithMessage = (error: unknown): error is { message: string } => {
        return typeof error === 'object' && error !== null && 'message' in error;
    };

    try {
      await onSubmit(token);
    } catch (error) {
       if (isErrorWithMessage(error)) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage("An unexpected error occurred.");
        }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div className="RT-Login__form">
      {logoUrl && (
        <div className="two-factor-screen__logo">
          <img src={logoUrl} alt="Logo" />
        </div>
      )}
      <div style={{fontSize:"0.9rem", display:"block", marginTop: 20, marginBottom: 20}} >
        We've sent a code to your email address. Enter it below to finish logging in.
      </div>
      {errorMessage && (
            <div className="RT-Login__error">
            {errorMessage}
            </div>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <input
            className="RT-Login__input"
            placeholder="Enter your code"
            type="text"
            name="token"
            value={token}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="RT-Login__button-container">
          {!loading && (
            <button
              type="submit"
              className="RT-Button RT-Button--primary RT-Button--block"
            >
              Submit
            </button>
          )}
          {loading && <CustomLoading />}
        </div>
      </form>
    </div>
  );
};

export default TwoFactorScreen;
