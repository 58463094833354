import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Button,DataGrid,IDataGridLayout} from 'ktm-ui';
import Activate_policyScreen from 'enroll/policy/Activate_policy';
import toastr from "toastr";

interface IdsErrors {
    Policy_Id:string;
    BatchType:string;
    Message:string;
    Severity:string;
};

export default KTMScreen({name:"enroll.policy.activation"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsErrors:IdsErrors[];
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsErrors";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Activation";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.default_view)}
            </Layout>
        );
    }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = (
        <>
            <Button action={action(this.refresh_list)} label="Refresh" style="outline"/>
            <Button action={action(this.activate_policy)} label="Activate Policy" style="primary"/>
        </>);
        
        let layout:IDataGridLayout = {
            columns:[
                {name:"Severity",label:"Severity",type:"character"},
                {name:"Message",label:"Message",type:"longchar",width:500},
            ],
            sort:[{name:"Severity"}]
        };

        return (
            <DataGrid collection={collection("@dsErrors")} layout={layout} actions={actions}/>
        );
    }

    async refresh_list({screen}:ActionEvent){
        await KTMApi.action(screen,"refresh",{
            "@policy_id":this.props.policy_id,
        });
        await screen.restart();
    }

    async activate_policy({screen}:ActionEvent){
        await KTMApi.action(screen,"refresh",{
            "@policy_id":this.props.policy_id,
        });

        if (!this.check_errors()){
            toastr.error("There are errors in the table.", "Error");
            return;
        }

        await screen.openDialog(<Activate_policyScreen policy_id={this.props.policy_id}/>);
    }

    check_errors(){
        let errors = this.data.dsErrors.filter((error)=>error.Severity=="ERROR");
        if (errors.length>0){
            return false;
        }
        return true;
    }
        
});
