import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';

interface IdsDateRange {
    Id:string;
    Policy_Id:string;
    Employee_Id:string;
    Dependent_Id:string;
    RangeType:string;
    FromDate:string;
    ToDate:string;
    Comment:string;
    CreatedAt:string;
    CreatedBy:string;
    UpdatedAt:string;
    UpdatedBy:string;
};

export default KTMScreen({name:"planadmin.employee.add-date-range"}, class extends React.Component<{employee_id:string}> {

    data:{
        dsDateRange:IdsDateRange;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsDateRange";
        await KTMApi.start(screen,{
            "@employee_id":this.props.employee_id,
        });
        screen.title = "Add date range";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add date range"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        let dateRangeSubmitButton = null;
        if((this.data.dsDateRange.RangeType === null || this.data.dsDateRange.RangeType === undefined || this.data.dsDateRange.RangeType === '-- Select --') || (this.data.dsDateRange.Dependent_Id === '-- Select --') || (this.data.dsDateRange.FromDate === null || this.data.dsDateRange.FromDate === undefined || this.data.dsDateRange.FromDate === '')) {
            dateRangeSubmitButton = 
            <button disabled className='btn btn-secondary'>Save</button>
        }
        else {
            dateRangeSubmitButton = <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
        }

        return (
            <Dialog.Footer>
                {dateRangeSubmitButton}
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display required field={field("RangeType")}/>
                <Display required field={field("Dependent_Id")}/>
                <Line>
                    <Display required field={field("FromDate")}/>
                    <Display field={field("ToDate")}/>
                </Line>
                <Display field={field("Comment")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@employee_id":this.props.employee_id,
            "@dsDateRange.Dependent_Id":this.data.dsDateRange.Dependent_Id,
            "@dsDateRange.RangeType":this.data.dsDateRange.RangeType,
            "@dsDateRange.FromDate":this.data.dsDateRange.FromDate,
            "@dsDateRange.ToDate":this.data.dsDateRange.ToDate,
            "@dsDateRange.Comment":this.data.dsDateRange.Comment,
        });
        screen.close({"continue":true});
    }

});
