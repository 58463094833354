import React from "react";
import toastr from "toastr";
import { KTMScreen } from "ktm-ui";
import { Spinner, Table } from "react-bootstrap";
import axios from "axios";
import CustomPagination from "components/CustomPagination";
import EditPlanAdminSettingModal from "components/EditPlanAdminSettingModal";
import moment from "moment";

export default KTMScreen(
  { name: "inactive_planadmin" },
  class extends React.Component<any, any> {

    constructor(props: any) {
      super(props);
      this.state = {
      loading: true,
      planadmins: [],
      adminEmailInput: "",
      currentPage: 1,
      itemsPerPage: 8,
      isPAUpdated: false,
    };

      this.getInactivePlanAdmins = this.getInactivePlanAdmins.bind(this);
      this.assignAdmin = this.assignAdmin.bind(this);
      this.removeAdmin = this.removeAdmin.bind(this);
    }

    componentDidMount() {
     this.getInactivePlanAdmins();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
      if (this.state.isPAUpdated !== prevState.isPAUpdated) {
        this.getInactivePlanAdmins();
        this.setState({ isPAUpdated: false });
      }
    }

    setCurrentPage = (page: number) => {
      this.setState({ currentPage: page });
    };

    getInactivePlanAdmins = () => {
      axios
        .get("/api/assignplanadmin/getinactiveplanadmins?policyId=" + this.props.policy_id)
        .then((res) => {
          this.setState({ 
            planadmins: res.data,
            loading: false,
          });
        })
        .catch((err) => {
          toastr.error("Failed to get plan admins", "Error");
        });
    }

    assignAdmin = async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          policyId: this.props.policy_id,
          email: this.state.adminEmailInput,
        }),
      };

      try {
        const response = await fetch(
          "/api/assignplanadmin/assign",
          requestOptions
        );
        const data = await response.json();
        if(data.success) {
          this.getInactivePlanAdmins();
          toastr.success("Plan admin assigned","Success");
        }
        else {
          toastr.error(data.message, "Error");
        }
      } catch (error) {
        toastr.error("Failed to assign admin", "Error");
      }
    };

    removeAdmin = (id:any) => {
      axios
      .post("/api/assignplanadmin/remove", {
        policyId: this.props.policy_id,
        userId: id,
        })
      .then((res) => {
        if(res.data.success) {
          this.getInactivePlanAdmins();
          toastr.success("Plan admin removed","Success");
        }
        else {
          toastr.error(res.data.message, "Error");
        }
      })
      .catch((err) => {
        toastr.error("Failed to remove admin", "Error");
      });
    };

    sendEmail = (id: any) => {
      axios
      .put("/api/assignplanadmin/sendpaemail", {
        policyId: this.props.policy_id,
        userId: id,
        })
      .then((res) => {
        if(res.data.success) {
          toastr.success("Email sent","Success");
          this.setState({
            isPAUpdated : true
          });
        }
        else {
          toastr.error(res.data.message, "Error");
        }
      })
      .catch((err) => {
        toastr.error("Failed to send email", "Error");
      });
    };

    render() {
      const {
        planadmins,
        loading,
        currentPage,
        itemsPerPage
      } = this.state;
      const { policy_id } = this.props;

      const headers = [
        {
          label: "Last Name",
          key: "lastName",
          resizable: true,
        },
        {
          label: "First Name",
          key: "firstName",
          resizable: true,
        },
        {
          label: "Email",
          key: "email",
          resizable: true,
        },
        {
          label: "Hide on EE Portal",
          key: "hideOnEE",
        },
        {
          label: "Receive Email",
          key: "receiveEmail",
        },
        {
          label: "Group PA",
          key: "groupPA",
        },
        {
          label: "Individual PA",
          key: "individualPA",
        },
        {
          label: "Role",
          key: "role",
        },
        {
          label: "Email Send Date",
          key: "emailSendDate"
        },
        {
          label: "Remove",
          key: "remove",
        },
        {
          label: "Send Email",
          key: "sendEmail",
        },
        {
          label: "Edit",
          key: "edit",
        }
      ];

      var contents = null;
      if (loading) {
          contents = (
              <div className="pad-container">
                  <Spinner
                  className="pad-spinner"
                  animation="border"
                  variant="primary"
                  />
              </div>
              );
      } else {
          const indexOfLastRecord = currentPage * itemsPerPage;
          const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
          const currentRecords = planadmins.slice( indexOfFirstRecord, indexOfLastRecord );
          const nPages = Math.ceil(planadmins.length / itemsPerPage);

      let actions = (
        <>
          <input
            className="RT-TextBox__input"
            type="text"
            placeholder="Enter admin email"
            id="paSearch"
            onChange={(event) => {
              this.setState({ adminEmailInput: event.target.value });
            }}
            style={{ backgroundColor: "white", width: "auto", height: "26px" }}
          />
          <button
            className="RT-Button RT-Button--primary"
            onClick={() => this.assignAdmin()}
            style={{ height: "26px", width: "80px", padding: "0px" }}
          >
            Assign
          </button>
        </>
      );

      let adminList =currentRecords.map((data, i) => {
        return (<tr>
          <td>{data.lastName}</td>
          <td>{data.firstName}</td>
          <td style={{width:"250px"}}>{data.email}</td>
          <td>
                {data.hideOnEE ? "Yes" : "No"}
          </td>
          <td>
                {data.receiveEmail ? "Yes" : "No"}
          </td>
          <td>
                {data.sendGroupPAEmail ? "Yes" : "No"}
          </td>
          <td>
                {data.sendIndividualPAEmail ? "Yes" : "No"} 
          </td>
              <td>
                {data.role == null ? "" : data.role} 
          </td>
              <td>
                {data.welcomeEmailSendDate == null ? "Pending": moment(data.welcomeEmailSendDate).format("YYYY-MM-DD")}
              </td>
          <td>
          <a href="#"
              className="RT-Link"
              onClick={() => this.removeAdmin(data.id)}
              style={{ height: "26px", width: "80px", padding: "0px", color: "red" }}
            >
              Remove
            </a>
          </td>
          <td>
          <a href="#"
              className="RT-Link"
              onClick={() => this.sendEmail(data.id)}
              style={{ height: "26px", width: "80px", padding: "0px" }}
            >
              Send Email
            </a>
          </td>
              <td>
                <EditPlanAdminSettingModal 
                  policyId={policy_id} 
                  paData={data} 
                  updatePA={() => {
                    this.setState({ 
                      loading: true,
                      isPAUpdated: true, 
                    });
                  }}/>
              </td>
          </tr>);
          });

          contents = (
            <div className="RT-Stackable-Container RT-Stackable-Container--embedded RT-Stackable-Container--padding-none RT-Stackable-Container--scrollable">
              <div className="RT-Stackable-Container__main">
                <div
                  className="RT-Stackable-Container__body"
                  style={{ display: "block" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      overflow: "unset",
                    }}
                  >
                    <div
                      className="RT-DataTable__list-actions"
                      style={{ paddingBottom: 10 }}
                    >
                      {actions}
                    </div>
                    <Table hover
                      style={{
                        backgroundColor: "white",
                        overflow: "auto",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          }}
                        >
                              {headers.map((column) => (
                                  <th key={column.key} style={
                                    column.key == "email" ? {width:"250px"} : {}
                                  }>
                                    {column.label}
                                  </th>
                                ))}
                        </tr>
                      </thead>
                      <tbody>{adminList}</tbody>
                    </Table>
                    <div className="pagination-container" style={{alignSelf:"end", marginBottom:"10px"}}>
                        <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {planadmins.length} entries</span>
                        </div>
                        <CustomPagination 
                          nPages={nPages} 
                          currentPage={currentPage} 
                          setCurrentPage={this.setCurrentPage}
                        />
                      </div>  
                  </div>
                </div>
              </div>
            </div>
          );
      }

      return <div>{contents}</div>;
    }
  }
);