import React from 'react';
import { ChromePicker } from 'react-color';

export const CustomColorPicker = ({policyId, selectedColor, updateColor, closeColorPicker}) => {
    const [color, setColor] = React.useState(selectedColor);

    const handleCancel = () => {
        closeColorPicker();
    };

    const handleChange = (color) => {
        setColor(color.hex);
    };

    const handleSave = async () => {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ policyId: policyId, newColor: color }),
        };

        let response = await fetch("/api/Settings/updatecompanybrandcolor", requestOptions);
        let data = await response.json();
        if (data.success === false) {
            alert(data.message);
            return;
        }

        updateColor(color);
    }

    return (
        <div className="color-picker-container"
            style={{ 
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#F4F6F9',
                border: '1px solid #e5e7eb',
                borderRadius: '5px',
                width: '270px',
                height: '330px',
                padding: '10px',
            }}
        >
            <ChromePicker color={color} onChange={handleChange} width="250px"/>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <button 
                    className="btn btn-secondary" 
                    onClick={handleCancel}
                    style={{ marginRight: '10px', width: '60px', padding: '5px' }}
                >
                    Cancel
                </button>
                <button 
                    className="btn btn-primary" 
                    onClick={handleSave}
                    style={{ marginRight: '10px', width: '60px', padding: '5px' }}
                >
                    Save
                </button>
            </div>
        </div>
    );
};