import { Component } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import toastr from 'toastr';
import { CustomDropdown } from "components/CustomDropdown";
import { Line } from 'ktm-ui';
import DatePicker from 'react-date-picker';

export default class EditIndividualEmpCampaignModal extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.bencatOptions.find((option) => option.name === this.props.employee.name),
            selectionDeadline: this.props.employee.flexSelectionDeadline || this.props.campaignSelectionDeadline,
            newSalaryMoney: this.props.employee.salaryMoney || 0,
            saveCompleted: false,
            showEmailPrompt: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.employee.name !== this.props.employee.name) {
            this.setState({
                selectedOption: this.props.bencatOptions.find((option) => option.name === this.props.employee.name),
            });
        }
        if (prevProps.employee.flexSelectionDeadline !== this.props.employee.flexSelectionDeadline) {
            this.setState({ selectionDeadline: this.props.employee.flexSelectionDeadline });
        }
        if (prevProps.employee.salaryMoney !== this.props.employee.salaryMoney) {
            this.setState({ newSalaryMoney: this.props.employee.salaryMoney });
        }
    }
    
    handleSave = () => {
        let selectionDate = new Date(this.state.selectionDeadline);

        var body = {
            policyId: this.props.policyId,
            employeeId: this.props.employee.id,
            selectedBenCatId: this.state.selectedOption.id,
            newSelectionDealine: selectionDate.toLocaleDateString(),
            newSalaryMoney: this.state.newSalaryMoney,
        };

        axios({
            method: "put",
            url: "/api/Flex/updateindividualemployeecampaign",
            data: body,
        })
        .then((response) => {
            if (response.data.success) {
                toastr.success("Employee's campaign updated successfully", "Success");
                const today = new Date();
                if (this.state.selectedOption.isFlex && this.props.employee.bencatId !== this.state.selectedOption.id) {
                    if (new Date(this.props.employee.launchDate) <= today) {
                        this.setState({showEmailPrompt: true})
                    }
                    this.setState({saveCompleted: true});
                }
                else {
                    this.props.closeEditIndividualEmpCampaignModal(true);
                }
            } else {
                toastr.error(response.data.message, "Error");
                this.props.closeEditIndividualEmpCampaignModal(false);
            }
        })
        .catch(function (error) {
            toastr.error("Failed to update employee's campaign", "Error");
        });
    };

    handleSendEmail = () => {
        var body = {
            PolicyId: this.props.policyId,
            RecipientIds: [this.props.employee.id],
        };
       
        axios({
            method: "put",
            url: "/api/Flex/sendselectionemails",
            data: body,
        })
        .then((response) => {
            if (response.data.success) {
                toastr.success("Email sent successfully", "Success");
            } else {
                toastr.error(response.data.message, "Error");
            }
        })
        .catch(function (error) {
            toastr.error("Failed to send email", "Error");
        });

        this.setState({ saveCompleted: false, showEmailPrompt: false });
        this.props.closeEditIndividualEmpCampaignModal(true);
    }

    handleCloseEmailModal = () => {
        this.setState({ saveCompleted: false, showEmailPrompt: false });
        this.props.closeEditIndividualEmpCampaignModal(true);
    }

    handleSelect = (option: string) => {
        this.setState({ selectedOption: this.props.bencatOptions.find((bencat) => bencat.name === option) });
    }

    handleDateChange = (date: Date) => {
        this.setState({ selectionDeadline: date });
    }

    handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^0-9.]/g, '');
        this.setState({ newSalaryMoney: parseFloat(value) });
    }

    render() {
        const { 
            showEditIndividualEmpCampaignModal, 
            bencatOptions, 
            closeEditIndividualEmpCampaignModal,
            employee,
        } = this.props;

        const { 
            selectedOption,
            selectionDeadline,
            newSalaryMoney,
            saveCompleted,
            showEmailPrompt,
        } = this.state;

        return (
            <div>
                <Modal className="edit-employee-campaign-modal-container" show={showEditIndividualEmpCampaignModal && !showEmailPrompt}>
                    <Modal.Header className="campaign-edit-employee-modal-header">
                        <Modal.Title>Edit Employee Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="campaign-edit-employee-modal-body">
                        <Card>
                            <Line>
                                <div className='RT-Static-Field' style={{marginLeft: 10}}>
                                    <label className='RT-Static-Field__label' style={{color: 'black'}}>Employee Name</label>
                                    <div className='RT-Static-Field__content' style={{ height: '21px', fontWeight: 'bold' }}>
                                        {employee.lastName},{employee.firstName}
                                    </div>
                                </div>
                            </Line>
                            <Line>
                                <div className="RT-TextBox">
                                    <label className="RT-Label">Benefit Category</label>
                                    <CustomDropdown
                                        options={bencatOptions.map((option) => option.name)}
                                        selectedOption={selectedOption.name}
                                        setSelectedOption={this.handleSelect}
                                    />
                                </div>
                            </Line>
                            <Line>
                                <div className="date-picker-container">
                                    <label className="RT-Label">Selection Deadline</label>
                                    <DatePicker
                                        onChange={this.handleDateChange}
                                        value={new Date(selectionDeadline)}
                                        format={"y-MM-dd"}
                                        minDate={new Date()}
                                        clearIcon={null}
                                    />
                                </div>
                            </Line>
                            {(employee.creditCalcMethod === "SALARY" || selectedOption.creditCalcMethod === "SALARY") && (
                                <Line>
                                    <div className="form-group" style={{ marginLeft: 10, width: "50%" }}>
                                        <label htmlFor="limit">Salary</label>
                                        <div className="input-group" style={{width:250, height:35}}>
                                            <span className="input-group-text">$</span>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="limit" 
                                                value={newSalaryMoney}
                                                onChange={this.handleSalaryChange}
                                            />
                                        </div>
                                    </div>
                                </Line>
                            )}
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className="campaign-edit-employee-modal-footer">
                        <Button variant="secondary" onClick={closeEditIndividualEmpCampaignModal}>Close</Button>
                        <Button variant="primary" onClick={this.handleSave}>Save</Button>
                    </Modal.Footer>
                </Modal>

                <Modal className="edit-employee-campaign-modal-container" show={showEmailPrompt}>
                    <Modal.Header className="campaign-edit-employee-modal-header">
                        <Modal.Title>Send Flex Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="campaign-edit-employee-modal-body">
                        <Card>
                            <p style={{fontWeight: 'bold', color: 'red'}}>Do you want to send a flex email to the employees?</p>
                        </Card>
                    </Modal.Body>
                    <Modal.Footer className="campaign-edit-employee-modal-footer">
                        <Button variant="secondary" onClick={this.handleCloseEmailModal}>No</Button>
                        <Button variant="primary" onClick={this.handleSendEmail}>Yes</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
