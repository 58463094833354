import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';

interface IdsBenefit {
    Id:string;
    Policy_Id:string;
    BenefitType_Id:string;
    BenefitPricing_Id:string;
};

interface IdsPrice {
    Id:string;
    BenefitType_Id:string;
    Name:string;
    PriceSingle:number;
    PriceCouple:number;
    PriceFamily:number;
};

export default KTMScreen({name:"enroll.product.edit-pricing"}, class extends React.Component<{policyBenefit_id:string}> {

    data:{
        dsBenefit:IdsBenefit;
        dsPrice:IdsPrice;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenefit";
        await KTMApi.start(screen,{
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.title = "Edit product pricing";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit product pricing"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("BenefitPricing_Id")}/>
                <Line>
                    <Display field={field("@dsPrice.PriceSingle")}/>
                    <Display field={field("@dsPrice.PriceCouple")}/>
                    <Display field={field("@dsPrice.PriceFamily")}/>
                </Line>
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@dsBenefit.BenefitPricing_Id":this.data.dsBenefit.BenefitPricing_Id,
            "@policyBenefit_id":this.props.policyBenefit_id,
        });
        screen.close({"continue":true});
    }

});
