import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, Panel, Column} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Button,DataGrid,IDataGridLayout} from 'ktm-ui';
import Add_date_rangeScreen from './Add_date_range';
import Edit_date_rangeScreen from './Edit_date_range';
import Remove_date_rangeScreen from "./Remove_date_range";
import { FaMinusCircle } from "react-icons/fa";

interface IdsData {
    Id:string;
    Policy_Id:string;
    Employee_Id:string;
    Dependent_Id:string;
    RangeType:string;
    FromDate:string;
    ToDate:string;
    Comment:string;
    DependentName:string;
    RangeTypeLabel:string;
};

export default KTMScreen({name:"enroll.employee.date-range-list"}, class extends React.Component<{employee_id:string}> {

    data:{
        dsData:IdsData[];
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsData";
        await KTMApi.start(screen,{
            "@employee_id":this.props.employee_id,
        });
        await this.setEmployee();
        screen.title = "Date ranges";
    }

    main({ field, draw, action }: RenderEvent) {
        return (
          <Panel>
            {this.data.dsData && this.data.dsData.length > 0 ? (
              <Column>
                {draw(this.default_view)}
              </Column>
            ) : (
              <Column>{draw(this.note)}</Column>
            )}
          </Panel>
        );
      }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = (<><Button action={action(this.add)} label="Add date range" style="add"/>
</>);
        let layout:IDataGridLayout = {
            columns:[
                {name:"RangeTypeLabel",label:"Range Type",type:"character"},
                {name:"DependentName",label:"Dependent",type:"character",width:200},
                {name:"FromDate",label:"From Date",type:"date"},
                {name:"ToDate",label:"To Date",type:"date"},
                {name:"Comment",label:"Comment",type:"longchar",width:200},
                {action:"edit",label:"Edit",width:80},
                {action:"remove",label:"Remove",width:80}
            ],
            sort:[{name:"FromDate",descending:true},{name:"DependentName"}]
        };
        return (
            <DataGrid collection={collection("@dsData")} layout={layout} actions={actions}/>
        );
    }

    note({ field, draw, action, collection }: RenderEvent) {
        let actions = (
          <>
            <Button
              action={action(this.add)}
              label="Add date range"
              style="add"
            />
          </>
        );
        return (
          <div
            style={{
              textAlign: "center",
              width: "50%",
              margin: "auto",
              padding: "50px",
              position: "relative",
            }}
          >
            <div style={{ margin: "auto", fontSize: "75px" }}>
              <FaMinusCircle color={"#bebebe"} style={{ margin: "30px auto" }} />
            </div>
            <div style={{ paddingBottom: 15 }}>
              <b>Welcome to date ranges!</b>
            </div>
            <div style={{ paddingBottom: 25 }}>
              If you need to enter a date range for this employee or their
              dependents, this is the place to do it! You can enter a date range
              for a parental leave or leave of absence or more. This is also the
              place to apply Student Status to dependents, ensuring they are
              eligible under the Student Status extended upper age limit.
            </div>
            <div>{actions}</div>
          </div>
        );
      }

    async search({screen}:ActionEvent){
        await KTMApi.action(screen,"search",{
            "@employee_id":this.props.employee_id,
        });
    }

    async add({screen}:ActionEvent){
        await screen.openDialog(<Add_date_rangeScreen employee_id={this.props.employee_id}/>
,{onRecordChanged:this.refresh_row});
    }

    async edit({screen}:ActionEvent){
        await screen.openDialog(<Edit_date_rangeScreen dateRange_id={screen.getValue("Id")}/>
,{onRecordChanged:this.refresh_row});
    }

  async remove({screen}:ActionEvent){
    await screen.openDialog(<Remove_date_rangeScreen dateRange_id={screen.getValue("Id")}/>
,{onRecordChanged:this.refresh_data});
}

    async refresh_row({screen,eventValue}:ActionEvent){
        await KTMApi.refreshRow(screen,{collection:
"@dsData",rowid:eventValue});
        await this.setEmployee();
    }

  async refresh_data({screen}:ActionEvent){
    await screen.restart();
  }

  async setEmployee(){
    if (this.data.dsData && this.data.dsData.length > 0) {
      for (let i = 0; i < this.data.dsData.length; i++) {
        let row = this.data.dsData[i];
        if (row.Dependent_Id == null && row.DependentName == "") {
          row.DependentName = "Employee";
        }
      }
    }
  }

});
