import React, { useState, useEffect } from 'react';
import { Card, Line } from 'ktm-ui';
import { Spinner } from 'react-bootstrap';
import { MdEmail } from 'react-icons/md';
import toastr from 'toastr';
import ReactMoment from 'react-moment';
import EmployeeCampaignTable from './Employee_campaigns';
import { CustomLoading } from 'components/CustomLoading';

interface CampaignProps {
    policy_id: string;
}

const Campaign: React.FC<CampaignProps> = ({ policy_id }) => {
    const [loading, setLoading] = useState(true);
    const [hasCampaign, setHasCampaign] = useState(false);
    const [campaignData, setCampaignData] = useState({
        SelectionDeadline: null,
        LaunchDate: null,
        ReminderDate: null,
        LastDayReminder: null,
    });

    useEffect(() => {
        getCampaign();
    }, [policy_id]);

    const getCampaign = async () => {
        try {
            const response = await fetch(`/api/Flex/getpolicycampaign?PolicyId=${policy_id}`);
            const data = await response.json();
            if (data.success) {
                setCampaignData({
                    SelectionDeadline: data.campaign.selectionDeadline,
                    LaunchDate: data.campaign.launchDate,
                    ReminderDate: data.campaign.reminderDate,
                    LastDayReminder: data.campaign.lastDayReminder,
                });
                setHasCampaign(true);
            } else {
                setHasCampaign(false);
            }
        } catch (error) {
            toastr.error('Error getting campaign info');
            setHasCampaign(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <CustomLoading />;
    }

    const renderCampaignDetails = () => (
        <div className="campaign-info-container">
            <Card label="Campaign Details">
                <Line>
                    {['LaunchDate', 'ReminderDate', 'LastDayReminder', 'SelectionDeadline'].map((dateType) => (
                        <div key={dateType} className="RT-Static-Field">
                            <label className="RT-Static-Field__label">{`${dateType.replace(/([A-Z])/g, ' $1')} (YYYY-MM-DD)`}</label>
                            <div className="RT-Static-Field__content" style={{ height: '21px' }}>
                                {campaignData[dateType] ? <ReactMoment format="YYYY-MM-DD">{campaignData[dateType]}</ReactMoment> : ''}
                            </div>
                        </div>
                    ))}
                </Line>
            </Card>
            <div className="employee-campaigns-container" style={{ marginTop: '15px' }}>
                <EmployeeCampaignTable policy_id={policy_id} />
            </div>
        </div>
    );

    const renderEmptyState = () => (
        <div className='campaign-empty-state' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 60, textAlign: 'center' }}>
            <MdEmail style={{ fill: 'rgb(100,120,140)', fontSize: 80, opacity: 0.2, marginBottom: 20 }} />
            <div style={{ fontSize: '15px', fontWeight: 500, marginBottom: 20 }}>
                Campaigns for the next benefit year’s Flex selections can be created 2 months in advance of your current year-end date.
                <br />
                If none is created, a campaign will automatically generate 1 month prior to year-end, with an email reminder to Plan Administrators to review amounts and benefit categories.
                <br />
                Please Note: Creating a new Flex campaign always applies to the next benefit year, never the current one.
            </div>
            <button className="RT-Button RT-Button--primary" disabled={true}>
                Add Flex Campaign
            </button>
        </div>
    );

    return <div>{hasCampaign ? renderCampaignDetails() : renderEmptyState()}</div>;
};

export default Campaign;
