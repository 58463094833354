import React from 'react';
import { KTMScreen, ActionEvent, RenderEvent, renderScreen, Card, Line } from 'ktm-ui';
import { KTMApi } from 'api/KTMApi';
import { Layout, Dialog, Button } from 'ktm-ui';

interface IdsBenCat {
    Id: string;
    Policy_Id: string;
};

export default KTMScreen({name:"planadmin.ben-limit.remove-hsa"}, class extends React.Component<{limit_id:string, employeesCount:number}> {

    data: {
        dsBenCat: IdsBenCat;
    }

    state = {
        showCampaignWarning: false,
    };

    render() {
        return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen, {
            "@limit_id": this.props.limit_id,
        });
        screen.title = "Remove HSA  benefit";
        
        await this.checkCampaignStatus();
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {(this.state.showCampaignWarning && this.props.employeesCount > 0) && (
                    <Card>
                        <Line>
                            <span style={{ color: "red", fontSize: 14, fontWeight: 'bold' }}>
                            This benefit category has been assigned to employees as part of their year-end Flex Campaign. Any changes saved here will apply to the next benefit year as well as to the current one, and the assigned employees may be required to re-select.
                            <br/>
                            If assistance is required, please click ‘Cancel’ and contact enrollments@nationalhealthclaim.com.
                            </span>
                        </Line>
                    </Card>
                )}
                <Card>
                    <div>HSA benefit will be removed.</div>
                </Card>
                {draw(this.footer)}
            </Layout>
        );
    }

    header({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Header label="Remove HSA Benefit" />
        );
    }

    footer({ field, draw, action }: RenderEvent) {
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Remove" style="destructive" />
            </Dialog.Footer>
        );
    }

    async submit({ screen }: ActionEvent) {
        await KTMApi.action(screen, "submit", {
            "@limit_id": this.props.limit_id,
        });
        screen.close({ "continue": true });
    }

    async checkCampaignStatus() {
        let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsBenCat.Policy_Id);
        let res = await response.json();
        if (res.isCampaignOpen) {
            this.setState({ showCampaignWarning: true });
        }
    }
});
