import { Canvas } from "./Canvas";
import { FieldRef } from "./FieldRef";


export class Focus {
    static shouldFocus(canvas:Canvas,field: FieldRef): boolean {
            
        if (!field.record || field.readonly) return false;

        let pendingFocusField = canvas.pendingFocusField;
        if (pendingFocusField === undefined){
            canvas.pendingFocusField = null;
            return true;
        }
        
        if (
            pendingFocusField &&
            pendingFocusField.record == field.record &&
            pendingFocusField.name == field.name
        ) {
            canvas.pendingFocusField = null;
            return true;
        }
        return false;
    }

    static shouldFocusButton(canvas:Canvas,name:string):boolean {
        let pendingFocusField = canvas.pendingFocusField;
        if (
            pendingFocusField &&
            !pendingFocusField.meta &&
            pendingFocusField.name == name
        ) {
            canvas.pendingFocusField = null;
            return true;
        }
        return false;
    }

    static focus(canvas:Canvas,field: FieldRef) {
        if (!field) return;
        canvas.pendingFocusField = field;
    }

    static focusButton(canvas:Canvas,name:string) {
        canvas.pendingFocusField = new FieldRef(canvas,null,null,name);
    }


    static cancelInitialFocus(canvas:Canvas) {
        if (canvas.pendingFocusField === undefined) {
            canvas.pendingFocusField = null;
        }
    }

}