import { makeComponent } from '../core';
import React from 'react';

export const Spacer = makeComponent( class extends React.Component<{size?:string;divider?:string}>{
    render(){
        let className = "RT-Spacer";    
        if (this.props.size){
            className += " RT-Spacer--" + this.props.size;
        }
        let divider;
        if (this.props.divider){
            divider= <div className={"RT-Spacer__divider-" + this.props.divider} />
        }
        return <div className={className}>{divider}</div>
    }
});