import React from 'react';
import {makeComponent,FieldRef} from '../core';


export const Panel = makeComponent(class extends React.Component<{ children?: any; columnSize?:string; background?:string,annotated?:boolean,annotation?:any }> {
    render() {
        var children = this.props.children;


        let className = "RT-Row";
        if (this.props.columnSize == "equalHt"){
            className += " RT-Row--equal-ht-columns";
        }
        if (this.props.background){
            className += " RT-Row--background RT-Row--background-" + this.props.background;
        }
       
        let content = ( <div className={className}>
            {children}
        </div>)
        let annotation;
        if (this.props.annotated){
            annotation = this.props.annotation;
        }
        return <PanelPresenter children={content} annotation={annotation} />
    }
});

class PanelPresenter extends React.Component<{
    titleSize?:string,toggle?:FieldRef,annotation?:any,
    subdued?: boolean, isTab?: boolean
}>{

    render() {

        let label: string;
        let help:string;
       
    
        let body;
        if (this.props.annotation) {
            body = this.renderAnnotated(label,this.props.annotation,this.props.children);
        }
        else {
            body = this.renderContent(label, this.props.children);
        }
        return  body;

    }

    renderContent(title: string, content:any) {

        let header;
        let footer;


       
        let helpIcon;
        let helpContent;
    
          //  helpIcon = <div className="RT-Layout__help-icon" onClick={this.handleHelpClick}>{this.renderIcon("help",null)}</div>
        /*
        let controlState = form.controlState;
        if (controlState && controlState.helpVisible){
         //   helpContent = <div className="RT-Layout__help-content"><HelpTopic topic={this.props.frame.helpTopic} /></div>
        }
        */

        let containerClass = ['RT-Layout'];

        let dropTarget;

        let bodyClass = "RT-Layout__body";
       
        let body = <div className={containerClass.join(' ')}  >
            {header}
            <div className={bodyClass}>
                {content}
                {dropTarget}
            </div>
            {footer}
        </div>
        return body;
    }

    renderAnnotated(title:string,annotation:any,content:any) {
        let titleElem;
        if (this.props.toggle){
            let checked = !!this.props.toggle.value;
            titleElem = (<div className="form-check">
                <label>
                    <input type="checkbox" className="form-check-input" checked={checked} onChange={this.handleToggleChange} />
                    <span className="form-check-faux">{this.renderCheckmark()}</span>
                    <span className="form-check-label" style={{fontSize:"1rem",marginLeft:10}}>{title}</span>
                </label>
            </div>);
            if (!checked){
                return (
                    <div className="RT-Layout RT-Layout--annotated">
                        <div className="RT-Layout__labels">
                            <div className="RT-Layout__title" style={{paddingBottom:0}}>
                                {titleElem}
                            </div>
                            <div className="RT-Layout__descrip">
                                {annotation}
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            titleElem = title;
        }
      
       
        return (
          
            <div className="RT-Layout RT-Layout--annotated" >
                <div className="RT-Layout__labels">
                    <div className="RT-Layout__title">
                        {titleElem}
                    </div>
                    <div className="RT-Layout__descrip">
                        {annotation}
                    </div>
                </div>
                <div className="RT-Layout__content">
                    {content}
                </div>
            </div>
        );
    }

    renderCheckmark() {
        return <svg viewBox="0 0 52 52"><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
    }

    handleToggleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
      //  this.props.form.setValue(this.props.toggle,e.target.checked);
    }

    formatText(text:string):any{
        if (!text) return null;
        let segments = text.split('\n');
        let elems = [];
        for(var i = 0; i < segments.length;i++){
            if (segments[i]){
                elems.push(<div key={i}>{this.getSegmentElements(segments[i])}</div>);
            }
            else {
                elems.push(<br key={i}/>);
            }
        }
        return elems;
    }

    getSegmentElements(segment:string):any{
        let spans = segment.split("**");
        if (spans.length == 1) return segment;
        let asStrong = false;
        let elems = [];
        for(let i = 0; i < spans.length;i++){
            if (asStrong){
                elems.push(<strong key={i}>{spans[i]}</strong>)
            }
            else {
                elems.push(spans[i]);
            }
            asStrong = !asStrong;
        }
        return elems;
    }


    handleHelpClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        /*
        let form = this.props.form;
        let helpVisible:boolean;
        if (form.controlState){
            helpVisible = form.controlState.helpVisible;
        }
        helpVisible = !helpVisible;
        form.setControlState({helpVisible});
        this.clearSelection();
        this.forceUpdate();
        */
    }

    public clearSelection(targetWindow?: any) {
        var sel;
        if (!targetWindow) {
            targetWindow = window;
        }
        if (targetWindow.getSelection) {
            sel = targetWindow.getSelection();
            if (sel && sel.removeAllRanges) sel.removeAllRanges();
        }
    }

    renderIcon(icon:string,fill:string):any {
        /*
        let iconDef = icons[icon];
        if (iconDef){
            let html = {__html:iconDef.content}
            return <svg style={{fill,display:"block"}} viewBox={iconDef.viewBox} dangerouslySetInnerHTML={html}/>
        }
        */
    }
}

