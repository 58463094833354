import React from "react";
import { Button, Modal } from "react-bootstrap";
import "../styles/Campaign.css";
import axios from "axios";
import FlexDatesCard from "./FlexDatesCard";
import toastr from 'toastr';

export default class EditFlexDatesModal extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            showEditModal: this.props.showEditModal,
            SelectionDeadline: new Date(),
            LaunchDate: new Date(),
            ReminderDate: new Date(),
            LastDayReminder: new Date(),
        };
    }
  
    handleSave = async () => {
        try {
            const { SelectionDeadline, LaunchDate, ReminderDate, LastDayReminder } = this.state;

            let selectionDeadlineDate = new Date(SelectionDeadline);
            let launchDate = new Date(LaunchDate);
            let reminderDate = new Date(ReminderDate);
            let lastDayReminder = new Date(LastDayReminder);

            const currentDate = new Date();

            if (
                selectionDeadlineDate > lastDayReminder &&
                lastDayReminder > reminderDate &&
                reminderDate > launchDate &&
                launchDate > currentDate
            ) {
                const body = {
                    PolicyId: this.props.policyId,
                    EmployeeIds: this.props.selectedEmployees,
                    FlexLaunchDate: launchDate.toLocaleDateString(),
                    FlexReminderDate: reminderDate.toLocaleDateString(),
                    FlexLastDayReminderDate: lastDayReminder.toLocaleDateString(),
                    FlexSelectionDeadline: selectionDeadlineDate.toLocaleDateString(),
                };
        
                await axios.put("/api/Settings/editcurrentyearemployeeflexdates", body)
                    .then((response) => {
                    if (response.data.success) {
                        this.props.refreshEmployees();
                    }
                    })
                    .catch(function (error) {
                    console.log(error);
                    });
                } else {
                    toastr.error("Invalid date selections.");
                }
        } 
        catch (error) {
          console.log(error);
        }
    }

    handleDateChange(e: any, arg1: string) {
        switch (arg1) {
            case "SelectionDeadline":
                this.setState({ SelectionDeadline: e});
                break;
            case "LaunchDate":
                this.setState({ LaunchDate: e});
                break;
            case "ReminderDate":
                this.setState({ ReminderDate: e});
                break;
            case "LastDayReminder":
                this.setState({ LastDayReminder: e});
                break;
            default:
                break;
        }
    }

    render() {
        const { SelectionDeadline, LaunchDate, ReminderDate, LastDayReminder } = this.state;
        const currentDate = new Date();
        const isAnyDateNull =
            !SelectionDeadline ||
            !LaunchDate ||
            !ReminderDate ||
            !LastDayReminder ||
            SelectionDeadline <= currentDate ||
            LastDayReminder <= currentDate ||
            ReminderDate <= currentDate ||
            LaunchDate <= currentDate ||
            SelectionDeadline <= LastDayReminder ||
            LastDayReminder <= ReminderDate ||
            ReminderDate <= LaunchDate;

        return (
            <Modal
                className="react-modal edit-campaign-modal"
                show={this.props.showEditModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className="modal-title">Edit Flex Dates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexDatesCard
                        handleDateChange={this.handleDateChange.bind(this)} 
                        LaunchDate={LaunchDate} 
                        ReminderDate={ReminderDate} 
                        LastDayReminder={LastDayReminder} 
                        SelectionDeadline={SelectionDeadline}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleSave} disabled={isAnyDateNull}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
};