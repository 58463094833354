import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import toastr from "toastr";
import NotesModal from "../components/NotesModal";
import "./styles/support-dashboard.css";
import "./styles/progress-circle.css";

export default class QueuedImages extends React.Component<any, any> {
  state = {
    imageUrls: [] as any,
    isModalOpen: false,
    modalImageId: null,
    modal: null,
    skip: 3,
    displayLoad: "none",
    loaded: false,
    buttonText: "Push New Claim",
    buttonDisplay: "none",
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }

  async startLoading() {
    const newSrc = this.state.imageUrls.slice();
    if (this.props.images.length > 4) {
      this.setState({ displayLoad: "" });
    }
    this.props.images.map(async (data, i) => {
      if (i <= 3) {
        await axios
          .get("/api/upload/getimage/" + data.imageId)
          .then((response) => {
            const mapping = {
              imageId: data.imageId,
              notes: data.Note,
              src: response.data.src,
            };
            newSrc.push(mapping);
            this.props.loaded();
          });
      }
    });
    this.setState({ imageUrls: newSrc, loaded: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.images !== prevProps.images) {
      const newSrc = [];
      this.props.images.map((data, i) => {
        axios
          .get("/api/upload/getimage/" + data.imageId)
          .then((response) => {
            const mapping = {
              imageId: data.imageId,
              notes: data.Note,
              src: response.data.src,
            };

            newSrc.push(mapping);

            this.props.loaded();
          });
      });
      this.setState({ imageUrls: newSrc, displayLoad: "none" });
    }
  }

  loadMore = () => {
    const newSrc = this.state.imageUrls.slice();
    this.props.images.map((data, i) => {
      if (i > this.state.skip && i <= this.state.skip + 4) {
        axios.get("/api/upload/getimage/" + data.imageId).then((response) => {
          const mapping = {
            imageId: data.imageId,
            src: response.data.src,
          };
          newSrc.push(mapping);
          this.props.loaded();
        });
      }
    });
    var skip = this.state.skip + 4;
    this.setState({ imageUrls: newSrc, skip: skip });
    if (skip >= this.props.images.length - 1) {
      this.setState({ displayLoad: "none" });
    }
  };

  displayModal = (imageId) => {
    this.setState({ isModalOpen: true, modalImageId: imageId });
  };
  renderOpenModal() {
    if (!this.state.isModalOpen) return null;
    return (
      <NotesModal
        imageId={this.state.modalImageId}
        close={() => this.setState({ isModalOpen: false })}
      ></NotesModal>
    );
  }

  displayPreview = (base64URL) => {
    var newTab = window.open(
      "",
      "Image Preview",
      "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=500,top=100,left=100"
    );
    newTab.document.body.innerHTML =
      '<img src="data:image/png;base64,' + base64URL + '" width="900">';
  };

  deleteImage = (id, index) => (e) => {
    e.preventDefault();
    axios
      .post("/api/upload/DeleteImage/" + id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            imageUrls: this.state.imageUrls.filter((_, i) => i !== index),
          });
          toastr.success("Image deleted", "Success");
        } else {
          toastr.error("Failed to delete image", "Error");
        }
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Failed to delete image", "Error");
      });
  };

  render() {
    let imageDiv = null;
    let imageContainer = null;
    if (this.state.imageUrls && this.state.imageUrls.length > 0) {
      imageDiv = this.state.imageUrls.map((data, i) => {
        return (
          <div
            key={data.imageId}
            className="col-3 mg-b-30"
            style={{ position: "relative" }}
          >
            <img
              onClick={() => this.displayPreview(data.src)}
              className="img-fluid"
              src={`data:image/png;base64,${data.src}`}
              width={300}
              style={{ cursor: "pointer" }}
            ></img>
            <button
              className="btn btn-danger pd-x-5 pd-y-1"
              onClick={this.deleteImage(data.imageId, i)}
              style={{ position: "absolute", bottom: "10px", right: "25px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
            <button
              className="btn btn-warning pd-x-5 pd-y-1"
              onClick={() => this.displayModal(data.imageId)}
              style={{ position: "absolute", bottom: "10px", left: "25px" }}
            >
              <i className="fa fa-edit fa-1x mg-r-5" /> Edit Notes
            </button>
          </div>
        );
      });

      imageContainer = (
        <div className="br-pagebody no-padding mg-t-0 mg-x-50">
          <div
            className="br-section-wrapper no-padding"
            style={{ maxWidth: 1250 }}
          >
            <div className="card">
              <div className="card-header tx-medium tx-uppercase bg-white">
                Submitted Images
              </div>
              {this.renderOpenModal()}
              <div className="card-body no-padding">
                <div className="pd-10 clearfix">
                  <div
                    style={{
                      backgroundColor: "#fff",
                      margin: 15,
                      padding: 10,
                      textAlign: "center",
                      border: "1px solid #ffb75d",
                    }}
                  >
                    <div style={{ width: 780, margin: "0 auto" }}>
                      These image(s) have been submitted and will be removed
                      from this page when our team is reviewing them. In the
                      meantime, you may enter notes, delete or add more images.
                      You will receive an email notification once your claim is
                      processed.
                    </div>
                  </div>
                </div>

                <div
                  className="container"
                  style={{
                    maxHeight: 550,
                    overflowY: "auto",
                    marginBottom: 30,
                    border: "1px solid #e9ecef",
                  }}
                >
                  <div className="row">{imageDiv}</div>
                </div>
                <div className="pd-10">
                  <button
                    className="btn-support btn-primary-support btn-submit"
                    onClick={this.loadMore}
                    style={{ display: this.state.displayLoad }}
                  >
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return imageContainer;
  }
}

