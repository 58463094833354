import { DynamicScreen, makeComponent, RenderEvent } from '../core';
import React from 'react';

export const Screen = makeComponent( class extends React.Component<{event:RenderEvent,name:string,params?:any}>{
    render(){
        let screen = this.props.name;
        if (!screen) return null;
        let newProps = {...this.props.params,screen:this.props.name,event:this.props.event};
        return <DynamicScreen {...newProps}/>
    }
});