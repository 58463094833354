export class ConditionBuilder {

    build(element:any,children:any[]):string  {
        let stream = new StringBuilder();
        this.conditionElem(stream,element);
        this.conditionChildren(stream,children);
        return stream.value;
    }

    private conditionChildren(stream:StringBuilder,children:any[]){
        if (!children) return;
        for (let i = 0; i < children.length;i++){
            let child = children[i];
            if (child.$kind == "Condition"){
                this.conditionElem(stream,child);
            }
            else if (child.$kind == "ConditionBlock"){
                this.conditionBlock(stream,child);
            }
            else {
                this.addError(child,"$kind","Expected condition or condition block");
            }
        }
    }

    private conditionBlock(stream:StringBuilder,element:any){
        let children = element.children;
        let childStream = new StringBuilder();
        this.conditionChildren(childStream,children);
        if (childStream.isEmpty) return;

        let logical = element.logical || "and";
        let notExpr = (element.not) ? "NOT" : "";
        if (!stream.isEmpty){
            stream.append(" " + logical + " " + notExpr + "(" + childStream.value + ")");
        }
        else {
            stream.append(notExpr + "(" + childStream.value + ")");
        }
    }

    private conditionElem(stream:StringBuilder,element:any) {
        let conditionExpr;
        if (element.expr || element.value || element.toValue){
            let compare = element.compare;
            if (!compare){
                this.addError(element,"compare","Compare is required");
            }
            if (compare != "exists"){
                if (!element.expr){
                    this.addError(element,"expr","Expression is required");
                }
            }
            if (!element.value && compare != "is null" && compare != "is true" && compare != "is false" && compare != "is uid" && compare != "is empty"){
                this.addError(element,"value","value is requied");
            }
            if (compare == "between" && !element.toValue){
                this.addError(element,"toValue","to value is required");
            }
            if (element.not){
                conditionExpr = "NOT ";
            }
            else {
                conditionExpr = "";
            }
            let value = element.value;
            if (compare == "is true"){
                conditionExpr += element.expr + " = TRUE";
            }
            else if (compare == "is false"){
                conditionExpr += element.expr + " = FALSE";
            }
            else if (compare == "is null"){
                conditionExpr += element.expr + " = NULL";
            }
            else if (compare == "is empty"){
                conditionExpr += "NOT " + element.expr;
            }
            else {
                conditionExpr += element.expr + " " + compare + " " + value;
            }
            if (compare == "between"){
                conditionExpr += " AND " + element.toValue;
            }
        }
        if (conditionExpr){
            if (!stream.isEmpty){
                let logical = element.logical || "and";
                stream.append(" " + logical + " ");
            }
            stream.append(conditionExpr);
        }
    }

    addError(element:any,attr:string,message:string){

    }
}




class StringBuilder {
    value:string;

    constructor(){
        this.value = "";
    }
    append(text:string){
        this.value += text;
    }

    get isEmpty():boolean {
        return (this.value.length == 0);
    }
}