import React, {Component} from "react";
import {FiEdit, FiUsers} from "react-icons/fi";
import {BiBuildings, BiBarChartSquare} from "react-icons/bi";
import {FaHandHoldingUsd} from "react-icons/fa";
import {HiOutlineSearchCircle} from "react-icons/hi";
import {Column, Panel} from "ktm-ui";
import {CustomLoading} from "components/CustomLoading";
import "bootstrap/dist/css/bootstrap.min.css";
import toastr from "toastr";
import moment from "moment";
import Moment from "react-moment";
import axios from "axios";
import {
    Layout,
    RecordHeader,
    TabSet,
    Tab,
    Display,
    Button,
    Line,
    Card,
} from "ktm-ui";

import {KTMScreen, ActionEvent, RenderEvent, renderScreen} from "ktm-ui";
import {Col} from "react-bootstrap";
import PaymentFiles from "./PaymentFiles";
import PolicyFunding from "./PolicyFunding";
import FundingRefund from "./FundingRefund";
import EFTReissue from "./EFTReissue";
import DistroPayments from "./DistroPayments";
import OperationalTransfers from "./OperationalTransfers";
import OperationalReports from "./OperationalReports";
import ChequeDeposit from "./ChequeDeposit";
import TeladocDashboard from "./TeladocDashboard";

export default KTMScreen(
    {name: "financials"},
    class extends React.Component<any, any> {
        state = {
            loading: true,
            currentListItem: "Apply Funding",
            userRole: null,
        };

        componentDidMount() {
            this.GetFinanceUserRole();
        }

        GetFinanceUserRole() {
            axios.get("/api/PlanAdmin/getfianaceuserrole")
                .then(res => {
                        if (res.data.success) {
                            this.setState({
                                userRole: res.data.userRole,
                                loading: false
                            });
                        } else {
                            toastr.error(res.data.message, "Error");
                        }
                    }
                )
                .catch(error => {
                    toastr.error(error, "Error");
                });
        }

        setCurrentItem = (item) => {
            this.setState({currentListItem: item});
        };

        render() {
            let container = null;
            let contents = null;
            if (this.state.loading) {
                contents = <CustomLoading/>;
            } else {
                let listGroup = [];

                listGroup.push("Apply Funding");
                listGroup.push("Payment Files");
                listGroup.push("Cheque Deposit");
                listGroup.push("Reissue EFT");
                listGroup.push("Funding Refund");

                if (this.state.userRole === "Finance") {
                    listGroup.push("Distro Payments");
                    listGroup.push("Teladoc Dashboard");
                    // listGroup.push("Deposit Slips");
                    listGroup.push("Operational Transfers");
                    // listGroup.push("Distro and Advisors");
                    listGroup.push("Reports");
                }

                if (this.state.currentListItem === "Apply Funding") {
                    contents = <PolicyFunding/>;
                } else if (this.state.currentListItem === "Payment Files") {
                    contents = <PaymentFiles/>;
                } else if (this.state.currentListItem === "Cheque Deposit") {
                    contents = <ChequeDeposit/>;
                } else if (this.state.currentListItem === "Reissue EFT") {
                    contents = <EFTReissue/>;
                } else if (this.state.currentListItem === "Funding Refund") {
                    contents = <FundingRefund/>;
                } else if (this.state.currentListItem === "Distro Payments") {
                    contents = <DistroPayments/>;
                } else if (this.state.currentListItem === "Teladoc Dashboard") {
                    contents = <TeladocDashboard/>;
                } else if (this.state.currentListItem === "Operational Transfers") {
                    contents = <OperationalTransfers/>;
                } else if (this.state.currentListItem === "Reports") {
                    contents = <OperationalReports/>;
                }

                container = (
                    <div className="ktm-reports">
                        <ul
                            className="list-group"
                            style={{width: "13%", marginRight: "10px"}}
                        >
                            {listGroup.map((item, index) => (
                                <li
                                    key={index}
                                    className="list-group-item"
                                    style={
                                        this.state.currentListItem == item
                                            ? {backgroundColor: "#848f99", color: "white"}
                                            : null
                                    }
                                    onClick={() => this.setCurrentItem(item)}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                        <Card class="ktm-financials-card">{contents}</Card>
                    </div>
                );
            }
            return <div style={{width: "100%", padding: 30}}>{container}</div>;
        }
    }
);