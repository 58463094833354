import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Button,DataGrid,IDataGridLayout} from 'ktm-ui';
import RecordScreen from './Record';

interface IdsCampaigns {
    Id:string;
    Policy_Id:string;
    BenefitYear_Id:string;
    PolicyBenefit_Id:string;
    LaunchDate:string;
    Year:string;
    Benefit:string;
};

export default KTMScreen({name:"enroll.campaign.browse"}, class extends React.Component<{policy_id:string}> {

    data:{
        dsCampaigns:IdsCampaigns[];
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsCampaigns";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
        });
        screen.title = "Campaigns";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.default_view)}
            </Layout>
        );
    }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = (<><Button action={action(this.add_record)} label="Add campaign" style="add"/>
</>);
        let layout:IDataGridLayout = {
            columns:[
                {name:"Benefit",label:"Benefit",type:"character"},
                {name:"Year",label:"Year",type:"date"},
                {name:"LaunchDate",label:"Launch Date",type:"date"},
            ],
            sort:[{name:"Year"},{name:"Benefit"}]
        };
        return (
            <DataGrid collection={collection("@dsCampaigns")} layout={layout} onRowClick={action(this.show_record)} actions={actions}/>
        );
    }

    async refresh_row({screen,eventValue}:ActionEvent){
        await KTMApi.refreshRow(screen,{collection:
"@dsCampaigns",rowid:eventValue});
    }

    async show_record({screen}:ActionEvent){
        screen.browse(<RecordScreen campaign_id={screen.getValue("Id")}/>,{label:"Campaign",onRecordChanged:this.refresh_row});
    }

    async add_record({screen}:ActionEvent){
    }

});
