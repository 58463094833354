import { Canvas } from "./Canvas";
import { Focus } from "./Focus";
import { RecordMeta } from "./RecordMeta";
import { IActionDefinition, IActionResult, IEventOptions, IEventScope } from "./types";

export class ActionRef {
    canvas:Canvas;
    name:string;
    scope:IEventScope;
    def:IActionDefinition;
    
   
    constructor(canvas:Canvas,name:string,scope:IEventScope){
        this.canvas = canvas;
        this.name = name;
        this.scope = scope;
    }

    get hidden():boolean {
        let meta =  RecordMeta.getFieldMeta(this.canvas.$$meta,this.name);
        if (meta){
            return meta.hidden;
        }
        return false;
    }
    
    get disabled():boolean {
        let meta =  RecordMeta.getFieldMeta(this.canvas.$$meta,this.name);
        if (meta){
            return meta.disabled;
        }
        return false;
    }

    get disabledHelp():string {
        let meta =  RecordMeta.getFieldMeta(this.canvas.$$meta,this.name);
        if (meta){
            return meta.disabledHelp;
        }
        return null;
    }
    

    get label():string {
        let meta =  RecordMeta.getFieldMeta(this.canvas.$$meta,this.name);
        if (meta){
            if (meta.label !== undefined) return meta.label;
        }
        if (this.def){
            return this.def.label;
        }
    }

    get help():string {
        let meta =  RecordMeta.getFieldMeta(this.canvas.$$meta,this.name);
        if (meta){
            if (meta.help !== undefined) return meta.help;
        }
        if (this.def){
            return this.def.help;
        }
    }

    get shouldFocus():boolean {
        return Focus.shouldFocusButton(this.canvas,this.name);
    }

    async trigger(options?:IEventOptions):Promise<IActionResult>{
        options = options || {};
        options.scope = {...this.scope,...options.scope};
        try {
            let result = await this.canvas.triggerAction(this,options);
            return result;
        }
        catch(e){
            if (e != "stopped"){
                throw e;
            }
        }
    }
}

