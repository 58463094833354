import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import EmployeeBenefit from "./EmployeeBenefit";
import toastr from "toastr";
import axios from "axios";
import { CustomLoading } from 'components/CustomLoading';

const ViewEmployee = ({ data }) => {
  const [employeeNotes, setEmployeeNotes] = useState('');
  const [employeeNotesUpdatedAt, setEmployeeNotesUpdatedAt] = useState(null);
  const [employeeSupportNotes, setEmployeeSupportNotes] = useState('');
  const [employeeSupportNotesUpdatedAt, setEmployeeSupportNotesUpdatedAt] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (data.id) {
      Promise.all([fetchEmployeeNotes(), fetchEmployeeSupportNotes()]).finally(() => {
        setLoading(false);
      });
    }
  }, [data.id]);

  const handleEmployeeNotesChange = (e) => {
    setEmployeeNotes(e.target.value);
  };

  const handleEmployeeSupportNotesChange = (e) => {
    setEmployeeSupportNotes(e.target.value);
  };

  const fetchEmployeeNotes = async () => {
    try {
      const response = await axios.get(`/api/support/employeenotes?employeeId=${data.id}`);
      if (response.data && response.data.noteText != null) {
        setEmployeeNotes(response.data.noteText);
        setEmployeeNotesUpdatedAt(response.data.updatedAt);
      } else {
        setEmployeeNotes("");
      }
    } catch (error) {
      toastr.error("Failed to fetch employee notes", "Error");
    }
  };

  const fetchEmployeeSupportNotes = async () => {
    try {
      const response = await axios.get(`/api/support/employesupportenotes?employeeId=${data.id}`);
      if (response.data && response.data.noteText != null) {
        setEmployeeSupportNotes(response.data.noteText);
        setEmployeeSupportNotesUpdatedAt(response.data.updatedAt);
      } else {
        setEmployeeSupportNotes("");
      }
    } catch (error) {
      toastr.error("Failed to fetch employee notes", "Error");
    }
  }

  const saveEmployeeNotes = () => {
    var noteId = null;
    if (data.employeeNotes != null) {
        noteId = data.employeeNotes.id;
    }

    var body = {
        Id: noteId,
        EmployeeId: data.id,
        Notes: employeeNotes,
    };
    var self = this;
    axios({
        method: "post",
        url: "/api/support/saveemployeenotes",
        data: body,
    })
    .then(function (response) {
        if (!response.data.success) {
            toastr.success("Notes updated", "Success");
            fetchEmployeeNotes();
        }
    })
    .catch(function (error) {
        toastr.error("Failed save notes", "Error");
    });
  };

  const saveEmployeeSupportNotes = () => {
    var noteId = null;
    if (data.employeeSupportNotes != null) {
        noteId = data.employeeSupportNotes.id;
    }

    var body = {
        Id: noteId,
        EmployeeId: data.id,
        Notes: employeeSupportNotes,
    };
    var self = this;
    axios({
        method: "post",
        url: "/api/support/saveemployeesupportnotes",
        data: body,
    })
    .then(function (response) {
        if (!response.data.success) {
            toastr.success("Notes updated", "Success");
            fetchEmployeeSupportNotes();
        }
    })
    .catch(function (error) {
        toastr.error("Failed save notes", "Error");
    });
  };

  const renderPlanAdmins = () => {
    return data.planAdmins && data.planAdmins.length > 0 ? (
      data.planAdmins.map((item, i) => (
        <div key={i}>
          {item.lastName} {item.firstName} <a href={`mailto:${item.email}`} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>{item.email}</a>
        </div>
      ))
    ) : null;
  };

  const renderDependents = () => {
    return data.dependents && data.dependents.length > 0 ? (
      <div className="row pt-1">
        <div className="col-3">Dependents</div>
        <div className="col-9">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Hidden</th>
              </tr>
            </thead>
            <tbody>
              {data.dependents.map((dep, i) => (
                <tr key={i}>
                  <td>{dep.firstName} {dep.lastName}</td>
                  <td>{dep.relation}</td>
                  <td>{dep.isHidden ? "Yes" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ) : null;
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <div className="mt-3 card shadow-sm p-3 mb-5 bg-body rounded">
      <div className="row">
        <div className="col-3">Name</div>
        <div className="col-9"><b>{data.lastName}, {data.firstName}</b></div>
      </div>
      <div className="row pt-1">
        <div className="col-3">Email</div>
          <div className="col-9">
              <a
                  href={"mailto:" + data.email}
                  target="_blank"
                  style={{color: "blue"}}
              >
                  {data.email}
              </a>
          </div>
      </div>
      <div className="row pt-1">
          <div className="col-3">Bank EFT</div>
          <div className="col-9">
              <b>{data.bankInfo}</b>
          </div>
      </div>
      <div className="row pt-1">
          <div className="col-3">Company</div>
          <div className="col-9">
              <b>{data.policyName}</b>
          </div>
      </div>
      <div className="row pt-1">
          <div className="col-3">DOB</div>
          <div className="col-9">
              <Moment local format="YYYY/MM/DD">
                  {data.dob}
              </Moment>
          </div>
      </div>
      <div className="row pt-1">
        <div className="col-3">Plan Admin</div>
        <div className="col-9">{renderPlanAdmins()}</div>
      </div>
      <div className="row pt-1">
        <div className="col-3">Employee Notes</div>
        <div className="col-9">
          <div>
            {" "}
            <span>
              Note updated at : 
              <Moment local format="YYYY/MM/DD hh:mm A">
                {employeeNotesUpdatedAt}
              </Moment>
            </span>
          </div>
          <textarea className="form-control" value={employeeNotes} onChange={handleEmployeeNotesChange}></textarea>
          <button type="button" className="btn btn-outline-primary btn-sm mt-1" onClick={saveEmployeeNotes}>Save</button>
        </div>
      </div>
      <div className="row pt-1">
        <div className="col-3">Authorized Account Access Notes</div>
        <div className="col-9">
          <div>
            {" "}
            <span>
              Note updated at : 
              <Moment local format="YYYY/MM/DD hh:mm A">
                {employeeSupportNotesUpdatedAt}
              </Moment>
            </span>
          </div>
          <textarea className="form-control" value={employeeSupportNotes} onChange={handleEmployeeSupportNotesChange}></textarea>
          <button type="button" className="btn btn-outline-primary btn-sm mt-1" onClick={saveEmployeeSupportNotes}>Save</button>
        </div>
      </div>
      <div className="row pt-1">
          <div className="col-3">Company Notes</div>
          <div className="col-9">{data.policyNotes != null ? data.policyNotes.noteText : ""}</div>
      </div>
      {renderDependents()}
      <br/>
        <EmployeeBenefit
          policyId={data.polId}
          employeeId={data.id}
        />
    </div>
  );
};

export default ViewEmployee;
