import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Card} from 'ktm-ui';

interface IdsBenLimit {
    PolicyBenefit_Id:string;
};

export default KTMScreen({name:"planadmin.ben-limit.add"}, class extends React.Component<{policy_id:string,bencat_id:string,benyear_id:string}> {

    data:{
        dsBenLimit:IdsBenLimit;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenLimit";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
            "@bencat_id":this.props.bencat_id,
            "@benyear_id":this.props.benyear_id,
        });
        screen.title = "Add benefit";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add benefit"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} style="primary" label="Save"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("PolicyBenefit_Id")}/>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@policy_id":this.props.policy_id,
            "@dsBenLimit.PolicyBenefit_Id":this.data.dsBenLimit.PolicyBenefit_Id,
            "@bencat_id":this.props.bencat_id,
            "@benyear_id":this.props.benyear_id,
        });
        screen.close({"continue":true});
    }

});
