import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,RecordHeader,TabSet,Tab,Button,Display,Card} from 'ktm-ui';
import EditScreen from './Edit';

interface IdsCostCentre {
    Id:string;
    Policy_Id:string;
    Name:string;
    Description:string;
    IsActive:boolean;
};

export default KTMScreen({name:"enroll.cost-centre.record"}, class extends React.Component<{costcentre_id:string}> {

    data:{
        dsCostCentre:IdsCostCentre;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsCostCentre";
        await KTMApi.start(screen,{
            "@costcentre_id":this.props.costcentre_id,
        });
        screen.title = this.data.dsCostCentre.Name;
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.tabs)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <RecordHeader entityLabel="Cost centre" label={this.data.dsCostCentre.Name}>
            </RecordHeader>
        );
    }

    tabs({field,draw,action}:RenderEvent){
        return (
            <TabSet>
                <Tab label="Details" template={this.details}/>
            </TabSet>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card label="Details" actions={<><Button action={action(this.edit)} label="Edit" style="link"/>
</>}>
                <Display field={field("Name")}/>
                <Display field={field("Description")}/>
            </Card>
        );
    }

    async edit({screen}:ActionEvent){
        // Connect edit screen
        await screen.openDialog(<EditScreen costcentre_id={this.data.dsCostCentre.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async refresh_data({screen}:ActionEvent){
        await screen.restart();
    }

});
