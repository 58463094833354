import { IRecordSchema } from "./Schema";
import { FieldType } from "./types";


export type IGroupTotalSet = {[name:string]:ITotalAccumulator};

export interface IPrintSort {
    localField: string;
    descending?: boolean;
}

export interface IPrintGroup {
    name:string;
    field:string;
    label?:string;
    sort:IPrintSort[];
}

export interface IReportTotal {
    name:string;
    label:string;
    fieldType?:FieldType;
    createTotal?:() => ITotalAccumulator;
}

export interface IQueryGroupHeader {
    label:string;
    text:string;
    value:string;
    rowCount?:number;
    totals?:IGroupTotalSet;
    group:IPrintGroup;
    innermost?:boolean;
    row:any;
}

export interface IQueryGroupFooter {
    row?:any;
    totals?:any;
    group:IPrintGroup;
    innermost?:boolean;
}

export interface IQueryGroupItem {
    type:'row' | 'header' | 'footer' | 'last-row'
    level?:number;
    row?:any;
    rowIndex?:number;
    header?:IQueryGroupHeader;
    footer?:IQueryGroupFooter;
    continued?:boolean;
}


export interface IPageIndex {
    start:number;
    end:number;
}

export interface IQueryGroupMap {
    items:IQueryGroupItem[];
    pages:IPageIndex[];
}

export class CollectionGrouper {

    public schema:IRecordSchema;
    public groups:IPrintGroup[];
    public definedTotals:IReportTotal[];
    public pageSize:number;

    private _currentHeaders:IQueryGroupHeader[];
    public items:IQueryGroupItem[];
    private _currentTotals:IGroupTotalSet;
    private _currentRowCount:number;
    private _lineIndex:number;
    private _pageRowCount:number;

    private _lastGroup:number;
    public reportTotals:IGroupTotalSet;
    private _pages:IPageIndex[]

    constructor(){
        this._currentHeaders = [];
        this.pageSize = 20;
    }

    public groupRows(rows:any[]):IQueryGroupMap{
        if (!this.groups.length) return this.noGroups(rows);
        this.items = [];
        this._pages = [{start:0,end:0}];
        this._pageRowCount = 0;
        this._lineIndex = 0;
        this._lastGroup = this.groups.length - 1;
        this._currentTotals = this.createTotals();
        this.reportTotals = this.createTotals();
        if (!rows.length) return;

        let firstRow = rows[0];
        this.createHeaders(firstRow,0);
        this.items.push({type:"row",row:firstRow,rowIndex:0});
        this._lineIndex++;
        this._pageRowCount++;

        this._currentRowCount = 1;
        this.addRowTotals(firstRow);
        let lastRow = firstRow;
        let row;
        for (let i = 1; i < rows.length;i++){
            row = rows[i];
            
            let level = this.getBreakLevel(row);
            if (level != -1){
                this.createFooters(lastRow,level);
                if (this._pageRowCount + 5 > this.pageSize){
                    this.newPage(level);
                }
                this.createHeaders(row,level);
                this._currentTotals = this.createTotals();
                this._currentRowCount = 0;
            }
            if (this._pageRowCount >= this.pageSize){
                this.newPage(this._lastGroup + 1);
            }
            this.addRowTotals(row);
            this._currentRowCount++;
            this._pageRowCount++;
            this.items.push({type:"row",row,rowIndex:i});
            this._lineIndex++;
            lastRow = row;
        }
        this.createFooters(lastRow,0);
        this.items.push({type:"last-row",row:lastRow,footer:{totals:this.reportTotals,group:null}});
        this._lineIndex++;
        let pageIndex = this._pages[this._pages.length - 1];
        pageIndex.end = this._lineIndex;

        return {items:this.items,pages:this._pages}
    }

    private newPage(breakLevel:number){
        let pageIndex = this._pages[this._pages.length - 1];
        pageIndex.end = this._lineIndex;
        this._pages.push({start:this._lineIndex,end:this._lineIndex});
        this._pageRowCount = 0;
        if (breakLevel != -1){
            this.addPageHeaders(breakLevel);
        }
    }

    private addPageHeaders(level:number){
        for(let i = 0; i < level;i++){
            let header = this._currentHeaders[i];
            this.items.push({type:"header",level:i,header,row:header.row,continued:true});
            this._lineIndex++;
            this._pageRowCount++;
        }
    }

    public noGroups(rows:any[]):IQueryGroupMap{
        this.items = [];
        this._currentTotals = this.createTotals();
        this.reportTotals = this.createTotals();
        this._pages = [{start:0,end:0}];

        if (!rows.length) return;

        this._pageRowCount = 0;
        this._lineIndex = 0;

        let row;
        for (let i = 0; i < rows.length;i++){
            row = rows[i];
            if (this._pageRowCount >= this.pageSize){
                this.newPage(-1);
            }
            this.addRowTotals(row);
            this._currentRowCount++;
            this._pageRowCount++;
            this.items.push({type:"row",row,rowIndex:i});
            this._lineIndex++;
        }
        this.addTotals(this.reportTotals,this._currentTotals);
        if (this.definedTotals.length){
            this.items.push({type:"last-row",row,footer:{totals:this.reportTotals,group:null}});
            this._lineIndex++;
        }

        let pageIndex = this._pages[this._pages.length - 1];
        pageIndex.end = this._lineIndex;
        return {items:this.items,pages:this._pages}
    }

    public getBreakLevel(row:any):number {
        for(let i = 0; i <= this._lastGroup;i++){
            let group = this.groups[i];
            let value = "";
            for(let s = 0; s < group.sort.length;s++){
                let sort = group.sort[s];
                value += row[sort.localField] + "|";
            }
            if (value.toLowerCase() !== this._currentHeaders[i].value) return i;
        }
        return -1;
    }

    public createHeaders(row:any,level:number){
        for(let i = level; i <= this._lastGroup; i++){
            let group = this.groups[i];
            let value = "";
            for(let s = 0; s < group.sort.length;s++){
                let sort = group.sort[s];
                value += row[sort.localField] + "|";
            }
            value = value.toLowerCase();
            let totals = this.createTotals();
            let innermost = i == this._lastGroup;
            let header : IQueryGroupHeader = {value,label:group.label,text:value,totals,rowCount:0,group,innermost,row};
            this._currentHeaders[i] = header;
            this.items.push({type:"header",level:i,header,row});
            this._lineIndex++;
            this._pageRowCount++;
        }
    }

    public createFooters(row:any,level:number){
        for(let i = this._lastGroup ;i >= 0; i--){
            let group = this.groups[i];
            let header = this._currentHeaders[i];
            this.addTotals(header.totals,this._currentTotals);
            header.rowCount += this._currentRowCount;
            if (i >= level){
            // let afterGroup = this.report.getGroup(group.name);
                let afterGroup = null;
                let innermost = (i == this._lastGroup);
                let footer : IQueryGroupFooter = {totals:header.totals,group:afterGroup,innermost};
                this.items.push({type:"footer",level:i,footer,row});
                this._lineIndex++;
                this._pageRowCount++;
            }
        }
        this.addTotals(this.reportTotals,this._currentTotals);
        
    }

    private createTotals():any {
        let totals:IGroupTotalSet = {};
    
        for(let i = 0 ; i < this.definedTotals.length;i++){
            let defTotal = this.definedTotals[i];
            totals[defTotal.name] = defTotal.createTotal();
        }
        return totals;
    }

    private addTotals(target:IGroupTotalSet,source:IGroupTotalSet){
        let totals = this.definedTotals;
        for(let i =0 ; i < totals.length;i++){
            let total = totals[i];
            let targetValue = target[total.name];
            targetValue.add(source[total.name]);
        }
    }

    private addRowTotals(row:any){
        let totals = this.definedTotals;
        for(let i =0 ; i < totals.length;i++){
            let total = totals[i];
            let targetValue = this._currentTotals[total.name];
            targetValue.addRow(row);  
        }

    }



    
}

export interface ITotalAccumulator {
    addRow(row:any);
    add(source:ITotalAccumulator);
    readonly value:any;
}

export class TotalSum implements ITotalAccumulator {
    field:string;
    private _value:number;

    constructor(field:string){
        this.field = field;
        this._value = 0;
    }
    addRow(row:any){
        this._value += row[this.field] || 0;
    }
    add(source:TotalSum){
        this._value += source.value;
    }
    get value():any {
        return this._value;
    }
}

export class TotalAvg implements ITotalAccumulator {
    field:string;
    private _count:number;
    private _value:number;

    constructor(field:string){
        this.field = field;
        this._value = 0;
        this._count = 0;
    }
    addRow(row:any){
        this._value += row[this.field] || 0;
        this._count++;
    }
    add(source:TotalAvg){
        this._value += source._value;
        this._count += source._count;
    }
    get value():any {
        if (this._count){
            return this._value / this._count;
        }
        return 0;
    }
}

export class TotalMin implements ITotalAccumulator {
    field:string;
    private _value:number;

    constructor(field:string){
        this.field = field;
        this._value = undefined;
    }
    addRow(row:any){
        let value = row[this.field] || 0;
        if (this._value === undefined){
            this._value = value;
        }
        else if (value < this._value){
            this._value = value;
        }
    }
    add(source:TotalMin){
        if (this._value === undefined){
            this._value = source._value;
        }
        else if (source._value < this._value){
            this._value = source._value;
        }
    }
    get value():any {
            return this._value || 0;
    }
}

export class TotalMax implements ITotalAccumulator {
    field:string;
    private _value:number;

    constructor(field:string){
        this.field = field;
        this._value = undefined;
    }
    addRow(row:any){
        let value = row[this.field] || 0;
        if (this._value === undefined){
            this._value = value;
        }
        else if (value > this._value){
            this._value = value;
        }
    }
    add(source:TotalMax){
        if (this._value === undefined){
            this._value = source._value;
        }
        else if (source._value > this._value){
            this._value = source._value;
        }
    }
    get value():any {
            return this._value || 0;
    }
}
