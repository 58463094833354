import CustomPagination from "components/CustomPagination";
import { Card } from "ktm-ui";
import React from "react";
import { Spinner, Table } from "react-bootstrap";

export default class EmployeeCampaignTable extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            EmployeeCampaignDetails: [],
            currentPage: 1,
            itemsPerPage: 20,
            hasSalaryBaseBenefits: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getEmployeeCampaigns();
    }


    getEmployeeCampaigns =()=> {
        fetch("/api/Flex/getemployeecampaigns?PolicyId=" + this.props.policy_id, {
            method: "GET",
          })
        .then((response) => response.json())
        .then((data) => {
            if (data.success) {
                this.setState({
                    EmployeeCampaignDetails: data.employeeCampaigns,
                    hasSalaryBaseBenefits: data.hasSalaryBaseBenefits,
                    loading: false,
                });
            }
        });
    }

    setCurrentPage = (page: number) => {
        this.setState({ currentPage: page });
    };

    render(){
        const {
            loading,
            EmployeeCampaignDetails,
            currentPage,
            itemsPerPage,
            hasSalaryBaseBenefits,
        } = this.state;

        let contents = null;
        if (loading) {
            contents = (
                <div className="pad-container">
                    <Spinner
                    className="pad-spinner"
                    animation="border"
                    variant="primary"
                    />
                </div>
                );
        } else if(EmployeeCampaignDetails.length > 0) {
            const indexOfLastRecord = currentPage * itemsPerPage;
            const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
            const currentRecords = EmployeeCampaignDetails.slice(indexOfFirstRecord, indexOfLastRecord);
            const nPages = Math.ceil(EmployeeCampaignDetails.length / itemsPerPage);

            contents = (
                <Card>
                    <Table hover>
                        <thead>
                            <tr 
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                }}
                            >
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Email</th>
                                <th>Email Notifications Enabled</th>
                                <th>Benefit Category</th>
                                {hasSalaryBaseBenefits &&
                                (<>
                                <th>Limit Calculation Method</th>
                                <th>Salary</th>
                                </>)} 
                                <th>Is Flex</th>
                                <th>Coverage</th>
                                <th>Flex Total Limit</th>
                                <th>Selection Deadline</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRecords.map((employee: any) => (
                                <tr>
                                    <td>{employee.lastName}</td>
                                    <td>{employee.firstName}</td>
                                    <td>{employee.email}</td>
                                    <td>
                                    {(() => {
                                        if (employee.enableFlexEmails) {
                                        return employee.isFlex ? "Yes" : "N/A";
                                        }
                                        return "No";
                                    })()}
                                    </td>
                                    <td>{employee.name}</td>
                                    {hasSalaryBaseBenefits &&
                                    (<>
                                    <td>{employee.creditCalcMethod}</td>
                                    <td>{employee.creditCalcMethod == "SALARY" ? employee.salaryMoney : "N/A"}</td>
                                    </>)}
                                    <td>{employee.isFlex ? "Yes" : "No"}</td>
                                    <td>{employee.coverage}</td>
                                    <td>{employee.isFlex ? employee.flexBase : "N/A"}</td>
                                    <td>{employee.flexSelectionDeadline != null ? employee.flexSelectionDeadline.toString().substring(0,10) : "N/A"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    
                    <div className="pagination-container" style={{position:"relative", right:"10px", margin:"0px", justifyContent: "end"}}>
                        <div className="pagination-info">
                            <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {this.state.EmployeeCampaignDetails.length} entries</span>
                        </div>
                        <CustomPagination 
                            nPages={nPages} 
                            currentPage={currentPage} 
                            setCurrentPage={this.setCurrentPage} 
                        />
                    </div>
                </Card>
            );
        }

        return (
            <div className="employee-campaigns-container">
                {contents}
            </div>
        );
    }
};