import React, { useState } from "react";
import {Column, Panel} from "ktm-ui";
import "bootstrap/dist/css/bootstrap.min.css";
import toastr from "toastr";
import Moment from "react-moment";
import axios from "axios";
import {KTMScreen} from "ktm-ui";
import ViewEmployee from './ViewEmployee';
import ViewPolicy from "./ViewPolicy";
import ViewUser from "./ViewUser";

export default KTMScreen(
    {name: "search"},
    class extends React.Component<any, any> {
        state = {
            searchType: "policy",
            searchInput: "",
            searchResult: null,
            loading: false,
            claimCard: null,
            employeeData: null,
            employeeId: "",
            policyId: "",
            showViewEmployee: false,
            policyData: null,
            showViewPolicy: false,
            showViewUser: false,
            userData: null
        };

        handleViewEmployeeClick = (data) => {
            this.setState({
                employeeData: data,
                showViewEmployee: true,
            });
        };

        handleViewPolicyClick = (data) => {
            this.setState({
                policyData: data,
                showViewPolicy: true,
            });
        };

        handleViewUserClick = (data) => {
            this.setState({
                userData: data,
                showViewUser: true,
            });
        };

        searchBy = (type) => {
            this.setState({searchType: type});
        };

        setInput = (e) => {
            this.setState({searchInput: e.target.value});
        };

        setSearchType = (e) => {
            this.setState({
                searchType: e.target.value,
                searchInput: "",
                searchResult: null,
                claimCard: null,
            });
        };

        submitSearch = () => {
            if (this.state.searchInput != null && this.state.searchInput != "") {
                const encodedParamValue = encodeURIComponent(this.state.searchInput);
                fetch(
                    "/api/support/searchsupport?type=" +
                    this.state.searchType +
                    "&input=" +
                    encodedParamValue,
                    {
                        method: "GET",
                    }
                )
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            searchResult: data,
                            loading: false,
                            claimCard: null,
                        },
                        () => {
                            if (this.state.searchResult.length == 0) {
                                toastr.error("No search result found", "Error");
                            }
                        }
                    );
                })
                .catch((error) => {
                    this.setState({searchResult: null, success: false});
                    toastr.console.error("Search failed", "Error");
                });
            }
        };

        handleKeyDown = (event) => {
            if (event.key === "Enter") {
                this.submitSearch();
            }
        };

        render() {
            let placeholderText = "Enter search input";
            if (this.state.searchType == "policy") {
                placeholderText = "Enter Policy Holder name, DBA, or policy number..";
            } else if (this.state.searchType == "employee") {
                placeholderText = "Enter employee name, email, or phone number..";
            } else if (this.state.searchType == "claim") {
                placeholderText = "Enter claim number..";
            } else if (this.state.searchType == "dependent") {
                placeholderText = "Enter dependent name..";
            } else if (this.state.searchType == "user") {
                placeholderText = "Enter username, email..";
            }

            let searchInput = (
                <>
                    <div>
                        <p className="fs-3 pb-3">Search</p>
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <select
                                className="form-select"
                                onChange={(e) => this.setSearchType(e)}
                                defaultValue={this.state.searchType}
                            >
                                <option value="policy">Policy Holder</option>
                                <option value="employee">Employee</option>
                                <option value="claim">Claim</option>
                                <option value="dependent">Dependent</option>
                                <option value="user">User</option>
                            </select>
                        </div>
                        <input
                            onKeyDown={this.handleKeyDown}
                            type="text"
                            className="form-control"
                            name="searchInput"
                            onChange={(e) => this.setInput(e)}
                            value={this.state.searchInput}
                            placeholder={placeholderText}
                        />
                        <button
                            onClick={() => this.submitSearch()}
                            className="btn btn-outline-primary"
                            type="button"
                        >
                            Search
                        </button>
                    </div>
                </>
            );
            let searchContent = null;
            if (this.state.searchType == "policy") {
                let policyTable = null;
                let policyRows = null;

                if (
                    this.state.searchResult != null &&
                    this.state.searchResult.length > 0
                ) {
                    policyRows = this.state.searchResult.map((data, i) => {
                        let statusText = <td className="green">Active</td>;
                        if (!data.isActive) {
                            statusText = <td className="red">Inactive</td>;
                        }

                        return (
                            <tr key={data.id}>
                                <th scope="row">{i + 1}</th>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm"
                                        onClick={() => this.handleViewPolicyClick(data)}
                                    >
                                        {data.name}
                                    </button>
                                </td>
                                {statusText}
                                <td className="text-end">{data.empCount}</td>
                                <td className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            window.open(
                                                "/ops/enroll.policy.record?policy_id=" + data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        OPS View
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "/ops/planadmin.policy.record?policy_id=" + data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        PA View
                                    </button>
                                </td>
                            </tr>
                        );
                    });

                    policyTable = (
                        <div>
                            <div className="pt-2">
                                Found <b>{this.state.searchResult.length}</b>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col" style={{textAlign: "right"}}>
                                        Employees
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>{policyRows}</tbody>
                            </table>
                        </div>
                    );
                }

                searchContent = (
                    <div>
                        {searchInput}
                        {policyTable}
                        {this.state.showViewPolicy && <ViewPolicy data={this.state.policyData} />}
                    </div>
                );
            } else if (this.state.searchType == "employee") {
                let employeeTable = null;
                let employeeRows = null;

                if (
                    this.state.searchResult != null &&
                    this.state.searchResult.length > 0
                ) {
                    employeeRows = this.state.searchResult.map((data, i) => {
                        let statusText = <td className="green">Active</td>;
                        if (!data.isActive) {
                            statusText = <td className="red">Inactive</td>;
                        }

                        let claimNo = null;
                        if (data.latestClaim != null) {
                            claimNo = (
                                <button
                                    onClick={() => {
                                        window.open(
                                            "https://employee.nationalhealthclaim.com/ee/" +
                                            data.id +
                                            "/viewclaim/" +
                                            data.latestClaim.id,
                                            "_blank"
                                        );
                                    }}
                                    type="button"
                                    className="btn-link btn-sm"
                                >
                                    {data.latestClaim.claimNo}
                                </button>
                            );
                        }

                        return (
                            <tr key={data.id}>
                                <th scope="row">{i + 1}</th>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm"
                                        onClick={() => this.handleViewEmployeeClick(data)}
                                    >
                                        {data.lastName}, {data.firstName}
                                    </button>
                                </td>
                                {statusText}
                                <td>{data.isPlanAdmin === true ? "Yes" : "No"}</td>
                                <td>{data.policyName}</td>
                                <td>{claimNo}</td>
                                <td className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            window.open(
                                                "/ops/enroll.policy.record?policy_id=" + data.polId,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        Policy OPS
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "/ops/enroll.employee.record?employee_id=" + data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        EE Ops
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-dark btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "https://employee.nationalhealthclaim.com/ee/" +
                                                data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        EE Portal
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-dark btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "/ops/planadmin.policy.record?policy_id=" + data.polId,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        PA View
                                    </button>
                                </td>
                            </tr>
                        );
                    });

                    employeeTable = (
                        <div style={{maxHeight: 500, overflowY: "auto"}}>
                            <div className="pt-2">
                                Found <b>{this.state.searchResult.length}</b>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">PA</th>
                                    <th scope="col">Policy</th>
                                    <th scope="col">Latest Claim</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>{employeeRows}</tbody>
                            </table>
                        </div>
                    );
                }

                searchContent = (
                    <div>
                        {searchInput}
                        {employeeTable}
                        {this.state.showViewEmployee && <ViewEmployee data={this.state.employeeData} />}
                    </div>
                );
            } else if (this.state.searchType == "claim") {
                let claimTable = null;
                let claimRows = null;

                if (
                    this.state.searchResult != null &&
                    this.state.searchResult.length > 0
                ) {
                    claimRows = this.state.searchResult.map((data, i) => {
                        let statusText = <td className="green">Active</td>;
                        if (!data.isActive) {
                            statusText = <td className="red">Inactive</td>;
                        }

                        let claimNo = (
                            <button
                                onClick={() => {
                                    window.open(
                                        "https://employee.nationalhealthclaim.com/ee/" +
                                        data.id +
                                        "/viewclaim/" +
                                        data.claimId,
                                        "_blank"
                                    );
                                }}
                                type="button"
                                className="btn-link btn-sm"
                            >
                                {data.claimNo}
                            </button>
                        );

                        return (
                            <tr key={data.id}>
                                <th scope="row">{i + 1}</th>
                                <td>{claimNo}</td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm"
                                        onClick={() => this.handleViewEmployeeClick(data)}
                                    >
                                        {data.lastName}, {data.firstName}
                                    </button>
                                </td>
                                {statusText}
                                <td>
                                    {" "}
                                    <Moment local format="YYYY/MM/DD">
                                        {data.dob}
                                    </Moment>
                                </td>
                                <td>{data.policyName}</td>
                                <td className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            window.open(
                                                "/ops/enroll.employee.record?employee_id=" + data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        OPS View
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "/ops/planadmin.employee.record?employee_id=" + data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        PA View
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-dark btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "https://employee.nationalhealthclaim.com/ee/" +
                                                data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        EE Portal
                                    </button>
                                </td>
                            </tr>
                        );
                    });

                    claimTable = (
                        <div style={{maxHeight: 500, overflowY: "auto"}}>
                            <div className="pt-2">
                                Found <b>{this.state.searchResult.length}</b>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Claim</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">DOB</th>
                                    <th scope="col">Policy</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>{claimRows}</tbody>
                            </table>
                        </div>
                    );
                }

                searchContent = (
                    <div>
                        {searchInput}
                        {claimTable}
                        {this.state.showViewEmployee && <ViewEmployee data={this.state.employeeData} />}
                    </div>
                );
            } else if (this.state.searchType == "dependent") {
                let employeeTable = null;
                let employeeRows = null;

                if (
                    this.state.searchResult != null &&
                    this.state.searchResult.length > 0
                ) {
                    employeeRows = this.state.searchResult.map((data, i) => {
                        let statusText = <td className="green">Active</td>;
                        if (!data.isActive) {
                            statusText = <td className="red">Inactive</td>;
                        }

                        let claimNo = null;
                        if (data.latestClaim != null) {
                            claimNo = (
                                <button
                                    onClick={() => {
                                        window.open(
                                            "https://employee.nationalhealthclaim.com/ee/" +
                                            data.id +
                                            "/viewclaim/" +
                                            data.latestClaim.id,
                                            "_blank"
                                        );
                                    }}
                                    type="button"
                                    className="btn-link btn-sm"
                                >
                                    {data.latestClaim.claimNo}
                                </button>
                            );
                        }

                        return (
                            <tr key={data.id}>
                                <th scope="row">{i + 1}</th>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm"
                                        onClick={() => this.handleViewEmployeeClick(data)}
                                    >
                                        {data.lastName}, {data.firstName}
                                    </button>
                                </td>
                                {statusText}
                                <td>
                                    {" "}
                                    <Moment local format="YYYY/MM/DD">
                                        {data.dob}
                                    </Moment>
                                </td>
                                <td>{data.policyName}</td>
                                <td>{claimNo}</td>
                                <td className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            window.open(
                                                "/ops/enroll.employee.record?employee_id=" + data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        OPS View
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "/ops/planadmin.employee.record?employee_id=" + data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        PA View
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-dark btn-sm ms-3"
                                        onClick={() => {
                                            window.open(
                                                "https://employee.nationalhealthclaim.com/ee/" +
                                                data.id,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        EE Portal
                                    </button>
                                </td>
                            </tr>
                        );
                    });

                    employeeTable = (
                        <div style={{maxHeight: 500, overflowY: "auto"}}>
                            <div className="pt-2">
                                Found <b>{this.state.searchResult.length}</b>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">DOB</th>
                                    <th scope="col">Policy</th>
                                    <th scope="col">Latest Claim</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>{employeeRows}</tbody>
                            </table>
                        </div>
                    );
                }

                searchContent = (
                    <div>
                        {searchInput}
                        {employeeTable}
                        {this.state.showViewEmployee && <ViewEmployee data={this.state.employeeData} />}
                    </div>
                );
            } else if (this.state.searchType == "user") {
                let userTable = null;
                let userRows = null;

                if (this.state.searchResult != null && this.state.searchResult.length > 0) {
                    userRows = this.state.searchResult.map((data, i) => {
                        let statusText = <td className="green">Active</td>;
                        if (!data.isActive) {
                            statusText = <td className="red">Inactive</td>;
                        }

                        return (
                            <tr key={data.id}>
                                <th scope="row">{i + 1}</th>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm"
                                        onClick={() => this.handleViewUserClick(data)}
                                    >
                                        {data.username}
                                    </button>
                                </td>
                                <td>
                                        {data.lastName}, {data.firstName}
                                </td>
                                {statusText}
                                {/* <td>{data.isPlanAdmin === true ? "Yes" : "No"}</td> */}
                                {/* <td>{data.policyName}</td> */}
                            </tr>
                        );
                    });

                    userTable = (
                        <div style={{maxHeight: 500, overflowY: "auto"}}>
                            <div className="pt-2">
                                Found <b>{this.state.searchResult.length}</b>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Username</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    {/* <th scope="col">PA</th>
                                    <th scope="col">Policy</th>
                                    <th scope="col"></th> */}
                                </tr>
                                </thead>
                                <tbody>{userRows}</tbody>
                            </table>
                        </div>
                    );
                }

                searchContent = (
                    <div>
                        {searchInput}
                        {userTable}
                        {this.state.showViewUser && <ViewUser data={this.state.userData} />}
                    </div>
                );
            } 

            let searchContainer = (
                <div className="home-card-container">
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <Panel>
                                    <Column>
                                        <div className="search-card">{searchContent}</div>
                                    </Column>
                                </Panel>
                            </div>
                        </div>
                    </div>
                </div>
            );

            return <div>{searchContainer}</div>;
        }
    }
);
