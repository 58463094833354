import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, TemplateEditor, Collection} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,RecordHeader,TabSet,Tab,Display,Column,Panel,Button,Line,Card} from 'ktm-ui';
import Edit_email_templateScreen from './Edit_email_template';
import Use_default_emailScreen from './Use_default_email';
import Edit_datesScreen from './Edit_dates';
import Edit_flex_optionsScreen from './Edit_flex_options';

interface IdsCampaign {
    Id:string;
    Policy_Id:string;
    BenefitYear_Id:string;
    PolicyBenefit_Id:string;
    AllocationIncrement:number;
    SelectionDeadline:string;
    LaunchDate:string;
    ReminderDate:string;
    LastDayReminder:string;
    UseCustomEmailTemplate:boolean;
    EmailSubject:string;
    EmailTemplate:string;
    AllocationIncrementAmount:number;
};

interface IdsBenefit {
    Id:string;
    Name:string;
};

interface IdsYear {
    Id:string;
    StartDate:string;
};

interface IdsEmailTemplate {
    Name:string;
    Content:string;
    Subject:string;
    Tags:string;
};

interface IdsEmailTags {
    Name:string;
    Label:string;
};

export default KTMScreen({name:"enroll.campaign.record"}, class extends React.Component<{campaign_id:string}> {

    data:{
        dsCampaign:IdsCampaign;
        dsBenefit:IdsBenefit;
        dsYear:IdsYear;
        dsEmailTemplate:IdsEmailTemplate;
        dsEmailTags:IdsEmailTags;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsCampaign";
        await KTMApi.start(screen,{
            "@campaign_id":this.props.campaign_id,
        });
        screen.title = this.data.dsBenefit.Name;
        await screen.triggerAction(this.configure_ui);
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.tabs)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <RecordHeader entityLabel="Campaign">
                <Display field={field("@dsYear.StartDate")}/>
            </RecordHeader>
        );
    }

    tabs({field,draw,action}:RenderEvent){
        return (
            <TabSet>
                <Tab label="Details" template={this.main_tab}/>
                <Tab label="Selection email" template={this.email_tab}/>
                <Tab label="Flex selection screen" template={this.flex_selection_tab}/>
            </TabSet>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
                <Column>
                    {draw(this.flex_options)}
                </Column>
            </Panel>
        );
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card label="Dates" actions={<><Button action={action(this.edit_dates)} label="Edit" style="link"/>
</>}>
                <Line>
                    <Display field={field("LaunchDate")}/>
                    <Display field={field("ReminderDate")}/>
                    <Display field={field("LastDayReminder")}/>
                    <Display field={field("SelectionDeadline")}/>
                </Line>
            </Card>
        );
    }

    flex_options({field,draw,action}:RenderEvent){
        return (
            <Card label="Flex options" actions={<><Button action={action(this.edit_flex_options)} label="Edit" style="link"/>
</>}>
                <Line>
                    <Display field={field("AllocationIncrement")}/>
                    <Display field={field("AllocationIncrementAmount")}/>
                </Line>
            </Card>
        );
    }

    selection_email({field,draw,action}:RenderEvent){
        return (
            <Card label="Selection email" actions={<><Button action={action(this.create_custom_email)} label="Create custom template" style="link"/>
<Button action={action(this.use_default_email)} label="Use default template" style="link"/>
<Button action={action(this.edit_email)} label="Edit template" style="link"/>
</>}>
                {draw(this.custom_email_subject)}
                <div style={{borderTop:"solid 2px #e2e4e6",marginTop:15,marginBottom:15}}/>
                {draw(this.custom_email_viewer)}
                {draw(this.default_email_viewer)}
            </Card>
        );
    }

    selection_email_copy({field,draw,action}:RenderEvent){
        return (
            <Card label="Flex selection welcome screen" actions={<><Button action={action(this.create_custom_email)} label="Create custom template" style="link"/>
<Button action={action(this.use_default_email)} label="Use default template" style="link"/>
<Button action={action(this.edit_email)} label="Edit template" style="link"/>
</>}>
                {draw(this.custom_email_viewer)}
                {draw(this.default_email_viewer)}
            </Card>
        );
    }

    custom_email_viewer({field,draw,action,collection}:RenderEvent){
        return (
            <TemplateEditor field={field("@dsCampaign.EmailTemplate")} tags={collection("@dsEmailTags")} /> 
        );
    }

    default_email_viewer({field,draw,action,collection}:RenderEvent){
        return (
            <TemplateEditor field={field("@dsEmailTemplate.Content")} tags={collection("@dsEmailTags")} />
        );
    }

    email_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.selection_email)}
                </Column>
            </Panel>
        );
    }

    flex_selection_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.selection_email_copy)}
                </Column>
            </Panel>
        );
    }

    custom_email_subject({field,draw,action,collection}:RenderEvent): JSX.Element{
        return (
            <TemplateEditor field={field("@dsCampaign.EmailSubject")} tags={collection("@dsEmailTags")} />
        );
    }

    default_email_subject({field,draw,action,collection}:RenderEvent){
        return (
            <TemplateEditor field={field("@dsEmailTemplate.Subject")} tags={collection("@dsEmailTags")} /> 
        );
    }

    async create_custom_email({screen}:ActionEvent){
        await screen.openDialog(<Edit_email_templateScreen campaign_id={this.data.dsCampaign.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async use_default_email({screen}:ActionEvent){
        await screen.openDialog(<Use_default_emailScreen campaign_id={this.data.dsCampaign.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async edit_email({screen}:ActionEvent){
        await screen.openDialog(<Edit_email_templateScreen campaign_id={this.data.dsCampaign.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async configure_ui({screen}:ActionEvent){
        screen.hide(this.custom_email_viewer);
        screen.hide(this.default_email_viewer);
        screen.hide(this.create_custom_email);
        screen.hide(this.use_default_email);
        screen.hide(this.edit_email);
        if (screen.getValue("@dsCampaign.UseCustomEmailTemplate")){
            screen.unhide(this.custom_email_viewer);
            screen.unhide(this.use_default_email);
            screen.unhide(this.edit_email);
        }
        else {
            screen.unhide(this.default_email_viewer);
            screen.unhide(this.create_custom_email);
        } 
    }

    async refresh_data({screen}:ActionEvent){
        await screen.restart();
    }

    async edit_dates({screen}:ActionEvent){
        await screen.openDialog(<Edit_datesScreen campaign_id={this.data.dsCampaign.Id}/>
,{onRecordChanged:this.refresh_data});
    }

    async edit_flex_options({screen}:ActionEvent){
        await screen.openDialog(<Edit_flex_optionsScreen campaign_id={this.data.dsCampaign.Id}/>
,{onRecordChanged:this.refresh_data});
    }

});
