import axios from "axios";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { CustomLoading } from "components/CustomLoading";
import { CSVLink } from "react-csv";
import React from "react";
import toastr from "toastr";
import { Button, Table } from "react-bootstrap";
import CustomPagination from "components/CustomPagination";
import { EmptyStateTemplate } from "./Empty_state_Template";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import base64 from "../../data/base64.json";
import "../../styles/Reports.css";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class MonthlyInvoice extends React.Component<any, any> {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      loading: true,
      invoiceList: [],
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth() + 1,
      accountType: "HSA",
      hasRst: false,
      policyName: null,
      address: null,
      city: null,
      province: null,
      postalCode: null,
      invoiceDate: null,
      invoiceNumber: null,
      accountNumber: null,
      pdfDate: null,
      invoiceClaims: [],
      groupedDivisions: [],
      groupedCostCentres: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  fetchData = () => {
    this.setState({
      invoiceList: [],
      invoiceClaims: [],
    });
    axios
      .get("/api/Reports/getmonthlyinvoicelist", {
        params: {
          policyId: this.props.policyId,
          year: this.state.selectedYear,
          month: this.state.selectedMonth,
          accountType: this.state.accountType,
          showHSAReports: this.props.showHSAReports,
          showLSAReports: this.props.showLSAReports,
        },
      })
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              invoiceList: res.data.invoiceList,
              loading: false,
              policyName: res.data.policyName,
              address: res.data.address,
              city: res.data.city,
              province: res.data.province,
              postalCode: res.data.postal,
              invoiceDate: res.data.invoiceDate,
              invoiceNumber: res.data.invoiceNumber,
              accountNumber: res.data.accountNumber,
              pdfDate: res.data.pdfDate,
              invoiceClaims: res.data.invoiceClaims,
              hasRst: res.data.hasRst,
            },
            () => {
              const groupedDivisions = this.groupAndSumByDivision();
              const groupedCostCentres = this.groupAndSumByCostCentre();
              this.setState({ groupedDivisions, groupedCostCentres });
            }
          );
        } else {
          this.setState({
            invoiceList: [],
            loading: false,
          });
        }
      })
      .catch((err) => {
        toastr.error(err, "Error");
      });
  };

  submit = () => {
    this.setState({ loading: true });
    this.fetchData();
  };

  groupAndSumByDivision() {
    const { invoiceClaims, hasRst } = this.state;

    const groupedData = invoiceClaims.reduce((acc, claim) => {
      if (!claim.division) {
        return acc;
      }

      if (!acc[claim.division]) {
        acc[claim.division] = {
          division: claim.division,
          hsaAmount: 0,
          lsaAmount: 0,
          adminFee: 0,
          gst: 0,
          total: 0,
        };

        if (hasRst) {
          acc[claim.division].rst = 0;
          acc[claim.division].premium = 0;
        }
      }

      acc[claim.division].hsaAmount += claim.hsaAmount || 0;
      acc[claim.division].lsaAmount += claim.lsaAmount || 0;
      acc[claim.division].adminFee += claim.adminFee || 0;
      acc[claim.division].gst += claim.gst || 0;

      acc[claim.division].total +=
        (claim.hsaAmount || 0) +
        (claim.lsaAmount || 0) +
        (claim.adminFee || 0) +
        (claim.gst || 0);

      // Include these fields in the total if hasRst is true
      if (hasRst) {
        acc[claim.division].rst += claim.rst || 0;
        acc[claim.division].premium += claim.premium || 0;
        acc[claim.division].total += (claim.rst || 0) + (claim.premium || 0);
      }

      return acc;
    }, {});

    return Object.values(groupedData);
  }

  groupAndSumByCostCentre() {
    const { invoiceClaims, hasRst } = this.state;

    const groupedData = invoiceClaims.reduce((acc, claim) => {
      if (!claim.costCentre) {
        return acc;
      }

      if (!acc[claim.costCentre]) {
        acc[claim.costCentre] = {
          costCentre: claim.costCentre,
          hsaAmount: 0,
          lsaAmount: 0,
          adminFee: 0,
          gst: 0,
          total: 0,
        };

        if (hasRst) {
          acc[claim.costCentre].rst = 0;
          acc[claim.costCentre].premium = 0;
        }
      }

      acc[claim.costCentre].hsaAmount += claim.hsaAmount || 0;
      acc[claim.costCentre].lsaAmount += claim.lsaAmount || 0;
      acc[claim.costCentre].adminFee += claim.adminFee || 0;
      acc[claim.costCentre].gst += claim.gst || 0;
      acc[claim.costCentre].total +=
        (claim.hsaAmount || 0) +
        (claim.lsaAmount || 0) +
        (claim.adminFee || 0) +
        (claim.gst || 0);

      if (hasRst) {
        acc[claim.costCentre].rst += claim.rst || 0;
        acc[claim.costCentre].premium += claim.premium || 0;
        acc[claim.costCentre].total += (claim.rst || 0) + (claim.premium || 0);
      }

      return acc;
    }, {});

    return Object.values(groupedData);
  }

  createDivisionTableBody() {
    const { groupedDivisions, hasRst } = this.state;
    let header = [
      "Division",
      "HSA Amount",
      "LSA Amount",
      "Admin Fee",
      "GST/HST",
      "Total",
    ];

    if (hasRst) {
      header = [...header.slice(0, 5), "RST", "Premium Tax", "Total"];
    }

    const grandTotal = groupedDivisions.reduce(
      (sum, division) => sum + division.total,
      0
    );

    const body = groupedDivisions.map((division) => [
      division.division,
      division.hsaAmount.toFixed(2),
      division.lsaAmount.toFixed(2),
      division.adminFee.toFixed(2),
      division.gst.toFixed(2),
      ...(hasRst ? [division.rst.toFixed(2), division.premium.toFixed(2)] : []),
      division.total.toFixed(2),
    ]);

    const adjustedBody = body.map((row) => {
      return row.map((cell, index) => {
        return { text: cell, alignment: index === 0 ? "left" : "right" };
      });
    });

    const totalRow = Array(hasRst ? 8 : 6).fill({
      text: "",
      alignment: "right",
    });
    totalRow[0] = { text: "Total Amount", alignment: "left" };
    totalRow[totalRow.length - 1] = {
      text: grandTotal.toFixed(2),
      alignment: "right",
    };

    return [
      header.map((h) => ({
        text: h,
        alignment: h === "Division" ? "left" : "right",
      })),
      ...adjustedBody,
      totalRow,
    ];
  }

  createCostCentreTableBody() {
    const { groupedCostCentres, hasRst } = this.state;
    let header = [
      "Cost Centre",
      "HSA Amount",
      "LSA Amount",
      "Admin Fee",
      "GST/HST",
      "Total",
    ];

    if (hasRst) {
      header = [...header.slice(0, 5), "RST", "Premium Tax", "Total"]; // Insert 'RST' and 'Premium Tax' before 'Total'
    }

    const grandTotal = groupedCostCentres.reduce(
      (sum, costCentre) => sum + costCentre.total,
      0
    );

    const body = groupedCostCentres.map((costCentre) => [
      costCentre.costCentre,
      costCentre.hsaAmount.toFixed(2),
      costCentre.lsaAmount.toFixed(2),
      costCentre.adminFee.toFixed(2),
      costCentre.gst.toFixed(2),
      ...(hasRst
        ? [costCentre.rst.toFixed(2), costCentre.premium.toFixed(2)]
        : []),
      costCentre.total.toFixed(2),
    ]);

    const adjustedBody = body.map((row) => {
      return row.map((cell, index) => {
        return { text: cell, alignment: index === 0 ? "left" : "right" };
      });
    });

    const totalRow = Array(hasRst ? 8 : 6).fill({
      text: "",
      alignment: "right",
    });
    totalRow[0] = { text: "Total Amount", alignment: "left" };
    totalRow[totalRow.length - 1] = {
      text: grandTotal.toFixed(2),
      alignment: "right",
    };

    return [
      header.map((h) => ({
        text: h,
        alignment: h === "Cost Centre" ? "left" : "right",
      })),
      ...adjustedBody,
      totalRow,
    ];
  }

  downloadPdf = (filename) => {
    // Set table
    const invoiceClaims = this.state.invoiceClaims;
    const headerRow = ["Date", "Description"];
    if (this.props.showHSAReports) {
      headerRow.push("HSA Amount");
    }
    if (this.props.showLSAReports) {
      headerRow.push("LSA Amount");
    }
    headerRow.push("Admin Fee");
    headerRow.push("GST/HST");
    if (this.state.hasRst) {
      headerRow.push("RST");
      headerRow.push("Premium Tax");
    }

    const numberOfColumns = this.state.hasRst ? 8 : 6;
    let divAndCostWidths;
    if (numberOfColumns === 8) {
      divAndCostWidths = ["auto", "*", "*", "*", "*", "*", "*", "15%"];
    } else {
      divAndCostWidths = ["auto", "*", "*", "*", "*", "15%"];
    }

    const dataRows = invoiceClaims.map((claim, index, array) => {
      const isLastRow = index === array.length - 1;

      const basicData = [
        isLastRow
          ? { text: claim.disbursementDate || "", bold: true, fontSize: 10 }
          : { text: claim.disbursementDate || "", bold: false, fontSize: 10 },
        isLastRow
          ? { text: claim.description || "", bold: true, fontSize: 10 }
          : { text: claim.description || "", bold: false, fontSize: 10 },
        ...(this.props.showHSAReports
          ? [
              {
                text:
                  isLastRow && claim.hsaAmount === 0
                    ? ""
                    : claim.hsaAmount.toFixed(2),
                alignment: "right",
                bold: isLastRow,
                fontSize: 10,
              },
            ]
          : []),
        ...(this.props.showLSAReports
          ? [
              {
                text:
                  isLastRow && claim.lsaAmount === 0
                    ? ""
                    : claim.lsaAmount.toFixed(2),
                alignment: "right",
                bold: isLastRow,
                fontSize: 10,
              },
            ]
          : []),
        {
          text:
            isLastRow && claim.adminFee === 0 ? "" : claim.adminFee.toFixed(2),
          alignment: "right",
          bold: isLastRow,
          fontSize: 10,
        },
        {
          text: isLastRow && claim.gst === 0 ? "" : claim.gst.toFixed(2),
          alignment: "right",
          bold: isLastRow,
          fontSize: 10,
        },
      ];

      const additionalData = this.state.hasRst
        ? [
            {
              text: isLastRow && claim.rst === 0 ? "" : claim.rst.toFixed(2),
              alignment: "right",
              bold: isLastRow,
              fontSize: 10,
            },
            {
              text:
                isLastRow && claim.premium === 0
                  ? ""
                  : claim.premium.toFixed(2),
              alignment: "right",
              bold: isLastRow,
              fontSize: 10,
            },
          ]
        : [];

      return [...basicData, ...additionalData];
    });

    const tableBody = [headerRow, ...dataRows];

    let columnWidths = [55, "*"];
    if (this.props.showHSAReports) {
      columnWidths.push(55);
    }
    if (this.props.showLSAReports) {
      columnWidths.push(55);
    }
    columnWidths.push(40, 40);

    // Notes
    let notes = [
      "National HealthClaim Corp GST: #89503 3314RT",
      "Health Spending Account (HSA) Premiums are Private Health Service Plan Premiums disbursed to employees in accordance withclaims approved.",
      "An administration fee is charged by National HealthClaim Corp for the approval and disbursement service rendered.",
      "Taxes charged are based on the home province of each employee. National HealthClaim collects then remits all taxes to the appropriate authority.",
      "GST/HST is incurred on the administration fee only.",
    ];

    if (this.state.hasRst) {
      columnWidths.push(40, 40);
      notes.push(
        "RST (Retail Sales Tax: 8% for Ontario) is incurred on the claim amount (HSA only). RST: # 895033314 TR0001",
        "Premium Tax (2% for Ontario) is incurred on the total of the claim and admin fee (HSA only). Premium Tax: # 895033314 TP0001"
      );
    }

    // Set PDF
    let content = {
      content: [
        {
          columns: [
            {
              image: base64.logo,
              width: 230,
            },
            {
              text: "STATEMENT",
              fontSize: 20,
              bold: true,
              alignment: "right",
              margin: [0, 0, 80, 0],
            },
          ],
        },
        {
          columns: [
            {
              stack: ["5320 - 7005 Fairmount Dr. SE", "Calgary, AB", "T2H 0K4"],
              margin: [0, 20, 0, 30],
            },
            {
              stack: [
                "Account No.: " + this.state.accountNumber,
                "Statement No.: " + this.state.invoiceNumber,
                "Date: " + this.state.invoiceDate,
              ],
              margin: [65, 20, 0, 30],
            },
          ],
        },
        ...(this.state.invoiceNumber.startsWith("20")
          ? [
              {
                text: "For an accurate breakdown of the report from the Version 2 of our system, please contact our support team at support@nationalhealthclaim.com for assistance.",
                margin: [0, 5, 0, 10],
              },
            ]
          : []),
        {
          table: {
            body: [
              [
                {
                  stack: [
                    {
                      text:
                        "Spending Account services, for the month of " +
                        this.state.pdfDate +
                        ", rendered to:",
                      margin: [15, 15, 15, 5], // Adjust the margin as needed
                    },
                    {
                      stack: [
                        this.state.policyName,
                        this.state.address,
                        this.state.city + ", " + this.state.province,
                        this.state.postalCode,
                      ],
                      margin: [30, 5, 15, 15], // Adjust the margin as needed
                    },
                  ],
                },
              ],
            ],
            widths: ["*"],
          },
          layout: {
            // Custom layout to set the border size and color
            hLineWidth: function () {
              return 1;
            },
            vLineWidth: function () {
              return 1;
            },
            hLineColor: function () {
              return "black";
            },
            vLineColor: function () {
              return "black";
            },
          },
        },
        {
          table: {
            headerRows: 1,
            body: tableBody,
            //widths: columnWidths,
          },
          margin: [0, 20, 0, 0],
        },
        {
          stack: [
            {
              ul: notes,
              fontSize: 9,
            },
          ],
          margin: [15, 25, 0, 0],
        },
        ...(this.state.groupedDivisions.length > 0
          ? [
              {
                text: "Billing Overview per Division",
                bold: true,
                margin: [0, 20, 0, 5],
              },
              {
                style: "tableStyle",
                table: {
                  headerRows: 1,
                  widths: divAndCostWidths,
                  body: this.createDivisionTableBody(),
                },
                margin: [0, 5, 0, 10],
              },
            ]
          : []),
        ...(this.state.groupedCostCentres.length > 0
          ? [
              {
                text: "Billing Overview per Cost Centres",
                bold: true,
                margin: [0, 20, 0, 5],
              },
              {
                style: "tableStyle",
                table: {
                  headerRows: 1,
                  widths: divAndCostWidths,
                  body: this.createCostCentreTableBody(),
                },
                margin: [0, 5, 0, 10],
              },
            ]
          : []),
      ],
      styles: {
        tableStyle: {
          fontSize: 10,
        },
      },
    };

    pdfMake.createPdf(content).download(filename);
  };

  render() {
    let contents = null;
    if (this.state.loading) {
      contents = <CustomLoading />;
    } else {
      contents = (
        <div className="funding-activity-and-transactions-table report-table-container">
          {this.state.invoiceList.length > 0 ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Invoice</th>
                    <th>Account</th>
                    {/* <th>Type</th> */}
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoiceList.map((data, i) => {
                    return (
                      <tr>
                        <td>{data.name}</td>
                        <td>{data.accountNumber}</td>
                        {/* <td>{data.accountType}</td> */}
                        <td>{data.invoiceDate}</td>
                        <td>
                          <button
                            onClick={() => this.downloadPdf(data.name + ".pdf")}
                            className="btn btn-primary btn-sm"
                          >
                            Export to PDF
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <div className="empty-state-template">
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: 500,
                  marginBottom: "20px",
                }}
              >
                No data found for the selected date.
              </div>
            </div>
          )}
        </div>
      );
    }

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear - i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const formatMonth = (month) => {
      return new Intl.DateTimeFormat("en-US", { month: "short" }).format(
        new Date(currentYear, month - 1)
      );
    };

    let reportPicker = (
      <div
        className="d-flex align-items-center justify-content-start mt-4"
        style={{ maxWidth: 400 }}
      >
        {/* <select
          name="accountType"
          value={this.state.accountType}
          onChange={this.handleChange}
          className="form-select me-2"
        >
          {this.props.showHSAReports && <option value="HSA">HSA</option>}
          {this.props.showLSAReports && <option value="LSA">LSA</option>}
        </select> */}

        <select
          name="selectedYear"
          value={this.state.selectedYear}
          onChange={this.handleChange}
          className="form-select me-2"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        <select
          name="selectedMonth"
          value={this.state.selectedMonth}
          onChange={this.handleChange}
          className="form-select me-2"
          aria-label="Default select example"
        >
          {months.map((month) => (
            <option key={month} value={month}>
              {formatMonth(month)}
            </option>
          ))}
        </select>

        <button onClick={this.submit} className="btn btn-primary">
          Submit
        </button>
      </div>
    );

    return (
      <>
        <div className="m-4">
          <h2 style={{ fontSize: 24 }}>
            Monthly Statement for Claim Disbursements
          </h2>
          {reportPicker}
        </div>
        {contents}
      </>
    );
  }
}
