import { ComponentContextType, ComponentContextValue} from "./types";
import React from 'react';
import { ActionEvent } from "./ActionEvent";
import { RenderEvent } from "./RenderEvent";
import {Request} from './Request';
import { Application } from "./Application";
import { Canvas } from "./Canvas";
import { Url } from "./Url";
import { ActionRef } from "./ActionRef";

export function defineScreen<T>(options:{name:string},component:React.ComponentType<T>):(props:T) => JSX.Element {
   
    let wrapped =  (props:T) => {
        let screenName = options.name;
        var keys = Object.getOwnPropertyNames(component.prototype);
        for(let i =0 ; i < keys.length;i++){
            let key = keys[i];
            let value = component.prototype[key];
            if (typeof value == "function"){
                value.$name = key.replace(/_/g,'-');
            }
        }
        let newProps = {...props,ScreenComponent:component,$screenInfo:options};
        return <ScreenContainer {...newProps}/>
    }
    Application.screens[options.name] = wrapped as any;
    return wrapped;
}


export function DynamicScreen(props:{screen:string} & any):JSX.Element {
    let newProps = {...props,ScreenComponent:GenericScreen,$screenInfo:{name:props.screen}};
    return <ScreenContainer {...newProps}/>
}

export default class ScreenContainer extends React.Component<{ScreenComponent:any,$parent?:Canvas,$layer?:any,$layerZIndex?:number
    ,$onClose?:any,$handleRecordChanged?:(data:any) => Promise<void>,eventValue?:any,$screenInfo?:{name:string},event?:RenderEvent}>{

    canvas:Canvas;    

    constructor(props){
        super(props);
        let parent = this.props.$parent;
        if (!parent){
            if (this.props.event){
                parent = this.props.event.canvas;
            }
        }
        this.canvas = new Canvas(parent);
        let launchProps = {};
        
        for(let key in this.props){
            let value = this.props[key];
            if (key[0] != '$' && key != "event" && key != "ScreenComponent" && key != "eventValue" && Url.isPrimitive(value)){
                launchProps[key] = value;
            }
        }

        if (this.props.$screenInfo){
            this.canvas.launchParams = {
                screen:this.props.$screenInfo.name,
                props:launchProps
            }
        }
    }

    async componentDidMount(){
        let canvas = this.canvas;
        canvas.app.registerScreen(this);
        canvas.layer = this.props.$layer;
        if (!canvas.layer){
            if (canvas.parent){
                canvas.layer = "embedded";
            }
            else {
                canvas.layer = "page";
            }
        }
        canvas.layerZIndex = this.props.$layerZIndex;
        canvas.closeDialogBox = this.props.$onClose;
        canvas.handleRecordChanged = this.props.$handleRecordChanged;
    
        await this.start(canvas);
    }

  
    componentWillUnmount(){
        this.canvas.updateComponent = null;
        this.canvas.app.unregisterScreen(this);
    }

    render(){
        let canvas = this.canvas;
        let value:ComponentContextValue = {canvas: canvas};
        
        let newProps = {...this.props,$canvas:canvas};

     

        return (
            <ComponentContextType.Provider value={value}>
                <CanvasWrapper canvas={canvas} component={this.props.ScreenComponent} componentProps={newProps}/>
            </ComponentContextType.Provider>
        );
    }

    async start(canvas:Canvas){

        let actionTarget = canvas.actionTarget;
        if (actionTarget && actionTarget.start){
            canvas.app.spinner.show();
            let action = new ActionRef(canvas,"start",null);
            let result = await canvas.triggerAction(action,{value:this.props.eventValue,title:"Attempting to load screen: " + this.props.$screenInfo.name});
            if (!result.continue){
                // start failed;
                return;
            }
            canvas.app.spinner.hide();
        }
        canvas.initialized = true;
        canvas.update();
    }

    async reload(){
        this.canvas.resetData();
        await this.start(this.canvas);
    }
}

class CanvasWrapper extends React.Component<{canvas:Canvas,component:React.ComponentType,componentProps:any}>{
    componentDidMount(){
        this.props.canvas.updateComponent = () => {
            this.forceUpdate();
        }
    }
    componentWillUnmount(){
        this.props.canvas.updateComponent = null;
    }
    render(){
        let ScreenComponent = this.props.component;
        return <ScreenComponent {...this.props.componentProps} />
    }
}

export function renderScreen<T>(component:React.Component<T & {$canvas?:Canvas}>,loading?:any){
    let canvas = component.props.$canvas as Canvas;
    canvas.attachToScreen(component);
    if (!canvas.initialized) return null;
    
    let templateRef = canvas.getTemplateRef(canvas.layoutTemplate || "main");
    if (!templateRef){
        return null;
    }
    let renderEvent = new RenderEvent(canvas,templateRef.name);
    let elem = renderEvent.draw(canvas.layoutTemplate || "main");
    if (elem){
        return React.cloneElement(elem,{event:renderEvent});
    }
    return null;
}

class GenericScreen extends React.Component<any>{
    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        let params = {};
        let props = this.props;
        for(let key in props){
            if (key[0] != "$" && key != "ScreenComponent" && key != "event"){
                params[key] = props[key];
            }
        }
        await Request.callScript(screen,"start","start",params);
        
    }
}