import React from 'react';
import {makeComponent} from '../core';

export const Line = makeComponent(class extends React.Component<{ spacing?:string,align?:string,vertSpacing?:string; separator?:string,vertAlign?:string}>{
    render(){
        return (<div className="RT-Line">
            {this.renderContent()}
        </div>)
    }

    renderContent(){
    
        let children;
        let spacing = this.props.spacing;
        if (spacing == "sentence"){
            children = [];
            React.Children.forEach(this.props.children,(child:any,index:number) => {
                if (index){
                    children.push(" ");
                }
                
                children.push(React.cloneElement(child,{key:index}));
            })
        }
        else {
            children = React.Children.map(this.props.children, (child: any) => {
                let span = child.props.span;
                let className = "RT-Line__item";
                if (span){
                    className += " RT--span-" + span;
                }
                return <div className={className} key={child.key}>{child}</div>
            });

        }
        
        
        
        let className = "RT-Line__content";
        

        if (spacing == "compress"){
            className += " RT-Line__content--compress";
        }
        else if (spacing == "sentence"){
            className += " RT-Line__content--sentence";
        }
        else if (spacing != "content"){
            className += " RT-Line__content--space-evenly";
        }
        if (this.props.align){
            className += " RT-Line__content--align-" + this.props.align;
        }
        if (this.props.vertSpacing){
            className += " RT-Line__content--vspace-" + this.props.vertSpacing
        }
        if (this.props.separator){
            className += " RT-Line__content--separator-" + this.props.separator;
        }
        if (this.props.vertAlign){
            className += " RT-Line__content--valign-" + this.props.vertAlign;
        }


        return (
            <div className={className}>
                {children}
            </div>
        )
    }
});