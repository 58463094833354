import { Canvas } from "./Canvas";
import { Collection } from "./Collection";
import { RecordMeta } from "./RecordMeta";
import { IRecordSchema } from "./Schema";
import { IEventScope } from "./types";

export class CollectionRef {
    canvas:Canvas;
    name:string;
    scope:IEventScope;
    rows:any[];
    schema:IRecordSchema;

    constructor(canvas:Canvas,scope:IEventScope,rows:any[],name:string,schema:IRecordSchema){
        this.canvas = canvas;
        this.scope = scope;
        this.rows = rows;
        this.name = name;
        this.schema = schema;
    }

    get currentRow():any {
        return Collection.getCurrentRow(this.canvas,this.name);
    }

    set currentRow(value:any){
        Collection.setCurrentRow(this.canvas,this.name,value);
    }

    locate(rowid:any):any {
        let index = this.findRow(rowid);
        if (index != -1){
            this.currentRow = this.rows[index];
        }
        else {
            this.currentRow = null;
        }
        return this.currentRow;
    }

    addRow(options:{position?:null | 'first' | 'current'} = {}){
        let rows = this.rows;
        if (!rows){
            rows = [];
            this.canvas.setRecord(this.name,rows);
        }
        let newRow = RecordMeta.initalizeRecord(this.schema,{});
        rows.push(newRow);
        this.currentRow = newRow;
    }

    removeRow(options:{rowid?:any,mode?:null | 'all'}){
        let rows = this.rows;
        if (!rows) return;
        if (options.mode == "all"){
            rows.splice(0,rows.length);
            return;
        }
        let index = this.findRow(options.rowid);
        if (index){
            rows.splice(index,1);
        }
    }

    findRow(rowid:any):number {
        let rows = this.rows;
        for(let i =0 ; i < rows.length;i++){
            let row = rows[i];
            if (row.Id == rowid) {
               return i;
            }
        }
        return -1;
    }

    clearErrors(){
        let rows = this.rows;
        if (!rows) return;
        for(let i = 0; i < rows.length;i++){
            let row = rows[i];
            if (row){
                RecordMeta.clearErrors(row);
            }
        }
    }

}