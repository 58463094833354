import React from 'react';
import {makeComponent,ActionRef} from '../core'; 
import { ClickThrottle } from './helpers/ClickThrottle';


export const EditLink = makeComponent(class extends React.Component<{label:string,action:ActionRef}>{
    
    clickThrottle:ClickThrottle;

    render(){
        let button = this.props.action;
        if (!button || button.hidden) return null;
        return <a href='#' className="RT-Link" onClick={this.handleClick}>{this.props.label}</a>
    }

    handleClick = (e:React.MouseEvent) => {
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,() => {
            e.preventDefault();
            e.stopPropagation();
            this.props.action.trigger();
        });
    }
});