import CustomPagination from "components/CustomPagination";
import { Card, Line } from "ktm-ui";
import React from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { CSVLink } from "react-csv";

export default class OpsAdminFeeReport extends React.Component<any, any> {
  state = {
    loading: true,
    success: false,
    adminFees: [],
    total: 0,
    currentPage: 1,
    itemsPerPage: 10,
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
    totalEnroll: 0,
    totalNhc: 0,
    totalDistro: 0,
    includeLimits: false,
    includeMonths: false,
  };

  componentDidMount() {
    this.initialize();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.fromDate !== this.state.fromDate ||
      prevState.toDate !== this.state.toDate ||
      prevState.includeLimits !== this.state.includeLimits ||
      prevState.includeMonths !== this.state.includeMonths
    ) {
      this.setState({ loading: true });
      this.initialize();
    }
  }
  setCurrentPage = (page: number) => {
    this.setState({ currentPage: page });
  };

  initialize = () => {
    fetch(
      "/api/opstransfer/getadminfeereport?dateFrom=" +
        this.state.fromDate.toISOString() +
        "&dateTo=" +
        this.state.toDate.toISOString() +
        "&includeLimits=" +
        this.state.includeLimits +
        "&includeMonths=" +
        this.state.includeMonths,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          loading: false,
          adminFees: data.adminFees,
          totalEnroll: data.totalEnroll,
          totalNhc: data.totalNhc,
          totalDistro: data.totalDistro,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  setFromDate = (date) => {
    this.setState({
      fromDate: date,
    });
  };

  setToDate = (date) => {
    this.setState({
      toDate: date,
    });
  };

  toggleIncludeLimits = () => {
    this.setState({ includeLimits: !this.state.includeLimits });
  };
  toggleIncludeMonths = () => {
    this.setState({ includeMonths: !this.state.includeMonths });
  };

  render() {
    const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
    const currentRecords = this.state.adminFees.slice(
      indexOfFirstRecord,
      indexOfLastRecord
    );
    const nPages = Math.ceil(
      this.state.adminFees.length / this.state.itemsPerPage
    );

    let totalRevenue = this.state.totalEnroll + this.state.totalNhc;

    const currentDate = new Date();
    const past12Months = [];

    for (let i = 0; i <= 11; i++) {
      const newDate = new Date(currentDate);
      newDate.setMonth(newDate.getMonth() - i);
      const formattedDate = `${newDate.getFullYear()}-${String(
        newDate.getMonth() + 1
      ).padStart(2, "0")}-01`;
      past12Months.unshift(formattedDate);
    }

    var adminFeeTable = null;
    if (this.state.adminFees.length > 0) {
      adminFeeTable = (
        <>
          <table className="table">
            <thead>
              <tr>
                {/* <th></th> */}
                <th>Company</th>
                <th>Policy No.</th>
                <th>Total Limits</th>
                <th>Status</th>
                <th style={{ minWidth: 100 }}>Enroll Date</th>
                <th style={{ minWidth: 100 }}>Start Date</th>
                <th style={{ minWidth: 100 }}>Effective Date</th>
                <th>Advisor Id</th>
                <th>Primary Advisor</th>
                <th>Distro Id</th>
                <th>Distro</th>
                <th className="text-end">HSA Admin Rate</th>
                <th className="text-end">LSA Admin Rate</th>
                <th className="text-end">NHC Commission Ratio</th>
                <th className="text-end">Advisor Commission Ratio</th>
                <th className="text-end">Distro Commission Amount</th>
                <th className="text-end">NHC Commission Amount</th>
                {this.state.includeMonths ? past12Months.map((date, index) => (
                  <th key={index}>{date}</th>
                )) : null}
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((data, i) => {
                return (
                  <tr>
                    <td>{data.company}</td>
                    <td>{data.policyNo}</td>
                    <td>{data.totalEmployeesLimits}</td>
                    <td
                      style={{
                        color: data.status == "Active" ? "green" : "gray",
                      }}
                    >
                      {data.status}
                    </td>
                    <td>{data.enrollDate}</td>
                    <td>{data.startDate}</td>
                    <td>{data.effectiveDate}</td>
                    <td>{data.advisorId}</td>
                    <td>{data.advisorName}</td>
                    <td>{data.distroId}</td>
                    <td>{data.distroName}</td>
                    <td className="text-end">{data.hsaAdminRate}</td>
                    <td className="text-end">{data.lsaAdminRate}</td>
                    <td className="text-end">{data.advisorCommissionRatio}</td>
                    <td className="text-end">{data.nhcCommissionRatio}</td>
                    <td className="text-end">{data.distroCommissionAmount}</td>
                    <td className="text-end">{data.nhcCommissionAmount}</td>
                    {this.state.includeMonths ? past12Months.map((date, index) => (
                      <td key={index}>{data[date]}</td>
                    )) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              position: "absolute",
              left: "25px",
              margin: "0px",
              fontWeight: 500,
              fontSize: 20,
            }}
          >
            Total Claim Commission:{" "}
            {this.state.totalNhc.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </div>
          <div
            style={{
              position: "absolute",
              left: "25px",
              margin: "0px",
              paddingTop: 30,
              fontWeight: 500,
              fontSize: 20,
            }}
          >
            Total Enroll Fee Commission:{" "}
            {this.state.totalEnroll.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </div>
          <div
            style={{
              position: "absolute",
              left: "25px",
              margin: "0px",
              paddingTop: 60,
              fontWeight: 500,
              fontSize: 20,
            }}
          >
            Total NHC Revenue:{" "}
            {totalRevenue.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })}
          </div>
          <div
            className="pagination-container"
            style={{
              position: "relative",
              right: "10px",
              margin: "0px",
              justifyContent: "end",
              height: 100,
            }}
          >
            <div className="pagination-info">
              <span>
                Showing {indexOfFirstRecord + 1} -{" "}
                {indexOfFirstRecord + currentRecords.length} entries out of{" "}
                {this.state.adminFees.length} entries
              </span>
            </div>
            <CustomPagination
              nPages={nPages}
              currentPage={this.state.currentPage}
              setCurrentPage={this.setCurrentPage}
            />
          </div>
        </>
      );
    }

    const today = new Date().toISOString().slice(0, 10);
    const fileName = `adminfees_${today}.csv`;

    return (
      <>
        {this.state.loading ? (
          <div className="financial-spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          <div style={{ overflowX: "auto" }}>
            <div className="mb-2 d-flex justify-content-between">
              <div>
                <CustomDateRangePicker
                  fromDate={this.state.fromDate}
                  toDate={this.state.toDate}
                  setFromDate={this.setFromDate}
                  setToDate={this.setToDate}
                />
                <div className="form-check mt-3">
                  <input
                    type="checkbox"
                    id="includeLimits"
                    checked={this.state.includeLimits}
                    onChange={this.toggleIncludeLimits}
                  />
                  <label className="form-check-label" htmlFor="includeLimits">
                    Include Employee Limits
                  </label>
                </div>{" "}
                <div className="form-check mt-3">
                  <input
                    type="checkbox"
                    id="includeLimits"
                    checked={this.state.includeMonths}
                    onChange={this.toggleIncludeMonths}
                  />
                  <label className="form-check-label" htmlFor="includeMonths">
                    Show Past 12 Months Revenue
                  </label>
                </div>
              </div>

              {this.state.adminFees.length > 0 ? (
                <div>
                  <CSVLink data={this.state.adminFees} filename={fileName}>
                    <Button
                      variant="primary"
                      className="csv-button export-to-csv"
                    >
                      <span className="csv-text">Export to CSV</span>
                    </Button>
                  </CSVLink>
                </div>
              ) : null}
            </div>

            {adminFeeTable}
          </div>
        )}
      </>
    );
  }
}
