import { Spinner } from "react-bootstrap"

export const CustomLoading = () => {
    return (
        <div className="pad-container">
        <Spinner
            className="pad-spinner"
            animation="border"
            variant="primary"
        />
    </div>
    )
}