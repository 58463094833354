import React, { Component } from "react";
import { KTMScreen } from "ktm-ui";
import toastr from "toastr";
import Moment from "react-moment";
import {
  Button,
  Table,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Spinner,
  Card,
} from "react-bootstrap";
import axios from "axios";

export default KTMScreen(
  { name: "padfunding" },
  class extends React.Component<{ policy_id: string; amount: any }> {
    state = {
      policy: null,
      submitted: false,
      loading: true,
      success: false,
    };

    componentDidMount() {
      if (this.props.policy_id == null) {
        this.setState({ success: false, loading: false });
      } else {
        fetch("/api/payment/getpolicyfunds/" + this.props.policy_id, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({ policy: data, success: true, loading: false });
          })
          .catch((error) => {
            this.setState({ policy: null, loading: false, success: false });
          });
      }
    }

    refreshBalance = () => {
      this.setState({ loading: true });
      fetch("/api/payment/getpolicyfunds/" + this.props.policy_id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          //toastr.success("Funding updated", "Success");
          this.setState({ policy: data, loading: false, submitted: true });
        });
    };

    submitFunding = () => {
      this.setState({ loading: true });
      var body = {
        PolicyId: this.props.policy_id,
        FundingType: "PAD",
        Amount: this.props.amount,
      };
      var self = this;
      axios({
        method: "post",
        url: "/api/payment/setpolicyfunds",
        data: body,
      })
        .then(function (response) {
          self.setState({
            policy: response.data,
            loading: false,
            submitted: true,
          });
          toastr.success("Funding submitted", "Success");
        })
        .catch(function (error) {
          toastr.error("Failed to submit funding", "Error");
        });
    };

    goToOps = () => {
      window.open("/ops", "_blank");
    };

    getToCompany = () => {
      window.open(
        "/ops/enroll.policy.record?policy_id=" + this.props.policy_id,
        "_blank"
      );
    };

    render() {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      var contents = null;
      if (this.state.loading) {
        contents = (
          <div className="pad-container">
            <Spinner
              className="pad-spinner"
              animation="border"
              variant="primary"
            />
          </div>
        );
      } else if (
        !this.state.loading &&
        !this.state.success &&
        this.state.policy == null
      ) {
        contents = (
          <div className="pad-container text-center">
            <Card className="text-center p-5" body>
              Failed to load policy
            </Card>
          </div>
        );
      } else {
        var ledgerEntries = null;
        if (this.state.policy.ledgerEntries.length > 0) {
          ledgerEntries = this.state.policy.ledgerEntries.map((item, idx) => {
            var transDate = (
              <Moment format="YYYY-MM-DD">{item.transDate}</Moment>
            );

            var amount = formatter.format(item.amount);
            return (
              <tr key={idx}>
                <td className="text-end">{amount}</td>
                <td>{item.note}</td>
                <td>{transDate}</td>
              </tr>
            );
          });
        }

        var submitRow = null;
        if (!this.state.submitted) {
          submitRow = (
            <div>
              <div className="mt-3">
                <p className="fw-light">New Funding Amount</p>
              </div>
              <div className="row">
                <div className="col">
                  <p className="fw-bolder" style={{ color: "red" }}>
                    {formatter.format(this.props.amount)}
                  </p>
                </div>
                <div className="col">
                  <button
                    style={{ float: "right" }}
                    className="btn btn-primary"
                    onClick={() => this.submitFunding()}
                    disabled={this.props.amount <= 0}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          );
        } else {
          submitRow = (
            <div>
              <div className="mt-3"></div>
              <div className="row">
                <div className="col">
                  <button
                    style={{ float: "left" }}
                    className="btn btn-primary"
                    onClick={() => this.goToOps()}
                  >
                    Go to admin home
                  </button>
                </div>
                <div className="col">
                  <button
                    style={{ float: "right" }}
                    className="btn btn-outline-primary"
                    onClick={() => this.getToCompany()}
                  >
                    Go to policy
                  </button>
                </div>
              </div>
            </div>
          );
        }

        contents = (
          <Card className="p-3 pad-form" body>
            <div className="text-center fs-2">PAD Funding Entry</div>
            <div className="fs-5">
              <div className="mt-2">
                <p className="fw-light">Company</p>
              </div>
              <div>
                {this.state.policy.policyName} ({this.state.policy.policyNumber}
                )
              </div>
              <div className="mt-2">
                <p className="fw-light">Current Balance</p>
              </div>
              <div style={{ color: "green" }}>
                {formatter.format(this.state.policy.currentBalance)}
              </div>
              <div>
                <div className="mt-2">
                  <p className="fw-light">Most recent funding entries</p>
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-end">Amount</th>
                      <th>Note</th>
                      <th>Date</th>
                    </tr>
                  </thead>

                  <tbody>{ledgerEntries}</tbody>
                </table>
              </div>
              {submitRow}
            </div>
          </Card>
          //   <div>
          //     <div>{this.state.policy.policyName}</div>
          //     <div>{this.state.policy.policyNo}</div>
          //     <div>{this.state.policy.currentBalance}</div>
          //     <div>{ledgerEntries}</div>
          //   </div>
        );
      }

      return <div style={{ position: "relative" }}>{contents}</div>;
    }
  }
);
