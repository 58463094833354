import { ScreenRef } from "./ActionEvent";
import { CommandElem, executeCommands } from "./ActionRunner";
import { ConditionBuilder } from "./expressions/ConditionBuilder";
import {KIND} from './types';

export class IfBlockRunner {

    /*
        Executes a if statement block and returns the index of the next command to execute
    */
    static async scanIf(screen:ScreenRef,commands:CommandElem[],index:number):Promise<number> {

        // process the if statement
        let command = commands[index];
        let wasTrue = await IfBlockRunner.processCommand(screen,command);
        if (wasTrue) return IfBlockRunner.findEndOfBlock(commands,index + 1);
    
        index++;

        while (index < commands.length){
            command = commands[index];
            let kind = command.$kind;
            if (kind == KIND.ELSE){
                await executeCommands(screen,command.children);
                return index + 1; // start with next statement
            }
            else if (kind == KIND.ELSE_IF){
                wasTrue = await IfBlockRunner.processCommand(screen,command);
                if (wasTrue){
                    return IfBlockRunner.findEndOfBlock(commands,index + 1);
                }
            }
            else if (kind == KIND.COMMENT){
                // keep going
            }
            else {
                return index; // continue with this command
            }
            index++;
        }
        return index;
    }


    static async processCommand(screen:ScreenRef,command:CommandElem):Promise<boolean>{
        let builder = new ConditionBuilder();
        let expr = builder.build(command,command.conditions);
        let test = screen.getValue(expr);
        if (test){
            await executeCommands(screen,command.children);
            return true; 
        }
        return false;
    }

    static findEndOfBlock(commands:CommandElem[],startIndex:number):number {
        for(let index = startIndex;index < commands.length;index++){
            let command = commands[index];
            let kind = command.$kind;
            if (kind == KIND.ELSE_IF){
                return index + 1;
            }
            else if (kind == KIND.COMMENT){
                // keep going
            }
            else if (kind != KIND.ELSE_IF){
                return index; // need to execute this command so move index back one spot
            }
        }
        return commands.length;
    }
}