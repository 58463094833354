import React, { Component } from "react";
import { KTMScreen } from "ktm-ui";
import toastr from "toastr";
import Moment from "react-moment";
import {
  Button,
  Table,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Spinner,
  Card,
} from "react-bootstrap";
import axios from "axios";

export default KTMScreen(
  { name: "policyfunding" },
  class extends React.Component<any, any> {
    state = {
      loading: true,
      paymentFiles: [],
      paymentFileData: [],
    };

    componentDidMount() {
      fetch("/api/payment/distropaymentfiles", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            paymentFiles: data,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }

    loadPayment = (id) => {
      this.setState({ loading: true });
      fetch("/api/payment/distropaymentfiledata/" + id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            paymentFileData: data,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    };

    downloadEft = (id, fileNum) => {
      this.setState({ loading: true });
      fetch(`/api/payment/downloaddistroeft/${id}`)
        .then((response) => response.blob())
        .then((blob) => {
          const currentDate = new Date().toISOString().slice(0, 10);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `DistroPayment_${fileNum}_${currentDate}.txt`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    };

    downloadPdf = (id,distroId) => {
      fetch(`/api/payment/downloaddistropdf/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Distro_${distroId}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    };

    render() {
      let paymentRows = null;
      if (this.state.paymentFiles != null) {
        paymentRows = this.state.paymentFiles.map((data, i) => {
          return (
            <tr>
              <td>{data.fileNum}</td>
              <td style={{ textAlign: "right" }}>{data.count}</td>
              <td style={{ textAlign: "right" }}>{data.totalAmount}</td>
              <td>
                <Moment local format="YYYY-MM-DD">
                  {data.createdAt}
                </Moment>
              </td>
              <td style={{ textAlign: "right" }}>
                <button
                  onClick={() => this.downloadEft(data.id, data.fileNum)}
                  className="btn btn-primary me-2"
                >
                  Download EFT
                </button>
                <button
                  onClick={() => this.loadPayment(data.id)}
                  className="btn btn-outline-primary"
                >
                  View
                </button>
              </td>
            </tr>
          );
        });
      }

      let paymentDataTable = null;
      let paymentDataRow = null;
      if (
        this.state.paymentFileData != null &&
        this.state.paymentFileData.length > 0
      ) {
        paymentDataRow = this.state.paymentFileData.map((data, i) => {
          return (
            <tr>
              <td>{data.payment.paymentId}</td>
              <td>{data.distro.healthClaimDistroId}</td>
              <td>{data.distro.name}</td>
              <td>{data.distro.contactEmail}</td>
              <td>
                <td style={{ textAlign: "right" }}>
                  <button
                    onClick={() => this.downloadPdf(data.payment.id,data.distro.healthClaimDistroId)}
                    className="btn btn-sm btn-outline-danger me-2"
                  >
                    Download PDF
                  </button>
                </td>
              </td>
            </tr>
          );
        });

        paymentDataTable = (
          <div style={{ paddingTop: 20, height: 500, overflowY: "scroll" }}>
            <table className="table">
              <thead>
                <tr>
                  <td>Payment Id</td>
                  <td>Distro Id</td>
                  <td>Name</td>
                  <td>Contact Email</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>{paymentDataRow}</tbody>
            </table>
          </div>
        );
      }

      return (
        <div>
          <div className="ktm-financials-content">
            {this.state.loading ? (
              <div className="financial-spinner">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                <div className="ktm-financials-label">Distro Payment Files</div>
                <div style={{ paddingTop: 20 }}>
                  <table className="table">
                    <thead>
                      <tr>
                        <td>File Num</td>
                        <td style={{ textAlign: "right" }}>Count</td>
                        <td style={{ textAlign: "right" }}>Amount</td>
                        <td>Date</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>{paymentRows}</tbody>
                  </table>
                </div>
                {paymentDataTable}
              </>
            )}
          </div>
        </div>
      );
    }
  }
);
