import React from 'react';
import {makeComponent, Canvas,RenderEvent, ISidebarFrame} from '../core';
import { AnchorPoint } from './helpers/AnchorPoint';
import { Popup } from './popups/Popup';
import {DialogContainer} from './popups/DialogContainer';
import { BrowseNavigator } from './BrowseNavigator';
import { getTemplateName } from 'ktm-ui/core/types';

export const Layout = makeComponent(class extends React.Component<{event?:RenderEvent,padding?:string,dialogSize?:string,dialogHeight?:string,screen?:any,header?:any[],footer?:any[],$dynamic?:boolean, viewType?:string}>{
       
    animationComplete:boolean;
    render(){
        let event = this.props.event;
        if (!event) return null;;
        let canvas = event.canvas;
        if (!canvas || !canvas.initialized){
            return "Loading...";
        }

        let layer = canvas.layer
        if (layer == "dialog" || layer == "lookup"){
           return this.renderAsDialog(canvas);
        }
        /*
        if (layer == "embedded"){
            return this.renderAsEmbedded(canvas);
        }
        */
        // todo: check for embedded layer
        let covered:boolean;
        let stackContent = this.renderStackChild(canvas);
        if (stackContent){
            covered = true;
        }
        let body = [];
        let header = this.props.header || [];
        let sidebar;
        let footer = this.props.footer || [];
        let title = canvas.title;

        React.Children.forEach(this.props.children,(child:any,index)=> {
            if (child && child.type){
                let section = child.type.$section;
                if (section == "header"){
                    header.push(child);
                }
                else if (section == "sidebar"){
                    sidebar = child;
                }
                else {
                    body.push(child);
                }
            }
        });

        let navigator;
        if (canvas.recordBrowse){
            navigator = <BrowseNavigator canvas={canvas}/>
        }

        let padding;
        if (canvas.layer == "embedded"){
            padding = "none";
        }
        else {
            padding = this.props.padding;
        }
        return (<StackLayerContainer scrollable body={body} header={header} canvas={canvas} padding={padding} stackContent={stackContent} 
            title={title} sidebar={sidebar} footer={footer} navigator={navigator} viewType={this.props.viewType}/>);
    }


    renderAsDialog(canvas:Canvas){


        let zIndex = canvas.layerZIndex;

        let animate = false;
        if (!this.animationComplete){
            animate = true;
            this.animationComplete = true;
        }
        let size = this.props.dialogSize || "small";
        let height = this.props.dialogHeight;

        let header;
        let body;
        let footer;
        if (this.props.$dynamic){
            header = this.props.header;
            footer = this.props.footer;
            body = this.props.children;
        }
        else {

            let content = this.props.children;
            header = [];
            body = [];
            footer = [];
            React.Children.forEach(content,(child:any,index)=>{
                if (child && child.type){
                    let section = child.type.$section;
                    if (section == "header"){
                        header.push(child);
                    }
                    else if (section == "footer"){
                        footer.push(child);
                    }
                    else {
                        body.push(child);
                    }
                }
            });
        }
        let isLookup = (canvas.layer == "lookup");
        if (size == "full"){
            return this.renderFullScreenDialog(zIndex,animate,header,body,footer,isLookup);
        }
        let className = "RT-Action-Dialog__body";
        if (this.props.padding){
            className += " RT-Action-Dialog--padding-" + this.props.padding;
        }
        if (!footer.length){
            footer.push(<div className="RT-Action-Dialog__buttons"></div>)
        }
        
        return (<DialogContainer size={size} zIndex={zIndex} animate={animate} height={height} isLookup={isLookup}>
            {header}
            <div className = {className}>
                <div style={{height:"100%",overflow:"auto",display:"flex",flexDirection:"column"}}>
                    {body}
                </div>
            </div>
            {footer}
        </DialogContainer>);
    }
    
    renderFullScreenDialog(zIndex:number,animate:boolean,header:any,body:any,footer:any,isLookup:boolean){
        let maxWidth = 1280;
        return (<DialogContainer size="full-screen" zIndex={zIndex} animate={animate} height="full-screen" isLookup={isLookup}>
            <div  style={{backgroundColor:"#fff",boxShadow:"0 3px 3px #f2f3f4",position:"relative",zIndex:1,
                paddingTop:5,paddingBottom:5}}>
                <div style={{marginLeft:"auto",marginRight:"auto",maxWidth,display:'flex',alignItems:"center"}}>
                    <div className="RT--full-screen">{header}</div>
                    <div className="RT--full-screen" style={{marginLeft:"auto"}}>{footer}</div>
                </div>
            </div>
            <div style={{height:"100%",overflow:"auto",display:"flex",flexDirection:"column",backgroundColor:"#f8f9fa",
                padding:"30px 10px"}}>
                <div style={{marginLeft:"auto",marginRight:"auto",maxWidth,width:"100%"}}>
                    {body}
                </div>
            </div>
            <div style={{backgroundColor:"#f8f9fa",paddingBottom:15}}>
                <div className="RT--full-screen" style={{marginLeft:'auto',marginRight:'auto',maxWidth}}>
                    {footer}
                </div>
            </div>
        </DialogContainer>);
    }
    renderAsEmbedded(canvas:Canvas){
        return this.props.children;
    }
    renderStackChild(canvas:Canvas) {
        if (canvas.stackChild) {
           return canvas.stackChild();
        }
        return null;
    }

});



class StackLayerContainer extends React.Component<{canvas: Canvas,scrollable?:boolean;header?:any,body:any,footer?:any,sidebar?:any,
    title:string;stackContent:any,
    navigator?:any,padding?:string, viewType?:string}>{
   
    render(){
        let body = this.props.body;
        let canvas = this.props.canvas;
        let stackContent = this.props.stackContent;
        let clickHandler;
        let opacity = 1;
        let bodyDisplay = "block";
        let covered = false;
        if (stackContent){
            covered = true;
        }
      
        if (covered) {
            clickHandler = this.handleCloseStack;
            opacity = 0.6;
            bodyDisplay = "none";
            covered = true;
        }
       

        let headerElem;
        let stack;
        if (covered){
            let stackClass = "RT-Stackable-Container__stack RT-Stackable-Container--" + canvas.layer;
           
            stack = <div key="stack" className={stackClass} >
                {this.renderCoveredHeader(this.props.title,this.props.navigator)} 
                <div className="RT-Stackable-Container__stack-body">
                    {stackContent}
                </div>
            </div>
        }
        else {
            headerElem = <div key="header" className="RT-Stackable-Container__header">
                {this.props.header}
            </div>
        }
        let sidebarOpen = canvas.sidebarState && canvas.sidebarState.isOpen;
        
    
        let className="RT-Stackable-Container";
        className += " RT-Stackable-Container--" + canvas.layer;
        if (this.props.padding){
            className += " RT-Stackable-Container--padding-" + this.props.padding;
        }
        if (this.props.scrollable){
            className += " RT-Stackable-Container--scrollable";
        }
        if (covered){
            className += " RT-Stackable-Container--covered";
        }
        if (this.props.viewType === "business-owner"){
            className += " RT-Stackable-Container--business-owner";
        }
        let sidebar;
        let previewContent;
        /*
        if (stackContent && stackLayer == "preview"){
            previewContent = <div className="RT-Stackable-Container__preview">
                {stackContent}
            </div>
        }
        */
        if (sidebarOpen){
          
            sidebar = <div className="RT-Stackable-Container__sidebar">
                {this.props.sidebar}
                <div className="RT-Stackable-Container__close-sidebar-button" onClick={this.handleCloseSidebar}>{this.renderCloseSidebar()}</div>
            </div>
        }
        else if (this.props.sidebar){
            sidebar = <div className="RT-Stackable-Container__open-sidebar-button" onClick={this.handleOpenSidebar}>{this.renderOpenSidebar()}</div>
        }
        return <>
            <div key="content" className={className}>
                <div className="RT-Stackable-Container__main">
                    {headerElem}
                    <div className="RT-Stackable-Container__body" style={{display:bodyDisplay }}>
                        {body}
                    </div>
                    <div className="RT-Stackable-Container__footer" style={{display:bodyDisplay}}>{this.props.footer}</div>
                </div>
                <div className="RT-Stackable-Container__side-overlay">
                    {previewContent}
                    {sidebar}
                </div>
               
            </div>
            {stack}
        </>
    }

    renderCoveredHeader(title:string,navigator:any){
        let nav;
        if (navigator){
            nav =  (<div className="RT-Stackable-Container__stack-header-nav">
                {navigator}
            </div>);
        }
        return (<div key="breadcrumb" className="RT-Stackable-Container__stack-header"  onClick={this.handleCloseStack}>
                <div className='RT-Record-Navigator__back__header'>
                    {this.renderBackIcon()}
                    {title}
                </div>
             <div className="RT-Stackable-Container__stack-header-shadow" />
             {nav}
        </div>);
    }
    
    renderBackIcon(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
           <path d="M8.29,11.29a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l3,3a1,1,0,0,0,1.42-1.42L11.41,13H15a1,1,0,0,0,0-2H11.41l1.3-1.29a1,1,0,0,0,0-1.42,1,1,0,0,0-1.42,0ZM2,12A10,10,0,1,0,12,2,10,10,0,0,0,2,12Zm18,0a8,8,0,1,1-8-8A8,8,0,0,1,20,12Z"/>
        </svg>);
    }

    handleOpenSidebar = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.canvas.sidebarState.isOpen = true;
        this.forceUpdate();
    }

    handleCloseSidebar = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.canvas.sidebarState.isOpen = false;
        this.forceUpdate();

    }
    handleCloseStack = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.canvas;
        canvas.closeStack();
        this.forceUpdate();
    }

    renderOpenSidebar(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
          <path d="M17,11H5.41l2.3-2.29A1,1,0,1,0,6.29,7.29l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L5.41,13H17a1,1,0,0,0,0-2Zm4-7a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z"/>
        </svg>);
    }

    renderCloseSidebar(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M17.71,11.29l-4-4a1,1,0,1,0-1.42,1.42L14.59,11H3a1,1,0,0,0,0,2H14.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76A1,1,0,0,0,17.71,11.29ZM21,4a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z"/>
        </svg>);
    }

   
}

export const Sidebar = makeComponent(class Sidebar extends React.Component<{event?:RenderEvent}>{

    static $section = "sidebar";

    dirty = true;

    render(){
        if (this.dirty){
            this.dirty = false;
            this.discoverSidebar();
        }
        let event = this.props.event;
        if (!event) return null;
        let canvas = event.canvas;
        let sidebar = canvas.sidebarState;
        let activeFrame = this.getActiveFrame();
        let content;
        if (activeFrame){
            content = event.draw(activeFrame.name);
        }
        return <>
            <div className="RT-Stackable-Container__sidebar_header" style={{padding:"0 20px"}}>
                <SidebarDropMenu active={activeFrame} options={sidebar.frames} onChoose={this.handleChooseSidebarOption} />
            </div>
            <div className="RT-Stackable-Container__sidebar_body" style={{flexGrow:1,overflowY:"auto",marginTop:20}}>
                {content}
            </div>
        </>
    }

    getActiveFrame():ISidebarFrame {
        let event = this.props.event;
        let children = this.props.children;
        let activeFrame = event.canvas.sidebarState.activeFrame;
        let result:ISidebarFrame;

        React.Children.forEach(children, (child: any) => {
            if (child && child.props) {
                let template = child.props.template;
                if (template){
                    let name = getTemplateName(template);
                    if (name == activeFrame){
                        result = {name,label:child.props.label,content:child};
                    }
                }
            }
        });
        return result;
    }

    renderContent(){
        let children = this.props.children;
        let event = this.props.event;
        let activeFrame = event.canvas.sidebarState.activeFrame;
    
        React.Children.forEach(children, (child: any) => {
            if (child && child.props) {
                let template = child.props.template;
                if (template){
                    let name = getTemplateName(template);
                    if (name == activeFrame){
                        return child;
                    }
                }
            }
        });
        return null;
    }

    handleChooseSidebarOption = (option:IDropMenuOption) =>{
        let event = this.props.event;
        event.canvas.sidebarState.activeFrame = option.name;
        this.forceUpdate();
    }

    discoverSidebar(){
        let children = this.props.children;
        let event = this.props.event;
        let sidebarState = event.canvas.sidebarState;
        sidebarState.frames = [];
        
        React.Children.forEach(children, (child: any) => {
            if (child && child.props) {
                let label = child.props.label;
                let template = child.props.template;
                if (template){
                    let name = getTemplateName(template);
                    if (name){
                        let option:ISidebarFrame = {
                        label,
                        name
                        }
                        sidebarState.frames.push(option);
                    }
                }
            }
        });
    
        if (sidebarState.frames.length && !sidebarState.activeFrame){
            sidebarState.activeFrame = sidebarState.frames[0].name;
        }
        else {
          //  sidebarState.activeFrame = null;
        }
    }

  
});

interface IDropMenuOption {
    name:string;
    label:string;
}


class SidebarDropMenu extends React.Component<{active:IDropMenuOption,options:IDropMenuOption[],onChoose:(option:IDropMenuOption) => void},{open:boolean}>{

    dropRef:React.RefObject<HTMLDivElement> = React.createRef();
   
    state= {open:false}
    render(){
        let label;
        if (this.props.active){
            label = this.props.active.label;
        }
        else {
            label = "Select"
        }
        let popup;
        if (this.state.open){
            popup = this.renderPopup();
        }
        return <>
            <div style={{fontSize:"18px",fontWeight:500,display:'flex',alignItems:'center',cursor:"pointer"}} onClick={this.handleClick} ref={this.dropRef}>
                <div>{label}</div>
                <div style={{marginLeft:8}}>
                    {this.renderAngleDown()}
                </div>
            </div>
            {popup}
        </>
    }

    renderPopup(){
        return (<Popup
            attachedRef={this.dropRef.current as HTMLElement}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
        />);
    }

    renderMenu = ()=>{
        let elems = [];
        let options = this.props.options;
        for(let i = 0; i < options.length;i++){
            let option = options[i];
            elems.push(<DropMenuItem option={option} onClick={this.handleOptionClick}/>)
        }
        let content = <div style={{ width: 300, backgroundColor: "#fff", boxShadow: "0 0 0 2px hsla(0,0%,0%,0.1)", borderRadius: 4,
            padding:8 }}>
            {elems}
        </div>
        return content;


    }
    handleOptionClick = (option:IDropMenuOption) => {
        this.setState({open:false});
        this.props.onChoose(option);
    }
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({open:true});
    }

    handlePopupForceClose = () => {
        this.setState({open:false});
    }

    renderAngleDown(){
        return (<svg xmlns="http://www.w3.org/2000/svg" style={{display:"block",width:"20px",height:"20px",fill:"rgb(21,27,38)"}} viewBox="0 0 24 24">
           <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
        </svg>);
    }

}

class DropMenuItem extends React.Component<{option:IDropMenuOption, onClick:(option:IDropMenuOption) => void}>{
    render(){
        let option = this.props.option;
        return <a href='#' style={{display:"block",color:"rgb(21,27,38)",textDecoration:"none",fontSize:"14px",fontWeight:500,padding:"8px 10px"}} onClick={this.handleClick}>
            {option.label}
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.option);
    }
}