

import React from 'react';
import {makeComponent} from '../core'; 
import { Icon } from './Icon';


export const EmptyState = makeComponent(class EmptyState extends React.Component<{label?:string,icon?:string}>{
    render(){
        let icon;
        if (this.props.icon){
            icon = <div style={{opacity:0.2,marginBottom:20}}><Icon icon={this.props.icon} size={80} fill="rgb(100,120,140)"/></div>
        }
        return (<div style={{display:"flex",flexDirection:'column',alignItems:'center',paddingTop:60}}>
            {icon}
            <div style={{fontSize:"15px",fontWeight:500,marginBottom:20}}>{this.props.label}</div>
            {this.props.children}
        </div>)
    }
});

