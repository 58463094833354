import DatePicker from "react-date-picker";
import { Card, Line } from "ktm-ui";
import CustomToolTip from "./CustomToolTip";

const FlexDatesCard = ({ handleDateChange, LaunchDate, ReminderDate, LastDayReminder, SelectionDeadline, isEditable = (Date: any) => true }) => {

  return (
    <Card>
      <Line>
        <div className="date-picker-container">
          <div className="RT-Section-Header">
            <div className="RT-Section-Header-Title">
              Launch Date
              <CustomToolTip
                header="Launch Date"
                bodyContent="The date employees should receive their first Flex Selection emails."
              />
            </div>
          </div>
          <DatePicker
            onChange={(date) => handleDateChange(date, "LaunchDate")}
            value={new Date(LaunchDate)}
            format={"y-MM-dd"}
            clearIcon={null}
            disabled={!isEditable(LaunchDate)}
          />
        </div>
        <div className="date-picker-container">
          <div className="RT-Section-Header">
            <div className="RT-Section-Header-Title">
              Reminder Date
              <CustomToolTip
                header="Reminder Date"
                bodyContent="The date employees should receive their first Reminder email if they have not yet made a selection."
              />
            </div>
          </div>
          <DatePicker
            onChange={(date) => handleDateChange(date, "ReminderDate")}
            value={new Date(ReminderDate)}
            format={"y-MM-dd"}
            clearIcon={null}
            disabled={!isEditable(ReminderDate)}
          />
        </div>
      </Line>
      <Line>
        <div className="date-picker-container">
          <div className="RT-Section-Header">
            <div className="RT-Section-Header-Title">
              Last Day Reminder
              <CustomToolTip
                header="Last Day Reminder"
                bodyContent="The date employees should receive their last Reminder email if they have not yet made a selection. 
                            We recommend setting this the day before the Deadline."
              />
            </div>
          </div>
          <DatePicker
            onChange={(date) => handleDateChange(date, "LastDayReminder")}
            value={new Date(LastDayReminder)}
            format={"y-MM-dd"}
            clearIcon={null}
            disabled={!isEditable(LastDayReminder)}
          />
        </div>
        <div className="date-picker-container">
          <div className="RT-Section-Header">
            <div className="RT-Section-Header-Title">
              Selection Deadline
              <CustomToolTip
                header="Selection Deadline"
                bodyContent="This date is simply the last day that employees are able to make their selection. 
                            After this date they will default to the policy’s chosen Default split, 
                            which can be found in the assigned benefit category details."
              />
            </div>
          </div>
          <DatePicker
            onChange={(date) => handleDateChange(date, "SelectionDeadline")}
            value={new Date(SelectionDeadline)}
            format={"y-MM-dd"}
            clearIcon={null}
          />
        </div>
      </Line>
    </Card>
  );
};

export default FlexDatesCard;
