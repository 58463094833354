import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,DataGrid,IDataGridLayout} from 'ktm-ui';

interface IdsPrices {
    Id:string;
    BenefitType_Id:string;
    Name:string;
    PriceSingle:number;
    PriceCouple:number;
    PriceFamily:number;
};

export default KTMScreen({name:"planadmin.lookups.benefit-price"}, class extends React.Component<{benefitType_id:string,keyword?:string,eventValue?:string}> {

    data:{
        dsPrices:IdsPrices;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsPrices";
        await KTMApi.start(screen,{
            "@benefitType_id":this.props.benefitType_id,
            "@keyword":this.props.keyword,
        });
        screen.onEnterKey = this.search;
        screen.title = "Benefit price";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="medium" dialogHeight="medium" padding="none">
                {draw(this.header)}
                {draw(this.default_view)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Benefit price lookup">
            </Dialog.Header>
        );
    }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = null;
        let layout:IDataGridLayout = {
            columns:[
                {name:"Name",label:"Name",type:"character"},
                {name:"PriceSingle",label:"Price Single",type:"money"},
                {name:"PriceCouple",label:"Price Couple",type:"money"},
                {name:"PriceFamily",label:"Price Family",type:"money"},
            ],
            sort:[]
        };
        return (
            <DataGrid collection={collection("@dsPrices")} layout={layout} actions={actions} onRowClick={action(this.on_row_click)}/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
            </Dialog.Footer>
        );
    }

    async search({screen}:ActionEvent){
        await KTMApi.action(screen,"search",{
            "@benefitType_id":this.props.benefitType_id,
        });
    }

    async on_row_click({screen}:ActionEvent){
        screen.close({continue:true,value:screen.getValue("Id"),label:screen.getValue("Name")});
    }

});
