import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import moment from "moment";
import ReactMoment from "react-moment";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  Employee_Id: string;
  PolicyBenefit_Id: string;
  BenefitCategory_Id: string;
  Coverage: string;
  HasProrating: boolean;
  BenefitYear_Id: string;
}

interface IdsEmployee {
  Id: string;
  Name: string;
  HireDate: string;
  WaitingPeriodMonths: number;
  BenefitStartDate: string;
  IsStartDateAdjusted: boolean;
}

interface IdsBenefitYear {
  Id: string;
  StartDate: string;
}

export default KTMScreen(
  { name: "enroll.employee-benefits.edit-benefit-dates" },
  class extends React.Component<{
    employeeBenefit_id: string;
  }> {
    data: {
      dsBenefit: IdsBenefit;
      dsEmployee: IdsEmployee;
      dsBenefitYear: IdsBenefitYear;
    };

    state = {
      resetPAapprovedDate: false,
      showStartDateInput: false,
      showBenefitDatesModal: true,
      showStartDateModal: false,
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@employeeBenefit_id": this.props.employeeBenefit_id,
      });
      screen.title = "Edit employee benefit limits";

      screen.setOptions("WaitingPeriodMonths", [
        { value: "0", label: "None" },
        { value: "1", label: "1 month" },
        { value: "2", label: "2 months" },
        { value: "3", label: "3 months" },
        { value: "4", label: "4 month" },
        { value: "5", label: "5 months" },
        { value: "6", label: "6 months" },
        { value: "7", label: "7 months" },
        { value: "8", label: "8 months" },
        { value: "9", label: "9 months" },
        { value: "10", label: "10 months" },
        { value: "11", label: "11 months" },
        { value: "12", label: "12 months" },
      ]);
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label={"Edit benefit date setup"} />;
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={this.state.showBenefitDatesModal ? action(this.submitOne) : action(this.submitTwo)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
              {this.state.showBenefitDatesModal && draw(this.benefit_dates)}
              {this.state.showStartDateModal && draw(this.start_date)}
          </Column>
        </Panel>
      );
    }

    benefit_dates({ field, draw, action }: RenderEvent) {
      return (
            <Card>
              <Line>
                <Display field={field("@dsEmployee.HireDate")} />
                <Display
                  required
                  field={field("@dsEmployee.WaitingPeriodMonths")}
                />
                <Display field={field("HasProrating")} />
              </Line>
            </Card>
      );
    }

    start_date({ field, draw, action }: RenderEvent) {
        var systemBenefitStartDate = this.systemGeneratedBenefitStartDate();
        return (
          <div>
                <Card>
                  <Line>
                    <div className="RT-TextBox">
                      <label>
                        Based on the Hire Date your entered and the Waiting
                        Period, the system has calculated the Benefit Start Date:
                      </label>
                      <div className="RT-TextBox__container">
                        <ReactMoment format="YYYY-MM-DD">
                          {systemBenefitStartDate}
                        </ReactMoment>
                      </div>
                    </div>
                  </Line>
                </Card>
                <Card>
                  <Line>
                    <div className="RT-TextBox">
                      <label>
                        If you wish to over-ride the system calculated start date
                        for this employee, please click the check mark and enter
                        in your desired start date.
                      </label>
                    </div>
                    <div className="RT-TextBox">
                      <div className="form-check" style={{ paddingLeft: 0 }}>
                        <label>
                          <input
                            type="checkbox"
                            checked={this.state.showStartDateInput}
                            onChange={() => this.toggleStartDateInput()}
                            style={{ paddingLeft: 0, marginRight: 10 }}
                          />
                          Override Employee Start Date
                        </label>
                      </div>
                      {this.state.showStartDateInput && (
                        <Display
                          label="PA Approved Benefit Start Date"
                          field={field("@dsEmployee.BenefitStartDate")}
                        />
                      )}
                    </div>
                  </Line>
                </Card>
                <a
                  href="#"
                  className="RT-Link"
                  onClick={() =>
                    this.reset_PAapprovedDate(systemBenefitStartDate)
                  }
                  style={{ paddingTop: "10px" }}
                >
                  Click here to clear PA Approved Benefit Start Date Override
                </a>
          </div>
        );
    }

    systemGeneratedBenefitStartDate() {
      var systemStartDate;
      if (this.data.dsEmployee.HireDate) {
        systemStartDate = this.data.dsEmployee.HireDate;
        if (this.data.dsEmployee.WaitingPeriodMonths) {
          systemStartDate = moment(this.data.dsEmployee.HireDate)
            .add(this.data.dsEmployee.WaitingPeriodMonths, "months")
            .format("YYYY-MM-DD");
        }
      }
      return systemStartDate;
    }

    reset_PAapprovedDate = (systemBenefitStartDate: any) => {
      this.data.dsEmployee.BenefitStartDate = systemBenefitStartDate;
      this.data.dsEmployee.IsStartDateAdjusted = false;
      this.setState({
        resetPAapprovedDate: true,
        showStartDateInput: false,
      });
    };

    toggleStartDateInput = () => {
      this.setState({ showStartDateInput: !this.state.showStartDateInput });
      this.data.dsEmployee.IsStartDateAdjusted = !this.state.showStartDateInput;
    };

    async submitOne({ screen }: ActionEvent) {

        await KTMApi.action(screen, "submitOne", {
          "@dsBenefit.BenefitCategory_Id": this.data.dsBenefit.BenefitCategory_Id,
          "@dsBenefit.HasProrating": this.data.dsBenefit.HasProrating,
          "@dsEmployee.HireDate": this.data.dsEmployee.HireDate,
          "@dsEmployee.WaitingPeriodMonths":
            this.data.dsEmployee.WaitingPeriodMonths,
          "@employeeBenefit_id": this.props.employeeBenefit_id,
        });
  
        this.setState({ 
            showBenefitDatesModal: false,
            showStartDateModal: true,
        });
    }

    async submitTwo({ screen }: ActionEvent) {
      
      await KTMApi.action(screen, "submitTwo", {
        "@dsBenefit.BenefitCategory_Id": this.data.dsBenefit.BenefitCategory_Id,
        "@employeeBenefit_id": this.props.employeeBenefit_id,
        "@dsEmployee.BenefitStartDate": this.data.dsEmployee.BenefitStartDate,
        "@dsEmployee.IsStartDateAdjusted":
          this.data.dsEmployee.IsStartDateAdjusted
      });

        this.setState({ showStartDateModal: false });
        screen.close({ continue: true });
    }
  }
);
