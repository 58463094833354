import React from 'react';
import { Broadcast, ActionRef, FieldType, RenderEvent ,CollectionRef, FieldFormatter} from '../core';
import { Collection, ICollectionSortField } from '../core/Collection';
import { CollectionGrouper, IQueryGroupMap } from '../core/CollectionGrouper';
import { IRecordSchema } from '../core/Schema';
import { ClickThrottle } from './helpers/ClickThrottle';

export interface IDataGridLayout {
    rowSelector?:boolean;
    columns:IDataGridColumn[];
    sort?:ICollectionSortField[];
}

export interface IDataGridColumn {
    name?:string;
    label?:string;
    type?:FieldType;
    align?:'left' | 'center' | 'right';
    render?:(row:any) => any;
    left?:number;
    width?:number;
    action?:string;
    onClickAction?:string;
    format?:string;
}

interface IDataGrid {
    rowSelector?:boolean;
    columns:IDataGridColumn[];
    schema:IRecordSchema;
    totalWidth?:number;
    sort?:ICollectionSortField[];
    version?:number;
}

const selectorWidth = 50;

export class DataGrid extends React.Component<{event?:RenderEvent;layout:IDataGridLayout,collection:CollectionRef,onRowClick?:ActionRef,actions?:any, viewType?:string}>{

    containerState = {
        scrollLeft:0
    }
    broadcast = new Broadcast();
    headerContainer :React.RefObject<HeaderContainer> = React.createRef();
    containerRef: React.RefObject<HTMLDivElement> = React.createRef();
    grid:IDataGrid;
    groupMap:IQueryGroupMap;
    currentPage = 0;
    renderedVersion:number;

    componentDidMount(){
        this.broadcast.connect(this);
    }

    componentWillUnmount(){
        this.broadcast.disconnect(this);
    }

    formatData(rows:any[]){
        let grid = this.grid;
        if (grid.sort && grid.sort.length){
            Collection.sort(rows,grid.schema,grid.sort);
        }
        let grouper = new CollectionGrouper();
        grouper.pageSize = 10;
        grouper.groups = [];
        grouper.definedTotals = [];
        this.groupMap = grouper.groupRows(rows);
    }

    render(){
        let collection = this.props.collection;
        let rows:any[];
        if (collection && collection.rows){
            rows = collection.rows;
            let schema = Collection.getSchema(rows) ?? collection.schema;
            if (!this.grid){
                let layout:IDataGridLayout = this.props.layout;
                if (!layout){
                    layout = {
                        columns:this.props.children as any
                    }
                }
                this.grid = Grid.create(layout,schema);
            }
            let version = Collection.getVersion(rows);
            if (version != this.renderedVersion){
                this.formatData(rows);
            }
            this.renderedVersion = version;
        }
        else {
            return null;
        }
      
        let grid = this.grid;
        let totalWidth = grid.totalWidth;
        if (grid.rowSelector){
            totalWidth += selectorWidth;
        }
        let bodyStyle:React.CSSProperties = {
            position:"relative",
            minWidth:"100%",
            width:totalWidth
        }

        let gridState:IGridState = {
            rows,
            groupMap:this.groupMap,
            currentPage:this.currentPage,
            onRowClick:this.props.onRowClick,
            grid,
            dataVersion:this.renderedVersion,
            event:this.props.event,
            broadcast:this.broadcast,
        }

        let style:React.CSSProperties = {};
        style.display = "flex";
        style.flexDirection = "column";
        style.height = "100%";
        style.overflow = "hidden";
        style.marginBottom = "15px";
        
        return <div style={style} className={this.props.viewType? "data-grid "+this.props.viewType : "data-grid"}>
            {this.renderButtonBar()}
            <div className="RT-DataTable RT-DataTable--pull-padding">
                <HeaderContainer ref={this.headerContainer} containerState={this.containerState} >
                    <ColumnarHeader gridState={gridState} rows={rows} />
                </HeaderContainer>
                <div className="RT-DataTable__body" ref={this.containerRef} onScroll={this.handleContainerScroll}>
                    <div style={bodyStyle}>
                         <GridRows gridState={gridState}/>
                    </div>
                </div>
            </div>
        </div>
    }

    renderButtonBar() {
        let actions = this.props.actions;
        let verbose;
        let queryPopulated:boolean;
        let rowCount:number;
        let collection = this.props.collection;
        
        if (collection && collection.rows){
            queryPopulated = true;
            rowCount = collection.rows.length;
        }
        if (queryPopulated){
            let pagination;
            let groupMap = this.groupMap;
            if (groupMap){    
                let numPages = groupMap.pages.length;
                if (numPages > 1){
                    pagination = <Pagination currentPage={this.currentPage + 1} numPages={numPages} onClick={this.handlePageNumberClick}/>
                }
            }
            
            verbose = <div style={{marginLeft:"auto",display:"flex",alignItems:"center"}}>
                {pagination}
                <VerboseWhere rowCount={rowCount}/>
            </div>
        }

        if (!actions && !verbose) return null;
        return (
            <div
                className = "RT-DataTable__list-actions"
            >
               {actions}
               {verbose}
            </div>
        );
    }
    handlePageNumberClick = (pageNumber:number) => {
        this.currentPage = pageNumber - 1;
        this.grid.version++;
        this.broadcast.refresh();
    }
    
    
    handleContainerScroll = (e) => {
        this.containerState.scrollLeft = this.containerRef.current.scrollLeft;
        this.headerContainer.current.forceUpdate();
    }
}

interface IGridState { 
    event:RenderEvent,
    rows:any[],
    groupMap:IQueryGroupMap,
    currentPage:number,
    grid:IDataGrid,
    onRowClick?:ActionRef,
    dataVersion:number,
    selectAllValue?:boolean,
    broadcast:Broadcast,
};
class GridRows extends React.Component<{gridState:IGridState}>{
    clickThrottle = new ClickThrottle();

    renderedVersion:number = -1;
    renderedLayoutVersion:number = -1;
    shouldComponentUpdate(nextProps){
        if (nextProps.dataVersion == this.renderedVersion && nextProps.layout && nextProps.layout.version == this.renderedLayoutVersion) return false;
        return true;
    }

    render(){
        let gridState = this.props.gridState;
        let layout = gridState.grid;
        this.renderedVersion = gridState.dataVersion;
        this.renderedLayoutVersion = layout.version;
        let rowElems = [];
        let rows = gridState.rows;
        if (!rows) return null;
       
        let groupMap = gridState.groupMap;
        if (groupMap){
            let currentPage = gridState.currentPage;
            let items = groupMap.items;
            currentPage = currentPage > groupMap.pages.length-1 ? 0 : currentPage;
            let pageIndex = groupMap.pages[currentPage];
            let start = pageIndex.start;
            let n = pageIndex.end;

            for(let i = start; i < n;i++){
                let item = items[i];
                if (item.type == "row"){
                    let isActive = false; //  (query.activeRowIndex == item.rowIndex);
                    rowElems.push(<ColumnarRow key={i} gridState={gridState} row={item.row} rowIndex={i} clickThrottle={this.clickThrottle}/>);
                }
            }
        }
        else {
            for(let i = 0; i < rows.length;i++){
                let row = rows[i];
                rowElems.push(<ColumnarRow key={i} gridState={gridState} row={row} rowIndex={i} clickThrottle={this.clickThrottle}/>);
            }
        }
        return rowElems;
    }

}


class ColumnarRow extends React.Component<{gridState:IGridState,row:any,rowIndex:number,clickThrottle:ClickThrottle}>{

    private renderedRowDataVersion: number;

    shouldComponentUpdate(nextProps){
       return true;
    }
    render(){

        let cells = [];
        let gridState = this.props.gridState;
        let layout = gridState.grid;
        let schema = layout.schema;
        let columns = layout.columns;
        let row = this.props.row;

        let offset = 0;
        if (layout.rowSelector){
            offset = selectorWidth;
            cells.push(this.renderSelectorCell(row));
        }
       
        for(let i = 0; i < columns.length;i++){
            let col = columns[i];
            let style:React.CSSProperties;
            let left = col.left + offset;
            if (col.width) {
                style = {
                    position: "absolute",
                    top: 0,
                    left,
                    width: col.width,
                    bottom: 0
                };
            } else {
                style = {
                    position: "absolute",
                    top: 0,
                    left,
                    right: 0,
                    bottom: 0
                };
            }
            let value = row[col.name];
            let cellClasses:string[] = ['RT-DataTable__cell'];
            if (col.align == "right"){
                cellClasses.push("align-right");
            }
            let content;
            if (col.render){
                content = col.render(row);
            }
            else if (col.type == "logical"){
                if (value){
                    content = this.renderCheckmark();
                }
            }
            else if (col.action){
                content = <a href='#'>{col.label}</a>
            }
            else if (col.onClickAction){
                if (value){
                    content = <a href='#'>{value}</a>
                }
            }
            else {
                content= FieldFormatter.format(value,col.type,col.format);
            }
            cells.push(<div key={i} className={cellClasses.join(' ')} data-col-index={i} style={style}>
                {content}
            </div>)
        }

        var now = new Date();
        let rowStyle:React.CSSProperties = {height:34,position:"relative",cursor:"pointer"};
        if(row.IsActive == false && new Date(row.BenefitEndDate) <= now)
        {
            rowStyle = {height:34,position:"relative",cursor:"pointer", backgroundColor: 'pink'};
        }     
        else if(row.IsActive && row.BenefitEndDate != null)
        {
            rowStyle = {height:34,position:"relative",cursor:"pointer", backgroundColor: 'yellow'};
        }
           
        return <div data-row-index={this.props.rowIndex} style={rowStyle} onClick={this.handleClick} className="RT-DataTable__row">
            {cells}
        </div>
    }

    handleClick = (e:React.MouseEvent<HTMLDivElement>) => {
        this.props.clickThrottle.handle(e,()=> {
            e.preventDefault();
            e.stopPropagation();
            let target:HTMLDivElement = e.target as any;
            let cell = target.closest("[data-col-index]");
            let gridState = this.props.gridState;
            if (cell){
                let colIndex = parseInt(cell.getAttribute("data-col-index"),10);
                let column = gridState.grid.columns[colIndex];
                let buttonName = column.action || column.onClickAction;
                if (buttonName){
                    let canvas = gridState.event.canvas;
                    let schema = gridState.grid.schema;
                    let buttonRef = new ActionRef(canvas,buttonName,{[schema.name]:this.props.row});
                    buttonRef.trigger();
                    return;
                }
            }
            if (gridState.onRowClick){
                let row = this.props.row;
                let scope = {[gridState.grid.schema.name]:row};
                gridState.onRowClick.trigger({current:row,value:row.Id,table:gridState.rows,scope});
            }
        });
    }

    renderSelectorCell(row:any) {
        let checked = Collection.isRowSelected(row);
        return (
            <div
                key="sel"
                className="RT-DataTable__selector-cell"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    width:selectorWidth
                }}
                onClick={this.handleSelectorClick}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={this.handleSelectorChange}
                />
            </div>
        );
    }

    renderCheckmark(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{display:"inline-block",width:20,height:20,fill:"var(--rt-primary-color)"}}><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>);
    }

    handleSelectorChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let target = e.target;
        let checked = target.checked;
        Collection.setRowSelected(this.props.row,checked);
        this.forceUpdate();
    };

    handleSelectorClick = (e: React.MouseEvent) => {
        // cancel row click if clicking on sow selector
        e.stopPropagation();
        let target = e.target as any;
        if (target.type == "checkbox") return;
        this.forceUpdate();
    };
}



class HeaderContainer extends React.Component<{containerState:{scrollLeft:number}}>{
    render(){
        let leftAdjust = -this.props.containerState.scrollLeft;
        let style:React.CSSProperties= {
            marginLeft:leftAdjust
        }
        return <div className="RT-DataTable__header" style={style}>{this.props.children}</div>
    }   
}

class ColumnarHeader extends React.Component<{gridState:IGridState,rows:any[]}>{
    render(){
        let gridState = this.props.gridState;
        let grid = gridState.grid;
        let columns = grid.columns;

        if (!columns) return null;
        var elems = [];
        let rows = this.props.rows;
        let offset = 0;
        if (grid.rowSelector){
            offset = selectorWidth;
            elems.push(this.renderHeaderSelectorCell());
        }

       let broadcast = gridState.broadcast;
        for (var i = 0; i < columns.length; i++) {
            let col = columns[i];
            elems.push(<HeaderCell key={i} rows={rows} broadcast={broadcast} layout={grid} column={col} offset={offset}  onResize={this.handleColumnResize}/>)
        }
        return elems;
    }

    handleColumnResize = () => {
        let gridState = this.props.gridState;
        gridState.grid.version++;
        gridState.broadcast.refresh();
    }

    renderHeaderSelectorCell() {
        let checked = this.props.gridState.selectAllValue;
        return <div key="sel" style={{ position: "absolute", left: 0, top: 0, bottom: 0, width:selectorWidth, cursor: "pointer" }} className="RT-DataTable__header-selector-cell">
            <input type="checkbox" checked={checked} onChange={this.handleSelectorChange} />
        </div>
    }

    handleSelectorChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let target = e.target;
        let checked = target.checked;
        let gridState = this.props.gridState;
        gridState.selectAllValue = checked;
        Grid.setSelected(gridState.rows,checked);
        gridState.grid.version++;
        gridState.broadcast.refresh();
    };
}

class HeaderCell extends React.Component<{broadcast:Broadcast,rows:any[],layout:IDataGrid,column:IDataGridColumn,offset:number,onResize:() => void}>{
    render() {
        let col = this.props.column;
        let style:React.CSSProperties;
        let offset = this.props.offset;

        if (col.width) {
            style = { position: "absolute", left: col.left + offset, width: col.width, top: 0, bottom: 0, cursor: "pointer" };
        }
        else {
            style = { position: "absolute", left: col.left + offset, top: 0, bottom: 0, right: 0, cursor: "pointer" }
        }
        if (col.align == 'right'){
            style.justifyContent = "flex-end";
        }
        else if (col.align == "center"){
            style.justifyContent = "center";
        }
        let sort = Grid.getColumnSort(this.props.layout,col);
        let sortElem;
        if (sort){
            if (sort.descending) {
                sortElem = <span className="RT-DataTable__header-sort-icon"><SortDirectionDesc /></span>;
            }
            else {
                sortElem = <span className="RT-DataTable__header-sort-icon"><SortDirectionAsc /></span>;
            }
        }

        let resizeHandle = <ColumnResizeHandle layout={this.props.layout} column={col} onResize={this.props.onResize}/>
        return <div style={style} className="RT-DataTable__header-cell" onClick={this.handleClick}>{col.label}{sortElem}{resizeHandle}</div>;
    }

    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        Grid.setSort(this.props.layout,this.props.column);
        Collection.incrementVersion(this.props.rows);
        this.props.broadcast.refresh();
    }
}


class SortDirectionDesc extends React.Component {
    render(){
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M 12 3 C 11.448 3 11 3.448 11 4 L 11 17.070312 L 7.1367188 13.207031 C 6.7457187 12.816031 6.1126563 12.816031 5.7226562 13.207031 L 5.6367188 13.292969 C 5.2457187 13.683969 5.2457187 14.317031 5.6367188 14.707031 L 11.292969 20.363281 C 11.683969 20.754281 12.317031 20.754281 12.707031 20.363281 L 18.363281 14.707031 C 18.754281 14.316031 18.754281 13.682969 18.363281 13.292969 L 18.277344 13.207031 C 17.886344 12.816031 17.253281 12.816031 16.863281 13.207031 L 13 17.070312 L 13 4 C 13 3.448 12.552 3 12 3 z"></path>
    </svg>
    }
}

class SortDirectionAsc extends React.Component {
    render(){
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M 12 3.3417969 C 11.744125 3.3417969 11.488469 3.4412187 11.292969 3.6367188 L 5.6367188 9.2929688 C 5.2457188 9.6829687 5.2457187 10.316031 5.6367188 10.707031 L 5.7226562 10.792969 C 6.1126563 11.183969 6.7457187 11.183969 7.1367188 10.792969 L 11 6.9296875 L 11 20 C 11 20.552 11.448 21 12 21 C 12.552 21 13 20.552 13 20 L 13 6.9296875 L 16.863281 10.792969 C 17.253281 11.183969 17.886344 11.183969 18.277344 10.792969 L 18.363281 10.707031 C 18.754281 10.317031 18.754281 9.6839688 18.363281 9.2929688 L 12.707031 3.6367188 C 12.512031 3.4412187 12.255875 3.3417969 12 3.3417969 z"></path>
    </svg>
    }
}


class ColumnResizeHandle extends React.Component<{layout:IDataGrid,column:IDataGridColumn,onResize:()=> void},{startX:number,currentWidth:number,newWidth:number,moving:boolean}> {

    minWidth = 45;
    constructor(props){
        super(props);
        this.state = {
            currentWidth:0,
            startX:0,
            newWidth:0,
            moving:false
        }
    }

    render() {
        let right = -8;
        let css = "RT-DataTable__resize-handle";
        if (this.state.moving){
            css += " RT-DataTable__resize-handle--moving";
        }
        return <div style={{ position: "absolute", width: 16, top: -2, height: 36, right, zIndex: 2, cursor: "ew-resize" }} className={css}
            onMouseDown={this.handleMouseDown} onClick={this.handleClick}>
            <div/>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            startX:e.clientX,
            currentWidth:this.props.column.width,
            newWidth:this.props.column.width,
            moving:true
        })
        document.addEventListener("mousemove",this.handleMouseMove,true);
        document.addEventListener("mouseup",this.handleMouseUp,true)

    }

    handleMouseUp = e => {
        e.preventDefault();
        e.stopPropagation();
        document.removeEventListener("mousemove",this.handleMouseMove,true);
        document.removeEventListener("mouseup",this.handleMouseUp,true);
        let change = e.clientX - this.state.startX;
        let col = this.props.column;
        let newWidth = this.state.currentWidth + change;
        if (newWidth < this.minWidth){
            newWidth = this.minWidth;
        }
        Grid.setWidth(this.props.layout,col,newWidth); 
        this.setState({
            moving:false
        })
    }

    handleMouseMove = e => {
        e.preventDefault();
        e.stopPropagation();
        let change = e.clientX - this.state.startX;
        let newWidth = this.state.currentWidth + change;
        if (newWidth < this.minWidth){
            newWidth = this.minWidth;
        }
        this.setState ({
            newWidth
        })
        let col = this.props.column;
        Grid.setWidth(this.props.layout,col,newWidth);
        this.props.onResize();
    }
}


class Grid {

    static create(layout:IDataGridLayout,schema:IRecordSchema):IDataGrid {
        let grid:IDataGrid = {
            rowSelector:layout.rowSelector,
            columns:layout.columns,
            schema,
            sort:layout.sort,
            version:1
        }
        Grid.computeColumns(grid);
        return grid;
    }

    static setWidth(layout:IDataGrid,column:IDataGridColumn,newWidth:number){
        column.width = newWidth;
        Grid.computeColumns(layout);
    }

    static computeColumns(layout:IDataGrid){
        let left = 0;
        let totalWidth = 0;
        let columns = layout.columns;
        for(let i =0; i < columns.length;i++){
            let col = columns[i];
            col.left = left;
            if (!col.width){
                col.width = 140;
            }
            left += col.width;
            totalWidth += col.width;
        }
        layout.totalWidth = totalWidth;
    }

    static setSort(layout:IDataGrid,column:IDataGridColumn){
        let current =layout.sort;
        if (current && current.length == 1){
            let currentSortField = current[0];
            if (currentSortField.name == column.name){
                currentSortField.descending = !currentSortField.descending;
                return;
            }
        }
        layout.sort = [{name:column.name,descending:false}];
    }

    static getColumnSort(layout:IDataGrid,column:IDataGridColumn):ICollectionSortField {
        if (!layout.sort) return null;
        for(let i = 0; i < layout.sort.length;i++){
            let item = layout.sort[i];
            if (item.name == column.name) return item;
        }
    }

    static setSelected(rows:any[],value:boolean){
        for(let i =0 ; i < rows.length;i++){
            let row = rows[i];
            Collection.setRowSelected(row,value);
        }
    }
}



class Pagination extends React.Component<{currentPage:number,numPages:number,onClick:(pageNumber:number) => void}>{
    render(){
        let numPages = this.props.numPages;
        let currentPage = this.props.currentPage;
        let style:React.CSSProperties = {userSelect:"none"};
        if (numPages > 7){
            
            let prev;
            let next;
           
            let start = currentPage - 2;
            if (start < 2){
                start = 2;
            }
            let end = start + 4;
            if (end > numPages - 1){
                end = numPages - 1;
                start = end - 4;
            }
            
            if (start > 2){
                prev = <PageIconButton name="prev" onClick={this.handleIconClick} numPages={numPages} currentPage={currentPage}/>
                start++;
            }
            if (end < numPages -1){
                next = <PageIconButton name="next" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
                end--;
            }
            let rangeElems = [];
            let i = start;
            while (i <= end){
                rangeElems.push(<PageButton key={i} pageNumber={i} currentPage={currentPage} onClick={this.handlePageClick} />)
                i++;
            }
            return <div style={style}>
                <PageButton pageNumber={1} leftEdge currentPage={currentPage} onClick={this.handlePageClick} />
                {prev}
                {rangeElems}
                {next}
                <PageButton pageNumber={numPages} rightEdge currentPage={currentPage} onClick={this.handlePageClick} />
            </div>
        }
        else {
            let elems = [];
            for(let i = 1; i <= numPages;i++){
                elems.push(<PageButton key={i} pageNumber={i} currentPage={currentPage} onClick={this.handlePageClick} />)
            }
            return <div style={style}>
                <PageIconButton name="prev" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
                {elems}
                <PageIconButton name="next" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
            </div>
        }
    }

    handleIconClick = (name:string) => {
        let pageNumber:number;
        let currentPage = this.props.currentPage;
        if (name == "prev"){
            if (currentPage > 1){
                pageNumber = currentPage -1;
                this.props.onClick(pageNumber);
            }
        }
        else if (name == "next"){
            if (currentPage < this.props.numPages){
                pageNumber = currentPage + 1;
                this.props.onClick(pageNumber);
            }
        }
    }

    handlePageClick = (pageNumber:number) => {
        this.props.onClick(pageNumber);
    }
}

class PageIconButton extends React.Component<{name:string,numPages:number,currentPage:number;onClick:(name:string) => void}>{
    render(){
        let name = this.props.name;
        let style:React.CSSProperties = {backgroundColor:"#fff",borderColor:"rgb(230,232,236)",outline:"none",borderStyle:"solid",width:40,height:40,display:"inline-block",padding:8,fontSize:"14px",color:"rgb(120,130,140"};
        let icon;
    
        style.borderWidth = "1px 1px 1px 0";
        if (name == "prev"){
            if (this.props.currentPage < 2){
                style.opacity = 0.4;
            }
            icon = "<";
        }
        else {
          
            icon = ">";
            if (this.props.currentPage >= this.props.numPages){
                style.opacity = 0.4;
            }
        }
        return <button style={style}
            onClick={this.handleClick}>{icon}</button>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}

class PageButton extends React.Component<{pageNumber:number,currentPage:number,onClick:(pageNumber:number) => void,leftEdge?:boolean,rightEdge?:boolean}>{
    render(){
        let style:React.CSSProperties = {backgroundColor:"#fff",borderColor:"rgb(230,232,236)",outline:"none",borderStyle:"solid",textAlign:'center',
        width:40,height:40,display:"inline-block",padding:8,fontSize:"14px",color:"rgb(70,80,90)"};
        if (this.props.pageNumber == this.props.currentPage){
            style.backgroundColor = "#ebf2fe";
            style.color = "#000";
            style.fontWeight = 500
        }
        else {
            style.backgroundColor = "#fff";
        }
        style.borderWidth = "1px 1px 1px 0";
        if (this.props.leftEdge){
            style.borderTopLeftRadius = 5;
            style.borderBottomLeftRadius = 5;
            style.borderWidth = "1px";
        }
        else if (this.props.rightEdge){
            style.borderTopRightRadius = 5;
            style.borderBottomRightRadius = 5;
        }
        return <button style={style}
            onClick={this.handleClick}>{this.props.pageNumber}</button>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.pageNumber);
    }
}


export class VerboseWhere extends React.Component<{rowCount:number,label?:string,labelPlural?:string}>{

    render(){
        let rowCount = this.props.rowCount;
        let objectLabel = (rowCount == 1) ? this.props.label : this.props.labelPlural;
        let tokens = null;
        if (tokens && tokens.length) {
            let tokenContent = this.renderTokens(tokens);
            return <div className="RT-DataTable__verbose-search"><span>Found <strong>{rowCount}</strong> {objectLabel} where </span>{tokenContent}</div>
        }
        else {
            return <div className="RT-DataTable__verbose-search"><span>Found <strong>{rowCount}</strong> {objectLabel}</span></div>
        }
    }

    renderTokens(tokens:any[]){
        let elems = [];
        if (tokens){
            for(var i = 0; i < tokens.length;i++){
                var item = tokens[i];
                let style:any = {};
                if (item.token == "field"){
                    style.fontWeight = 500;
                    style.color = "#888b8d";
                }
                else if (item.token == "value"){
                    style.color = "#000";
                    style.fontWeight = 500;
                }
                elems.push(<span key={i} style={style}>{item.value}</span>);
            }
        }
        return elems;
    }
}