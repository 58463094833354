import React from 'react';
import { Collection, makeComponent, RenderEvent } from 'ktm-ui/core';
import { CollectionRef } from 'ktm-ui/core';
import {Tab,VerticalTabSet} from './Tabs';
import { timingSafeEqual } from 'crypto';

 

export const RecordTabs = makeComponent(class extends React.Component<{event?:RenderEvent, vertical?:boolean,collection:CollectionRef,template:string,actions?:any}>{
    
    contentKey = 1;
    renderedRow:any;
    
    render(){
        let collection = this.props.collection;
        if (!collection || !collection.rows) return null;
        let tabs = [];
        let current= Collection.getCurrentRow(collection.canvas,collection.name);
        if (current != this.renderedRow){
            this.contentKey++;
            this.renderedRow = current;
        }
        for(let i = 0; i < collection.rows.length;i++){
            let row = collection.rows[i];
            let isActive = (current == row);
            let tab = <Tab key={i} name={row.Id} label={row.Label} $isActive={isActive} $onClick={this.handleClick} />
            tabs.push(tab);
        }
        let panel;
        if (current){
            panel =  <div key={this.contentKey} style={{paddingLeft:20}} className="RT-TabSet__tab-content">
                <div style={{height:"100%"}}>
                    {this.props.event.draw(this.props.template,{scope:{[collection.name]:current}})}
                </div>
            </div>
        }

        return <VerticalTabSet tabs={tabs} buttons={this.props.actions} panels={panel} scrollable/>

    }

    handleClick=(tabName:string,stack:any) => {
        let collection = this.props.collection;
        let rows = collection.rows;
        for (let i =0 ; i < rows.length;i++){
            let row = rows[i];
            if (row.Id == tabName){
                Collection.setCurrentRow(collection.canvas,collection.name,row);
                let event = this.props.event;
                if (event){
                    event.canvas.update();
                }
                else {
                    this.forceUpdate();
                }
                return;
            }
        }
    }
});

class ContentWrapper extends React.Component{
    render(){
        return this.props.children || null;
    }
}