import CustomPagination from "components/CustomPagination";
import { Card, Line } from "ktm-ui";
import React from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { CSVLink } from "react-csv";


export default class OpsLedgerReport extends React.Component<any, any> {
    state = {
      loading: true,
      success: false,
      ledger: [],
      total: 0,
      currentPage: 1,
      itemsPerPage: 10,
      fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      toDate: new Date(),
      totalEnroll: 0,
      totalNhc: 0,
      totalDistro: 0,
    };
  
    componentDidMount() {
      this.initialize();
    }
    componentDidUpdate(prevProps, prevState) {
      if (
        prevState.fromDate !== this.state.fromDate ||
        prevState.toDate !== this.state.toDate
      ) {
        this.setState({ loading: true });
        this.initialize();
      }
    }
    setCurrentPage = (page: number) => {
      this.setState({ currentPage: page });
    };
  
    initialize = () => {
      fetch(
        "/api/opstransfer/getopsledgerreport?dateFrom=" +
          this.state.fromDate.toISOString() +
          "&dateTo=" +
          this.state.toDate.toISOString(),
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            loading: false,
            ledger: data.ledger,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    };
  
    setFromDate = (date) => {
      this.setState({
        fromDate: date,
      });
    };
  
    setToDate = (date) => {
      this.setState({
        toDate: date,
      });
    };
  
    render() {
      const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
      const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
      const currentRecords = this.state.ledger.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
      const nPages = Math.ceil(
        this.state.ledger.length / this.state.itemsPerPage
      );
  
      let totalRevenue = this.state.totalEnroll + this.state.totalNhc;
  
      var ledgerTable = null;
      if (this.state.ledger.length > 0) {
        ledgerTable = (
          <>
            <table className="table">
              <thead>
                <tr>
                  {/* <th></th> */}
                  <th>Distro</th>
                  <th>Type</th>
                  <th>Note</th>
                  <th className="text-end">Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.map((data, i) => {
                  return (
                    <tr>
                      {/* <td>{i + 1}</td> */}
                      <td>{data.distro}</td>
                      <td>{data.transType}</td>
                      <td>{data.note}</td>
                      <td className="text-end">{data.amount}</td>
                      <td>{data.transDate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className="pagination-container"
              style={{
                position: "relative",
                right: "10px",
                margin: "0px",
                justifyContent: "end",
              }}
            >
              <div className="pagination-info">
                <span>
                  Showing {indexOfFirstRecord + 1} -{" "}
                  {indexOfFirstRecord + currentRecords.length} entries out of{" "}
                  {this.state.ledger.length} entries
                </span>
              </div>
              <CustomPagination
                nPages={nPages}
                currentPage={this.state.currentPage}
                setCurrentPage={this.setCurrentPage}
              />
            </div>
          </>
        );
      }
  
      const today = new Date().toISOString().slice(0, 10);
      const fileName = `ledger_${today}.csv`;
  
      return (
        <>
          {this.state.loading ? (
            <div className="financial-spinner">
              <Spinner animation="border" />
            </div>
          ) : (
            <div style={{ overflowX: "auto" }}>
              <div className="mb-2 d-flex justify-content-between">
                <CustomDateRangePicker
                  fromDate={this.state.fromDate}
                  toDate={this.state.toDate}
                  setFromDate={this.setFromDate}
                  setToDate={this.setToDate}
                />
                {this.state.ledger.length > 0 ? (
                  <div>
                    <CSVLink data={this.state.ledger} filename={fileName}>
                      <Button
                        variant="primary"
                        className="csv-button export-to-csv"
                      >
                        <span className="csv-text">Export to CSV</span>
                      </Button>
                    </CSVLink>
                  </div>
                ) : null}
              </div>
  
              {ledgerTable}
            </div>
          )}
        </>
      );
    }
  }
  