import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Layout, Dialog, Button, Column, Panel, Display, Card } from "ktm-ui";
import CustomToolTip from '../../components/CustomToolTip';

interface IdsExpenseType {
  Id: string;
  Policy_Id: string;
  CoverageRate: number;
  AccountType: string;
  ShortName: string;
}

export default KTMScreen(
  { name: "enroll.ben-limit.edit-expense-type" },
  class extends React.Component<{ expense_id: string; policy_id: string, bencat_id: string, expense_type_id: string }> {
    data: {
      dsExpenseType: IdsExpenseType;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsExpenseType";
      await KTMApi.start(screen, {
        "@expense_id": this.props.expense_id,
        "@policy_id": this.props.policy_id,
        "@bencat_id": this.props.bencat_id,
        "@expense_type_id": this.props.expense_type_id,
      });
      screen.title = "Edit expense type";
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.details)}
          {draw(this.footer)}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label="Edit expense type" />;
    }

    footer({ field, draw, action }: RenderEvent) {
      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Display field={field("AccountType")} />
          <Display field={field("ShortName")} />
          <Display
            field={field("CoverageRate")}
            toolTip={
              <CustomToolTip
                header="Coverage"
                bodyContent="Shortcut to apply the same Copay to every expense. "
              />
            }
          />
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@dsExpenseType.CoverageRate": this.data.dsExpenseType.CoverageRate,
        "@expense_id": this.props.expense_id,
      });
      screen.close({ continue: true });
    }
  }
);
