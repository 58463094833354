import { useState, useRef, useEffect } from "react";
import { Card } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment";
import "../../styles/Reports.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export const DateDropdownWithCalendarPicker  = ({ options, selectedOption, setSelectedOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const dropdownRef = useRef(null);
    const calendarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && calendarRef.current && !calendarRef.current.contains(event.target)) {
              setIsOpen(false);
              setShowCalendar(false);
            }
          };
          document.addEventListener("click", handleClickOutside);
          return () => {
            document.removeEventListener("click", handleClickOutside);
          };
    }, [dropdownRef, calendarRef]);

    useEffect(() => {
        if (isOpen) {
            dropdownRef.current.querySelector(".dropdown-menu").focus();
        }
        if (selectedOption !== null) {
            let findIndex = options.indexOf(selectedOption);
            if(findIndex === -1) {
                findIndex = 0;
            }
            setSelectedIndex(findIndex);
        }
    }, [isOpen]);

    const handleKeyDown = (event) => {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            setSelectedIndex(Math.min(selectedIndex + 1, options.length - 1));
            break;
          case "ArrowUp":
            event.preventDefault();
            setSelectedIndex(Math.max(selectedIndex - 1, 0));
            break;
         case "Tab":
            setIsOpen(false);
            setShowCalendar(false);
            break;
          case "Enter":
            if(options[selectedIndex].id === null) {
                setShowCalendar(true);
            } else {
                setSelectedOption(options[selectedIndex]);
                setIsOpen(false);
            }
            break;
          default:
            break;
        }
      };

    const handleSave = () => {
        var dateRange = moment(fromDate).format("YYYY-MM-DD") + " - " + moment(toDate).format("YYYY-MM-DD");
        setSelectedOption({id:null, dateRange:dateRange, status: "", startDate: fromDate.toISOString(), endDate: toDate.toISOString()});
        setIsOpen(false);
    }
    
    return (
        <div className="dropdown" ref={dropdownRef}>
            <button 
                className="btn btn-secondary dropdown-toggle" 
                onClick={() => {
                    setIsOpen(!isOpen);
                    setShowCalendar(false);
                }}>
                <span className="btn-label">{selectedOption.dateRange}</span>
                <FontAwesomeIcon icon={faAngleDown} className="ml-2 dropdown-icon"/>
            </button>
            {isOpen ? (
                <ul className="dropdown-menu" tabIndex={0} onKeyDown={handleKeyDown}>
                    {options.map((option, index) => (
                        <li
                            className={"dropdown-item" + (selectedIndex === index ? " active" : "")}
                            key={option}
                            onClick={() => {
                                if(option.id === null){
                                    setShowCalendar(!showCalendar);
                                    setSelectedIndex(index);
                                } else {
                                    setSelectedOption(option);
                                    setIsOpen(false);
                                }
                            }} 
                        >
                            {option.dateRange}
                        </li>
                    ))}
                </ul>
            ) : null}
            {showCalendar ? (
                <Card className="calendar-picker-container">
                <div className="calendar-pickers">
                    <div className= "from-date-picker">
                        <label className="date-picker-lable">From</label>
                        <Calendar onChange={(e) => {setFromDate(e)}} value={fromDate} selectRange={false} className="from-calendar"/>
                    </div>
                    <div className= "to-date-picker">
                        <label className="date-picker-lable">To</label>
                        <Calendar onChange={(e) => {setToDate(e)}} value={toDate} selectRange={false} className="to-calendar"/>
                    </div>
                </div>
                <div className="calendar-picker-btns">
                    <button className="RT-Button" onClick={() => setShowCalendar(false)}>Cancel</button>
                    <button className="save-btn" onClick={handleSave}>Save</button>
                </div>
            </Card>
            ): null}
        </div>
    );
}