import axios from "axios";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import { CustomLoading } from "components/CustomLoading";
import { CSVLink } from "react-csv"; 
import React from "react";
import toastr from "toastr";
import { Button, Table } from "react-bootstrap";
import CustomPagination from "components/CustomPagination";
import { EmptyStateTemplate } from "./Empty_state_Template";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import "../../styles/Reports.css";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class MonthlySummaryTable extends React.Component<any, any> {
    
    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            currentPage: 1,
            itemsPerPage: 12,
            loading: true,
            data: [],
            dataToExport: [],
            fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
            toDate: new Date(),
            sortOrder: "asc",
            totalHsaAmount: 0,
            totalLsaAmount: 0,
            totalAdminFee: 0,
            totalGstHst: 0,
            totalRst: 0,
            totalPremiumTax: 0,
        };
    }

    componentDidMount() {
        this.setState({loading: true});
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fromDate !== this.state.fromDate || prevState.toDate !== this.state.toDate) {
            this.setState({loading: true});
            this.fetchData();
        }
    }

    fetchData = () => {
        axios.get("/api/Reports/getmonthlysummary", {
            params: {
                policyId: this.props.policyId,
                fromDate: this.state.fromDate.toISOString(),
                toDate: this.state.toDate.toISOString(),
            },
        })
        .then(res => {
            if(res.data.success) {
                this.setupTable(res.data.monthlySummary);
            } else {
                this.setState({
                    data: [],
                    dataToExport: [],
                    loading: false,
                });
            }
        })
        .catch(err => {
            toastr.error(err, "Error");
        });
    }

    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    setFromDate = (date) => {
        this.setState({
            fromDate: date,
        });
    }

    setToDate = (date) => {
        this.setState({
            toDate: date,
        });
    }

    sortByDate = () => {
        const { data, dataToExport, sortOrder } = this.state;
        const sortedData = data.reverse();
        const sortedDataToExport = dataToExport.reverse();
        this.setState({
            data: sortedData,
            dataToExport: sortedDataToExport,
            sortOrder: sortOrder === "asc" ? "desc" : "asc",
        });
    };

    setupTable = (data) => {
        let dataToExport = [];
        let totalHsaAmount = 0;
        let totalLsaAmount = 0;
        let totalAdminFee = 0;
        let totalGstHst = 0;
        let totalRST = 0;
        let totalPremiumTax = 0;

        const { fromDate, toDate } = this.state;
        const formattedFromDate = fromDate.toISOString().slice(0,10);
        const formattedToDate = toDate.toISOString().slice(0,10);

        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let formattedItem = [
                item.date,
                item.description,
                item.employeeNo,
                item.hsaAmount,
                item.lsaAmount,
                item.adminFee,
                item.gsthst,
                item.rst,
                item.premiumTax,
            ];
            dataToExport.push(formattedItem);

            totalHsaAmount += parseFloat(item.hsaAmount);
            totalLsaAmount += parseFloat(item.lsaAmount);
            totalAdminFee += parseFloat(item.adminFee);
            totalGstHst += parseFloat(item.gsthst);
            totalRST += parseFloat(item.rst);
            totalPremiumTax += parseFloat(item.premiumTax);
        }

        const headerRow = ["Date", "Description", "Employee ID", "HSA Amount", "LSA Amount", "Admin Fee", "GST/HST", "RST", "Premium Tax"];
        dataToExport.unshift(headerRow);
        
        let totalItem = [
            "Total",
            "",
            "",
            totalHsaAmount.toFixed(2),
            totalLsaAmount.toFixed(2),
            totalAdminFee.toFixed(2),
            totalGstHst.toFixed(2),
            totalRST.toFixed(2),
            totalPremiumTax.toFixed(2),
        ];
        dataToExport.push(totalItem);
        
        let totalAmount = totalHsaAmount + totalLsaAmount + totalAdminFee + totalGstHst + totalRST + totalPremiumTax;
        let totalItem2 = [
            "Total Amount",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            totalAmount.toFixed(2),
        ];
        dataToExport.push(totalItem2);

        const dateRangeRow = [
            "Date Range",
            `${formattedFromDate} - ${formattedToDate}`
        ];
        dataToExport.unshift(dateRangeRow);

        this.setState({
            data: data,
            dataToExport: dataToExport,
            totalHsaAmount: totalHsaAmount.toFixed(2),
            totalLsaAmount: totalLsaAmount.toFixed(2),
            totalAdminFee: totalAdminFee.toFixed(2),
            totalGstHst: totalGstHst.toFixed(2),
            totalRst: totalRST.toFixed(2),
            totalPremiumTax: totalPremiumTax.toFixed(2),
            loading: false
        });
    }

    exportToPdf = () => {
        const { dataToExport } = this.state;
    
        const header = dataToExport[1];
        const bodyRows = dataToExport.slice(2, dataToExport.length - 2);
        const totalRows = dataToExport.slice(dataToExport.length - 2);
      
        const headerData = this.formatRow(header, 'tableHeader');
        const bodyData = this.formatTableBody(bodyRows);
        bodyData.push(this.formatRow(totalRows[0], 'tableFooter'), this.formatRow(totalRows[1], 'tableFooter'));

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [
                {
                    columns: [
                        this.createLogo(),
                        this.createDateRange(dataToExport[0][1]),
                    ],
                },
                this.createTitle(),
                this.createTable(headerData, bodyData)
            ],
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download("Monthly_Summary.pdf");
    };
    
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    createTitle = () => ({
        text: 'Claim Summary',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map(row => this.formatRow(row, 'tableBody'));
    
    formatTableFooter = (rows) => rows.map(row => this.formatRow(row, 'tableFooter'));

    createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('auto'),
            body: [header, ...body]
        }
    });
    
    getPdfStyles = () => ({
        table: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 10,
            color: 'black',
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    render() {
        const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
        const currentRecords = this.state.data.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(this.state.data.length / this.state.itemsPerPage);

        let contents = null;
        if (this.state.loading) {
            contents = (<CustomLoading />);
        } else {
            contents = (
                <div className="funding-activity-and-transactions-table report-table-container">
                    <div className="report-toolbar"
                        style={{display: "flex", alignItems: "center"}}
                    >
                        <CustomDateRangePicker fromDate={this.state.fromDate} toDate={this.state.toDate} setFromDate={this.setFromDate} setToDate={this.setToDate} />
                        {this.state.data.length > 0 ? (
                        <div className="export-button-container">
                            <Button variant="primary" className="export-to-pdf" onClick={this.exportToPdf}>
                                <span className="pdf-text">Export to PDF</span>
                            </Button>
                            <CSVLink data={this.state.dataToExport} filename={"Monthly_Summary_Report.csv"} className="export-button">
                                <Button variant="primary" className="csv-button export-to-csv">
                                    <span className="csv-text">Export to CSV</span>
                                </Button>
                            </CSVLink>
                        </div>)
                        : null}
                    </div>
                    {this.state.data.length > 0 ? (
                    <>
                        <Table hover className="report-table">
                            <thead>
                                <tr>
                                    <th className="table-text-content" onClick={this.sortByDate}>
                                        Date
                                        {this.state.sortOrder === "asc" ? (
                                            <FontAwesomeIcon icon={faSortUp} className="asc-icon"/>
                                        ) : (
                                            <FontAwesomeIcon icon={faSortDown} className="desc-icon"/>
                                        )}
                                    </th>
                                    <th className="table-text-content">Description</th>
                                    <th>Employee ID</th>
                                    <th>HSA Amount</th>
                                    <th>LSA Amount</th>
                                    <th>Admin Fee</th>
                                    <th>GST/HST</th>
                                    <th>RST</th>
                                    <th>Premium Tax</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((item, index) => 
                                    (
                                        <tr key={index} className="table-body-itme">
                                            <td className="table-text-content">{item.date}</td>
                                            <td className="table-text-content">{item.description}</td>
                                            <td>{item.employeeNo}</td>
                                            <td>{item.hsaAmount}</td>
                                            <td>{item.lsaAmount}</td>
                                            <td>{item.adminFee}</td>
                                            <td>{item.gsthst}</td>
                                            <td>{item.rst}</td>
                                            <td>{item.premiumTax}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className="table-footer-item total-amount">Total</td>
                                    <td></td>
                                    <td></td>
                                    <td className="table-footer-item">{this.state.totalHsaAmount}</td>
                                    <td className="table-footer-item">{this.state.totalLsaAmount}</td>
                                    <td className="table-footer-item">{this.state.totalAdminFee}</td>
                                    <td className="table-footer-item">{this.state.totalGstHst}</td>
                                    <td className="table-footer-item">{this.state.totalRst}</td>
                                    <td className="table-footer-item">{this.state.totalPremiumTax}</td>
                                </tr>
                                <tr>
                                    <td className="table-footer-item total-amount">Total Amount</td>
                                    <td></td>
                                    <td></td>
                                    <td colSpan={6} className="table-footer-item">{(parseFloat(this.state.totalHsaAmount) + parseFloat(this.state.totalLsaAmount) + parseFloat(this.state.totalAdminFee) + parseFloat(this.state.totalGstHst) + parseFloat(this.state.totalRst) + parseFloat(this.state.totalPremiumTax)).toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </Table>
                        <div className="pagination-container">
                            <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {this.state.data.length} entries</span>
                            </div>
                            <CustomPagination
                                nPages={nPages} 
                                currentPage={this.state.currentPage} 
                                setCurrentPage={this.setCurrentPage} 
                            />
                        </div> 
                    </>) : ( <EmptyStateTemplate /> )}
                </div>
            );
        }

        return (<>{contents}</>);
    }
}

