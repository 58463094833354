import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Button,DataGrid,IDataGridLayout,EmptyState} from 'ktm-ui';
import Edit_expense_typeScreen from './Edit_expense_type';
import Remove_expense_typeScreen from './Remove_expense_type';
import Add_expense_typesScreen from './Add_expense_types';

interface IdsExpenseTypes {
    Id:string;
    Policy_Id:string;
    Limit_Id:string;
    ExpenseType_Id:string;
    CoverageRate:number;
    AccountType:string;
    Code:string;
    ShortName:string;
    Description:string;
};

interface IdsPrevYearExpenseTypes {
    Id:string;
    Policy_Id:string;
    Limit_Id:string;
    ExpenseType_Id:string;
    CoverageRate:number;
    AccountType:string;
    Code:string;
    ShortName:string;
    Description:string;
};

export default KTMScreen(
    {name:"enroll.ben-limit.expense-types"}, 
    class extends React.Component<{policy_id:string,limit_id:string, category_id:string, showRunoff: boolean}> {

    data:{
        dsExpenseTypes:IdsExpenseTypes[];
        dsPrevYearExpenseTypes:IdsPrevYearExpenseTypes[];
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsExpenseTypes";
        await KTMApi.start(screen,{
            "@policy_id":this.props.policy_id,
            "@limit_id":this.props.limit_id,
            "@category_id":this.props.category_id,
        });
        screen.title = "Expense types";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.default_view)}
                {this.props.showRunoff && draw(this.runoff_view)}
            </Layout>
        );
    }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = (<><Button action={action(this.add)} label="Add Expense Types" style="add"/></>);
        let layout:IDataGridLayout = {
            columns:[
                {name:"AccountType",label:"Account Type",type:"character"},
                {name:"Code",label:"Code",type:"character"},
                {name:"CoverageRate",label:"Coverage (%)",type:"decimal"},
                {name:"ShortName",label:"Short Name",type:"character",width:450},
                {action:"edit",label:"Edit",width:80},
                {action:"remove",label:"Remove",width:80}
            ],
            sort:[{name:"AccountType"},{name:"ShortName"}]
        };
        return (
            <DataGrid collection={collection("@dsExpenseTypes")} layout={layout} actions={actions} viewType='expense-types'/>
        );
    }

    runoff_view({field,draw,action,collection}:RenderEvent){
        let layout:IDataGridLayout = {
            columns:[
                {name:"AccountType",label:"Account Type",type:"character"},
                {name:"Code",label:"Code",type:"character"},
                {name:"CoverageRate",label:"Coverage (%)",type:"decimal"},
                {name:"ShortName",label:"Short Name",type:"character",width:450},
            ],
            sort:[{name:"AccountType"},{name:"ShortName"}]
        };
        return (
            <DataGrid collection={collection("@dsPrevYearExpenseTypes")} layout={layout} viewType='runoff-section'/>
        );
    }

    empty_state({field,draw,action}:RenderEvent){
        return (
            <EmptyState label="No expenses types have been added yet." icon="bill">
                <Button action={action(this.add)} label="Add Expense Types" style="primary"/>
            </EmptyState>
        );
    }

    async edit({screen}:ActionEvent){
        await screen.openDialog(
        <Edit_expense_typeScreen 
            expense_id={screen.getValue("@dsExpenseTypes.Id")} 
            policy_id={screen.getValue("@dsExpenseTypes.Policy_Id")}
            bencat_id={this.props.category_id}
            expense_type_id={screen.getValue("@dsExpenseTypes.ExpenseType_Id")}
        />
,{onRecordChanged:this.refresh_data});
    }

    async remove({screen}:ActionEvent){
        await screen.openDialog(<Remove_expense_typeScreen policy_id={screen.getValue("@dsExpenseTypes.Policy_Id")} expense_id={screen.getValue("@dsExpenseTypes.Id")}/>
,{onRecordChanged:this.refresh_data});
    }

    async add({screen}:ActionEvent){
        await screen.openDialog(
        <Add_expense_typesScreen 
            policy_id={this.props.policy_id} 
            limit_id={this.props.limit_id}
            bencat_id={this.props.category_id}
        />
,{onRecordChanged:this.refresh_data});
    }

    async refresh_data({screen}:ActionEvent){
        await screen.restart();
    }

});
