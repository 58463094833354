import { Schema } from "..";
import { IFieldDefinition, IRecordSchema } from "../Schema";

export interface ITypeCheckWith {
    name:string;
    schema:IRecordSchema;
}

export class FieldReferenceSet {
    public root:any = {};

    public addField(table:string,field:string){
        this.root[table] = this.root[table] || {};
        this.root[table][field] = true;
    }

    public addVariable(name:string){
        this.root[name] = this.root[name] || {};
    }

    public getTable(table:string):any {
        return this.root[table];
    }
}

export class TypeChecking {
   
    public locals:{name:string,field:IFieldDefinition,isScope?:boolean}[];
    public withStack:ITypeCheckWith[];
    public rootSchema:IRecordSchema;
    public references:FieldReferenceSet[];
    public inlineBatches:{[name:string]:boolean} = {};
    public defaultRecord:string;

    constructor(){
        this.locals = [
           
        ];
        this.withStack = [];
        this.references = [];
    }

    public getLocalType(name:string):IFieldDefinition{
        name = name.toLowerCase();
        for(var i = 0; i < this.locals.length; i++){
            if (this.locals[i].name == name){
                return this.locals[i].field;
            }
        }
        return null;
    }

    public startLocalScope(){
        this.locals.push({isScope:true,field:null,name:null})
    }

    public endLocalScope(){
        var locals = this.locals;
        while (locals.length){
            var item = locals.pop();
            if (item.isScope){
                return;
            }
        }
    }

    public pushWith(name:string,schema:IRecordSchema){
        this.withStack.push({name,schema});
    }

    public popWith():ITypeCheckWith {
        return this.withStack.pop();
    }

    public pushReferenceSet(r:FieldReferenceSet){
        this.references.push(r);
    }

    public popReferenceSet():FieldReferenceSet{
        return this.references.pop();
    }
    
    public getCurrentWith():ITypeCheckWith{
        if (this.withStack.length){
            return this.withStack[this.withStack.length - 1];
        }
        return null;
    }

    public findWith(varName:string):ITypeCheckWith {
        if (!this.withStack.length) return null;
        for(let i = this.withStack.length - 1; i >=0 ; i--){
            let item = this.withStack[i];
            let f = Schema.getFieldDef(item.schema,varName);
            if (f) return item;
        }
        return null;
    }

    public getVariableType(name:string):IFieldDefinition{
        let f =this.getField(name);
        if (f){
            this.addVariableReference(f.name);
        }
        return f;
    }   

    
    public getTableType(name:string):IRecordSchema {
        let type = this.getLocalType(name);
        if (type){
            return type.recordSchema;
        }
        type = this.getVariableType(name);
        if (type){
            return type.recordSchema;
        }
        return null;
    }


  
    public getPropertyType(schema:IRecordSchema,name:string):IFieldDefinition{    
        if (!schema) return null; 
        return  Schema.getFieldDef(schema,name);
    }

    private addFieldReference(table:string,field:string){
        if (this.references){
            for(let i = 0; i < this.references.length;i++){
                this.references[i].addField(table,field);
            }
        }
    }

    private addVariableReference(name:string){
        if (this.references){
            for(let i = 0; i < this.references.length;i++){
                this.references[i].addVariable(name);
            }
        }
    }


    private getField(name:string):IFieldDefinition {
        if (!this.rootSchema) return null;
        return Schema.getFieldDef(this.rootSchema,name);
    }
    
    public pushLocal(name:string,field:IFieldDefinition){
        this.locals.push({name:name.toLowerCase(),field});
    }

    public getQualifiedField(name:string):IFieldDefinition {
        if (!name) return null;
        let i = name.indexOf(".");
        let schema:IRecordSchema;
        if (i == -1){
            return this.getField(name);
        }

        let table = name.substr(0,i);
        name = name.substr(i + 1);
        let f = this.getField(table);
        if (!f && !f.recordSchema) return null;
        return this.getPropertyType(f.recordSchema,name);
    }

    public getQualifiedLocal(name:string):IFieldDefinition {
        if (!name) return null;
        let i = name.indexOf(".");
        if (i == -1){
            return this.getLocalType(name);
        }
        let table = name.substr(0,i);
        name = name.substr(i + 1);
        let tableField = this.getLocalType(table);
        if (!tableField) return null;
        return this.getPropertyType(tableField.recordSchema,name);
    }
}