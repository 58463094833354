import React, { Component } from "react";
import { KTMScreen } from "ktm-ui";
import toastr from "toastr";
import Moment from "react-moment";
import {
  Button,
  Table,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Spinner,
  Card,
} from "react-bootstrap";
import axios from "axios";
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import DatePicker from "react-date-picker";

import base64 from "../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default KTMScreen(
  { name: "chequedeposit" },
  class extends React.Component<any, any> {
    state = {
      loading: true,
      success: false,
      chequeData: null,
      selectedDate: new Date(),
    };

    componentDidMount() {}

    setDate = (date) => {
      this.setState(
        {
          selectedDate: date,
        }
      );
    };

    downloadSlip = () => {
      const formattedDate = this.state.selectedDate.toISOString().split("T")[0];

      fetch("/api/payment/getdepositslip?selectedDate=" + formattedDate, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState(
            {
              chequeData: data,
            },
            () => {
              if (data.cheques.length > 0) {
                this.printSlip();
                toastr.success("Cheque downloaded", "Success");
              } else {
                toastr.error("No cheques found", "Error");
              }
            }
          );
        })
        .catch((error) => {
          this.setState({ searchResult: null, success: false });
          toastr.error("Search failed", "Error");
        });
    };

    printSlip = () => {
      const year = this.state.selectedDate.getFullYear();
      const month = String(this.state.selectedDate.getMonth() + 1).padStart(
        2,
        "0"
      );
      const day = String(this.state.selectedDate.getDate()).padStart(2, "0");

      const fileDate = `${year}_${month}_${day}`;
      const formatDate = `${year}-${month}-${day}`;
      const slipDate = `${year}${month}${day}`;

      let content = {
        content: [
          {
            columns: [
              {
                image: base64.logo,
                width: 230,
              },
              {
                text: "CHEQUE DEPOSIT",
                fontSize: 20,
                bold: true,
                alignment: "right",
                margin: [0, 0, 20, 0],
              },
            ],
          },
          {
            columns: [
              {
                width: "*",
                text: [
                  {
                    text: "\n\n\nNational HealthClaim Corporation\n\n",
                    bold: true,
                  },
                  "5320 - 7005 Fairmount Dr. SE\n",
                  "Calgary, AB T2H 0K4\n\n",
                  "Phone (403) 539-5820 Fax (403) 228-1580",
                ],
                margin: [0, 0, 20, 0],
              },
              {
                width: "*",
                text: [
                  {
                    text: "\n\n\nDeposit Slip No: " + slipDate + "\n\n",
                    bold: true,
                  },
                  "Transit: 01049\n",
                  "AccountNo: 7438-5250174\n\n",
                  { text: "Date: " + formatDate, bold: true },
                ],
                margin: [0, 0, 20, 0],
              },
            ],
          },
          {
            table: {
              headerRows: 1,
              body: [
                [
                  "Date",
                  "Cheque No.",
                  "Description",
                  "Currency",
                  "Cheque Amount",
                ],
                ...this.state.chequeData.cheques.map((cheque) => [
                  cheque.transDate,
                  cheque.chequeNo,
                  cheque.policy,
                  "CAD",
                  { text: cheque.amount.toString(), alignment: "right" },
                ]),
              ],
            },
            layout: "lightHorizontalLines",
            margin: [0, 50, 0, 20],
          },
          {
            text: "Total Items: " + (this.state.chequeData.cheques.length),
            margin: [0, 10, 0, 0], 
          },
          {
            text: "Total Amount: " + this.state.chequeData.total,
            bold: true,
            margin: [0, 5, 0, 0],
          },
        ],
      };
      pdfMake.createPdf(content).download("chq_deposit_" + fileDate);
    };

    render() {
      return (
        <div>
          <div className="ktm-financials-content">
            <>
              <div className="ktm-financials-label">Cheque Deposit Slip</div>
              <div className="container mt-2">
                <div className="mt-4">
                  <div className="d-flex align-items-start">
                    <div>
                      <DatePicker
                        onChange={(e) => {
                          this.setDate(e);
                        }}
                        value={new Date(this.state.selectedDate)}
                        format={"y-MM-dd"}
                        minDate={new Date(2000, 1, 1)}
                        clearIcon={null}
                      />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={this.downloadSlip}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      );
    }
  }
);
