import React from "react";
import { CustomLoading } from "components/CustomLoading";
import "bootstrap/dist/css/bootstrap.min.css";
import { KTMScreen} from "ktm-ui";
import AdvisorsReport from "./AdvisorsReport";
import DistroAccountsReport from "./DistroAccountsReport";
import { Card } from "react-bootstrap";

export default KTMScreen(
  { name: "advisors" },
  class extends React.Component<any, any> {
    state = {
      loading: true,
      currentListItem: "Distro Accounts",
    };

    componentDidMount() {
      this.setState({ loading: false });
    }

    setCurrentItem = (item) => {
      this.setState({ currentListItem: item });
    };

    render() {
      let container = null;
      let contents = null;
      if (this.state.loading) {
        contents = <CustomLoading />;
      } else {
        let listGroup = [];
        listGroup.push("Distro Accounts");
        listGroup.push("Advisors");

        if (this.state.currentListItem == "Distro Accounts") {
          contents = <DistroAccountsReport/>;
        } else if (this.state.currentListItem == "Advisors") {
          contents = <AdvisorsReport/>;
        }

        container = (
          <div className="ktm-reports">
            <ul
              className="list-group"
              style={{ width: "13%", marginRight: "10px" }}
            >
              {listGroup.map((item, index) => (
                <li
                  key={index}
                  className="list-group-item"
                  style={
                    this.state.currentListItem == item
                      ? { backgroundColor: "#848f99", color: "white" }
                      : null
                  }
                  onClick={() => this.setCurrentItem(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
            <Card 
              className="ktm-financials-card"
              style = {{
                flexGrow: 2,
                padding: "10px",
                height: "100%"
              }}
            >
              {contents}
            </Card>
          </div>
        );
      }
      return <div style={{ width: "100%", padding: 30 }}>{container}</div>;
    }
  }
);