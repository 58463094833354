import axios from "axios";
import { Card } from "ktm-ui";
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import "../styles/FlexTemplates.css";
import toastr from "toastr";
import { CustomLoading } from "components/CustomLoading";

const defaultTemplate =  'Dear *|FNAME|*\n\n*|COMPANY|* is providing you with a spending account as a part of your total compensation package. The yearly amount you have available is $*|LIMIT|*.\n\nTake a few minutes and choose how to use this limit by clicking on the custom link below.\n\nIt will open a browser set up by National HealthClaim, the company that administers this benefit on our behalf of your company.\n\n*|LINK|*\n\nPlease make your choice by *|DEADLINE|* or a default selection will be chosen for you. Once the selection deadline has passed, changes cannot be made to your allocation. \n\nBest regards,\nNational HealthClaim\n\nIf you have any questions, please respond to this email and our team will be happy to assist you.';
export default class SelectionEmailTemplate extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedTemplate: "Default",
            customTemplateContent: "",
            isDisabled: true,
        };

        this.edit = this.edit.bind(this);
        this.fetchCustomTemplate = this.fetchCustomTemplate.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount(): void {
        this.fetchCustomTemplate();
    }

    fetchCustomTemplate(): void {
        axios
        .get("/api/Flex/getcustomemailtemplate?policyId="+this.props.policyId)
        .then((response) => {
            this.setState({
                loading: false,
                selectedTemplate: response.data.customEmailTemplate ? "Custom" : "Default",
                customTemplateContent: response.data.customEmailTemplate || "",
            });
        })
        .catch((error) => {
            toastr.error("Error while fetching template","Error");
        });
    }

    edit(): void {
        this.setState({ isDisabled: false });
    }

    handleSave(): void {
        this.setState({ isDisabled: true });
        axios
        .put("/api/Flex/updatecustomemailtemplate", {
            policyId: this.props.policyId,
            customTemplateContent: this.state.customTemplateContent,
            templateType: this.state.selectedTemplate
        })
        .then((response) => {
            if (response.data.success) {
                this.fetchCustomTemplate();
                toastr.success("Template saved successfully","Success");
            }
            else
            {
                toastr.error("Error while saving template","Error");
            }
        })
        .catch((error) => {
            toastr.error("Error while saving template","Error");
        });
    }

    handleCancel(): void {
        this.setState({ 
            selectedTemplate: "Default",
            isDisabled: true 
        });
    }

    toggleTemplateOption(e) {
        this.setState({ selectedTemplate: e.target.value });
    }

    render() {
        var content = null;
        if (this.state.loading) {
            content = (<CustomLoading />);
        } else {
            content = (
                <Card 
                    label="Selection Email Template"
                    actions={<><a href="#" className="RT-Link" onClick={this.edit}>Edit</a></>}
                >
                    <div className="selection-options">
                        <label>
                            <input
                            className="default-checkbox"
                            type="checkbox"
                            value={"Default"}
                            checked={this.state.selectedTemplate == "Default"}
                            onChange={(e) => this.toggleTemplateOption(e)}
                            disabled={this.state.isDisabled}
                            />
                            Default
                        </label>
                        <label>
                            <input
                            className="custom-checkbox"
                            type="checkbox"
                            value={"Custom"}
                            checked={this.state.selectedTemplate == "Custom"}
                            onChange={(e) => this.toggleTemplateOption(e)}
                            disabled={this.state.isDisabled}
                            />
                            Custom
                        </label>
                    </div>
                    <div className="merge-tags">
                        <label>Merge Tags</label>
                        <Table className="merge-tags-table">
                            <thead>
                                <tr>
                                    <th>TAG</th>
                                    <th>DESCRIPTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{`*|FNAME|*`}</td>
                                    <td>Employee's first name</td>
                                </tr>
                                <tr>
                                    <td>{`*|LNAME|*`}</td>
                                    <td>Employee's last name</td>
                                </tr>
                                <tr>
                                    <td>{`*|LIMIT|*`}</td>
                                    <td>Total assigned limit</td>
                                </tr>
                                <tr>
                                    <td>{`*|DEADLINE|*`}</td>
                                    <td>Selection deadline</td>
                                </tr>
                                <tr>
                                    <td>{`*|LINK|*`}</td>
                                    <td>Selection link</td>
                                </tr>
                                <tr>
                                    <td>{`*|PAFIRST|*`}</td>
                                    <td>Plan admin first name</td>
                                </tr>
                                <tr>
                                    <td>{`*|PALAST|*`}</td>
                                    <td>Plan admin last name</td>
                                </tr>
                                <tr>
                                    <td>{`*|COMPANY|*`}</td>
                                    <td>Company name</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="selection-email-template-content">
                        <label>{this.state.selectedTemplate+" Template"}</label>
                        <textarea 
                            className="email-template-textarea"
                            value={this.state.selectedTemplate == "Default" ? defaultTemplate : this.state.customTemplateContent}
                            disabled={this.state.isDisabled}
                            onChange={(e) => this.setState({ customTemplateContent: e.target.value })}
                        >
                        </textarea>
                    </div>
                    <div className="flex-templates-buttons">
                        <button className="RT-Button" disabled={this.state.isDisabled} onClick={this.handleCancel}>Cancel</button>
                        <button className="RT-Button RT-Button--primary RT-Button--large RT-Button--large" disabled={this.state.isDisabled} onClick={this.handleSave}>Save</button>
                    </div>
                </Card>
            );
        }

        return (<div className="selection-email-template-container" style={{marginBottom:20}}>{content}</div>);
    }
}