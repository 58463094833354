import React from "react";

export default class Spinner extends React.Component {
  render() {
    return (
      <div className="d-flex ht-350 pos-relative align-items-center">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1 bg-gray-800" />
          <div className="sk-child sk-bounce2 bg-gray-800" />
          <div className="sk-child sk-bounce3 bg-gray-800" />
        </div>
      </div>
    );
  }
}
