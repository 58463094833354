import { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import CustomPagination from './CustomPagination';
import axios from 'axios';
import { CustomLoading } from './CustomLoading';
import Moment from 'react-moment';
import toastr from 'toastr';
import TerminateEmployeeModal from './TerminateEmployeeModal';

const EmployeeBulkEdits = ({ policyId }) => {
  const [loading, setLoading] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const getEmployeeDetails = async () => {
    try {
      const response = await axios.get('/api/Settings/getemployeestatus', {
        params: { policyId }
      });
      if (response.data.success) {
        setEmployeeDetails(response.data.employeeDetails);
        setSelectedEmployees([]);
      } else {
        toastr.error('Failed to fetch employee details.');
      }
    } catch (error) {
      console.error(error);
      toastr.error('An error occurred while fetching employee details.');
    } finally {
      setLoading(false);
    }
  };

  const updateSelectedEmployee = () => {
    if (selectedEmployees.length > 0) {
      setShowEditModal(true);
    }
  };

  const selectAll = () => {
    if (selectedEmployees.length === employeeDetails.length) {
      setSelectedEmployees([]);
    } else {
      const employeeIds = employeeDetails.map(employee => employee.id);
      setSelectedEmployees(employeeIds);
    }
  };

  const openEditEmployeeModal = (event, employeeId) => {
    event.preventDefault();
    setShowEditModal(true);
    setSelectedEmployees([employeeId]);
  };

  const handleCheckboxChange = (employeeId) => {
    setSelectedEmployees(prevSelected => 
      prevSelected.includes(employeeId) 
      ? prevSelected.filter(id => id !== employeeId) 
      : [...prevSelected, employeeId]
    );
  };

  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = employeeDetails.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(employeeDetails.length / itemsPerPage);

  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <div className="employee-bulk-edits-container"  style={{ width: '87%' }}>
          <Card>
            {showEditModal && (
              <TerminateEmployeeModal
                showEditModal={showEditModal}
                policyId={policyId}
                selectedEmployees={selectedEmployees}
                refreshEmployees={() => {
                  setShowEditModal(false);
                  getEmployeeDetails();
                }}
                closeModal={() => {
                  setShowEditModal(false);
                  setSelectedEmployees([]);
                }}
              />
            )}
            <Table hover>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedEmployees.length === employeeDetails.length}
                      onChange={selectAll}
                    />
                  </th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th>Benefit Category</th>
                  <th>Is Active</th>
                  <th>Benefit End Date</th>
                  <th>Access After Termination (days)</th>
                  <th>Termination Note</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {currentRecords.map(employee => (
                  <tr key={employee.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedEmployees.includes(employee.id)}
                        onChange={() => handleCheckboxChange(employee.id)}
                      />
                    </td>
                    <td>{employee.lastName}</td>
                    <td>{employee.firstName}</td>
                    <td>{employee.benefitCategory}</td>
                    <td>{employee.isActive ? "Yes" : "No"}</td>
                    <td>
                      {employee.benefitEndDate ? (
                        <Moment format="YYYY-MM-DD">
                          {employee.benefitEndDate}
                        </Moment>
                      ) : 'N/A'}
                    </td>
                    <td>{employee.accessAfterTerminationDays ?? 'N/A'}</td>
                    <td className="truncate">{employee.terminationNote ?? 'N/A'}</td>
                    <td>
                      <a
                        href="#"
                        className="RT-Link"
                        onClick={event => openEditEmployeeModal(event, employee.id)}
                      >
                        Edit
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="employee-campaign-button-container"  style={{padding:20}}>
              <Button variant="secondary" onClick={selectAll}>
                Select/Deselect All
              </Button>
              <Button
                variant="primary"
                onClick={updateSelectedEmployee}
                disabled={selectedEmployees.length === 0}
              >
                Update Selected Employees
              </Button>
              <div className="pagination-container">
                <div className="pagination-info">
                  <span>
                    Showing {indexOfFirstRecord + 1} -{' '}
                    {indexOfFirstRecord + currentRecords.length} entries out of{' '}
                    {employeeDetails.length} entries
                  </span>
                </div>
                <CustomPagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default EmployeeBulkEdits;
