
import ReactDOM from 'react-dom';
import {Application} from './Application';
import { Broadcast } from './Broadcast';
import { ISigninSettings, IStudioSettings } from './types';

export interface ISessionResult {
    success:boolean;
    error?:string;
    userType?:string;
    firstName?:string;
    lastName?:string;
    homeScreen?:string;
    config?:any
    signinSettings?:ISigninSettings;
    studioSettings?:IStudioSettings;
    requires2FA:boolean;
}

export class AppSession {

    app:Application;
    public homeScreen:string;
    public broadcast:Broadcast;

    private values:any = {};

    constructor(app:Application){
        this.app = app;
        this.broadcast = new Broadcast();
    }

    async signin(
        app: Application,
       
    ):Promise<ISessionResult> {
    
        let res = await this.tryGetSession(app);
        if (res.signinSettings){
            app.signinSettings = res.signinSettings;
            app.studioSettings = res.studioSettings;
        }

        let params = new URLSearchParams(document.location.search.substr(1));
        let pwdToken = params.get("_pwdtoken");
        if (pwdToken){
            this.renderResetPassword(app,pwdToken);
            return res;
        }

        if (res.userType){
            return res;
        }

        let elem = document.createElement("div");
        document.body.appendChild(elem);
        var p = new Promise<ISessionResult>((resolve) => {
            let onClose = (result:ISessionResult)=> {
                if(result.userType !== "financial admin"){
                    let financialCard = document.getElementsByClassName("financials-card");
                    if (financialCard[0]) {
                        financialCard[0].parentNode.removeChild(financialCard[0]);
                    }

                    let reportsCard = document.getElementsByClassName("reports-card");
                    if (reportsCard[0]) {
                        reportsCard[0].parentNode.removeChild(reportsCard[0]);
                    }
                }
                
                document.body.removeChild(elem);
                resolve(result);
            }
            let content = this.renderSignin(app,onClose,null);
            ReactDOM.render(content, elem);
        })
        res = await p;
        app.studioSettings = res.studioSettings;
        this.broadcast.refresh();
        return res;
    }
    
    getPostUrl(app:Application,method:string){
        return  "/session/" + method;
    }
    
    async tryGetSession(app:Application):Promise<ISessionResult>{

        try {
            let json = await Application.instance.doPostRequest(this.getPostUrl(app,"status"),{domain:app.domain,branch:app.branch});
            return json;  
        } catch (e) {
           return null;
        }
    }
    
    renderResetPassword(app: Application, token: string) {
        let container = document.createElement("div");
        document.body.appendChild(container);
        let done = () => {
            document.location.assign("/");
        };
        let content = this.renderSignin(app,done,token);
        ReactDOM.render(content, container);
    }

    renderSignin(app:Application,done:(result:ISessionResult)=> void,token:string):any{
        let container = document.createElement("div");
        document.body.appendChild(container);
        return app.renderSignin({app,done,token});
    }

    public async attemptSignin(username:string, password:string, ip: string):Promise<ISessionResult> {
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"signin"),{domain:this.app.domain,username,password,ip});
        return res;
    }

    public async requestToken(username:string):Promise<ISessionResult>{
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"send-reset-token"),{domain:this.app.domain,username});
        return res;
    }

    public async resetPassword(token:string,password:string):Promise<ISessionResult>{
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"set-password"),{domain:this.app.domain,token,password});
        return res;
    }

    public getValue(name:string):any {
        if (!name) return;
        return this.values[name.toLowerCase()];
    }

    public async signout():Promise<ISessionResult>{
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"signout"),{domain:this.app.domain});
        if (res.success){
            window.top.location.assign("/ops");
        }
        return res;
    }

    public async verifyTwoFactorCode(username: string, token: string, ip: string): Promise<ISessionResult> {
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app, "verify-2fa"), {username,token,ip});
        return res;
    }
}