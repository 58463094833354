import React from 'react';
import {  FieldRef, FieldType } from '..';
import { RecordMeta ,FilterOp} from '../core';
import { Focus } from '../core/Focus';
import {Display} from './Display';
import { AnchorPoint } from './helpers/AnchorPoint';
import { Popup } from './popups/Popup';
import { DisplayProps } from './Display';
const OPERATOR_SUFFIX = "$op";
const RANGE_END_SUFFIX = "$to";



interface IQueryOpItem {
    value:FilterOp,
    label:string
}

function isValueOperator(op:FilterOp){
    return op == "=" || op == ">" || op == ">=" || op == "<" || op == "<=" || op == "begins" || op == "contains" || op == "between" || op == "ends"
        || op == "<>";
}

const operatorMap = {
    "=":"is",
    ">":"greater than",
    ">=":"greater or equal",
    "<":"less than",
    "<=":"less or equal",
    "begins":"begins",
    "contains":"contains",
    "ends":"ends",
    "<>":"is not",
    "between":"between"
}

function getLabel(op:FilterOp):string {
    return operatorMap[op];
}

function add(list:QueryOpList,name:FilterOp){
    list.push({value:name,label:getLabel(name)});
}

function getList(fieldType:FieldType):QueryOpList {
    let list:QueryOpList = [];
    if (fieldType == "character"){
        add(list,"=");
        add(list,"begins");
        add(list,"contains");
        add(list,"between");
        add(list,">");
        add(list,">=");
        add(list,"<");
        add(list,"<=");
    }
    else if (fieldType == "decimal" || fieldType == "integer" || fieldType == "money"){
        add(list,"=");
        add(list,"between");
        add(list,">");
        add(list,">=");
        add(list,"<");
        add(list,"<=");
    }
    else if (fieldType == "date" || fieldType == "datetime"){
        add(list,"=");
        add(list,"between");
        add(list,">");
        add(list,">=");
        add(list,"<");
        add(list,"<=");
    }
    return list;
}

type QueryOpList = IQueryOpItem[];

export class FilterBox extends React.Component<DisplayProps & {operator?:FieldRef,upperBound?:FieldRef,width?:any},{popup:string}>{

    private operatorRef:React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            popup:null
        };
    }

    render(){
        let field = this.props.field;
        let opField = this.getOpField();
        let display:DisplayProps = {...this.props,hideLabel:true};
        if (!opField){
            return <div className="RT-FilterBox">
                <label>{field.label}</label>
                <div className="RT-FilterBox__content">
                    <Display {...display}/>
                </div>
            </div>
        }
        let label = this.props.label || field.label;
       

        if (field.type == "logical"){
            return <LogicalFilter field={field} label={label}/>
        }
        let op:FilterOp = opField.value;
        if (!op){
            op = "=";
        }
        let fromInput;
        if (isValueOperator(op)){
            fromInput = <Display {...display}/>
        }
        
        let opDesc:any;
        let opClass = "operator";

        
       
        if (opField.options){
            opDesc = opField.formattedValue;
        }
        else {
            opDesc = getLabel(op);
        }
        if (!isValueOperator(op) && typeof opDesc == "string"){
            let i = opDesc.indexOf(' ');
            if (i != -1){
                opDesc = <>{opDesc.substr(0,i)}<span style={{fontWeight:500,fontSize:"14px",color:"#000",marginLeft:8}}>{opDesc.substr(i)}</span></>
            }
        }
        let value = field.value;
        let hasValue:boolean;
        if (value || value === 0){
           hasValue = true;
        }
        let upperBoundInput;
        if (op == "between"){
            let upperBoundField = this.getUpperBoundField();
            if (upperBoundField){
                let upperBoundProps:DisplayProps = {...this.props,hideLabel:true,field:upperBoundField};
                upperBoundInput = <>
                    <div className={opClass + ' operator--and'}>and</div>
                    <Display {...upperBoundProps} />
                </>
                let upperBoundValue = upperBoundField.value;
                if (upperBoundValue || upperBoundValue === 0){
                    hasValue = true;
                }
            }
            
        }
        let labelElem = <label>{label}</label>;
    
        let operator=<div className={opClass}  ref={this.operatorRef} onClick={this.handleOperatorClick}>{opDesc}</div>
        let popup = this.renderPopup();
        let containerClass = "RT-FilterBox";
        /*
        if (hasValue){
            containerClass += " has-value";
        }
        */
        return <>
            <div className={containerClass}>
                {labelElem}
                <div className="RT-FilterBox__content">
                    {operator}
                    {fromInput}
                    {upperBoundInput}
                </div>
            </div>
            {popup}
        </>
    }

    getOpField():FieldRef {
        let opField = this.props.operator;
        
        if (!opField){
            let field = this.props.field;
            opField = new FieldRef(field.canvas,null,field.record,field.name + OPERATOR_SUFFIX);
            let options = getList("character");
            RecordMeta.setOptions(opField.meta,opField.name,options);
        }
    
        return opField;
    }

    getUpperBoundField():FieldRef {
        let upperField = this.props.upperBound;
        
        if (!upperField){
            let field = this.props.field;
            upperField = new FieldRef(field.canvas,null,field.record,field.name + RANGE_END_SUFFIX);
        }
        
        return upperField;
    }

    renderPopup(){
        if (this.state.popup == "operator"){
            let opField = this.getOpField();
            if (opField.options && opField.options.length){
                let type = this.props.field.type || "character";
                let content = <OperatorPopup fieldType={type} operatorField={opField} close={this.handleChooseOperator}/>
                return this.renderPopupContent(content);
            }
        }
        return null;
    }

    handleChooseOperator = (options:{operator:FilterOp}) => {
        let field = this.props.field;
    
        let opField = this.getOpField();
        
        Focus.focus(field.canvas,field);
        opField.setValue(options.operator);
        this.setState({popup:null});
    }

    renderPopupContent(dropContent:any){
        let anchorRef = this.operatorRef;
        return (
            <Popup
                attachedRef={anchorRef.current}
                onForceClose={this.handleForcePopupClose}
                zIndex={20000}
                render={() => dropContent}
                anchorPoint={AnchorPoint.BottomAlignLeft}

            />
        )
    }

    handleForcePopupClose = () => {
        this.setState({popup:null});
    }

    handleOperatorClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            popup:"operator"
        })
    }
}


class OperatorPopup extends React.Component<{fieldType:FieldType,operatorField:FieldRef,close:({operator:any})=>void}> {
    render(){
        let options = [];
        if (this.props.operatorField){
            let opField = this.props.operatorField;
            let fieldOptions = opField.options;
            if (fieldOptions){
                for(let i = 0; i < fieldOptions.length;i++){
                    let fo = fieldOptions[i];
                    if (fo.divider){
                        options.push(<div className="Operator-List__divider"/>);
                    }
                    else {
                        let label:any = fo.label;
                        if (label.substr(0,3) == "is "){
                            label = <span>is <span style={{fontWeight:500}}>{label.substr(3)}</span></span>
                        }
                        options.push(<OperatorChoice label={label} key={fo.value} value={fo.value} onChoose={this.handleChoose}/>)
                    }
                }
            }
        }
        else {
            let list = getList(this.props.fieldType);
            for(let i = 0; i < list.length;i++){
                let item = list[i];
                options.push(<OperatorChoice label={item.label} key={item.value} value={item.value} onChoose={this.handleChoose}/>)
            }
        }
        return <div className="Operator-List">
            {options}
        </div>
    }	

    handleChoose = (value:string) => {
        this.props.close({operator:value});
    }
}

class OperatorChoice extends React.Component<{label:string,value:string,onChoose:(value:string)=> void}>{
    render(){
        return <div className="Operator-List__item"><a ref='#' onClick={this.handleClick}>{this.props.label}</a></div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onChoose(this.props.value);
    }
}


class LogicalFilter extends React.Component<{label:string,field:FieldRef}>{
    render(){
        let value:boolean = this.props.field.value;
        let active:string;
        if (value === true){
            active = "yes";
        }
        else if (value === false){
            active = "no";
        }
        else {
            active = "any";
        }
        return <div className="Logical-Filter">
            <label>{this.props.label}</label>
            <div className="Logical-Filter__content">
                <LogicalFilterChoice name="any" label="any" active={active == "any"} onClick={this.handleClick} />
                <LogicalFilterChoice name="yes" label="yes" active={active == "yes"} onClick={this.handleClick} />
                <LogicalFilterChoice name="no" label="no" active={active == "no"} onClick={this.handleClick} />
            </div>
        </div>
    }

    handleClick = (name:string) => {
        let value:boolean;
        if (name=="yes"){
            value = true;
        }
        else if(name == "no"){
            value = false;
        }
        this.props.field.setValue(value);
    }
}

class LogicalFilterChoice extends React.Component<{name:string,label:string,active:boolean,onClick:(name:string) => void}> {
    render(){
        let className = "Logical-Filter__choice";
        if (this.props.active){
            className += " is-active";
        }
        if (this.props.name == "any"){
            className += " Logical-Filter__choice--any";
        }
        return <div className={className} onClick={this.handleClick}>{this.props.label}</div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}