import { IValidationError,ICanvasError, KIND } from './types';
import {RecordMeta} from './RecordMeta';
import {ScreenRef} from './ActionEvent';
import { Collection } from './Collection';
import { Schema } from './Schema';
import { DynamicScreen } from './ScreenContainer';
import { IfBlockRunner } from './IfBlockRunner';
import {Request} from './Request';
import {FileDownloadManager} from './FileDownloadManager';


type commandHandler = (screen:ScreenRef,elem:any) => void | Promise<any>;
var map:{[name:string]:commandHandler} = {
    "Set":setValue,
    "Trigger":trigger,
    "Activate":activateTab,
    "AddRow":addRow,
    "ClearErrors":clearErrors,
    "Close":close,
    "Dialog":prompt,
    "Disable":disable,
    "Download":download,
    "Droplist":setOptions,
    "Enable":enable,
    "Error":setError,
    "Focus":focus,
    "Format":setFormat,
    "Help":setHelp,
    "Hide":hide,
    "ElseIf":elseIfStatement,
    "Else":elseStatement, 
    "Initialize":initialize,
    "Label":setLabel,
    "LaunchUrl":launchUrl,
    "LoadDropValues":loadDropValues,
    "Locate":locate,
    "Message":message,
    "NotRequire":optional,
    "OpenScreen":openScreen,
    "PatchTable":patchTable,
    "Placeholder":setPlaceholder,
    "RecordChanged":recordChanged,
    "RefreshTemplate":refreshComp,
    "RefreshRow":refreshRow,
    "Remove":removeRow,
    "Required":required,
    "Restart":restart,
    "Stop":stop,
    "Style":setStyle,
    "ShowLayout":showLayout,
    "Title":setTitle,
    "Unhide":unhide,
    "ValueLabel":setLookupLabel,
    "Warning":showWarning,
    "RunScript":runScript
}



export type CommandElem = {$kind:string} & any;

export async function executeCommands(screen:ScreenRef,commands:CommandElem[]){
    if (!commands) return;
    let command:CommandElem;
    try {
        for(let i =0 ; i < commands.length;i++){
            command = commands[i];
            let kind = command.$kind;
            if (kind == KIND.RETURN) {
                if (command.value || command.value === 0){
                    screen.eventOptions.returnValue = screen.getValue(command.value);
                }
                return;
            }
            if (kind == KIND.IF){
                let nextCommandIndex = await IfBlockRunner.scanIf(screen,commands,i);
                i = nextCommandIndex - 1; // deduct 1 because will get incremented at bottom of loop
            }
            else {
                let func = map[kind];
                if (func){
                    let result = func(screen,command);
                    if (result && result.then){
                        await result;
                    }
                }
                else {
                    throw "invalid command";
                }
            }
        }
    }
    catch(e){
        if (e !== "stopped" && !(e instanceof Error && e.message.includes('$$error'))) {
            throw new Error("Error on command: " + command.$kind + " , " + e.toString());
        }
        throw e;
    }
}




function setValue(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    let fieldName = elem.name;
    if (fieldName == "@return-value"){
        screen.eventOptions.returnValue = value;
    }
    else {
        let field = screen.canvas.getFieldRef(elem.name,screen.eventOptions.scope);
        field.setValueNoUpdate(value);
    }
}

async function trigger(screen:ScreenRef,elem:any){
    let name = actionName(screen,elem,"name");
    return screen.triggerAction(name,{noUpdate:true});
}

function activateTab(screen:ScreenRef,elem:any){
    screen.activateTab(safeName(elem.name));
}

function addRow(screen:ScreenRef,elem:any){
    screen.addRow(elem.name,{position:elem.mode});
}

function close(screen:ScreenRef,elem:any){
    let flow = elem.flow;
    let shouldContinue = (flow == "continue");
    let value = exprVal(screen,elem,"returnValue");
    let label = exprVal(screen,elem,"returnLabel");
    screen.close({continue:shouldContinue,value,label});
}

async function runScript(screen:ScreenRef,elem:any){
    let callMethod = elem.call;
    let parameters = elem.parameters;
    let paramValues = screen.canvas.getCallParameters(parameters,screen.eventOptions);
    paramValues["@event-value"] = screen.eventOptions.value;
    await Request.callScript(screen,"batch",callMethod,paramValues);
}


async function prompt(screen:ScreenRef,elem:any){
    return screen.prompt(elem.template);
}

function disable(screen:ScreenRef,elem:any){
    screen.disable(safeName(elem.name),exprVal(screen,elem,"help"));
}

function enable(screen:ScreenRef,elem:any){
    screen.enable(safeName(elem.name));
}   

function setOptions(screen:ScreenRef,elem:any){
    screen.setOptions(elem.name,elem.options);
}

function setError(screen:ScreenRef,elem:any){
    let message = exprVal(screen,elem,"message");
    screen.error(elem.field,message);
}

function focus(screen:ScreenRef,elem:any){
    screen.focus(elem.name);
}

function setFormat(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.format(elem.name,value);
}

function setHelp(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setHelp(elem.name,{text:value});
}

function hide(screen:ScreenRef,elem:any){
    screen.hide(safeName(elem.name));
}   

function setLabel(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setLabel(elem.name,{text:value});
}

async function message(screen:ScreenRef,elem:any):Promise<any>{
    let text = exprVal(screen,elem,"text");
    let component = elem.component;
    let asDialog = elem.asDialog;
    let style = elem.style;
    await screen.message(text,{style,asDialog});
}

function optional(screen:ScreenRef,elem:any){
    screen.optional(elem.name);
}

function required(screen:ScreenRef,elem:any){
    screen.manditory(elem.name);
}

function openScreen(screen:ScreenRef,elem:any){
    let params = {};
    if (elem.params){
        for(let i = 0; i < elem.params.length;i++){
            let child = elem.params[i];
            if (child.$kind == KIND.PARAM_VALUE){
                let propName = child.name;
                if (propName){
                    if (propName[0] == '@'){
                        propName = propName.substr(1);
                    }
                    params[propName] = screen.getValue(child.value);
                }
            }
        }
    }

    let props = {...params,screen:elem.name};
    let content = <DynamicScreen {...props}/>
    if (elem.mode == "dialog"){
        return screen.openDialog(content,{onRecordChanged:elem.onUpdate});
    }
    let browseLabel = exprVal(screen,elem,"browseLabel");
    if (browseLabel){
        screen.browse(content,{onRecordChanged:elem.onUpdate,label:browseLabel});
    }
    else {
        screen.openOnStack(content,{onRecordChanged:elem.onUpdate});
    }
    // todo: navigate
}

function setPlaceholder(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setPlaceholder(elem.name,{text:value});
}

function recordChanged(screen:ScreenRef,elem:any){
    screen.recordChanged(elem.mode,exprVal(screen,elem,"rowid"));
}

function refreshComp(screen:ScreenRef,elem:any){
    throw "not implemented";
}

async function refreshRow(screen:ScreenRef,elem:any){
    let rowid = exprVal(screen,elem,"rowid");
    let newRowsOnBottom = (elem.newRows == "append");
    return screen.refreshRow(elem.name,rowid,{newRowsOnBottom});
}

function removeRow(screen:ScreenRef,elem:any){
    let rowid = exprVal(screen,elem,"rowid");
    screen.removeRow(elem.name,{rowid,mode:elem.mode});
}

function stop(screen:ScreenRef,elem:any){
    throw "stopped";
}

function setStyle(screen:ScreenRef,elem:any){
    let value = elem.value;
    screen.setStyle(elem.name,value);
}

function showLayout(screen:ScreenRef,elem:any){
    let template = templateName(screen,elem,"name");
    screen.showLayout(template);
}

function setTitle(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.canvas.title = value;
}

function unhide(screen:ScreenRef,elem:any){
    screen.unhide(elem.name);
}

function setLookupLabel(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setLookupLabel(elem.name,value);
}

async function showWarning(screen:ScreenRef,elem:any):Promise<any>{
    let message = exprVal(screen,elem,"message");
    let style = elem.style;
    let component = elem.component;
    await screen.showWarning({message,style,template:component});
}

function locate(screen:ScreenRef,elem:any){
    let rowid = exprVal(screen,elem,"rowid");
    screen.locate(elem.name,rowid);
}

function launchUrl(screen:ScreenRef,elem:any){
    var url = exprVal(screen,elem,"value");
    var newWindow = elem.newWindow;
    if (newWindow){
        window.open(url);
    }
    else {
        window.location.assign(url);
    }
}


async function restart(screen:ScreenRef,elem:any){
    await screen.restart();
}

function initialize(screen:ScreenRef,elem:any){
    screen.initRecord(elem.name,{});
}

function clearErrors(screen:ScreenRef,elem:any){
    screen.clearErrors(elem.name);
}

function patchTable(screen:ScreenRef,elem:any){
    let dataName = safeName(elem.dataset.substr(1));
    let data = screen.canvas.data;
    let schema = Schema.getRecordSchema(screen.canvas,elem.dataset);
    if (elem.action == "update-prepend" || elem.action == "update-append"){
        let currentRows:any[] = data[dataName];
        if (!currentRows){
            currentRows= [];
            data[dataName] = currentRows;
            Collection.bindSchema(schema,currentRows);
        }
        let newRows = elem.data.rows;
        for(let i = 0; i < newRows.length;i++){
            let row = newRows[i];
            if (row){
                let rowIndex = findRowIndex(currentRows,row.Id);
                if (rowIndex == -1){
                    if (elem.action == "update-append"){
                        currentRows.push(row);
                    }
                    else {
                        currentRows.unshift(row);
                    }
                }
                else {
                    currentRows.splice(rowIndex,1,row);
                }
            }
        }
        Collection.incrementVersion(currentRows);
    }
    else if (elem.action == "find"){
        data[dataName] =  RecordMeta.initalizeRecord(schema,elem.data.rows[0]);
    }
    else {
        let collection = elem.data.rows;
        data[dataName] = Collection.replace(data[dataName],collection,schema);
        Collection.bindSchema(schema,collection);
        if (collection.length){
            Collection.setCurrentRow(screen.canvas,schema.name,collection[0]);
        }
        else {
            Collection.setCurrentRow(screen.canvas,schema.name,null);
        }
    }
    
    if (elem.forEdit){
        screen.unlockRecord(elem.dataset);
    }
    else {
        screen.lockRecord(elem.dataset);
    }
}

function loadDropValues(screen:ScreenRef,elem:any){
    let name = elem.name;
    let options = elem.data || [];

    let defaultOptionExists = options.some(option => option.value === null);
    if (!defaultOptionExists) {
        options.unshift({ value: null, label: "-- Select -- " });
    }
    
    let isConfigure = true;
    if (isConfigure){
        let fieldDef = Schema.getQualifiedFieldDef(screen.canvas,name);
        if (fieldDef){
            fieldDef.options = options;
        }
        return;
    }
    screen.setOptions(name,options);
}

function download(screen:ScreenRef,elem:any){
    let autoOpen = elem.autoOpen;
    let content = elem.content;
    let filename =elem.filename;
    FileDownloadManager.downloadFile(filename,content,autoOpen);
}

function safeName(value:string):string {
    return value; // value.replace(/\-/g,"_");
}

function exprVal(screen:ScreenRef,elem:any,propName:string):any {
    let value = elem[propName];
    if (elem.$computed) return value;
    if (value){
        if (value[0] == '"'){
            return JSON.parse(value);
        }
    }
    return screen.getValue(value);
}

function numVal(screen:ScreenRef,elem:any,propName:string):number {
    let value = elem[propName];
    return value;
}

function boolVal(screen:ScreenRef,elem:any,propName:string):boolean {
    let value = elem[propName];
    return (value) ? true : false;
}


function actionName(screen:ScreenRef,elem,propName:string):string {
    let value:string = elem[propName];
    return safeName(value);
}

function templateName(screen:ScreenRef,elem:any,propName:string):string {
    let value:string = elem[propName];
    return safeName(value);
}

function findRowIndex(rows:any[],id:any):number {
    if (!rows) return -1;
    for(let i =0 ; i < rows.length;i++){
        let row = rows[i];
        if (row.Id == id) return i;
    }
    return -1;
}

function elseStatement(screen:ScreenRef,elem:any){
    // else must be part of IF block so if we encounter one in the wild it is an error
    throw "Unexpected ELSE statement";
}

function elseIfStatement(screen:ScreenRef,elem:any){
    // else if must be part of IF block so if we encounter one in the wild it is an error
    throw "Unexpected ELSE IF statement";
}


export function processErrors(screen:ScreenRef,commands:any[]):ICanvasError{
    let showDialog:boolean;
    if (!commands) return null;
    let validationErrors:IValidationError[] = [];
    let canvas = screen.canvas;
    for(let i = 0; i < commands.length;i++){
        let command = commands[i];
        if (command.$kind == "Error"){
            let fieldName = command.field;
            if (!fieldName || !command.noDialog){
                showDialog = true;
            }
            if (fieldName){
                let fieldDef = Schema.getQualifiedFieldDef(canvas,fieldName);
                let label:string;
                if (fieldDef){
                    label = fieldDef.label || fieldName;
                }
                else {
                    label = fieldName;
                }
                validationErrors.push({field:fieldName,label,message:command.message,type:command.type});
            }
            else {
                validationErrors.push({message:command.message,type:command.type});
            }
        }
    }
    if (!validationErrors.length) return null;

    return {
        showDialog,
        messages:['Error'],
        validationErrors,
        $$error:true
    }
}