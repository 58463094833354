import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {} from 'ktm-ui';

export default KTMScreen({name:"planadmin.employee.database"}, class extends React.Component<{}> {

    data:{
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        await KTMApi.start(screen,{
        });
    }

});
