import App from "App";
import { Application, DynamicScreen } from "ktm-ui/core";
import React from "react";
import { StudioLaunch } from "./StudioLaunch";

export class Navigator extends React.Component<{
  appName: string;
  domain: string;
  screen: string;
  branch?: string;
  screenProps: any;
  history: any;
}> {
  ready = false;

  async componentDidMount() {
    let app = Application.instance;
    app.navigator = this;
    app.domain = this.props.domain;
    app.appName = this.props.appName;
    app.branch = this.props.branch;
    window.addEventListener("message", this.handleMessage);
    await this.start();
    this.ready = true;
    this.forceUpdate();
  }

  componentWillUnmount() {
    Application.instance.navigator = null;
    window.removeEventListener("message", this.handleMessage);
  }
  render() {
    if (!this.ready) return null;

    let app = Application.instance;
    let screen = this.props.screen;
    let content;
    if (screen) {
      let Screen = Application.screens[screen];
      if (Screen) {
        content = <Screen {...this.props.screenProps} />;
      } else {
        content = <DynamicScreen {...this.props.screenProps} screen={screen} />;
      }
    }
    let studioLaunch;
    if (app.studioSettings && app.studioSettings.enabled) {
      studioLaunch = <StudioLaunch />;
    }
    return (
      <>
        {content}
        {studioLaunch}
      </>
    );
  }

  handleMessage = (event) => {
    let data = event.data;
    let action = data.action;
    if (action == "studio.ready") {
      Application.instance.studioOpen = true;
      let activeScreen = Application.instance.activeScreens[0];
      if (activeScreen && activeScreen.canvas.screenId) {
        Application.instance.studioWindow.postMessage(
          {
            action: "showScreen",
            screen: activeScreen.canvas.launchParams.screen,
          },
          "*"
        );
      }
    } else if ((action = "screen.reload")) {
      let screen = data.screen;
      Application.instance.reloadScreen(screen);
    }
  };

  navigate = (url: string) => {
    this.props.history.push(url);
  };

  async start() {
    let app = Application.instance;
    let sessionResult = await app.session.signin(app);

    let config = sessionResult.config;
    if (config) {
      let components = config.components;
      for (let i = 0; i < components.length; i++) {
        let component = components[i];
        let componentClass = Application.components[component.name] as any;
        if (componentClass) {
          componentClass.$def = component;
        }
      }
    }

    if (!this.props.screen && sessionResult.homeScreen){
        app.navigate(sessionResult.homeScreen,null);
    }
  }
}
