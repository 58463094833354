import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card} from 'ktm-ui';

export default KTMScreen({name:"planadmin.test.create-claim"}, class extends React.Component<{benefit_id:string,employee_id:string}> {

    data:{
        accountType:string;
        imageCount:number;
        claimNo:string;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        await KTMApi.start(screen,{
            "@benefit_id":this.props.benefit_id,
            "@employee_id":this.props.employee_id,
        });
        screen.title = "Create claim";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.body)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Create employee claim">
            </Dialog.Header>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Create" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    body({field,draw,action}:RenderEvent){
        return (
            <Card label="Claim information">
                <Line>
                    <Display field={field("@accountType")}/>
                    <Display field={field("@imageCount")}/>
                </Line>
            </Card>
        );
    }

    async submit({screen}:ActionEvent){
        await KTMApi.action(screen,"submit",{
            "@benefit_id":this.props.benefit_id,
            "@employee_id":this.props.employee_id,
            "@accountType":this.data.accountType,
            "@imageCount":this.data.imageCount,
            "@claimNo":this.data.claimNo,
        });
        screen.close({"continue":true});
    }

});
