import React from "react";
import {
  KTMScreen,
  ActionEvent,
  RenderEvent,
  renderScreen,
  Layout,
} from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import { Column, Panel, Display, Line, Card } from "ktm-ui";
import CustomToolTip from "../../components/CustomToolTip";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  Employee_Id: string;
  PolicyBenefit_Id: string;
  BenefitCategory_Id: string;
  Coverage: string;
  BenefitYear_Id: string;
  HasProrating: boolean;
}

export default KTMScreen(
  { name: "enroll.employee-benefits.add" },
  class extends React.Component<{ employee_id: string }> {
    data: {
      dsBenefit: IdsBenefit;
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@employee_id": this.props.employee_id,
      });
    }

    main({ field, draw, action }: RenderEvent) {
      return <Layout>{draw(this.body)}</Layout>;
    }

    body({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>{draw(this.details)}</Column>
        </Panel>
      );
    }

    details({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display field={field("BenefitYear_Id")} span="4" />
            <Display field={field("PolicyBenefit_Id")} />
          </Line>
          <Line>
            <Display field={field("BenefitCategory_Id")} />
            <Display field={field("Coverage")} />
            <Display
              field={field("HasProrating")}
              toolTip={
                <CustomToolTip
                  header="Pro-rating"
                  bodyContent="If checked, pro-rating is ON and an employee’s available credits are adjusted based on the months remaining in the benefit year. If unchecked, an employee receives their full yearly limit."
                />
              }
            />
          </Line>
        </Card>
      );
    }

    async submit({ screen }: ActionEvent) {
      await KTMApi.action(screen, "submit", {
        "@employee_id": this.props.employee_id,
        "@dsBenefit.PolicyBenefit_Id": this.data.dsBenefit.PolicyBenefit_Id,
        "@dsBenefit.BenefitCategory_Id": this.data.dsBenefit.BenefitCategory_Id,
        "@dsBenefit.Coverage": this.data.dsBenefit.Coverage,
        "@dsBenefit.BenefitYear_Id": this.data.dsBenefit.BenefitYear_Id,
        "@dsBenefit.HasProrating": this.data.dsBenefit.HasProrating,
      });
      screen.close({ continue: true });
    }
  }
);
