import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import toastr from "toastr";
import FlexDatesCard from "../../components/FlexDatesCard";
import "../../styles/Campaign.css";

interface NewCampaignModalProps {
    showNewCampaignModal: boolean;
    closeModal: () => void;
    refreshCampaigns: () => void;
    policy_id: string;
    benefitEndDate: string | null;
}

const NewCampaignModal: React.FC<NewCampaignModalProps> = ({
    showNewCampaignModal,
    closeModal,
    refreshCampaigns,
    policy_id,
    benefitEndDate,
}) => {
    const [SelectionDeadline, setSelectionDeadline] = useState<Date | null>(null);
    const [LaunchDate, setLaunchDate] = useState<Date | null>(null);
    const [ReminderDate, setReminderDate] = useState<Date | null>(null);
    const [LastDayReminder, setLastDayReminder] = useState<Date | null>(null);

    useEffect(() => {
        if (benefitEndDate) {
            const benefitEndDateObj = new Date(benefitEndDate);

            const defaultLaunchDate = new Date(benefitEndDateObj);
            defaultLaunchDate.setMonth(defaultLaunchDate.getMonth() - 1);

            const defaultReminderDate = new Date(benefitEndDateObj);
            defaultReminderDate.setDate(defaultReminderDate.getDate() - 14);

            const defaultLastDayReminder = new Date(benefitEndDateObj);
            defaultLastDayReminder.setDate(defaultLastDayReminder.getDate() - 1);

            setLaunchDate(defaultLaunchDate);
            setReminderDate(defaultReminderDate);
            setLastDayReminder(defaultLastDayReminder);
            setSelectionDeadline(benefitEndDateObj);
        }
    }, [benefitEndDate]);

    const handleStartCampaign = async () => {
        let selectionDeadline = new Date(SelectionDeadline);
        let launchDate = new Date(LaunchDate);
        let reminderDate = new Date(ReminderDate);
        let lastDayReminder = new Date(LastDayReminder);
        let benefitEndDateObj = new Date(benefitEndDate);
        let today = new Date();

        if (launchDate <= today) {
            toastr.error("Launch date must be after today", "Error");
            return;
        }

        if (selectionDeadline > benefitEndDateObj || launchDate > benefitEndDateObj ||
            reminderDate > benefitEndDateObj || lastDayReminder > benefitEndDateObj) {
            toastr.error("Dates cannot be after the benefit year end date", "Error");
            return;
        }

        if (launchDate >= selectionDeadline || reminderDate >= selectionDeadline ||
            lastDayReminder >= selectionDeadline) {
            toastr.error("Dates must be before the selection deadline", "Error");
            return;
        }

        if (launchDate >= reminderDate) {
            toastr.error("Launch date must be before the reminder date", "Error");
            return;
        }

        if (reminderDate >= lastDayReminder) {
            toastr.error("Reminder date must be before the last day reminder", "Error");
            return;
        }

        const data = {
            SelectionDeadline: SelectionDeadline?.toLocaleDateString(),
            LaunchDate: LaunchDate?.toLocaleDateString(),
            ReminderDate: ReminderDate?.toLocaleDateString(),
            LastDayReminder: LastDayReminder?.toLocaleDateString(),
        };

        const body = {
            PolicyId: policy_id,
            SelectionDeadline: data.SelectionDeadline,
            LaunchDate: data.LaunchDate,
            ReminderDate: data.ReminderDate,
            LastDayReminder: data.LastDayReminder,
        };

        try {
            await axios.post("/api/Flex/addpolicycampaign", body);
            refreshCampaigns();
            closeModal();
            toastr.success("Campaign started successfully");
        } catch (error) {
            toastr.error("Failed to start campaign");
            console.error(error);
        }
    };

    const handleDateChange = (date: Date, type: string) => {
        switch (type) {
            case "SelectionDeadline":
                setSelectionDeadline(date);
                break;
            case "LaunchDate":
                setLaunchDate(date);
                break;
            case "ReminderDate":
                setReminderDate(date);
                break;
            case "LastDayReminder":
                setLastDayReminder(date);
                break;
            default:
                break;
        }
    };

    const isAnyDateNull = !SelectionDeadline || !LaunchDate || !ReminderDate || !LastDayReminder;

    return (
        <Modal
            className="react-modal edit-campaign-modal"
            show={showNewCampaignModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title className="modal-title">New Flex Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FlexDatesCard
                    handleDateChange={handleDateChange}
                    LaunchDate={LaunchDate}
                    ReminderDate={ReminderDate}
                    LastDayReminder={LastDayReminder}
                    SelectionDeadline={SelectionDeadline}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleStartCampaign} disabled={isAnyDateNull}>
                    Start Campaign
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewCampaignModal;
