import React from 'react';
import { makeComponent, Template ,RenderEvent,RecordMeta, ActionRef, TemplateRef} from '../core';

export const TabSet = makeComponent(class extends React.Component<{event?:RenderEvent, vertical?:boolean},{activeTab:string,stackContent:any}>{
    constructor(props){
        super(props);
        let activeTab:string;
        let event = this.props.event;
        if (event){
            let templateState = event.getTemplateState();
            if (templateState){
                activeTab = templateState.activeTab;
            }
        }
        this.state = {activeTab,stackContent:null};
    }

    
    render(){
        let panels = [];
        let tabs = [];
        let children = this.props.children;
        let activeTab = this.state.activeTab;
        let event = this.props.event;
        if (!event) return null;
        let canvas = event.canvas;
        if (children) {
            let found = false;
            React.Children.forEach(children,(child:any,index:number) => {
                let isActive:boolean;
                if (child.props && child.props.template){
                    let template:TemplateRef = canvas.getTemplateRef(child.props.template);
                    
                    if (!RecordMeta.isTemplateHidden(canvas,template.name)){
                        let tabName = child.props.name || "template:" + template.name;
                        let key= tabName;
                        if (!activeTab){
                            if (!found){
                                isActive = true;
                                found = true;
                            }
                        }
                        else {
                            isActive = (tabName == activeTab);
                        }
                        tabs.push(React.cloneElement(child,{key,$onClick:this.handleTabClick,$isActive:isActive,name:tabName}));
                        // if tab bound to template then 
                        panels.push(<TabPanelContainer key={key} isActive={isActive} template={template.render} event={event}/>);
                        // add container for current content if active tab does not have template
                    }            
                }
                else if (child.props && child.props.name){
                    let tabName = child.props.name;
                    isActive = (tabName == activeTab);
                    tabs.push(React.cloneElement(child,{key:tabName,$onClick:this.handleTabClick,$isActive:isActive}));
                }
                else {
                    // non tab items
                    tabs.push(React.cloneElement(child,{key:index}));
                }
            });
            if (this.state.stackContent){
                panels.push(<div key="stack-content" className="RT-TabSet__tab-content">
                    {this.state.stackContent}
                </div>);
            }
        }
        if (this.props.vertical){
            return <VerticalTabSet tabs={tabs} panels={panels} scrollable/>
        }
        else {
            return <HorizontalTabSet tabs={tabs} panels={panels} scrollable/>
        }

    }
    
    handleTabClick = (tabName:string,stackContent:any) =>{
        let event = this.props.event;
        if (event){
            event.setTemplateState({activeTab:tabName});
        }
        this.setState({activeTab:tabName,stackContent});
    }
});


export const Tab = makeComponent(class extends React.Component<{name?:string,label:string,template?:Template | string,$isActive?:boolean,onClick?:ActionRef,$onClick?:(name:string,stackContent:any) => void, isHidden?:boolean}>{

    render(){
        let className = ['RT-TabSet__link'];
        if (this.props.$isActive) {
            className.push('active');
        }
        let tabStyle = {};
        if (this.props.isHidden == true){
            tabStyle = {display:'none'};
        }
        return <li className="RT-TabSet__label" style={tabStyle}>
            <a onClick={this.handleClick} className={className.join(' ')} href='#' data-studio-click data-studio-select>{this.props.label}</a>
        </li>;
    }

    handleClick = async (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let stackContent = null;
        if (this.props.onClick){
            let showStackContent = (content:any)=> {
                stackContent = content;
            }
            await this.props.onClick.trigger({showStackContent});
        }
        if (this.props.$onClick){
            this.props.$onClick(this.props.name,stackContent);
        }
     
    }
});


class TabPanelContainer extends React.Component<{ isActive: boolean, template: Template,event:RenderEvent }, { hasBeenActive: boolean }>{
    constructor(props) {
        super(props);
        this.state = {
            hasBeenActive: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isActive && !state.hasBeenActive) {
            return { hasBeenActive: true };
        }
        return null;
    }

    render() {

        let { isActive } = this.props;

        if (isActive || this.state.hasBeenActive) {
            let display: string;

            if (isActive) {
                display = "block";
            }
            else {
                display = "none";
            }
            
            let content = this.props.event.draw(this.props.template);

            return (
                <div style={{ display }} className="RT-TabSet__tab-content">
                    {content}
                </div>
            )
        }
        return null;
    }

}


class HorizontalTabSet extends React.Component<{tabs:any,panels:any,hideLabels?:boolean,scrollable?:boolean,insideDialog?:boolean}>{
    render(){

       

        let className = "RT-TabSet RT-TabSet--horizontal";
        if (this.props.scrollable){
            className += " RT-TabSet--scrollable";
        }
        if (this.props.insideDialog){
            className += " RT-TabSet--inside-dialog";
        }
    
        let tabs;
        if (this.props.tabs.length > 1){
            tabs = <ul className="RT-TabSet__labels--horizontal">
                {this.props.tabs}
            </ul>
        }
        if (this.props.hideLabels){
            className += " RT-TabSet--hide-labels";
        }
        return <div className={className}>
            {tabs}
            {this.props.panels}
        </div>
    
    }
}

export class VerticalTabSet extends React.Component<{tabs:any,panels:any,buttons?:any;scrollable?:boolean}>{
    render(){
        let className = "RT-TabSet RT-TabSet--vertical";
        if (this.props.scrollable){
            className += " RT-TabSet--scrollable";
        }
    
       
        let tabs = <ul className="RT-TabSet__labels--vertical">
            {this.props.tabs}
            {this.props.buttons}
        </ul>
       
        return <div style={{paddingTop:10}} className={className}>
            {tabs}
            {this.props.panels}
        </div>
    }
}