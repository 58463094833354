import { FieldRef, makeComponent } from 'ktm-ui/core';
import React from 'react';
import { DisplayProps } from './Display';


export const RadioButtons = makeComponent(class extends React.Component<DisplayProps & {direction?:'horizontal' | 'vertical'}>{

    public static nextId:number = 0;
    public controlName:string;
    constructor(props){
        super(props);
        this.controlName = "radio" + RadioButtons.nextId++;
    }

    render() {
        let field = this.props.field;
        if (!field){
            return null;
        }
        let optionElements = [];
        let options = field.options;
        let currentValue = field.value;
        let tabIndex;
        if (this.props.noTabStop){
            tabIndex = -1;
        }
        if (options){
            for(var i = 0; i < options.length;i++){
                let option = options[i];
                let isActive;
                /*
                if (!option.value){
                    isActive = !currentValue;
                }
                else {
                    isActive = (currentValue == option.value);
                }
                */
               if (option.value){
                    isActive = (currentValue == option.value);
                    optionElements.push(<RadioOption name={this.controlName} key={i} value={option.value} label={option.label} isActive={isActive} changeValue={this.changeValue} tabIndex={tabIndex} disabled={option.value == "ADD"?true:false}/>);
               }
            }
        }
        let label;
        let hideLabel = this.props.hideLabel;
        if (!hideLabel){
            label = <label>{field.label}</label>
        }
        let cls = "RT-Radio";
        if (this.props.direction == "horizontal"){
            cls += " RT-Radio--horizontal";
        }
        return <div className={cls}>
            {label}
            <div className="RT-Radio__items">{optionElements}</div>
        </div>
    };

    changeValue = value => {
        this.props.field.setValue(value);
    }
});


class RadioOption extends React.Component<{name:string;value:string,label:string,changeValue:any,isActive:boolean,tabIndex:number, disabled:boolean}> {

    render(){
        let checked = this.props.isActive as any;
        let checkedCls = "";
        let disabledStyle;
        if (checked){
            checkedCls = " RT-Radio--checked";
        }
        if (this.props.disabled){
            disabledStyle = {opacity:0.5};
        }
        return (
            <div className="RT-Radio__item" style={disabledStyle}>
                <div>
                    <label className="RT-Radio__choice">
                        <span className="RT-Radio__control">
                            <span className="RT-Radio__button">
                                <input type="radio" name={this.props.name} className="RT-Radio__button-input" tabIndex={this.props.tabIndex} value={this.props.value} checked={checked} onChange={this.handleChange} disabled={this.props.disabled}/>
                                <span className={"RT-Radio__backdrop " + checkedCls}/>
                                <span className={'RT-Radio__icon' + checkedCls}/>
                                
                            </span>
                        </span>
                        <span className="RT-Radio__label">{this.props.label}</span>
                    </label>
                    <div className="RT-Radio__descriptions"></div>
                </div>
            </div>
        )
    }

    handleClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.changeValue(this.props.value);
    }

    handleChange = (e:React.ChangeEvent) => {
        this.props.changeValue(this.props.value);
    }
}