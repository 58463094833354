 import React from 'react';
import { FieldRef, makeComponent, makeInput } from '../../core';
import { DisplayProps } from '../Display';
import { FieldError } from './FieldError';
import { FieldLabel } from './FieldLabel';
import { InputControl } from '../../core';
 
 export const Select = makeInput(class extends React.Component<DisplayProps>{
    
    componentDidMount(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus) {
            input.focusElemRef.current.focus();
        }
    }

    componentDidUpdate(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus){
            input.focusElemRef.current.focus();
        }
    }


    render(){
        let input = this.props.input;
       
        let field = input.field;
        
        let containerClass = ["RT-TextBox"];
        let inputClassName = ["RT-TextBox__select"];

        let helpElem;
        if (this.props.help) {
            helpElem = <small className="form-text text-muted">{this.props.help}</small>;
        }
        let errorElem;

        if (field.error) {
            containerClass.push("RT-TextBox--has-error");
            errorElem = <FieldError input={input}/>
        }


        let optionElems = [];
        if ((field.name === "CarryFwdHSA" || field.name === "CarryFwdLSA") && field.options ){
            optionElems = this.replaceSelectWith("No Carry Forward", field);
        }
        else if (field.name === "Dependent_Id" && field.options){
            optionElems = this.replaceSelectWith("Employee", field);
        }
        else if (field.options) {
            for (let i = 0; i < field.options.length; i++) {
                let opt = field.options[i];
                optionElems.push(<option key={i} value={opt.value}>{opt.label}</option>);
            }
        }

        let labelElem;
        let label = this.props.label;
        if (label) {
            labelElem = <FieldLabel label={label}  required={this.props.required} toolTip={this.props.toolTip}/>
        }

        let styleClass = this.getStyleClass(field);
        if (styleClass) {
            inputClassName.push(styleClass);
        }
        let inputStyle:React.CSSProperties = {};
        /*
        if (width){
            inputStyle.width = this.width;
        }
        */
    
        let value:string;
        if (input.type == "integer" || input.type == "decimal" || input.type == "money"){
            let v = field.value;
            if (v === undefined || v === null){
                value = "";
            }
            else {
                value = v.toString();
            }
        }
        else {
            value = field.value || "";
        }
        return (
            <div className={containerClass.join(" ")}>
                {labelElem}
                <select
                    ref={input.focusElemRef as any}
                    className={inputClassName.join(' ')}
                    style={inputStyle}
                    onChange={this.handleChange}
                    disabled={field.disabled || this.props.disabled}
                    value={value}
                    tabIndex={this.props.noTabStop ? -1 : null}
                >
                    {optionElems}
                </select>
                {errorElem}
            </div>
        );
    }

    handleChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        let value = e.target.value;
        this.props.input.setValue(value);
    }

    getStyleClass(field:FieldRef): string {

        if (field.style) {
            switch (field.style) {
                case "danger": return "alert-danger";
                case "warning": return "alert-warning";
                case "info": return "alert-info";
                case "success": return "alert-success";
            }
        }
    }

    replaceSelectWith(newOpt:String, field:FieldRef): any[] {
        let elements = []
        let optionIsInserted = false;
        for (let i = 0; i < field.options.length; i++) {
            let opt = field.options[i];
            if(opt.value === null) {
                if(optionIsInserted === false){
                    elements.push(<option key={i} value={opt.value}>{newOpt}</option>);
                    optionIsInserted = true;
                }
            }
            else
            elements.push(<option key={i} value={opt.value}>{opt.label}</option>);
        }
        return elements;
    }
});