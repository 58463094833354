import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';

interface IdsEmployee {
    Id:string;
    FirstName:string;
    LastName:string;
    MailingStreet1:string;
    MailingStreet2:string;
    MailingCity:string;
    MailingProv:string;
    MailingCountry:string;
    MailingPostalCode:string;
    HomePhone:string;
    MobilePhone:string;
    UpdatedBy:string;
};

export default KTMScreen({name:"enroll.employee.edit-address"}, class extends React.Component<{employee_id:string}> {
    data:{
        dsEmployee:IdsEmployee;
    }

    state = {
        homePhone: null,
        mobilePhone: null,
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsEmployee";
        await KTMApi.start(screen,{
            "@employee_id":this.props.employee_id,
        });
        screen.title = "Edit contact details";
        this.setState({homePhone: this.data.dsEmployee.HomePhone});
        this.setState({mobilePhone: this.data.dsEmployee.MobilePhone});
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Edit contact details"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.details)}
                </Column>
            </Panel>
        );
    }

    formattedInput(input){
        const areaCode = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 10);

        let formatted = "";
        if (input.length > 6) {
            formatted = `(${areaCode})${middle}-${last}`;
        } else if (input.length > 3) {
            formatted = `(${areaCode})${middle}`;
        } else if (input.length > 0) {
            formatted = `(${areaCode}`;
        }
        return formatted;
    }

    handleChange(e, name) {
        let input = e.target.value.replace(/\D/g, '');
        e.target.value =  this.formattedInput(input);
        this.setState({[name]: e.target.value});
    }

    details({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Display field={field("MailingStreet1")} hideLabel={true}/>
                <Display field={field("MailingStreet2")}/>
                <Line>
                    <Display field={field("MailingCity")}/>
                    <Display field={field("MailingProv")}/>
                </Line>
                <Line>
                    <Display field={field("MailingCountry")}/>
                    <Display field={field("MailingPostalCode")}/>
                </Line>
                <Line>
                    <div className="form-group col-md-12">
                        <label>Home Phone</label>
                        <input className="form-control" id="homePhone" name="homePhone"
                               value={this.state.homePhone}
                               onChange={(e) => this.handleChange(e, "homePhone")} placeholder="(000)000-0000" maxLength={14}/>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Mobile Phone</label>
                        <input className="form-control" id="mobilePhone" name="mobilePhone"
                               value={this.state.mobilePhone}
                               onChange={(e) => this.handleChange(e, "mobilePhone")} placeholder="(000)000-0000" maxLength={14}/>
                    </div>
                </Line>
            </Card>
        );
    }

    async submit({screen}: ActionEvent) {
        this.data.dsEmployee.HomePhone = this.state.homePhone;
        this.data.dsEmployee.MobilePhone = this.state.mobilePhone;
        await KTMApi.action(screen,"submit",{
            "@dsEmployee.MailingCity":this.data.dsEmployee.MailingCity,
            "@dsEmployee.HomePhone":this.data.dsEmployee.HomePhone,
            "@dsEmployee.MobilePhone":this.data.dsEmployee.MobilePhone,
            "@dsEmployee.MailingProv":this.data.dsEmployee.MailingProv,
            "@dsEmployee.MailingStreet1":this.data.dsEmployee.MailingStreet1,
            "@dsEmployee.MailingStreet2":this.data.dsEmployee.MailingStreet2,
            "@dsEmployee.MailingCountry":this.data.dsEmployee.MailingCountry,
            "@dsEmployee.MailingPostalCode":this.data.dsEmployee.MailingPostalCode,
            "@employee_id":this.props.employee_id,
        });
        screen.close({"continue":true});
    }

});
