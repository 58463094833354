import { Dialog, DialogResult } from './Dialog';
import { ActionEvent } from './ActionEvent';
import {ActionHandler,IEventOptions,SidebarState, Template,IEventScope, IActionResult, IScriptMethod, IActionDefinition, ITemplateDefinition, IScreenLaunchParams, getTemplateName} from './types';
import { FieldRef } from './FieldRef';
import { Application } from './Application';
import { RecordBrowse } from './RecordBrowse';
import { RecordMeta } from './RecordMeta';
import { ActionRef } from './ActionRef';
import { IRecordSchema, IScreenParameter, Schema } from './Schema';
import { TemplateRef } from './TemplateRef';
import { CollectionRef, ICanvasError } from '.';
import { Collection } from './Collection';
import {executeCommands} from './ActionRunner';
import { Evaluator } from './expressions/Evaluator';


export class Canvas {
    private _data:any;
    private screenComponent:any;
    closeDialogBox:(result:DialogResult) => void;
    updateComponent:() => void;
    initialized:boolean;
    layoutTemplate:string;
    pendingFocusField:FieldRef;
    pendingFocusRow:any;
    layer:'page' | 'stack' | 'dialog' | 'lookup' | 'embedded';
    layerZIndex:number;
    title:string;
    parent:Canvas;
    handleRecordChanged:(action:string,id:any) => Promise<void>;
    app:Application;

    sidebarState:SidebarState;
    stackChild: () => any;
    tabContent:() => any;
    recordBrowse:RecordBrowse;
    defaultRecord:string;
    enterKeyAction:string;
    schema:IRecordSchema;
    $$meta:RecordMeta;
    launchParams:IScreenLaunchParams;
    screenId:string;
    scriptMethods: {[name:string]:IScriptMethod}
    actions:{[name:string]:IActionDefinition};
    templates:{[name:string]:ITemplateDefinition};
    templateState:{[name:string]:any};
    
    constructor(parent:Canvas){
        this.parent = parent;
        if (parent){
            this.app = parent.app
        }
        else {
            this.app = Application.instance;
        }
        this._data = {$$meta:new RecordMeta()};
        this.$$meta = new RecordMeta();
        this.templates = {};
        this.sidebarState = new SidebarState();
        this.templateState = {};
    }

    setSchema(schema:IRecordSchema){
        this.schema = schema;
        let meta = RecordMeta.forRecord(this._data);
        meta.schema = schema;
    }

    attachToScreen(screenComponent:any){
        this.screenComponent = screenComponent;
        screenComponent.data = this._data;
    }

    resetData(){
        this._data = {$$meta:new RecordMeta()};
        this.$$meta = new RecordMeta();
        this.screenComponent.data = this._data;
        this.initialized = false;
    }

    setData(data:any){
        if (data){
            for(let key in data){
                this._data[key] = data[key];
            }
        }
    }

    update(){
        if (this.updateComponent){
            this.updateComponent();
        }
    }

    setRecordValueNoUpdate(field:FieldRef,value){
        if (field.record){
            let name = field.name;
            if (name[0] == '@'){
                field.record[name.substr(1)] = value;
            }
            else {
                field.record[name] = value;
            }
        }
    }
    
    async setValue(field:FieldRef,value:any,onValueChanged?:ActionRef){
        if (field.record){
            let name = field.name;
            if (name[0] == '@'){
                field.record[name.substr(1)] = value;
            }
            else {
                field.record[name] = value;
            }
        }
        if (onValueChanged){
            field.clearError();
            await onValueChanged.trigger({value});
        }
        else if (field.def && field.def.onValueChanged){
            let actionRef = new ActionRef(this,field.def.onValueChanged,field.scope);
            field.clearError();
            await actionRef.trigger({value});
        }
        this.update();
    }

    closeStack(){
        this.stackChild = null;
        this.update();
    }

    
    async recordChanged(action:string,id:any){
        if (this.handleRecordChanged){
            await this.handleRecordChanged(action,id);
            if (this.parent){
                await this.parent.recordChanged(action,id);
            }
        }
    }

    async triggerAction(actionRef:ActionRef,options:IEventOptions = {}):Promise<IActionResult>{
        if (!actionRef) return;
        var event = new ActionEvent(this,options);
        event.options.actionName = actionRef.name;
        let action = this.getActionHandler(actionRef.name);
        return this.executeActionHandler(actionRef.name,action,options);
    }

    async executeActionHandler(actionName:string,action:ActionHandler,options:IEventOptions = {}):Promise<IActionResult>{
        if (!action) return;
        var event = new ActionEvent(this,options);
        event.options.actionName = actionName;
       
        this.app.spinner.show();
        
        try {
            await action.call(this.screenComponent,event);
            this.app.spinner.hide();
        }
        catch(err){
            this.app.spinner.kill();
            this.update();
            if (err != "stopped"){
                let error:ICanvasError;

                error = {
                    messages:[err.toString()],
                    $$error:true
                }
                
                if (options.title){
                    error.validationErrors = error.validationErrors || [];
                    error.validationErrors.push({message:options.title});
                }
                let errorDialog = this.app.renderError(error);
                Dialog.open(this,errorDialog,null,"dialog");
            }
            throw "stopped";
        }
        if (!options.noUpdate){
            this.update();
        }
        return {continue:true};
    }

    handleEnterKey(scope:IEventScope){
        let actionName = (scope && scope.enterKeyAction) ? scope.enterKeyAction : this.enterKeyAction;
        if (!actionName) return;
        let actionRef = new ActionRef(this,actionName,null);
        this.triggerAction(actionRef);
    }
   
    getRecord(name:string,scope:any):any {
        if (!name) return null;
        if (scope){
            let record = scope[name];
            if (record) return record;
        }
        return this._data[name.substr(1)];
    }

    setRecord(name:string,value:any){
        if (!name) return null;
        this._data[name.substr(1)] = value;
    }
    
    getFieldRef(field:string,scope:any):FieldRef {
        let record:any;
        if (field){
            let recordName:string;
            if (field[0] == '@'){

                if (field.indexOf('.') != -1){
                    let [target,f] = field.split('.');
                    recordName = target;
                    field = f;
                }
                else {
                    field = field;
                }
                if (recordName){
                    let i = recordName.indexOf('[');
                    if (i != -1){
                        let indexer = parseInt(recordName.substring(i + 1,recordName.length - 1),10);
                        recordName = recordName.substr(0,i);
                        let collection = this._data[recordName.substr(1)];
                        if (collection){
                            return new FieldRef(this,scope,collection[indexer],field);
                        }
                        // invalid collection
                        return new FieldRef(this,scope,null,field);
                    }
                }
            }
            else if (this.defaultRecord){
                recordName = this.defaultRecord;
            }
            
            if (recordName){
                if (scope){
                    record = scope[recordName];
                    if (!record){
                        record = this._data[recordName.substr(1)];
                    }
                }
                else {
                    record = this._data[recordName.substr(1)];
                }
                if (Array.isArray(record)){
                    record = Collection.getCurrentRow(this,recordName);
                }
            }
            else {
                record = this._data;
            }
        }
        let fieldRef = new FieldRef(this,scope,record,field);
        return fieldRef;
    }

    getCollectionRef(collection:string,scope:any):CollectionRef {
        if (!collection) return null;
        let rows:any[];
        if (collection[0] == "@"){
            rows = this._data[collection.substr(1)];
        }
        let schema = Schema.getRecordSchema(this,collection);
        return new CollectionRef(this,scope,rows,collection,schema);
    }
    expressionCache = {};

    getValue(expr:string,options:IEventOptions):any{
        return Evaluator.evaluate({canvas:this,scope:options.scope,eventValue:options.value,returnValue:options.returnValue},expr);
    }

    /**
     * Returns the class defined method for an action if it exists
     * If a class method does not exist then create a dynamic action handler
     * 
     * @param name      name of the button or action
     * @returns         an Action handler function that can be called to execute
     */
    getActionHandler(name:string):ActionHandler {     
        let methodName = name.replace(/\-/g,'_');
        let actionHandler = this.screenComponent[methodName];
        if (actionHandler) return actionHandler;
        return this.createDynamicActionHandler(name);
    }

    /**
     * Creates an async function to run the statements in a dynamic button/action
     * 
     * @param actionName    name of the button or action 
     * @returns             executable async function
     */
    createDynamicActionHandler(actionName:string):ActionHandler{
        let action = this.actions[actionName];
        if (action){
            return async(event:ActionEvent) => {
                await executeCommands(event.screen,action.statements);
            }
        }
    }

    getTemplateRef(template:string | Template,scope?:any):TemplateRef {
        if (!template) return null;
        let render:Template;
        let name:string;
        let dynamicContent;
        if (typeof template == "string"){
            name = template;
            let methodName = name.replace(/\-/g,'_');
            render = this.screenComponent[methodName];
        }
        else {
            name = getTemplateName(template);
            render = template;
        }
        if (!render){
            let template = this.templates[name];
            if (template && template.draw){
                dynamicContent = [template.draw];
            }
        }
        if (!render && !dynamicContent) return null;

        return {
            name,
            canvas:this,
            scope:scope,
            render,
            dynamicContent
        }
    }

    get actionTarget():any {
        return this.screenComponent;
    }

    get templateTarget():any {
        return this.screenComponent;
    }

    getCallParameters(parameters:any[],eventOptions:IEventOptions):any {
        let params = {};
        for(let i = 0; i < parameters.length;i++){
            let name = parameters[i];
            params[name] = this.getValue(name,eventOptions);
        }
        return params;
    }

    getParamValues(parameters:IScreenParameter[],eventOptions:IEventOptions):any{
        let values:any = {};
        if (!parameters) return values;
        for(let i = 0; i < parameters.length;i++){
            let p = parameters[i];
            let name = p.name;
            if (name[0] == '@'){
                name = name.substr(1);
            }
            values[name] = this.getValue(p.value,eventOptions);
        }
        return values;
    }

    get data():any {
        return this._data;
    }



}



