import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Column,Panel,Display,Line,Card} from 'ktm-ui';
import toastr from "toastr";

interface IdsDependents {
    Id:string;
    Policy_Id:string;
    Employee_Id:string;
    Dependent_Id:string;
    RangeType:string;
    FromDate:string;
    ToDate:string;
    Comment:string;
};

interface IdsClaimExpenses {
    Id:string;
    Dependent_Id:string;
}

export default KTMScreen({name:"enroll.employee.remove-dependent"}, class extends React.Component<{dependent_id:string}> {

    data:{
        dsDependents:IdsDependents;
        dsClaimExpenses:IdsClaimExpenses[];
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsDependents";
        await KTMApi.start(screen,{
            "@dependent_id":this.props.dependent_id,
        });
        screen.title = "Remove dependent";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Remove Dependent"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
       return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Remove" style="destructive"/>
            </Dialog.Footer>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    <div>
                        Are you sure you want to remove this dependent?
                    </div>  
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        if(this.data.dsClaimExpenses.length > 0){
            toastr.error("Dependent cannot be removed as there are claims associated with this dependent.");
            screen.close({"continue":false});
            return;
        }

        await KTMApi.action(screen,"submit",{
            "@dependent_id":this.props.dependent_id,
        });
        screen.close({"continue":true});
    }
});
