import React from 'react';
import {makeComponent} from '../core';

export const DialogHeader = makeComponent(class extends React.Component<{label?:string}>{
    render(){
        let label = this.props.label;
        return (<div className="RT-Action-Dialog__header">
            <div className="RT-Action-Dialog__title">
                {label}
            </div>
        </div>);
    }
    static $section = "header";
});