import FlexSelectionTemplate from './FlexSelectionTemplate';
import SelectionEmailTemplate from './SelectionEmailTemplate';

const FlexTemplates = (props) => {
  return (
    <div style={{width:"87%"}}>
      <SelectionEmailTemplate policyId={props.policyId} />
      <FlexSelectionTemplate policyId={props.policyId} />
    </div>
  );
};

export default FlexTemplates;
