import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen, FilterBox} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Display,Button,Line,Card,DataGrid,IDataGridLayout} from 'ktm-ui';

interface IdsAdvisors {
    Id:string;
    FirstName:string;
    LastName:string;
    Name:string;
    Email:string;
    DistroAccount:string;
};

export default KTMScreen({name:"enroll.lookups.advisor"}, class extends React.Component<{keyword?:string,eventValue?:any}> {

    data:{
        dsAdvisors:IdsAdvisors;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsAdvisors";
        await KTMApi.start(screen,{
            "@keyword":this.props.keyword
        });
        screen.onEnterKey = this.search;
        screen.title = "Advisor lookup";
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout dialogSize="medium" dialogHeight="medium" padding="none">
                {draw(this.header)}
                {draw(this.search_panel)}
                {draw(this.default_view)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Advisor lookup">
            </Dialog.Header>
        );
    }

    search_panel({field,draw,action}:RenderEvent){
        return (
            <Card>
                <Line>
                    <FilterBox field={field("@keyword")}/>
                    <FilterBox field={field("@distro")}/>
                    <FilterBox field={field("@email")}/>
                    <Button action={action(this.search)} label="Search" style="primary"/>
                </Line>
            </Card>
        );
    }

    default_view({field,draw,action,collection}:RenderEvent){
        let actions = null;
        let layout:IDataGridLayout = {
            columns:[
                {name:"Name",label:"Name",type:"character",width:220},
                {name:"DistroAccount",label:"Distro account",type:"character",width:350},
                {name:"Email",label:"Email",type:"character",width:250},
            ],
            sort:[
                {name:"LastName"}
            ]
        };
        return (
            <DataGrid collection={collection("@dsAdvisors")} layout={layout} actions={actions} onRowClick={action(this.on_row_click)}/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
            </Dialog.Footer>
        );
    }

    async search({screen}:ActionEvent){
        await KTMApi.action(screen,"search",{
            "@keyword":screen.getFilter("@keyword"),
            "@distro":screen.getFilter("@distro"),
            "@email":screen.getFilter("@email")
        });
    }

    async on_row_click({screen}:ActionEvent){
        screen.close({continue:true,value:screen.getValue("Id"),label:screen.getValue("Name")});
    }

});
