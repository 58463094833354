import React, { Component } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";

export default KTMScreen(
  { name: "error" },
  class extends React.Component<any> {
    render() {
      return (
        <div className="error-page-container">
          <div>
            <IoWarningOutline
              className="error-icon"
              size={130}
            ></IoWarningOutline>
            <div className="error-label">
              Sorry! The page you were looking for could not be accessed.
            </div>
            <div className="error-text">
              If you believe you are receiving this message in error, please send an
              e-mail to <a href="mailto:support@nationalhealthclaim.com">support@nationalhealthclaim.com</a>.
            </div>
          </div>
        </div>
      );
    }
  }
);
