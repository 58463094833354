import React, {useEffect, useState} from "react";
import AdvisorsReport from "../AdvisorsReport";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import {CustomLoading} from "../../components/CustomLoading";


const FinancialsTable = ({ grossRevenue, submittedClaims, addedPolicies, totalPoliciesCount, addedEmployees, totalEmployeesCount, addedAdvisors, totalAdvisorsCount, month }) => {
    return (
        <div>
            {grossRevenue && submittedClaims ? (
                <div className="card-body">
                    <table className="table">
                        <thead>
                        <tr style={{textAlign: "center"}}>
                            <th>Gross Revenue</th>
                            <th>Current Month</th>
                            <th>Previous Year Month</th>
                            <th>Better/(Worse)</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Better/(Worse)</th>
                        </tr>
                        </thead>
                        <tbody className="text-right">
                        <tr style={{textAlign: "center"}}>
                            <td></td>
                            <td>{month}</td>
                            <td>{month}</td>
                            <td>Percent %</td>
                            <td>Year-to-date</td>
                            <td>Year-to-date</td>
                            <td>Percent %</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>HSA</td>
                            <td>{grossRevenue.currentMonthHsa}</td>
                            <td>{grossRevenue.previousYearMonthHsa}</td>
                            <td>{grossRevenue.monthChangePercentageHsa}</td>
                            <td>{grossRevenue.currentYearHsa}</td>
                            <td>{grossRevenue.previousYearHsa}</td>
                            <td>{grossRevenue.yearChangePercentageHsa}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>LSA</td>
                            <td>{grossRevenue.currentMonthLsa}</td>
                            <td>{grossRevenue.previousYearMonthLsa}</td>
                            <td>{grossRevenue.monthChangePercentageLsa}</td>
                            <td>{grossRevenue.currentYearLsa}</td>
                            <td>{grossRevenue.previousYearLsa}</td>
                            <td>{grossRevenue.yearChangePercentageLsa}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>Total</td>
                            <td>{grossRevenue.currentMonthTotal}</td>
                            <td>{grossRevenue.previousYearMonthTotal}</td>
                            <td>{grossRevenue.monthChangePercentageTotal}</td>
                            <td>{grossRevenue.currentYearTotal}</td>
                            <td>{grossRevenue.previousYearTotal}</td>
                            <td>{grossRevenue.yearChangePercentageTotal}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table className="table">
                        <thead>
                        <tr style={{textAlign: "center"}}>
                            <th>Submitted Claims</th>
                            <th>Current Month</th>
                            <th>Previous Year Month</th>
                            <th>Better/(Worse)</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Better/(Worse)</th>
                        </tr>
                        </thead>
                        <tbody className="text-right">
                        <tr style={{textAlign: "center"}}>
                            <td></td>
                            <td>Count</td>
                            <td>Count</td>
                            <td>Percent %</td>
                            <td>Year-to-date</td>
                            <td>Year-to-date</td>
                            <td>Percent %</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>HSA</td>
                            <td>{submittedClaims.currentMonthHsa}</td>
                            <td>{submittedClaims.previousYearMonthHsa}</td>
                            <td>{submittedClaims.monthChangePercentageHsa}</td>
                            <td>{submittedClaims.currentYearHsa}</td>
                            <td>{submittedClaims.previousYearHsa}</td>
                            <td>{submittedClaims.yearChangePercentageHsa}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>LSA</td>
                            <td>{submittedClaims.currentMonthLsa}</td>
                            <td>{submittedClaims.previousYearMonthLsa}</td>
                            <td>{submittedClaims.monthChangePercentageLsa}</td>
                            <td>{submittedClaims.currentYearLsa}</td>
                            <td>{submittedClaims.previousYearLsa}</td>
                            <td>{submittedClaims.yearChangePercentageLsa}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>Total</td>
                            <td>{submittedClaims.currentMonthTotal}</td>
                            <td>{submittedClaims.previousYearMonthTotal}</td>
                            <td>{submittedClaims.monthChangePercentageTotal}</td>
                            <td>{submittedClaims.currentYearTotal}</td>
                            <td>{submittedClaims.previousYearTotal}</td>
                            <td>{submittedClaims.yearChangePercentageTotal}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table className="table">
                        <thead>
                        <tr style={{textAlign: "center"}}>
                            <th>Policies Added</th>
                            <th>Current Month</th>
                            <th>Previous Year Month</th>
                            <th>Better/(Worse)</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Better/(Worse)</th>
                        </tr>
                        </thead>
                        <tbody className="text-right">
                        <tr style={{textAlign: "center"}}>
                            <td>Total: {totalPoliciesCount}</td>
                            <td>New Policies</td>
                            <td>New Policies</td>
                            <td>Percent %</td>
                            <td>Year-to-date</td>
                            <td>Year-to-date</td>
                            <td>Percent %</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>HSA/LSA</td>
                            <td>{addedPolicies.currentMonthHsa}</td>
                            <td>{addedPolicies.previousYearMonthHsa}</td>
                            <td>{addedPolicies.monthChangePercentageHsa}</td>
                            <td>{addedPolicies.currentYearHsa}</td>
                            <td>{addedPolicies.previousYearHsa}</td>
                            <td>{addedPolicies.yearChangePercentageHsa}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>Total</td>
                            <td>{addedPolicies.currentMonthTotal}</td>
                            <td>{addedPolicies.previousYearMonthTotal}</td>
                            <td>{addedPolicies.monthChangePercentageTotal}</td>
                            <td>{addedPolicies.currentYearTotal}</td>
                            <td>{addedPolicies.previousYearTotal}</td>
                            <td>{addedPolicies.yearChangePercentageTotal}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table className="table">
                        <thead>
                        <tr style={{textAlign: "center"}}>
                            <th>Employees / Users Added</th>
                            <th>Current Month</th>
                            <th>Previous Year Month</th>
                            <th>Better/(Worse)</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Better/(Worse)</th>
                        </tr>
                        </thead>
                        <tbody className="text-right">
                        <tr style={{textAlign: "center"}}>
                            <td>Total: {totalEmployeesCount}</td>
                            <td>New Employees</td>
                            <td>New Employees</td>
                            <td>Percent %</td>
                            <td>Year-to-date</td>
                            <td>Year-to-date</td>
                            <td>Percent %</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>HSA/LSA</td>
                            <td>{addedEmployees.currentMonthHsa}</td>
                            <td>{addedEmployees.previousYearMonthHsa}</td>
                            <td>{addedEmployees.monthChangePercentageHsa}</td>
                            <td>{addedEmployees.currentYearHsa}</td>
                            <td>{addedEmployees.previousYearHsa}</td>
                            <td>{addedEmployees.yearChangePercentageHsa}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>Total</td>
                            <td>{addedEmployees.currentMonthTotal}</td>
                            <td>{addedEmployees.previousYearMonthTotal}</td>
                            <td>{addedEmployees.monthChangePercentageTotal}</td>
                            <td>{addedEmployees.currentYearTotal}</td>
                            <td>{addedEmployees.previousYearTotal}</td>
                            <td>{addedEmployees.yearChangePercentageTotal}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table className="table">
                        <thead>
                        <tr style={{textAlign: "center"}}>
                            <th>Brokers / Advisors Added</th>
                            <th>Current Month</th>
                            <th>Previous Year Month</th>
                            <th>Better/(Worse)</th>
                            <th>Current Year</th>
                            <th>Previous Year</th>
                            <th>Better/(Worse)</th>
                        </tr>
                        </thead>
                        <tbody className="text-right">
                        <tr style={{textAlign: "center"}}>
                            <td>Total: {totalAdvisorsCount}</td>
                            <td>New Advisors</td>
                            <td>New Advisors</td>
                            <td>Percent %</td>
                            <td>Year-to-date</td>
                            <td>Year-to-date</td>
                            <td>Percent %</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>Advisors</td>
                            <td>{addedAdvisors.currentMonthHsa}</td>
                            <td>{addedAdvisors.previousYearMonthHsa}</td>
                            <td>{addedAdvisors.monthChangePercentageHsa}</td>
                            <td>{addedAdvisors.currentYearHsa}</td>
                            <td>{addedAdvisors.previousYearHsa}</td>
                            <td>{addedAdvisors.yearChangePercentageHsa}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}>Total</td>
                            <td>{addedAdvisors.currentMonthTotal}</td>
                            <td>{addedAdvisors.previousYearMonthTotal}</td>
                            <td>{addedAdvisors.monthChangePercentageTotal}</td>
                            <td>{addedAdvisors.currentYearTotal}</td>
                            <td>{addedAdvisors.previousYearTotal}</td>
                            <td>{addedAdvisors.yearChangePercentageTotal}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br/>
                </div>
            ) : (
                <p></p>
            )}
        </div>
    )
}

const OpsFinancialsReport = () => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [grossRevenueReport, setGrossRevenueReport] = useState(null);
    const [submittedClaimsReport, setSubmittedClaimsReport] = useState(null);
    const [addedPoliciesReport, setAddedPoliciesReport] = useState(null);
    const [totalPoliciesCount, setTotalPoliciesCount] = useState(null);
    const [addedEmployeesReport, setAddedEmployeesReport] = useState(null);
    const [totalEmployeesCount, setTotalEmployeesCount] = useState(null);
    const [addedAdvisorsReport, setAddedAdvisorsReport] = useState(null);
    const [totalAdvisorsCount, setTotalAdvisorsCount] = useState(null);
    const [loading, setLoading] = useState(false);

    const months = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];
    const currentYear = new Date().getFullYear();
    const years = Array.from({length: 6}, (_, i) => currentYear - i);

    const getGrossRevenue = async () => {
        try {
            const response = await axios.get('/api/opsreport/getgrossrevenuereport', {
                params: {
                    month: selectedMonth,
                    year: selectedYear
                }
            });
            setGrossRevenueReport(response.data.grossRevenue);
        } catch (error) {
            console.error('Error fetching gross revenue report:', error);
        }
    };

    const getSubmittedClaims = async () => {
        try {
            const response = await axios.get('/api/opsreport/getsubmittedclaimsreport', {
                params: {
                    month: selectedMonth,
                    year: selectedYear
                }
            });
            setSubmittedClaimsReport(response.data.submittedClaims);
        } catch (error) {
            console.error('Error fetching submitted claims report:', error);
        }
    };

    const getAddedPolicies = async () => {
        try {
            const response = await axios.get('/api/opsreport/getaddedpoliciesreport', {
                params: {
                    month: selectedMonth,
                    year: selectedYear
                }
            });
            setAddedPoliciesReport(response.data.addedPolicies);
            setTotalPoliciesCount(response.data.totalPoliciesCount);
        } catch (error) {
            console.error('Error fetching added policies report:', error);
        }
    };

    const getAddedEmployees = async () => {
        try {
            const response = await axios.get('/api/opsreport/getaddedemployeesreport', {
                params: {
                    month: selectedMonth,
                    year: selectedYear
                }
            });
            setAddedEmployeesReport(response.data.addedEmployees);
            setTotalEmployeesCount(response.data.totalEmployeesCount);
        } catch (error) {
            console.error('Error fetching added employees report:', error);
        }
    };

    const getAddedAdvisors = async () => {
        try {
            const response = await axios.get('/api/opsreport/getaddedadvisorsreport', {
                params: {
                    month: selectedMonth,
                    year: selectedYear
                }
            });
            setAddedAdvisorsReport(response.data.addedAdvisors);
            setTotalAdvisorsCount(response.data.totalAdvisorsCount);
        } catch (error) {
            console.error('Error fetching added advisors report:', error);
        }
    };

    const generateFinancialsReport = async () => {
        setLoading(true);

        try {
            await Promise.all([
                getSubmittedClaims(),
                getGrossRevenue(),
                getAddedPolicies(),
                getAddedEmployees(),
                getAddedAdvisors(),
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="row justify-content-center my-4 mx-2">
                <div className="col-md-12">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <label htmlFor="month">Month</label>
                        <select
                            id="month"
                            className="form-select mx-3"
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                            style={{maxWidth: '150px'}}
                        >
                            {months.map((month, index) => (
                                <option key={index} value={index}>
                                    {month}
                                </option>
                            ))}
                        </select>

                        <label htmlFor="year" className="mr-2">Year</label>
                        <select
                            id="year"
                            className="form-select mx-3"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                            style={{maxWidth: '100px'}}
                        >
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>

                        <button className="btn btn-outline-primary" onClick={() => generateFinancialsReport()}>Generate
                            Report
                        </button>
                    </div>
                </div>
            </div>
            {loading ? <div className="d-flex justify-content-center align-items-center p-5"><Spinner animation="border"/></div> :
                <FinancialsTable grossRevenue={grossRevenueReport} 
                                 submittedClaims={submittedClaimsReport} 
                                 addedPolicies={addedPoliciesReport} totalPoliciesCount={totalPoliciesCount}
                                 addedEmployees={addedEmployeesReport} totalEmployeesCount={totalEmployeesCount}
                                 addedAdvisors={addedAdvisorsReport} totalAdvisorsCount={totalAdvisorsCount}
                                 month={months[selectedMonth]} />}
        </div>
    );
}

export default OpsFinancialsReport;