import React from "react";
import Spinner from "components/Spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, Button, CloseButton } from "react-bootstrap";
import "./styles/support-dashboard.css";
import "./styles/progress-circle.css";
import UploadClaim from "./UploadClaim";
import EligibleExpenses from "./EligibleExpenses";
import toastr from "toastr";

export default class EmployeeBenefit extends React.Component<{
  employeeId: string;
  policyId: string;
}> {
  state = {
    loaded: false,
    plans: null,
    employee: null,
    showStartModal: false,
    showModal: false,
    modalType: null,
    modalBalance: null,
    modalStart: null,
    modalEnd: null,
    modalRunoff: null,
    modalCarry: null,
    company: null,
    HSAClaimCount: 0,
    LSAClaimCount: 0,
    isHSAModalOpen: false,
    isLSAModalOpen: false,
    accountType: null,
    policyNo: null,
    enableWellness: false,
    employeeId: this.props.employeeId,
    policyId: this.props.policyId,
    showEligibleExpenses: false,
    allowLsaDependentClaims: false,
    hasFlex: false,
    hasAdditionalAccounts: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      plans: null,
      employee: null,
      showStartModal: false,
      showModal: false,
      modalType: null,
      modalBalance: null,
      modalStart: null,
      modalEnd: null,
      modalRunoff: null,
      modalCarry: null,
      company: null,
      HSAClaimCount: 0,
      LSAClaimCount: 0,
      isHSAModalOpen: false,
      isLSAModalOpen: false,
      accountType: null,
      policyNo: null,
      enableWellness: false,
      employeeId: this.props.employeeId,
      policyId: this.props.policyId,
      showEligibleExpenses: false,
      allowLsaDependentClaims: false,
      hasFlex: false,
      hasAdditionalAccounts: false,
    };
  }

  componentDidMount() {
    this.startLoading();
  }

  componentDidUpdate(
    prevProps: Readonly<{ employeeId: string; policyId: string }>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (
      this.props.employeeId != prevProps.employeeId ||
      this.props.policyId != prevProps.policyId
    ) {
      this.startLoading();
    }
  }

  async startLoading() {
    const reqOne = axios.get(
      `/api/supportemployee/getbenefit?policyId=${this.props.policyId}&employeeId=${this.props.employeeId}`
    );
    const reqTwo = axios.get(
      `/api/supportemployee/getemployee?policyId=${this.props.policyId}&employeeId=${this.props.employeeId}`
    );

    await axios
      .all([reqOne, reqTwo])
      .then((responses) =>
        this.setState({
          loaded: true,
          policyNo: responses[0].data.policyNo,
          plans: responses[0].data.spendingAccounts,
          employee: responses[1].data.employee,
          company: responses[1].data.company,
          LSAClaimCount: responses[0].data.lsaClaimCount,
          HSAClaimCount: responses[0].data.hsaClaimCount,
          enableWellness: responses[0].data.enableWellness,
          isLSAModalOpen: false,
          isHSAModalOpen: false,
          allowLsaDependentClaims: responses[0].data.allowLsaDependentClaims,
          hasFlex: responses[0].data.hasFlex,
          hasAdditionalAccounts: responses[0].data.hasAdditionalAccounts,
        })
      )
      .catch(function (error) {
        toastr.error("Failed save notes", "Error");
      });
  }

  renderHSAModal() {
    if (!this.state.isHSAModalOpen) return null;
  }

  displayHSAModal = () => {
    this.setState({ isHSAModalOpen: true });
  };

  closeHSAModal = () => {
    this.setState({ isHSAModalOpen: false });
  };

  renderLSAModal() {
    if (!this.state.isLSAModalOpen) return null;
  }

  displayLSAModal = () => {
    this.setState({ isLSAModalOpen: true });
  };

  closeLSAModal = () => {
    this.setState({ isLSAModalOpen: false });
  };

  checkShowModal = (type) => {
    if (type == "HSA") {
      this.setState({ isHSAModalOpen: true, accountType: type });
    } else if (type == "LSA") {
      this.setState({ isLSAModalOpen: true, accountType: type });
    }
  };

  checkShowshowEligibleExpenses = (type) => {
    if (type == "HSA") {
      this.setState({ accountType: type });
    } else if (type == "LSA") {
      this.setState({ accountType: type });
    }
    this.setState({ showEligibleExpenses: !this.state.showEligibleExpenses });
  };

  renderContent() {
    if (!this.state.loaded) {
      return <Spinner />;
    }

    var accounts = null;
    accounts = this.state.plans.map((data, i) => {
      var currPercent = Math.round(
        (data.currYear.available / data.currYear.limit) * 100
      );
      var prevPercent = 0;
      if (data.prevYear != null) {
        prevPercent = Math.round(
          (data.prevYear.Available / data.prevYear.limit) * 100
        );
      }

      if (currPercent > 100) {
        currPercent = 100;
      }
      if (prevPercent > 100) {
        prevPercent = 100;
      }

      var currCircleClass = null;
      var prevCircleClass = null;
      if (data.type == "HSA") {
        currCircleClass = "progress-circle progress-" + currPercent;
        prevCircleClass = "progress-circle progress-" + prevPercent;
      } else if (data.type == "LSA") {
        currCircleClass = "progress-circle-green progress-" + currPercent;
        prevCircleClass = "progress-circle-green progress-" + prevPercent;
      } else {
        // other misc account types
      }

      var prevYearContainer = null;

      var dataType = null;
      if (data.type == "HSA") {
        dataType = "Health Spending Account";
      } else if (data.type == "LSA") {
        dataType = this.state.enableWellness
          ? "Wellness Spending Account"
          : "Lifestyle Spending Account";
      }

      var carryType = null;

      var link = "/limitcalculation/" + data.type;
      return (
        <div className="column" key={data.id}>
          <div className="pd-t-30">
            <div className="text-center pd-t-15">
              <div className={currCircleClass}>
                <span>
                  <div className="tx-available-val card-font-color">
                    ${data.currYear.available.toFixed(2)}
                  </div>
                  <div className="tx-available text-uppercase card-font-color">
                    available
                  </div>
                </span>
              </div>
            </div>
            {prevYearContainer}
            <div className="text-center">
              <div style={{ margin: "auto", textAlign: "center" }}>
                <div className="h6 black pd-t-10">{dataType}</div>
                <div className="h6 black">
                  Current year limit: ${data.currYear.limit.toFixed(2)}
                  <br />
                  Previous year balance: ${data.prevYear?.available.toFixed(2)}
                  <br />
                  Previous year limit: ${data.prevYear?.limit.toFixed(2)}
                </div>
              </div>
            </div>
            <div className="col-sm-1 col-md-8 col-lg-8 pd-b-40">
              {carryType}
            </div>

            <div className="step3">
              <div className="text-center mg-t-80">
                <div className="pd-t-10">
                  <Link
                    className={
                      "btn-support btn-primary-support pd-x-25-force home-camera-" +
                      data.type.toLowerCase()
                    }
                    to={"#"}
                    onClick={() => {
                      this.checkShowModal(data.type);
                    }}
                  >
                    Make a
                    {(this.state.enableWellness === false ||
                      data.type === "HSA") && (
                      <span style={{ textTransform: "uppercase" }}>
                        {" "}
                        {data.type}{" "}
                      </span>
                    )}
                    {this.state.enableWellness && data.type === "LSA" && (
                      <span style={{ textTransform: "uppercase" }}> WSA </span>
                    )}
                    Claim
                  </Link>
                </div>
                <div className="pd-t-10">
                  <Link
                    className={
                      "btn-support btn-support-primary-support-green pd-x-25-force home-camera-inv-" +
                      data.type.toLowerCase()
                    }
                    to={"#"}
                    onClick={() => {
                      this.checkShowshowEligibleExpenses(data.type);
                    }}
                  >
                    Eligible Expenses
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    var carryType = null;
    var carryText = null;
    if (this.state.modalCarry == "CCF") {
      carryType = "Credit Carry Forward";
      carryText = (
        <>
          <p>
            Unused credits will be added to your new benefit year for a maximum
            of one year. This sometimes can take a few weeks to process but you
            can still claim normally. Our team will take the credits out of the
            correct benefit period.
          </p>
          <p>
            Note: Any credits under this section that are from two years ago
            will not be transferred.
          </p>
        </>
      );
    } else if (this.state.modalCarry == "ECF") {
      carryType = "Expense Carry Forward";
      carryText = (
        <p>
          Unused credits will not be transfered over to your new benefit year.
          However, you have a longer period to claim receipts from your previous
          year using new benefit year credits
        </p>
      );
    }

    return (
        <div className="br-mainpanel">
          <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div className="row pt-1">
              <div className="col-3">Allow LSA Dependent Claims</div>
              <div className="col-9">
                {this.state.allowLsaDependentClaims ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
          <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div className="row pt-1">
              <div className="col-3">Has Flex</div>
              <div className="col-9">
                {this.state.hasFlex ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
          <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div className="row pt-1">
              <div className="col-3">Has Additional Accounts</div>
              <div className="col-9">
                {this.state.hasAdditionalAccounts ? 'Yes' : 'No'}
              </div>
            </div>
          </div>
          <div className="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div className="row pt-1">
              <div className="col-3">Benefits</div>
              {this.state.plans[0] != null ? (
                  <div className="col-9">
                    The current benefit year goes from{" "}
                    {this.state.plans[0].currYear?.startDate} to{" "}
                    {this.state.plans[0].currYear?.endDate}.
                    <br/>
                    The run-off deadline for the current benefit year is{" "}
                    {this.state.plans[0].currYear?.runoff} at 11:59 PM MT.
                  </div>
              ) : (
                  <div className="col-9">No plans found in this employee</div>
              )}
            </div>
          </div>
          <br/>
          {this.state.plans[0] != null && (
              <div>
                {this.renderHSAModal()}
                {this.renderLSAModal()}
                <div
                    className="br-section-wrapper mg-b-30 no-padding mg-x-50"
                    style={{maxWidth: 1250}}
                >
                  <div className="card mg-t-35">
                    <div
                        className="card-header tx-medium tx-black"
                        style={{backgroundColor: "#fff"}}
                    >
                      {this.state.company.name.toUpperCase()} SPENDING ACCOUNT
                    </div>
                    <div className="card-body">
                      <div
                          className="br-pagebody"
                          style={{maxWidth: 1250, marginTop: "0px"}}
                      >
                        <div className="row">{accounts}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {(this.state.isHSAModalOpen || this.state.isLSAModalOpen) && (
                    <UploadClaim
                        employeeId={this.props.employeeId}
                        policyId={this.props.policyId}
                        type={this.state.accountType}
                    ></UploadClaim>
                )}
                {this.state.showEligibleExpenses && (
                    <EligibleExpenses
                        employeeId={this.props.employeeId}
                        policyId={this.props.policyId}
                        type={this.state.accountType}
                    ></EligibleExpenses>
                )}
              </div>
          )}
        </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}
