import React, { ReactEventHandler } from "react";
import {
    KTMScreen,
    ActionEvent,
    RenderEvent,
    renderScreen,
    Sidebar,
    FileUpload,
    ColorSwatch,
} from "ktm-ui";
import {KTMApi} from "api/KTMApi";
import {
    Layout,
    RecordHeader,
    TabSet,
    Tab,
    Display,
    Button,
    Column,
    Panel,
    Line,
    Card,
} from "ktm-ui";
import toastr from "toastr";
import ProductsScreen from "./Products";
import Cost_centresScreen from "./Cost_centres";
import DivisionsScreen from "./Divisions";
import Limit_previewScreen from "./Limit_preview";
import Benefit_categoriesScreen from "./Benefit_categories";
import ActivationScreen from "./Activation";
import EmployeesScreen from "./Employees";
import EmployeesScreenMigration from "./Employees_migration";
import AssignPlanadmin from "./Assign_Planadmin";
import Year_dashboardScreen from "./Year_dashboard";
import Edit_detailsScreen from "./Edit_details";
import Edit_plan_adminScreen from "./Edit_plan_admin";
import Edit_policy_holderScreen from "./Edit_policy_holder";
import Change_advisorScreen from "./Change_advisor";
import Change_policy_holderScreen from "./Change_policy_holder";
import Edit_noteScreen from "./Edit_note";
import Add_first_yearScreen from "./Add_first_year";
import Edit_first_yearScreen from "./Edit_first_year";
import CustomToolTip from "../../components/CustomToolTip";
import CampaignScreen from "./Campaign";
import Inactive_PlanAdmins from "./Inactive_PlanAdmins";
import SettingsScreen from "../../components/Settings";
import Benefit_Categories_New from "./Benefit_Categories_New";
import Benefit_Categories from "./Benefit_categories";
import "../../styles/Ops.css";
import BillingProducts from "../../ops/BillingProducts";

interface IdsPolicy {
  Id: string;
  Name: string;
  PolicyNo: string;
  PolicyHolder_Id: string;
  PrimaryAdvisor_Id: string;
  EnrollmentStatus: string;
  EnrollmentNote: string;
  IsActive: boolean;
  DependentAge: number;
  StudentAge: number;
  TaxJurisdiction_Id: string;
  Type: string;
  TypeLabel: string;
  TaxName: string;
  CurrentBenYear_Id: string;
  WaitingPeriod: number;
  EnrollPAFirstName: string;
  EnrollPALastName: string;
  EnrollPAEmail: string;
  StartDate: string;
  HasFlex: boolean;
  EnrollmentDate: string;
  ActivationDate: string;
  Representative: string;
  HasTeladoc: string;
}

interface IdsAdvisor {
    Id: string;
    Name: string;
    Phone: string;
    Email: string;
    Distro: string;
}

interface IdsPolicyHolder {
    Id: string;
    Name: string;
    DBA: string;
    MailingStreet1: string;
    MailingStreet2: string;
    MailingCity: string;
    MailingProv: string;
    MailingCountry: string;
    MailingPostalCode: string;
    Phone: string;
    ContactPerson: string;
    ContactEmail: string;
    LegalForm: string;
}

interface IdsBenefitYear {
    Id: string;
    StartDate: string;
    EndDate: string;
    RunOffClosingDate: string;
    RunoffMonths: number;
}

interface IdsPrevBenefitYear {
    Id: string;
    StartDate: string;
    EndDate: string;
    RunOffClosingDate: string;
    RunoffMonths: number;
    Status: string;
}

interface IdsPolicyBalance {
    Policy_Id: string;
    LedgerType: string;
    Balance: number;
}

export default KTMScreen(
    {name: "enroll.policy.record"},
    class extends React.Component<{ policy_id: string; preview?: any }> {
        data: {
            dsPolicy: IdsPolicy;
            dsAdvisor: IdsAdvisor;
            dsPolicyHolder: IdsPolicyHolder;
            dsBenefitYear: IdsBenefitYear;
            dsPolicyBalance: IdsPolicyBalance;
            dsPrevBenefitYear: IdsPrevBenefitYear;
        };

        render() {
            return renderScreen(this);
        }

        async start({screen}: ActionEvent) {
            screen.defaultRecord = "@dsPolicy";
            await KTMApi.start(screen, {
                "@policy_id": this.props.policy_id,
            });
            screen.title = this.data.dsPolicyHolder.Name;
            screen.setOptions("@dsBenefitYear.RunoffMonths", [
                {value: "1", label: "1 month"},
                {value: "2", label: "2 months"},
                {value: "3", label: "3 months"},
            ]);
            screen.setOptions("@dsPrevBenefitYear.RunoffMonths", [
                {value: "1", label: "1 month"},
                {value: "2", label: "2 months"},
                {value: "3", label: "3 months"},
            ]);
            await screen.triggerAction(this.configure_ui);
        }

        main({field, draw, action}: RenderEvent) {
            return (
                <Layout>
                    {draw(this.header)}
                    {draw(this.tabs)}
                    {draw(this.sidebar)}
                </Layout>
            );
        }

        header({field, draw, action}: RenderEvent) {
            let paView = null;
            if (this.data.dsPolicy.EnrollmentStatus == "Active") {
                paView = (
                    <Button
                        action={action(this.open_pa_view)}
                        label="PA View"
                        style="primary"
                    />
                );
            }

      return (
        <RecordHeader entityLabel="Policy Holder">
          <div style={{ marginBottom: 15 }}>{paView}</div>
          {draw(this.header_bar)}
        </RecordHeader>
      );
    }

        tabs({field, draw, action}: RenderEvent) {
            if (this.data.dsPolicy.EnrollmentStatus == "Active") {
                return (
                    <TabSet>
                        <Tab label="Details" template={this.details_tab}/>
                        <Tab label="Product" template={this.products_tab}/>
                        <Tab label="Billing" template={this.billing_tab}/>
                        <Tab label="Benefit Categories" template={this.bencat_tab}/>
                        <Tab label="Divisions" template={this.divisions_tab}/>
                        <Tab label="Cost Centres" template={this.cost_centres_tab}/>
                        <Tab label="Employees" template={this.employees_tab}/>
                        <Tab label="Plan Admins" template={this.planadmin_tab}/>
                        {this.data.dsPolicy.HasFlex ? (
                            <Tab label="Campaign" template={this.campaign_tab}/>
                        ) : (
                            <></>
                        )}
                        <Tab label="Settings" template={this.settings_tab}/>
                    </TabSet>
                );
            } else if (
                this.data.dsPolicy.EnrollmentStatus == "READY" &&
                this.props.preview == "1"
            ) {
                return (
                    <TabSet>
                        <Tab label="Details" template={this.details_tab}/>
                        <Tab label="Product" template={this.products_tab}/>
                        <Tab label="Billing" template={this.billing_tab}/>
                        <Tab label="Benefit Categories" template={this.bencat_tab}/>
                        <Tab label="Divisions" template={this.divisions_tab}/>
                        <Tab label="Cost Centres" template={this.cost_centres_tab}/>
                        <Tab label="Employees" template={this.employees_tab}/>
                    </TabSet>
                );
            } else if (
                this.data.dsPolicy.EnrollmentStatus == "migration1" ||
                (this.data.dsPolicy.EnrollmentStatus == "migration2" &&
                    this.props.preview == "1")
            ) {
                return (
                    <TabSet>
                        <Tab label="Details" template={this.details_tab}/>
                        <Tab label="Product" template={this.products_tab}/>
                        <Tab label="Billing" template={this.billing_tab}/>
                        <Tab label="Benefit Categories" template={this.bencat_fullview_tab}/>
                        <Tab label="Divisions" template={this.divisions_tab}/>
                        <Tab label="Cost Centres" template={this.cost_centres_tab}/>
                        <Tab label="Employees" template={this.employees_migration_tab}/>
                        <Tab label="Plan Admins" template={this.inactive_planadmin_tab}/>
                    </TabSet>
                );
            }

            return (
                <TabSet>
                    <Tab label="Details" template={this.details_tab}/>
                    <Tab label="Product" template={this.products_tab}/>
                    <Tab label="Billing" template={this.billing_tab}/>
                    <Tab label="Benefit Categories" template={this.bencat_tab}/>
                    <Tab label="Divisions" template={this.divisions_tab}/>
                    <Tab label="Cost Centres" template={this.cost_centres_tab}/>
                    <Tab label="Employees" template={this.employees_tab}/>
                    <Tab label="Plan Admins" template={this.planadmin_tab}/>
                    <Tab label="Limit Preview" template={this.limit_preview_tab}/>
                    <Tab label="Activation" template={this.activation_tab}/>
                    <Tab label="Settings" template={this.settings_tab}/>
                </TabSet>
            );
        }

    header_bar({ field, draw, action }: RenderEvent) {
      return (
        <Line>
            <Display field={field("EnrollmentStatus")} size="medium" textStyle="bold"/>
            <Display field={field("PolicyNo")}/>
            {this.data.dsPolicy.HasTeladoc ? <><Display field={field("HasTeladoc")}/></> : <></>}
            <Display field={field("EnrollmentNote")} />
            <Button action={action(this.edit_note)} label="Edit note" style="link"/>
        </Line>
      );
    }

    details_tab({ field, draw, action }: RenderEvent) {
      let balance = null;
      if (this.data.dsPolicy.EnrollmentStatus == "Active") {
        balance = draw(this.balance);
      }
      
      return (
        <Panel>
          <Column>
            {draw(this.policy_details)}
            {draw(this.add_toolbar)}
            {draw(this.year_details)}
            {this.data.dsPrevBenefitYear.Status == "OPEN" && draw(this.previous_year_details)}
            {draw(this.advisor)}
          </Column>
          <Column>
            {draw(this.policy_holder)}
            {balance}
          </Column>
        </Panel>
      );
    }

        products_tab({field, draw, action}: RenderEvent) {
            return <ProductsScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        billing_tab({field, draw, action}: RenderEvent) {
            return <BillingProducts policy_id={this.data.dsPolicy.Id}/>;
        }

        cost_centres_tab({field, draw, action}: RenderEvent) {
            return <Cost_centresScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        divisions_tab({field, draw, action}: RenderEvent) {
            return <DivisionsScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        limit_preview_tab({field, draw, action}: RenderEvent) {
            return <Limit_previewScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        bencat_fullview_tab({field, draw, action}: RenderEvent) {
            return <Benefit_Categories_New policy_id={this.data.dsPolicy.Id}
                                           benyear_id={this.data.dsPolicy.CurrentBenYear_Id} reload={true}/>;
        }

        bencat_tab({field, draw, action}: RenderEvent) {
            return <Benefit_Categories policy_id={this.data.dsPolicy.Id}/>;
        }

        activation_tab({field, draw, action}: RenderEvent) {
            return <ActivationScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        employees_tab({field, draw, action}: RenderEvent) {
            return <EmployeesScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        employees_migration_tab({field, draw, action}: RenderEvent) {
            return <EmployeesScreenMigration policy_id={this.data.dsPolicy.Id}/>;
        }

        planadmin_tab({field, draw, action}: RenderEvent) {
            return <AssignPlanadmin policy_id={this.data.dsPolicy.Id}/>;
        }

        inactive_planadmin_tab({field, draw, action}: RenderEvent) {
            return <Inactive_PlanAdmins policy_id={this.data.dsPolicy.Id}/>;
        }

        funding_tab({field, draw, action}: RenderEvent) {
            return <div></div>;
        }

        campaign_tab({field, draw, action}: RenderEvent) {
            return <CampaignScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        settings_tab({field, draw, action}: RenderEvent) {
            return <SettingsScreen
                policyId={this.data.dsPolicy.Id}
                view={"ops"}
            />;
        }

    policy_details({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Policy Details"
          actions={
            <>
              <Button
                action={action(this.edit_policy)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display
              field={field("TypeLabel")}
              toolTip={
                <CustomToolTip
                  header="Type (options are Group or Individual) "
                  bodyContent="Marketing only field. Group accounts have employees. An individual account is a Business Owner only account."
                />
              }
            />
            <Display
              field={field("DependentAge")}
              toolTip={
                <CustomToolTip
                  header="Dependent Age "
                  bodyContent="Typically 20, meaning coverage ends at 21."
                />
              }
            />
          </Line>
          <Line>
            <Display
              field={field("StudentAge")}
              toolTip={
                <CustomToolTip
                  header="Student Age"
                  bodyContent="Typically 24, meaning coverage ends at 25."
                />
              }
            />
            <Display
              field={field("TaxName")}
              toolTip={
                <CustomToolTip
                  header="Province"
                  bodyContent="Each claim is taxed based on the home address of the employee."
                />
              }
            />
          </Line>
          <Line>
            {this.data.dsPolicy.EnrollmentStatus == "Active" ? 
              <Display field={field("ActivationDate")} /> : 
              <Display field={field("EnrollmentDate")} />
            }
            <Display field={field("Representative")}/>
          </Line>
          {/* <Line>
        policy_details({field, draw, action}: RenderEvent) {
            return (
                <Card
                    label="Policy details"
                    actions={
                        <>
                            <Button
                                action={action(this.edit_policy)}
                                label="Edit"
                                style="link"
                            />
                        </>
                    }
                >
                    <Line>
                        <Display
                            field={field("TypeLabel")}
                            toolTip={
                                <CustomToolTip
                                    header="Type (options are Group or Individual) "
                                    bodyContent="Marketing only field. Group accounts have employees. An individual account is a Business Owner only account."
                                />
                            }
                        />
                        <Display
                            field={field("DependentAge")}
                            toolTip={
                                <CustomToolTip
                                    header="Dependent Age "
                                    bodyContent="Typically 20, meaning coverage ends at 21."
                                />
                            }
                        />
                    </Line>
                    <Line>
                        <Display
                            field={field("StudentAge")}
                            toolTip={
                                <CustomToolTip
                                    header="Student Age"
                                    bodyContent="Typically 24, meaning coverage ends at 25."
                                />
                            }
                        />
                        <Display
                            field={field("TaxName")}
                            toolTip={
                                <CustomToolTip
                                    header="Province"
                                    bodyContent="Each claim is taxed based on the home address of the employee."
                                />
                            }
                        />
                    </Line>
                    {/* <Line>
            <Display field={field("InstantReleaseAmount")}/>
          </Line> */}
                </Card>
            );
        }

        plan_admin({field, draw, action}: RenderEvent) {
            return (
                <Card
                    label="Main Contact"
                    actions={
                        <>
                            <Button
                                action={action(this.edit_plan_admin)}
                                label="Edit contact"
                                style="link"
                            />
                        </>
                    }
                >
                    <Line>
                        <Display field={field("EnrollPAFirstName")}/>
                        <Display field={field("EnrollPALastName")}/>
                        <Display field={field("EnrollPAEmail")}/>
                    </Line>
                </Card>
            );
        }

    year_details({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Benefit Year Details"
          actions={
            <>
              <Button
                action={action(this.edit_year)}
                label="Edit"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display
              field={field("@dsBenefitYear.StartDate")}
              toolTip={
                <CustomToolTip
                  header="Start Date"
                  bodyContent="A policy can only be backdated up to a maximum of 2 years prior to the Benefit End Date."
                />
              }
            />
            <Display field={field("@dsBenefitYear.EndDate")} />
            <Display
              field={field("@dsBenefitYear.RunoffMonths")}
              toolTip={
                <CustomToolTip
                  header="RunOff Months"
                  bodyContent="Instead of days, employees have run-off month(s) to get their previous year receipts submitted."
                />
              }
            />
          </Line>
        </Card>
      );
    }

        previous_year_details({field, draw, action}: RenderEvent) {
            return (
                <Card label="Previous year details" background="previous_year_details previous-year-section">
                    <Line>
                        <Display field={field("@dsPrevBenefitYear.StartDate")}/>
                        <Display field={field("@dsPrevBenefitYear.EndDate")}/>
                        <Display field={field("@dsPrevBenefitYear.RunoffMonths")}/>
                    </Line>
                </Card>
            );
        }

        add_toolbar({field, draw, action}: RenderEvent) {
            return (
                <div className="RT-Action-Bar">
                    <Button
                        action={action(this.add_year)}
                        label="Add benefit year"
                        style="add"
                    />
                </div>
            );
        }

    policy_holder({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Policy Holder"
          actions={
            <>
              <Button
                action={action(this.edit_policy_holder)}
                label="Edit"
                style="link"
              />
              <Button
                action={action(this.change_policy_holder)}
                label="Change policy holder"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display field={field("@dsPolicyHolder.DBA")} />
            <Display field={field("@dsPolicyHolder.LegalForm")} />
          </Line>
          <Display field={field("@dsPolicyHolder.MailingStreet1")} />
          <Display field={field("@dsPolicyHolder.MailingStreet2")} />
          <Line>
            <Display field={field("@dsPolicyHolder.MailingCity")} />
            <Display field={field("@dsPolicyHolder.MailingProv")} />
          </Line>
          <Line>
            <Display field={field("@dsPolicyHolder.MailingCountry")} />
            <Display field={field("@dsPolicyHolder.MailingPostalCode")} />
          </Line>
          <Display field={field("@dsPolicyHolder.Phone")} />
        </Card>
      );
    }

        balance({field, draw, action}: RenderEvent) {
            return (
                <Card label="Balance">
                    <Display
                        field={field("@dsPolicyBalance.Balance")}
                        size="medium"
                        textStyle="bold"
                    />
                </Card>
            );
        }

    advisor({ field, draw, action }: RenderEvent) {
      return (
        <Card
          label="Primary Advisor"
          actions={
            <>
              <Button
                action={action(this.change_advisor)}
                label="Change advisor"
                style="link"
              />
            </>
          }
        >
          <Line>
            <Display field={field("@dsAdvisor.Name")} textStyle="normal" />
            <Display field={field("@dsAdvisor.Phone")} />
            <Display field={field("@dsAdvisor.Email")} />
          </Line>
          <Display field={field("@dsAdvisor.Distro")} />
        </Card>
      );
    }

        sidebar({draw}: RenderEvent) {
            return (
                <Sidebar>
                    <Tab label="Year dashboard" template={this.year_dashboard}/>
                    <Tab label="Branding" template={this.branding}/>
                </Sidebar>
            );
        }

        year_dashboard({field, draw, action}: RenderEvent) {
            return <Year_dashboardScreen policy_id={this.data.dsPolicy.Id}/>;
        }

        branding({field, draw, action}: RenderEvent) {
            return (
                <Card label="Branding">
                    {draw(this.brand_logo)}
                    {draw(this.brand_color)}
                </Card>
            );
        }

        brand_logo({field, draw, action}: RenderEvent) {
            return <FileUpload field={field("@logo")}/>;
        }

        brand_color({field, draw, action}: RenderEvent) {
            return <ColorSwatch field={field("@brandColor")}/>;
        }

        async configure_ui({screen}: ActionEvent) {
            screen.hide(this.add_year);
            screen.hide(this.year_details);
            let year = this.data.dsPolicy.CurrentBenYear_Id;

            if (!year) {
                screen.unhide(this.add_year);
            } else {
                screen.unhide(this.year_details);
            }
        }

        async edit_policy({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_detailsScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_plan_admin({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_plan_adminScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_policy_holder({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_policy_holderScreen
                    policyholder_id={this.data.dsPolicyHolder.Id}
                />,
                {onRecordChanged: this.refresh_data}
            );
            screen.recordChanged("updated", screen.getValue("@dsPolicy.Id"));
        }

        async change_advisor({screen}: ActionEvent) {
            await screen.openDialog(
                <Change_advisorScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async change_policy_holder({screen}: ActionEvent) {
            await screen.openDialog(
                <Change_policy_holderScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_note({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_noteScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async add_year({screen}: ActionEvent) {
            await screen.openDialog(
                <Add_first_yearScreen policy_id={this.data.dsPolicy.Id}/>,
                {onRecordChanged: this.refresh_data}
            );
        }

        async edit_year({screen}: ActionEvent) {
            await screen.openDialog(
                <Edit_first_yearScreen
                    year_id={this.data.dsPolicy.CurrentBenYear_Id}
                />,
                {onRecordChanged: this.refresh_data}
            );
        }

    async refresh_data({ screen }: ActionEvent) {
      await screen.restart();
    }

        async open_pa_view({screen}: ActionEvent) {
            var link =
                document.location.origin +
                "/ops/planadmin.policy.record?policy_id=" +
                this.data.dsPolicy.Id;
            window.open(link, "_blank");
            screen.message("Copied", {style: "success"});
        }

        async generate_claims({screen}: ActionEvent) {
            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    policyId: this.data.dsPolicy.Id,
                }),
            };

            await fetch("/api/planadmin/generateclaims", requestOptions);
            toastr.success("Claims generated", "Success");
        }
    }
);
