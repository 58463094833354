import React from 'react';
import {KTMScreen,ActionEvent,RenderEvent,renderScreen} from 'ktm-ui';
import {KTMApi} from 'api/KTMApi';
import {Layout,Dialog,Button,Display,Line,Card,Column,Panel} from 'ktm-ui';

interface IdsBenCat {
    Id:string;
    CreditCalcMethod:string;
    LSASingleLimit:number;
    LSACoupleLimit:number;
    LSAFamilyLimit:number;
    LSASinglePercent:number;
    LSAcouplePercent:number;
    LSAfamilyPercent:number;
    AllowLSADependentClaims:boolean;
};

export default KTMScreen({name:"planadmin.ben-limit.add-lsa"}, class extends React.Component<{limit_id:string}> {

    data:{
        dsBenCat:IdsBenCat;
    }

    render(){
        return renderScreen(this);
    }

    async start({screen}:ActionEvent){
        screen.defaultRecord = "@dsBenCat";
        await KTMApi.start(screen,{
            "@limit_id":this.props.limit_id,
        });
        screen.title = "Add LSA benefit";
        await screen.triggerAction(this.set_limit_visibility);
    }

    main({field,draw,action}:RenderEvent){
        return (
            <Layout>
                {draw(this.header)}
                {draw(this.main_tab)}
                {draw(this.footer)}
            </Layout>
        );
    }

    header({field,draw,action}:RenderEvent){
        return (
            <Dialog.Header label="Add LSA benefit"/>
        );
    }

    footer({field,draw,action}:RenderEvent){
        return (
            <Dialog.Footer>
                <Button action={action(this.submit)} label="Save" size="large" style="primary"/>
            </Dialog.Footer>
        );
    }

    limits({field,draw,action}:RenderEvent){
        return (
            <>
                <Line>
                    <Display field={field("LSASingleLimit")}/>
                    <Display field={field("LSACoupleLimit")}/>
                    <Display field={field("LSAFamilyLimit")}/>
                </Line>
            </>
        );
    }

    percentages({field,draw,action}:RenderEvent){
        return (
            <>
                <Line>
                    <Display field={field("LSASinglePercent")}/>
                    <Display field={field("LSAcouplePercent")}/>
                    <Display field={field("LSAfamilyPercent")}/>
                </Line>
            </>
        );
    }

    amounts({field,draw,action}:RenderEvent){
        return (
            <Card>
                {draw(this.limits)}
                {draw(this.percentages)}
            </Card>
        );
    }

    main_tab({field,draw,action}:RenderEvent){
        return (
            <Panel>
                <Column>
                    {draw(this.amounts)}
                </Column>
            </Panel>
        );
    }

    async submit({screen}:ActionEvent){
        if (this.data.dsBenCat.LSASingleLimit == null)
            this.data.dsBenCat.LSASingleLimit = 0;
        if (this.data.dsBenCat.LSACoupleLimit == null)
            this.data.dsBenCat.LSACoupleLimit = 0;
        if (this.data.dsBenCat.LSAFamilyLimit == null)
            this.data.dsBenCat.LSAFamilyLimit = 0;
        if (this.data.dsBenCat.LSASinglePercent == null)
            this.data.dsBenCat.LSASinglePercent = 0;
        if (this.data.dsBenCat.LSAcouplePercent == null)
            this.data.dsBenCat.LSAcouplePercent = 0;
        if (this.data.dsBenCat.LSAfamilyPercent == null)
            this.data.dsBenCat.LSAfamilyPercent = 0;

        await KTMApi.action(screen,"submit",{
            "@dsBenCat.LSASingleLimit":this.data.dsBenCat.LSASingleLimit,
            "@dsBenCat.LSACoupleLimit":this.data.dsBenCat.LSACoupleLimit,
            "@dsBenCat.LSAFamilyLimit":this.data.dsBenCat.LSAFamilyLimit,
            "@dsBenCat.LSASinglePercent":this.data.dsBenCat.LSASinglePercent,
            "@dsBenCat.LSAcouplePercent":this.data.dsBenCat.LSAcouplePercent,
            "@dsBenCat.LSAfamilyPercent":this.data.dsBenCat.LSAfamilyPercent,
            "@dsBenCat.AllowLSADependentClaims":this.data.dsBenCat.AllowLSADependentClaims,
            "@limit_id":this.props.limit_id,
        });
        screen.close({"continue":true});
    }

    async set_limit_visibility({screen}:ActionEvent){
        screen.hide(this.limits);
        screen.hide(this.percentages);
        if (screen.getValue("@dsBenCat.CreditCalcMethod") == 'SALARY'){
            screen.unhide(this.percentages);
        } else {
            screen.unhide(this.limits);
        }
    }

});
