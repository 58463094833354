import axios from "axios";
import React from "react";
import { Button, Table } from "react-bootstrap";
import toastr from "toastr";
import { CustomLoading } from "components/CustomLoading";
import { CustomDropdown } from "components/CustomDropdown";
import "../../styles/Reports.css";
import { CSVLink } from "react-csv"; 
import { CustomDateRangePicker } from "components/CustomDateRangePicker";
import CustomPagination from "components/CustomPagination";
import { EmptyStateTemplate } from "./Empty_state_Template";
import CustomToolTip from "components/CustomToolTip";
import base64 from "../../data/base64.json";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class DivisionsReportTable extends React.Component<any,any>{
    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            selectedDivision: this.props.divisions[0],
            divisions: [{id:null, name:"Display All"}, ...this.props.divisions],
            showInactiveEmployees: false,
            fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
            toDate: new Date(),
            currentPage: 1,
            itemsPerPage: 12,
            totalHSAAmount: 0,
            totalLSAAmount: 0,
        };
        
        this.fetchData = this.fetchData.bind(this);
        this.setSelectedDivsion = this.setSelectedDivsion.bind(this);
        this.toggleInactiveEmployees = this.toggleInactiveEmployees.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedDivision !== this.state.selectedDivision || prevState.fromDate !== this.state.fromDate || prevState.toDate !== this.state.toDate || prevState.showInactiveEmployees !== this.state.showInactiveEmployees) {
            this.setState({loading: true});
            this.fetchData();
        }
    }

    fetchData = () => {
        axios.get("/api/Reports/getemployeesdivisionreport", {
            params: {
                policyId : this.props.policyId,
                divisionId: this.state.selectedDivision.id,
                fromDate: this.state.fromDate,
                toDate: this.state.toDate,
            }
        })
            .then(res => {
                if (res.data.success) {
                    this.setupData(res.data.employees);
                } else {
                    this.setState({
                        loading: false,
                        data: [],
                        dataToExport: [],
                    });
                }
            });
    }

    setSelectedDivsion = (option) => {
        let findDivision = this.state.divisions.find(div => div.name === option);
        this.setState({
            selectedDivision: findDivision,
        });
    }

    setupData = (data) => {
        let totalHSA = 0;
        let totalLSA = 0;
        let dataToExport = [];
        let employees = data;
        const { fromDate, toDate } = this.state;
        const formattedFromDate = fromDate.toISOString().slice(0,10);
        const formattedToDate = toDate.toISOString().slice(0,10);
        if (!this.state.showInactiveEmployees) {
            employees = employees.filter(emp => emp.isActive);
        }
        
        employees.forEach(emp => {
            totalHSA += parseFloat(emp.hsaTotal);
            totalLSA += parseFloat(emp.lsaTotal);

            dataToExport.push([
                emp.lastName,
                emp.firstName,
                emp.email,
                emp.isActive ? "Active" : "Inactive",
                emp.divisionName,
                emp.hsaTotal,
                emp.lsaTotal,
            ]);
        });

        const headerRow = ["Last Name", "First Name", "Email", "Status", "Division", "HSA Total", "LSA Total"];
        dataToExport.unshift(headerRow);

        let totalItem = [
            "Total",
            "",
            "",
            "",
            "",
            totalHSA.toFixed(2),
            totalLSA.toFixed(2),
        ];
        dataToExport.push(totalItem);

        const dateRangeRow = [
            "Date Range",
            `${formattedFromDate} - ${formattedToDate}`
        ];
        dataToExport.unshift(dateRangeRow);

        this.setState({
            dataToExport: dataToExport,
            data: employees,
            totalHSAAmount: totalHSA.toFixed(2),
            totalLSAAmount: totalLSA.toFixed(2),
            loading: false,
        });
    }

    toggleInactiveEmployees = () => {
        this.setState({
            showInactiveEmployees: !this.state.showInactiveEmployees,
        });
    }

    setFromDate = (date) => {
        this.setState({
            fromDate: date,
        });
    }

    setToDate = (date) => {
        this.setState({
            toDate: date,
        });
    }

    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    exportToPdf = () => {
        const { dataToExport } = this.state;
    
        const header = dataToExport[1];
        const bodyRows = dataToExport.slice(2, dataToExport.length - 1);
        const footer = dataToExport[dataToExport.length - 1];
      
        const headerData = this.formatRow(header, 'tableHeader');
        const bodyData = this.formatTableBody(bodyRows);
        bodyData.push(this.formatRow(footer, 'tableFooter'));

        const docDefinition = {
            pageSize: 'A4',
            pageOrientation: 'portrait',
            content: [
                {
                    columns: [
                        this.createLogo(),
                        this.createDateRange(dataToExport[0][1]),
                    ],
                },
                this.createTitle(),
                this.createTable(headerData, bodyData)
            ],
            styles: this.getPdfStyles(),
            layout: this.getTableLayout()
        };
    
        pdfMake.createPdf(docDefinition).download("Divisions_Report.pdf");
    };
        
    createLogo = () => ({
        image: base64.logo,
        width: 230,
    });

    createTitle = () => ({
        text: 'Divisions Report',
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [10, 40, 0, 10]
    });
    
    createDateRange = (dateRange) => ({
        text: `Date Range: ${dateRange}`,
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 0, 0, 8]
    });
    
    formatRow = (rowData, style) => {
        return rowData.map(cell => {
            const isNumeric = !isNaN(parseFloat(cell)) && isFinite(cell);
            const isPercentage = typeof cell === 'string' && cell.includes('%');
    
            return {
                text: String(cell || ''),
                style,
                alignment: (isNumeric || isPercentage) ? 'right' : 'left'
            };
        });
    };
    
    formatTableBody = (rows) => rows.map(row => this.formatRow(row, 'tableBody'));
    
    createTable = (header, body) => ({
        style: 'table',
        table: {
            widths: Array(header.length).fill('auto'),
            body: [header, ...body]
        }
    });
    
    getPdfStyles = () => ({
        table: {
            margin: [0, 5, 0, 15]
        },
        tableHeader: {
            bold: true,
            fontSize: 12,
            color: 'black'
        },
        tableBody: {
            fontSize: 10,
            color: 'black',
            padding: [2, 2, 2, 2]
        },
        tableFooter: {
            bold: true,
            fontSize: 12,
            color: 'black'
        }
    });
    
    getTableLayout = () => ({
        hLineWidth: (i, node) => 0.5,
        vLineWidth: (i, node) => 0.5,
        paddingLeft: (i, node) => 4,
        paddingRight: (i, node) => 4
    });

    render() {
        let contents = null;
        if (this.state.loading) {
            contents = (<CustomLoading />);
        } else {
            const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
            const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
            const currentRecords = this.state.data.slice(indexOfFirstRecord, indexOfLastRecord);
            const nPages = Math.ceil(this.state.data.length / this.state.itemsPerPage);

            contents = (
                <div className="divisions-table report-table-container">
                    <div className="report-toolbar" style={{display: "flex", alignItems: "center"}}>
                        <CustomDateRangePicker fromDate={this.state.fromDate} toDate={this.state.toDate} setFromDate={this.setFromDate} setToDate={this.setToDate} />
                        <div className={"divisions-dropdown-container"} style={{marginLeft:"40px"}}>
                            <label className="division-label">Division:</label>
                            <CustomDropdown 
                                options={this.state.divisions.map(cc => cc.name)} 
                                selectedOption={this.state.selectedDivision.name} 
                                setSelectedOption={this.setSelectedDivsion}/>
                        </div>
                        <label className="divisions-deactivated-employees">
                        <input
                            type="checkbox"
                            checked={this.state.showInactiveEmployees}
                            onChange={this.toggleInactiveEmployees}
                            style={{marginRight:"10px"}}
                            />
                            Show deactivated employees
                        </label>
                        {this.state.data.length !== 0 ?
                        <div className="export-button-container">
                            <Button variant="primary" className="export-to-pdf" onClick={this.exportToPdf}>
                                <span className="pdf-text">Export to PDF</span>
                            </Button>
                            <CSVLink data={this.state.dataToExport} filename={"Divisions_Report.csv"} className="export-button">
                                <Button variant="primary" className="csv-button export-to-csv">
                                    <span className="csv-text">Export to CSV</span>
                                </Button>
                            </CSVLink>
                        </div> : null}
                    </div>
                    {this.state.data.length > 0 ?(
                    <>
                    <Table hover className="report-table">
                        <thead>
                            <tr>
                                <th className="table-text-content">Last Name</th>
                                <th className="table-text-content">First Name</th>
                                <th className="table-text-content">Email</th>
                                <th className="table-text-content">Status</th>
                                <th className="table-text-content">Division</th>
                                <th>
                                    HSA Total
                                    <CustomToolTip
                                        header="HSA Total"
                                        bodyContent="Claim amount including taxes and admin fees."
                                    />
                                </th>
                                <th>
                                    LSA Total
                                    <CustomToolTip
                                        header="LSA Total"
                                        bodyContent="Claim amount including taxes and admin fees."
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                                {currentRecords.map((emp, index) => 
                                (
                                    <tr key={index} style={emp.isActive?{backgroundColor:"white"}:{backgroundColor:"pink"}}>
                                        <td className="table-text-content">{emp.lastName}</td>
                                        <td className="table-text-content">{emp.firstName}</td>
                                        <td className="table-text-content">{emp.email}</td>
                                        <td className="table-text-content">{emp.isActive? "Active":"Inactive"}</td>
                                        <td className="table-text-content">{emp.divisionName}</td>
                                        <td>${emp.hsaTotal}</td>
                                        <td>${emp.lsaTotal}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={5} className="table-footer-item total-amount">Total</td>
                                    <td className="table-footer-item">${this.state.totalHSAAmount}</td>
                                    <td className="table-footer-item">${this.state.totalLSAAmount}</td>
                                </tr>
                            </tfoot>
                    </Table>
                        <div className="pagination-container">
                            <div className="pagination-info">
                                <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord+currentRecords.length} entries out of {this.state.data.length} entries</span>
                            </div>
                            <CustomPagination
                                nPages={nPages} 
                                currentPage={this.state.currentPage} 
                                setCurrentPage={this.setCurrentPage} 
                            />
                        </div> 
                    </>) : ( <EmptyStateTemplate /> )}
                </div>
            );
        }
        return (<>{contents}</>);
    }
}