import { makeComponent } from '../core';
import React from 'react';

export const Text = makeComponent( class extends React.Component<{text:string;size?:string;textStyle?:string;textAlign?:string;color?:string}>{
    render(){
        let textElem = toDisplay(this.props.text);
      
        let className = ['RT-Text'];
        switch (this.props.textAlign) {
            case "left": className.push("text-left"); break;
            case "center": className.push("text-center"); break;
            case "right": className.push("text-right"); break;
        }
        if (this.props.textStyle){
            className.push("RT-Text--" + this.props.textStyle);
        }

        if (this.props.color){
            className.push("RT-Text--" + this.props.color);
        }
        if(this.props.size){
            className.push("RT-Text--" + this.props.size);
        }
        return <span className={className.join(' ')}>{textElem}</span>
    }
});

function toDisplay(value: any) {
    if (value && typeof (value) !== "string") {
        return (value as any).toString();
    }
    return value;
}